import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const PREPROD = { id: 'PREPROD', name: __('PREPROD') };
const PROD = { id: 'PROD', name: __('PROD') };
const TST = { id: 'TEST', name: __('TEST') };
const STAGE = { id: 'STAGE', name: __('STAGE') };

const TYPES = [PREPROD, PROD, TST, STAGE];

const ENV_OPTIONS = standardFieldOptionMapper(undefined, TYPES);

export default ENV_OPTIONS;
