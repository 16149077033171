import _get from 'lodash/get';
import _size from 'lodash/size';
import { EMPTY_ARRAY, EMPTY_STRING } from 'tbase/app.constants';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { getFiltersWithOutNullValue } from 'tcomponents/organisms/filterSection/utils/filterSection.utils';
import ACTION_TYPES from '../constant/addTestCasesList.actionTypes';
import { getTestCaseByProductId, postAddTestCases, searchtestCaseFilter } from '../../../services/testSessions.services';
import {
  addTestCase, getTestCaseDTO, filterDTO, paginationDTO, paginationDTOwithoutFilter, addTestCaseForJiraIssue,
} from './addTestCaseList.request';

const handleSuccessUpdate = (response, setState) => {
  const statusCode = _get(response, 'data.status');
  setState({
    isAddTestCaseLoading: false,
    selection: [],
  })
  if (statusCode === 'Success') {
    toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
  }
};

const handleTableItemsFetch = async ({ setState, getState }) => {
  const {
    match, pageSize, currentPage, selectedFilters,
  } = getState();
  const productId = _get(match, 'params.productId');
  const projectId = _get(match, 'params.projectId');
  const testCaseDTO = getTestCaseDTO(productId, projectId, pageSize, currentPage, selectedFilters);
  setState({ isLoading: true });
  const responseData = await getTestCaseByProductId(testCaseDTO);
  const testCaseData = _get(responseData, 'data.data.hits');
  const countTestcase = _get(responseData, 'data.data.count');
  setState({
    totalCount: countTestcase,
    data: testCaseData,
    isLoading: false,
  });
};

const handleItemsSetFilter = async ({ params, getState, setState }) => {
  const {
    searchText, selectedTabId, sortDetails, pageSize, currentPage,
  } = getState();
  const { match } = getState();
  const productId = _get(match, 'params.productId');
  const projectId = _get(match, 'params.projectId');
  const { value } = params;
  const selectedFilters = getFiltersWithOutNullValue(value);
  setState({ isLoading: true });
  const filterReuestDTO = getTestCaseDTO(productId, projectId, pageSize, currentPage, selectedFilters);
  const searchData = await searchtestCaseFilter(filterReuestDTO);
  const testCaseData = _get(searchData, 'data.data.hits');
  const countTestCase = _get(searchData, 'data.data.count');

  setState({
    totalCount: countTestCase,
    data: testCaseData,
    selectedFilters,
    isLoading: false,
  });
};

const handleTableItemSelect = ({ params, setState }) => {
  const { value: selection } = params;
  setState({ selection });
};

const handleAddTestCases = ({ getState, setState, params }) => {
  const { selection, history, match } = getState();
  const testSessionId = _get(match, 'params.testsessionId');
  const jiraKey = _get(history, ['location','value','key'], EMPTY_STRING);
  toaster(TOASTER_TYPE.INFO, __('Checking with the Backend...Please wait for a while'));
  let addTestCaseDTO;
  if(jiraKey === EMPTY_STRING) {
    addTestCaseDTO = addTestCase(selection, testSessionId);
  } else {
    addTestCaseDTO = addTestCaseForJiraIssue(selection, testSessionId, jiraKey);
  }
  setState({
    isAddTestCaseLoading: true,
  })
  postAddTestCases(addTestCaseDTO).then(response => handleSuccessUpdate(response, setState));
};

const handlePagination = async ({ params, setState, getState }) => {
  const {
    match, selectedFilters,
  } = getState();
  const productId = _get(match, 'params.productId');
  const projectId = _get(match, 'params.projectId');
  const { value } = params;
  const { page: currentPage, resultsPerPage: pageSize } = value;
  if (selectedFilters === undefined) {
    const pagination = getTestCaseDTO(productId, projectId, pageSize, currentPage, selectedFilters);
    setState({ isLoading: true });
    const responseData = await getTestCaseByProductId(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  } else {
    const pagination = getTestCaseDTO(productId, projectId, pageSize, currentPage, selectedFilters);
    setState({ isLoading: true });
    const responseData = await getTestCaseByProductId(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  }
};


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEM_SELECT]: handleTableItemSelect,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleItemsSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePagination,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [ACTION_TYPES.ADD_TEST_CASES]: handleAddTestCases,
};

export default ACTION_HANDLERS;
