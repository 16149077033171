import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import produce from 'immer';
import _set from 'lodash/set';
import _get from 'lodash/get';

import FORM_PAGE_ACTION_TYPES from 'tcomponents/pages/formPage/constants/actionTypes';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import ACTION_TYPES from '../constant/testCasesForm.actionTypes';
import { addTestCaseToJira } from '../../../services/testCases.services';


const handleOnFormInit = () => {
  console.log('handle form init');
};

const handleTestCaseSubmissionError = () => {
  toaster(TOASTER_TYPE.ERROR, __('Some Error occured in editing Testcase'));
};

const handleFieldChange = ({ params, setState, getState }) => {
  console.log(params);
  const { id, value } = params;
  const { formValues } = getState();
  setState({ formValues: { ...formValues, [id]: value } });
  console.log(formValues);
};

const handleFormSubmit = async ({ params, setState, getState }) => {
  const { values } = getState();
  const {
    formValues,
    onSubmit,
    history,
  } = getState();
  try {
    await onSubmit(formValues);
  } catch (error) {
    handleTestCaseSubmissionError(error);
  }
};

const handleRichtext = ({ params, getState, setState }) => {
  const { id, updatedEditorValue } = params;
  const { formValues } = getState();
  setState({ formValues: { ...formValues, [id]: updatedEditorValue } });
};

const handleAddJiraToTestCaseOnCancel = ({ setState }) => {
  setState({
    showAddTestCaseToJira: false,
  });
}

const handleAddJiraToTestCaseOnSubmit = async ({ params, getState, setState }) => {
  console.log("getState",getState());
  toaster(TOASTER_TYPE.INFO, __('Please Wait ... Adding the jira id'));
  const { testCaseId} = getState();
  const{formValues } =params;
  const jiraId = formValues.jira;
  const id = testCaseId;
  try{
     const response = await addTestCaseToJira(jiraId, id)
     if(response && response.status === 200){
       toaster(TOASTER_TYPE.SUCCESS, __('Successfully changed the Jira Issue'));
     }
     setState({
      showAddTestCaseToJira: false,
      isAddTestCaseToJiraButtonLoading: false
    });
    }catch (error) {
      HandleErrorAndStatus(error);
    }
   // window.location.reload();
};

const handleAddJiraToTestCaseOnClick = ({ setState }) => {
  setState({
    showAddTestCaseToJira: true,
  });
}

export const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleOnFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  // [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleRichtext,
  [ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_CANCEL]: handleAddJiraToTestCaseOnCancel,
  [ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_SUBMIT]: handleAddJiraToTestCaseOnSubmit,
  [ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_CLICK]: handleAddJiraToTestCaseOnClick
};
