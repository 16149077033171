import axios from 'axios';
import _split from "lodash/split";
import CookieHelper from '@tekion/tap-components/utils/CookieHelper';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import HTTPClient from '@tekion/tekion-base/utils/httpClient';
import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

const contentType = 'application/json; charset=UTF-8';

const { TRR_API_URL } = process.env;
const { TRR_API_KEY } = process.env;
const { TRR_APP_KEY } = process.env;

export const fetchURL = url => fetch(
  url,
  Object.assign({}, {
    headers: {
      Accept: contentType,
      'tap-api-token': TokenManager.accessTokenKey,
      email: TokenManager.getItem('email'),
      locale: TokenManager.getItem('locale'),
    },
  }),
);


/**
 * @description Function to process the response based on the response status
 * If response is ok it process the JSON else rejects the Promise
 * @param {Object} - res - JSON response object.
 * @returns {Object} - response - Resolved promise object.
 */
export const processResponse = (res) => {
  let response = null;
  if (res.ok) {
    response = res.json();
  } else {
    response = Promise.reject(res);
  }
  return response;
};

/**
 * @description Sending a GET request to JSON API.
 * doGet method resolves or rejects the promise that is obtained
 * from the fetchURL method
 * @param {string} url
 * @returns {object}
 */
export const doGet = url => fetchURL(url).then(res => processResponse(res));

/**
* @description Sending a POST request to JSON API.
* doPost method resolves or rejects the promise that is obtained
* from the fetchURL method
* @param {string} url
* @param {object} data
* @returns {object}
*/
export const doPost = (url, data) => {
  const header = {
    'Content-Type': contentType,
    'tap-api-token': TokenManager.accessTokenKey,
    locale: TokenManager.getItem('locale'),
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'POST', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doPut = (url, data) => {
  const header = {
    'Content-Type': contentType,
   'tap-api-token': TokenManager.accessTokenKey,
    locale: TokenManager.getItem('locale'),
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'PUT', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doDelete = (url, data) => {
  const header = {
    'Content-Type': contentType,
    'tap-api-token': TokenManager.accessTokenKey,
    locale: TokenManager.getItem('locale'),
  };
  const dataObj = JSON.stringify(data);
  return fetch(url,
    Object.assign({}, { method: 'DELETE', headers: header, body: dataObj }))
    .then(res => processResponse(res));
};

export const doAxiosGet = (baseUrl, timeout) => axios.create({
  baseURL: baseUrl,
  timeout: timeout || '0',
  headers: {
    'Content-Type': 'application/json',
  },
})();

const userName = _split(TokenManager.getItem('email'), '@')[0] || 'cqauser';

const createHTTP = (baseUrl, headers) => axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': TRR_API_KEY,
    // 'userName': userName,
    // 'app-key': TRR_APP_KEY,
    ...headers,
  },
});

// const mailIdDetails = { locale: getCookie('language') };
// const authenticationHeader = () => ({ locale: getCookie('language'), access_token: getCookie('access_token') });
// const headerWithLocale = { locale: getCookie('language') };
const authenticationHeader = () => ({ Authorization: `Bearer ${TokenManager.getItem(TokenManager.accessTokenKey)}`});

const instance = {};

const URL_TYPES_VS_TEKION_SERVICES = {
  // LOCAL: 'api/',
  // TAP: 'api/',
  // EXTERNAL: 'api/',
  // GET_TAP_WITH_MAIL: 'api/',
  CQA: '/cqa/',
};

const URL_TYPES_VS_HEADERS = {
  // TAP: authenticationHeader,
  // EXTERNAL: () => headerWithLocale,
  // GET_TAP_WITH_MAIL: () => mailIdDetails,
  // LOCAL: () => headerWithLocale,
  CQA: authenticationHeader,
};

export const setDefaultModuleServices = () => {
  const cqa = new Http('CQA', {url : TRR_API_URL}); // eslint-disable-line
//   const local = new Http('LOCAL', { url: 'http://localhost:5000/' }); // eslint-disable-line
//   const tap = new Http('TAP', { url: 'http://localhost:5000/' }); // eslint-disable-line
//   const external = new Http('EXTERNAL', { url: 'http://localhost:5000/' }); // eslint-disable-line
//   const tapWithEmail = new Http('GET_TAP_WITH_MAIL', { url: 'http://localhost:5000/' }); // eslint-disable-line
};

// export const removeDefaultModuleServices = () => {
//   instance = {};
// };

const getHeaders = (moduleType) => {
  const getHeaderFunc = URL_TYPES_VS_HEADERS[moduleType] || URL_TYPES_VS_HEADERS.CDMS;
  return getHeaderFunc();
};

class Http {
  constructor(moduleType, { url, headers } = EMPTY_OBJECT) {
    instance[moduleType] = createHTTP(`${url}${URL_TYPES_VS_TEKION_SERVICES[moduleType]}`, { ...headers });
  }

  static get(moduleType, url, data) {
    return instance[moduleType]({ method: 'GET', headers: { ...getHeaders(moduleType) }, url, params: data }); // eslint-disable-line
  }

  static post(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'POST', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }

  static put(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'PUT', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }

  static patch(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'PATCH', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }

  static delete(moduleType, url, data, query) {
    return instance[moduleType]({ method: 'DELETE', headers: { ...getHeaders(moduleType) }, url, data, params: query }); // eslint-disable-line
  }
}

const init = () => {
  HTTPClient.getInstance().interceptors.response.use(
    response => response.data
  );
};

init();

export default Http;
