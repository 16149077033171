import React from 'react';

import _map from 'lodash/map';

import Label from 'tcomponents/atoms/Label';
import Icon from 'tcomponents/atoms/FontIcon';

import styles from './iconInfoList.module.scss';
import { INFO_ICONS, TEST_SESSION_SOURCE_VS_ICONS, INFO_ICON_SOURCE_VS_LABEL } from "../../constant/infoIconListMap";

const iconInfoList = () => {
    return(
        <div className={styles.containerDiv}>
            {_map(INFO_ICONS, (icon,index) => {
            if(index === INFO_ICONS.JIRA)  {
            return(
                <Label className={styles.label}>
                    <div>
                        <Icon>
                            {TEST_SESSION_SOURCE_VS_ICONS[icon]}
                        </Icon>
                    </div>
                    <div className="m-r-4">
                        - {INFO_ICON_SOURCE_VS_LABEL[icon]}
                    </div>
                </Label>
            )} else {
                return(
                    <Label className={styles.label}>
                        <div>
                            <Icon>
                                {TEST_SESSION_SOURCE_VS_ICONS[icon]}
                            </Icon>
                        </div>
                        <div className="m-r-4">
                            - {INFO_ICON_SOURCE_VS_LABEL[icon]} |
                        </div>
                    </Label>
                )}
            })}
        </div>
);
}

export default iconInfoList;
