import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const SMOKE = { id: 'SMOKE', name: __('Smoke') };
const SANITY = { id: 'SANITY', name: __('Sanity') };
const REGRESSION = { id: 'REGRESSION', name: __('Regression') };
const ADHOC = { id: 'AD_HOC', name: __('Adhoc') };

const TYPES = [SMOKE, SANITY, REGRESSION, ADHOC];

const TYPE_OPTIONS = standardFieldOptionMapper(undefined, TYPES);

export default TYPE_OPTIONS;
