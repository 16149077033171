import {
  TEST_CASE_NAME, AUTHOR, PRIORITY, COMPONENT, JIRA,
  BRANCH, DESCRIPTION, EXIT_CRITERIA, RELEASE, TYPE,
  ACTIVE, AUTOMATED, REGRESSION, MISC, STEPS, PRECONDITIONS,
  TCCATEGORY, UPSTREAMCONSUMPTION, DOWNSTREAMCONSUMPTION, HORIZONTALIMPLEMENTATION,
} from './testCasesForm.fields';

export const FORM_CONFIG = {
  [TEST_CASE_NAME.id]: TEST_CASE_NAME,
  [AUTHOR.id]: AUTHOR,
  [PRIORITY.id]: PRIORITY,
  [COMPONENT.id]: COMPONENT,
  [JIRA.id]: JIRA,
  [BRANCH.id]: BRANCH,
  [DESCRIPTION.id]: DESCRIPTION,
  [EXIT_CRITERIA.id]: EXIT_CRITERIA,
  [RELEASE.id]: RELEASE,
  [TYPE.id]: TYPE,
  [ACTIVE.id]: ACTIVE,
  [AUTOMATED.id]: AUTOMATED,
  [PRECONDITIONS.id]: PRECONDITIONS,
  [REGRESSION.id]: REGRESSION,
  [MISC.id]: MISC,
  [STEPS.id]: STEPS,
  [TCCATEGORY.id]: TCCATEGORY,
  [UPSTREAMCONSUMPTION.id]: UPSTREAMCONSUMPTION,
  [DOWNSTREAMCONSUMPTION.id]: DOWNSTREAMCONSUMPTION,
  [HORIZONTALIMPLEMENTATION.id]: HORIZONTALIMPLEMENTATION,

};
