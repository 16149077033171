import _get from 'lodash/get';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';

import ACTION_TYPES
  from '../constants/moveTestCaseForm.actionTypes';


const handleFieldChange = ({ params, getState, setState }) => {
  const id = _get(params, 'id', EMPTY_STRING);
  const value = _get(params, 'value', EMPTY_ARRAY);
  const { formValues } = getState();
  if (id === 'project') {
    setState({
      formValues: {
        ...formValues,
        project: value,
      },
    });
  } else if (id === 'module') {
    setState({
      formValues: {
        ...formValues,
        module: value,
      },
    });
  } else {
    setState({
      formValues: {
        ...formValues,
        subModule: value,
      },
    });
  }
};

const handleOnFormSubmit = ({ getState }) => {
  const { formValues, onSubmit } = getState();
  console.log(getState());
  onSubmit(formValues);
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleOnFormSubmit,
};

export default ACTION_HANDLERS;
