const ACTION_TYPES = {
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
  ROW_BULK_ACTION: 'ROW_BULK_ACTION',
  ROW_ACTION: 'ROW_ACTION',
  ON_DOWNLOAD_REPORT: 'ON_DOWNLOAD_REPORT',
  UPLOAD_TEST_CASE_MODAL_ON_CANCEL: 'UPLOAD_TEST_CASE_MODAL_ON_CANCEL',
  UPLOAD_TEST_CASE_MODAL_ON_SUBMIT: 'UPLOAD_TEST_CASE_MODAL_ON_SUBMIT',
};

export default ACTION_TYPES;
