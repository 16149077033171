// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const ACTIVE = {
  name: __('Active'),
  id: 'ACTIVE',
};
const INACTIVE = {
  name: __('Inactive'),
  id: 'INACTIVE',
};

export const STATUS_COLOR_MAP = {
  [ACTIVE.id]: COLOR_MAP.GREEN,
  [INACTIVE.id]: COLOR_MAP.RED,
};

export const STATUS_LABEL_MAP = {
  [ACTIVE.id]: ACTIVE.name,
  [INACTIVE.id]: INACTIVE.name,

};

const STATUSES = [ACTIVE, INACTIVE];

export const STATUS_OPTIONS = standardFieldOptionMapper(undefined, STATUSES);

export default STATUSES;
