import {
  PRODUCT, PROJECT, WORKFLOW_SCREEN, ATTACHED_DESIGN, MODULES_AFFECTED, AFFECTED_UPSTREAM_SERVICES,
  AFFECTED_DOWNSTREAM_SERVICES, JIRA_LINKS, INFO, ANY_CHANGE_IN_SCHEMA, ANY_DEALER_PROPERTY_TO_BE_SET,
  ANY_RESTRICTED_API_NEED_TO_SETUP, ANY_PERMISSIONS_ASSOCIATED, AREA_OF_REGRESSION, AUTOMATED,
} from './createTestPlan.fields';

export const FORM_CONFIG = {
  [PRODUCT.id]: PRODUCT,
  [PROJECT.id]: PROJECT,
  [WORKFLOW_SCREEN.id]: WORKFLOW_SCREEN,
  [INFO.id]: INFO,
  [ATTACHED_DESIGN.id]: ATTACHED_DESIGN,
  [MODULES_AFFECTED.id]: MODULES_AFFECTED,
  [AFFECTED_UPSTREAM_SERVICES.id]: AFFECTED_UPSTREAM_SERVICES,
  [AFFECTED_DOWNSTREAM_SERVICES.id]: AFFECTED_DOWNSTREAM_SERVICES,
  [JIRA_LINKS.id]: JIRA_LINKS,
  [ANY_CHANGE_IN_SCHEMA.id]: ANY_CHANGE_IN_SCHEMA,
  [ANY_DEALER_PROPERTY_TO_BE_SET.id]: ANY_DEALER_PROPERTY_TO_BE_SET,
  [ANY_RESTRICTED_API_NEED_TO_SETUP.id]: ANY_RESTRICTED_API_NEED_TO_SETUP,
  [ANY_PERMISSIONS_ASSOCIATED.id]: ANY_DEALER_PROPERTY_TO_BE_SET,
  [AREA_OF_REGRESSION.id]: AREA_OF_REGRESSION,
  [AUTOMATED.id]: ANY_DEALER_PROPERTY_TO_BE_SET,
};
