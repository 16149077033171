import React from 'react';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';


export const makeTableProps = ({
  isLoading,
  selection,
  tableData,
  totalCount,
  onAction,
  sortDetails,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: 8,
  resultsPerPage: 8,
  totalNumberOfEntries: totalCount,
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
});
