// Libraries
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ViewTestPlan from './organisms/ViewTestPlan/ViewTestPlan';
import EditTestPlan from './organisms/EditTestPlan/EditTestPlan';
import CreateTestPlan from './organisms/CreateTestPlan/CreateTestPlan';

// Components

const TestPlan = (props) => {
  const { match } = props;
  const { url: matchUrl } = match;
  return (
    <Switch>
      <Route path={`${matchUrl}/list`} component={ViewTestPlan} />
      <Route path={`${matchUrl}/edit`} component={EditTestPlan} />
      <Route path={`${matchUrl}/create`} component={CreateTestPlan} />
    </Switch>
  );
};

TestPlan.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TestPlan;
