// utils
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

// helpers
import makeTestCaseRequestDTO from '../../../helpers/testCasesCreate.requests';

// services
import { createTestCase } from '../../../services/testCases.services';

const handleAddTestCaseSuccess = () => {
  toaster(TOASTER_TYPE.SUCCESS, __('Test Case Created Successfully'));
};

const createTestCaseSubmit = async ({ formValues, history }) => {

  toaster(TOASTER_TYPE.INFO, __('Creating testCases..Please wait for a while'));

  const testCaseRequestDTO = makeTestCaseRequestDTO(formValues);
  try {
    await createTestCase(testCaseRequestDTO);
    handleAddTestCaseSuccess();
    history.push('/tcmsuiV2/testcase/list');
  } catch (errors) {
    toaster(TOASTER_TYPE.ERROR, __('Some Error Occured..Test Case Not Created'));
  }
};

export default createTestCaseSubmit;
