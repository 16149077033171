export const addTestCase = (selection, testSessionId )=> ({
  jqlFlag: false,
  testSessionId: testSessionId,
  testCaseIdList: selection,
  status: 'PENDING',
  createdBy: 'test',
});

export const addTestCaseForJiraIssue = (selection, testSessionId, jiraKey)=> ({
  jqlFlag: true,
  jiraIssueKey: jiraKey,
  testSessionId: testSessionId,
  testCaseIdList: selection,
  status: 'PENDING',
  createdBy: 'test',
});

export const getTestCaseDTO = (productId, projectId, pageSize, currentPage, selectedFilters) => {
  const DEFAULT_PRODUCT_BODY = {
    type: 'product_id',
    operator: 'IN',
    values: [
      productId,
    ],
  };

  const DEFAULT_PROJECT_BODY = {
    type: 'project_id',
    operator: 'IN',
    values: [
      projectId,
    ],
  };

  const objectBody = ({
    filter: [DEFAULT_PRODUCT_BODY, DEFAULT_PROJECT_BODY, ...selectedFilters],
    size: pageSize,
    page: currentPage,
  });

  return objectBody;
};

// export const filterDTO = (value, productId, projectId, pageSize, currentPage) => {
//   const filterValue = [...value];
//   const DEFAULT_PRODUCT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       productId,
//     ],
//   };

//   const DEFAULT_PROJECT_BODY = {
//     type: 'project_id',
//     operator: 'IN',
//     values: [
//       projectId,
//     ],
//   };

//   filterValue.push(DEFAULT_PRODUCT_BODY);
//   filterValue.push(DEFAULT_PROJECT_BODY);


//   const objectBody = ({
//     filter: filterValue,
//     size: pageSize,
//     page: currentPage,
//   });

//   return objectBody;
// };

// export const paginationDTO = (page, resultsPerPage, productId, projectId, selectedFilters) => {
//   const DEFAULT_PRODUCT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       productId,
//     ],
//   };

//   const DEFAULT_PROJECT_BODY = {
//     type: 'project_id',
//     operator: 'IN',
//     values: [
//       projectId,
//     ],
//   };


//   const objectBody = ({
//     filter: [...selectedFilters, DEFAULT_PRODUCT_BODY, DEFAULT_PROJECT_BODY],
//     size: resultsPerPage,
//     page,
//   });

//   return objectBody;
// };

// export const paginationDTOwithoutFilter = (page, resultsPerPage, productId, projectId, selectedFilters) => {
//   const DEFAULT_PRODUCT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       productId,
//     ],
//   };

//   const DEFAULT_PROJECT_BODY = {
//     type: 'project_id',
//     operator: 'IN',
//     values: [
//       projectId,
//     ],
//   };

//   const objectBody = ({
//     filter: [DEFAULT_PRODUCT_BODY, DEFAULT_PROJECT_BODY, ...selectedFilters],
//     size: resultsPerPage,
//     page,
//   });

//   return objectBody;
// };
