import React, { useCallback } from 'react';
import _noop from "lodash/noop";

import Modal from 'tcomponents/molecules/Modal';

import ExecutionScreen from "../../pages/ExecutionScreen";

const executionScreenModal = ({
    executionId,
    isExecutionScreenModalVisible,
    onCancel=_noop
}) => {

    return (
        <Modal
            title={__('Execution Screen')}
            hideSubmit={true}
            // submitBtnText={__('Bulk Update')}
            visible={isExecutionScreenModalVisible}
            // onSubmit={handleSubmit}
            onCancel={onCancel}
            width={Modal.SIZES.XXL}
            destroyOnClose
        >
            <ExecutionScreen executionId={executionId}/>
        </Modal>
    );
};

export default executionScreenModal;
