// Lodash
import _property from 'lodash/property';

const id = _property('id');
const name = _property('name');
const createdByUserId = _property('createdByUserId');
const createdTime = _property('createdTime');
const dealerId = _property('dealerId');
const info = _property('info');
const modifiedByUserId = _property('modifiedByUserId');
const modifiedTime = _property('modifiedTime');
const tenantId = _property('tenantId');
const countTestCase = _property('countTestCase');
const hasTest = _property('hasTest');
const projectId = _property('projectId');
const productId = _property('productId');
const featureId = _property('moduleId');
const subFeatureId =_property('subModuleID');
const READER = {
  id,
  name,
  createdByUserId,
  createdTime,
  dealerId,
  info,
  modifiedByUserId,
  modifiedTime,
  tenantId,
  countTestCase,
  hasTest,
  projectId,
  productId,
  featureId,
  subFeatureId,
};

export default READER;
