import _get from 'lodash/get';
import { ACTION_TYPES } from '../constants/navigationBar.actionTypes';

export const handleNavigationActions = (history, key) => {
  const action = _get(key, 'key');

  if (action == ACTION_TYPES.TESTCASES) {
    history.push('/tcmsuiV2/testcase/list');
  }
  if (action == ACTION_TYPES.TESTSESSION) {
    history.push('/tcmsuiV2/testsession/list');
  }
  if (action == ACTION_TYPES.AUTOMATION_PROGRESS) {
    history.push('/tcmsuiV2/executionprogress');
  }
  if (action == ACTION_TYPES.UPLOAD_STATUS) {
    history.push('/tcmsuiV2/uploadstatus');
  }
  if (action == ACTION_TYPES.RELEASE_BOARD) {
    history.push('/tcmsuiV2/releaseBoard');
  }
  if (action == ACTION_TYPES.HISTORY_COLLECTION) {
    history.push('/tcmsuiV2/historycollection');
  }
  if (action === ACTION_TYPES.JIRA_ISSUES) {
    history.push('/tcmsuiV2/jiraissues/list')
  }
  // if (action == ACTION_TYPES.HOME_BUTTON) {
  //   // window.history.push('/cqaV2/app/home');
  //   console.log(window);
  // }
};
