import React from 'react';
import _constant from 'lodash/constant';
import _size from 'lodash/size';
import _map from 'lodash/map';


import Button from '@tekion/tekion-components/src/atoms/Button';
import Menu from '@tekion/tekion-components/src/molecules/Menu';
import Dropdown from '@tekion/tekion-components/src/molecules/DropDown';
import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';

export const makeTableProps = ({
  isLoading,
  selection,
  totalCount,
  tableDataForSanity,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: tableDataForSanity.length,
  resultsPerPage: tableDataForSanity.length,
  totalNumberOfEntries: tableDataForSanity.length,
  type: TABLE_TYPES.FIXED_COLUMN,
  showPagination: false,
});
