import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';

import _keyBy from 'lodash/keyBy';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const PROJECT_NAME = {
  id: 'projectName',
  key: 'projectName',
  accessor: 'projectName',
  Header: 'ProjectName',
  Cell: TextRenderer,
  sortable: true,
};

const IS_HEALTHY = {
  id: 'isHealthyString',
  key: 'isHealthyString',
  accessor: 'isHealthyString',
  Header: 'IsHealthy',
  Cell: TextRenderer,
  sortable: true,
};

const LAST_RUN_DATE_TIME = {
  id: 'lastRunDateTime',
  key: 'lastRunDateTime',
  accessor: 'lastRunDateTime',
  Header: 'Last Run Date Time',
  Cell: TextRenderer,
};

const LAST_TEST_SESSION_ID = {
  id: 'lastTestSessionId',
  key: 'lastTestSessionId',
  accessor: 'lastTestSessionId',
  Header: 'Last Test Session Id',
  Cell: TextRenderer,
  sortable: true,
};
const LAST_TEST_SESSION_NAME = {
  id: 'lastTestSessionName',
  key: 'lastTestSessionName',
  accessor: 'lastTestSessionName',
  Header: 'Last Test Session Name',
  Cell: TextRenderer,
  sortable: true,
};
const LAST_USED_RELEASE_VERSION_TAG = {
  id: 'lastUsedReleaseVersionTag',
  key: 'lastUsedReleaseVersionTag',
  accessor: 'lastUsedReleaseVersionTag',
  Header: 'Last Used Release Version Tag',
  Cell: TextRenderer,
  sortable: true,
};
const TOTAL_ELAPSED_DAYS = {
  id: 'totalElapsedDays',
  key: 'totalElapsedDays',
  accessor: 'totalElapsedDays',
  Header: 'Total Elapsed Days',
  Cell: TextRenderer,
  sortable: true,
};

export const columns = [PROJECT_NAME, IS_HEALTHY, LAST_RUN_DATE_TIME, LAST_TEST_SESSION_ID, LAST_TEST_SESSION_NAME, LAST_USED_RELEASE_VERSION_TAG, TOTAL_ELAPSED_DAYS];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
