// Libraries
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import AutomationMetricPage from './organisms/AutomationMetricPage';

const AutomationMetric = (props) => {
  const { match } = props;
  const { url: matchUrl } = match;

  return (
    <Switch>
      <Route path={`${matchUrl}/Chart`} component={AutomationMetricPage} />
      <Redirect from="*" to={`${matchUrl}/Chart`} />
    </Switch>
  );
};

AutomationMetric.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AutomationMetric;
