import React, { useEffect, useCallback,useMemo } from 'react';
import SECTIONS from './constants/AddReleaseForm.sections';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import FormBuilder from 'tcomponents/organisms/FormBuilder';
import withActions from 'tcomponents/connectors/withActions';
import ACTION_HANDLERS from './helpers/AddReleaseForm.actionHandlers';
import { FIELDS } from './helpers/AddReleaseForm.formConfig';
// import FormPage from 'tcomponents/pages/formPage';
// import ACTION_TYPES from './constants/AddReleaseForm.actionTypes';
// import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
// import { EMPTY_ARRAY } from 'tbase/app.constants';

const FormWithSubmission = withEventSubmission(FormBuilder);
const AddReleaseForm = (props) => {

  const { onAction, formValues,onCancel} = props;
   const CONTEXT_ID = 'UPLOAD_TESTCASES_FORM';

    return (
    <div>
      <FormWithSubmission
        fields={FIELDS}
        sections={SECTIONS}
        values={formValues}
        onAction={onAction}
          contextId={CONTEXT_ID}
          // onClose={handleCancel}
          // visible={isVisible}
      />
    </div>
  );
}
export default withActions({}, ACTION_HANDLERS)(AddReleaseForm);
