import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { deepClone } from '@tekion/tap-components/utils/helper';
import {
  fetchAllCardsAPI, fetchCardAPI, addCardAPI, deleteCardAPI, updateCardAPI,
} from '../api/CardManagement';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;
const { BASE_URL } = process.env;

/**
 * @description Function to fetch all cards.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const getCards = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { getCards: true } });
    return fetchAllCardsAPI(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getCards: false } });
      dispatch({ type: ACTIONS.FETCH_ALL_CARD_SUCCESS, payload: response.cards });
    }).catch((error) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { getCards: false } });
      dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
    });
  }
);
/**
 * @description Function to fetch individual card details.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const activeCard = (baseUrl, data) => (
  dispatch => fetchCardAPI(baseUrl, data).then((response) => {
    dispatch({ type: ACTIONS.FETCH_CARD_SUCCESS, payload: response });
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_LISTING_ERROR, payload: error });
  })
);
/**
 * @description Function to create New card.
 * @param {String} baseUrl - baseurl to fetch the module.
 */
export const addCard = (baseUrl, data) => (
  (dispatch) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { addCard: true } });
    return addCardAPI(baseUrl, data).then((response) => {
      dispatch({ type: ACTIONS.FETCH_ADD_CARD_SUCCESS, payload: response });
      dispatch(getCards(BASE_URL));
      toaster('success', 'Card added successfully');
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addCard: false } });
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addCardSuccess: true } });
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addCardSuccess: false } });
    }).catch((error) => {
      const { status, msg } = error.response.data;
      if (status === 'Not ok') toaster('error', `${msg}`);
      else toaster('error', `${status} Error occurred while adding card `);
      dispatch({ type: ACTIONS.FETCH_ADD_CARD_SUCCESS, payload: error.response });
      dispatch({ type: ACTIONS.SET_LOADER, payload: { addCard: false } });
    });
  }
);
/**
 * @description Function to delete individual card.
 * @param {String} baseUrl - baseurl to delete the module.
 */
export const deleteCard = (baseUrl, data) => (
  (dispatch, getState) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteCard: true } });
    return deleteCardAPI(baseUrl, data).then(() => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteCard: false } });
      const { CardManagement } = getState();
      const newData = deepClone(CardManagement.allCards);
      const indexToDelete = newData.findIndex(item => item.appKey === data.appKey);
      newData.splice(indexToDelete, 1);
      dispatch({ type: ACTIONS.FETCH_ALL_CARD_SUCCESS, payload: newData });
      toaster('success', 'Card deleted successfully');
    }).catch((error) => {
      toaster('error', `${error.status} Error occurred while deleting card`);
      dispatch({ type: ACTIONS.SET_LOADER, payload: { deleteCard: false } });
    });
  }
);
/**
 * @description Function to update individual card.
 * @param {String} baseUrl - baseurl to update the module.
 */
export const updateCard = (baseUrl, data) => (
  (dispatch, getState) => {
    dispatch({ type: ACTIONS.SET_LOADER, payload: { updateCard: true } });
    return updateCardAPI(baseUrl, data).then((response) => {
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateCard: false } });
      const { CardManagement } = getState();
      const newData = deepClone(CardManagement.allCards);
      const updatedIndex = newData.findIndex(item => item.appKey === data.appKey);
      newData[updatedIndex] = {
        ...newData[updatedIndex],
        label: data.label,
        slabel: data.slabel,
        path: data.path,
        roleKey: data.roleKey,
      };
      dispatch({ type: ACTIONS.FETCH_UPDATE_CARD_SUCCESS, payload: response });
      dispatch({ type: ACTIONS.FETCH_ALL_CARD_SUCCESS, payload: newData });
      dispatch({ type: ACTIONS.FETCH_CARD_SUCCESS, payload: newData });
      toaster('success', 'Card updated successfully');
    }).catch((error) => {
      toaster('error', `${error.status} Error occurred while updating card`);
      dispatch({ type: ACTIONS.SET_LOADER, payload: { updateCard: false } });
    });
  }
);
