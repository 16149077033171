// Constants
import { PRODUCT, PROJECT, FEATURE,SUBFEATURE } from './uploadTestCasesForm.fields';

const FORM_CONFIG = {
  [PRODUCT.id]: PRODUCT,
  [PROJECT.id]: PROJECT,
  [FEATURE.id]: FEATURE,
  [SUBFEATURE.id]: SUBFEATURE,
};

export default FORM_CONFIG;
