import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'antd';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import FontIcon from 'tcomponents/atoms/FontIcon';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'tcomponents/molecules/Select';
import Button from 'tcomponents/atoms/Button';
import TextInput from 'tcomponents/molecules/TextInput';
import Avatar from 'tcomponents/atoms/Avatar';
import Loader from 'tcomponents/molecules/loader';
import { fetchGetUserDetails, updateUserDetails, resetAPISuccessEvent } from '../../../action/Profile.action';
import { makeUserDetails, makeFetchApiStatus } from './Profile.selector';
import { loader } from '../Calendar/Calendar.selector';
import './Profile.scss';

const { INTL_ENABLE } = process.env;

const dateFormat = 'MM/DD/YYYY';
// hardcoded language options will be removed after API integration.
const languageOptions = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'French',
    value: 'fr',
  },
];
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      profile_picture: '',
      preferred_language: 'en',
    };
  }

  componentDidMount() {
    const { getUserDetails, apiBaseUrl } = this.props;
    getUserDetails(apiBaseUrl);
  }

  static getDerivedStateFromProps(props, state) {
    const { userDetails, isFetchApiSuccess } = props;
    if (userDetails && isFetchApiSuccess) {
      const newState = {};
      const { resetUserDetailAPIEvent } = props;
      resetUserDetailAPIEvent();
      return {
        ...newState,
        ...userDetails,
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { userDetails: prevUserDetails } = prevProps;
    const { userDetails } = this.props;
    if (!_isEmpty(prevUserDetails) && !_isEmpty(userDetails) && !_isEqual(prevUserDetails.preferred_language, userDetails.preferred_language)) {
      this.setUserLanguageEvent(userDetails.preferred_language);
    }
  }

  setUserLanguageEvent = (userLanguage) => {
    const event = new CustomEvent('userLanguageChanged', { detail: userLanguage });
    document.dispatchEvent(event);
  };

  handleMandatoryFields = (e, type) => {
    this.setState({ [type]: e.target.value });
  };

  onChangeLanguage = (val) => {
    this.setState({ preferred_language: val });
  }

  handleSave = () => {
    const { phone, profile_picture: profilePic, preferred_language: lang } = this.state;
    const { updateUserDetailsData, apiBaseUrl } = this.props;
    const payload = { phone, profile_picture: profilePic, preferred_language: lang };
    updateUserDetailsData(payload, apiBaseUrl);
  };

  getImageFile = (e) => {
    const { size } = e.target.files[0];
    const fileZize = size / 1024 / 1024;
    if (fileZize > 1) {
      toaster('error', __('Please upload 1 MB file'));
      return false;
    }
    const reader = new FileReader();
    reader.addEventListener('load', () => this.setState({ profile_picture: reader.result }));
    reader.readAsDataURL(e.target.files[0]);
    return true;
  }

  removeProfilePicture = () => {
    this.setState({ profile_picture: '' });
  }

  createOptions = (option) => {
    const options = option.map((item) => {
      const temp = { ...item };
      temp.label = __(item.label);
      return temp;
    });
    return options;
  }

  render() {
    const { phone, profile_picture: profilePic, preferred_language: language } = this.state;
    const { userDetails, loading } = this.props;
    return (
      <React.Fragment>
        { loading
          ? <Loader />
          : (
            <div className="profile-container">
              <Row>
                <Col xs={24} md={24} sm={24} lg={11}>
                  <Row className="mb24" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Email ID')}</Col>
                    <Col span={12}>{userDetails.email}</Col>
                  </Row>
                  <Row className="mb24" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('First Name')}</Col>
                    <Col span={12}>
                      <TextInput
                        className="formItem"
                        id="firstName"
                        value={userDetails.firstName}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb24" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Last Name')}</Col>
                    <Col span={12}>
                      <TextInput
                        className="formItem"
                        id="lastName"
                        value={userDetails.lastName}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb24" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Phone #')}</Col>
                    <Col span={12}>
                      <TextInput
                        className="formItem"
                        id="phone"
                        value={phone}
                        onChange={e => this.handleMandatoryFields(e, 'phone')}
                      />
                    </Col>
                  </Row>
                  { INTL_ENABLE === 'true' && (
                    <Row className="mb24" align="middle" gutter={16}>
                      <Col xs={24} md={10} sm={24} lg={7}>{__('Language Preference')}</Col>
                      <Col span={12}>
                        <Select
                          showSearch
                          allowClear={false}
                          value={language}
                          onChange={this.onChangeLanguage}
                          options={this.createOptions(languageOptions)}
                          showArrow
                          style={{ minWidth: '13rem' }}
                          dropdownStyle={{ minWidth: '13rem', fontSize: '12px' }}
                          className="language__select"
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="mb24" align="middle">
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <div className="ant-form-item">{`${__('Signed up on')}: ${moment(userDetails.member_since).format(dateFormat)}`}</div>
                    </Col>
                    <Col span={9}></Col>
                  </Row>
                </Col>
                <Col xs={24} md={10} sm={24} lg={12} className="userprofile">
                  <div className="userprofilesection">
                    <Avatar image={profilePic} style={{ backgroundColor: '#ccc' }} name={`${userDetails.firstName} ${userDetails.lastName}`} displayName={false} size={125} />
                    <div className={profilePic ? 'profile' : 'profilecls'}>
                      <label htmlFor="userprofile">
                        <input type="file" id="userprofile" accept="image/x-png,image/gif,image/jpeg" style={{ display: 'none' }} onChange={this.getImageFile} />
                        <FontIcon size={15}>icon-camera</FontIcon>
                      </label>
                      {profilePic && <FontIcon size={15} onClick={this.removeProfilePicture}>icon-trash</FontIcon> }
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        <div className="profile-settings-footer">
          <Button view="primary" onClick={this.handleSave} disabled={loading}>{__('Save')}</Button>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  updateUserDetailsData: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
};

/**
 * @description Map the state objects (as props) that are required to render the details in the UI
 */
const mapStateToProps = createStructuredSelector({
  userDetails: makeUserDetails(),
  isFetchApiSuccess: makeFetchApiStatus(),
  loading: loader(),
});

/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const getUserDetails = bindActionCreators(fetchGetUserDetails, dispatch);
  const updateUserDetailsData = bindActionCreators(updateUserDetails, dispatch);
  const resetUserDetailAPIEvent = bindActionCreators(resetAPISuccessEvent, dispatch);
  return {
    getUserDetails,
    updateUserDetailsData,
    resetUserDetailAPIEvent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
