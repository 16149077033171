import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import produce from 'immer';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import _first from 'lodash/first';
import { EMPTY_OBJECT } from 'tbase/app.constants';
import FORM_PAGE_ACTION_TYPES from 'tcomponents/pages/formPage/constants/actionTypes';
import getCompactList from '../../../../../utils/getCompactList';
import ACTION_TYPES from '../constant/testSessionForm.actionTypes';
import { makeTestSessionRequestDTO } from '../../../helpers/testSessions.request';
import { PRODUCT, PROJECT, RELEASE } from './testsessionForm.fields';
import makeReleaseVersionOptions from '../../../../../constants/releaseVersionOptions';


const handleProductFieldChange = ({
  id, value, formValues, setState, projectListByProductId,
}) => {
  const selectedId = _first(value);
  const projectListOptions = _get(projectListByProductId, selectedId);

  setState({ formValues: { ...formValues, [id]: value, [PROJECT.id]: undefined }, projectListOptions });
};

const handleFieldChange = ({ params, getState, setState }) => {
  const { projectListByProductId } = getState();
  const { id, value } = params;

  const { formValues } = getState();
  if (id === 'product') {
    handleProductFieldChange({
      id, value, formValues, setState, projectListByProductId,
    });
  } else {
    const {setNewFormValues} = getState();
    let updatedFormValues = { ...formValues, [id]: value };
    setNewFormValues(updatedFormValues);
    setState({ formValues: updatedFormValues });
  }
};

const handleSubmitSuccess = (response) => {
  const statusCode = _get(response, 'status');
  if (statusCode === 'Success') {
    toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
  }
};

const handleFailedTestSession = (error) => {
  toaster(TOASTER_TYPE.ERROR, __('Error occured'));
};

const handleFormSubmit = async ({ params, setState, getState }) => {
  const { onSubmit, formValues, history } = getState();
  // try {
  await onSubmit(formValues);
  // console.log(response);
  // handleSubmitSuccess(response);
  history.push('/tcmsuiV2/testsession/list');
  // } catch (error) {
  //   handleFailedTestSession(error);
  // }
};

const handleCreateRelease = ({ params, getState, setState }) => {
  const { formValues, releaseVersion } = getState();
  const { newReleaseVersion } = params;
  const updatedReleaseVersion = getCompactList(releaseVersion, newReleaseVersion);
  setState({
    formValues: { ...formValues, [RELEASE.id]: [newReleaseVersion] },
    releaseVersion: updatedReleaseVersion,
  });
};

// const handleFormErrors = ({ params, setState }) => {
//   const { payload } = params;
//   const { errors } = payload;
//   setState({ errors });
// };

export const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  // [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [ACTION_TYPES.CREATE_RELEASE_VERSION]: handleCreateRelease,
};
