import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, RELEASE, TYPE, STATUS, ENV, JIRA_QUERY,
} from './createJiraRequest.fields';
import { VALIDATE_JIRA_QUERY } from "../constant/createJiraRequest.general";

const SECTIONS = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [JIRA_QUERY.id],
      },
      {
        columns: [VALIDATE_JIRA_QUERY],
      },
      {
        columns: [TEST_SESSION_NAME.id],
      },
      {
        columns: [PRODUCT.id],
      },
      {
        columns: [PROJECT.id],
      },
      {
        columns: [RELEASE.id],
      },
      {
        columns: [TYPE.id, STATUS.id],
      },
      {
        columns: [ENV.id],
      },
    ],
  },
];

export default SECTIONS;
