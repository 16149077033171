import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
// Utils
import { isRequiredRule } from 'tbase/utils/formValidators';

export const PROJECT = {
  id: 'project',
  renderer: SelectInput,
  renderOptions: {
    label: __('Project'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const MODULE = {
  id: 'module',
  renderer: SelectInput,
  renderOptions: {
    label: __('Module'),
    placeholder: __('Select'),
    required: true,
    // validators: [isRequiredRule],
  },
};

export const SUB_MODULE = {
  id: 'subModule',
  renderer: SelectInput,
  renderOptions: {
    label: __('Sub Module'),
    placeholder: __('Select'),
    required: true,
    // validators: [isRequiredRule],
  },
};
