import React, { useCallback, useMemo, useState } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _first from 'lodash/first';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';
import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import ViewReleaseBoardList from './organisms/ViewReleaseBoardList/ViewReleaseBoadList';
import ACTION_TYPES from './Constants/viewReleaseBoard.actionTypes';
import AddReleaseModal from './organisms/AddReleaseModal/AddReleaseModal';
import ACTION_HANDLERS from './helper/viewReleaseBoard.actionHandlers';
import { checkUserRole } from '../../helpers/UserRole/userRole.common';


const viewReleaseBoard = (props) => {
  const {
    onAction,
    isVisible,
    isLoading,
  } = props;

  const CONTEXT_ID = 'UPLOAD_TESTCASES_FORM';
  const handleOnClick = (value) => {
    onAction({
      type: ACTION_TYPES.OPEN_ADD_RELEASE_MODAL,
      payload: { isVisible },
    });
  };
  const handleOnCancel = (value) => {
    onAction({
      type: ACTION_TYPES.CLOSE_MODAL,
      payload: { isVisible },
    });
  };

  const onSubmit = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.FORM_SUBMIT_MODAL,
        payload: {
          formValues,
        },
      });
    },
    [onAction]
  );

  return (
    <div>
      <PageComponent.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100vw' }}>
          <div>
            <Heading>Release Board</Heading>
          </div>
          <div>
            <Button
              view={Button.VIEW.PRIMARY}
              style={{ float: 'right' }}
              onClick={handleOnClick}
              disabled={checkUserRole()}
            >
                      Add Release
            </Button>

          </div>
        </div>
      </PageComponent.Header>

      <AddReleaseModal
        loading={isLoading}
        onAction={onAction}
        isVisible={isVisible}
        onCancel={handleOnCancel}
        onSubmit={onSubmit}
      />

      <ViewReleaseBoardList onAction={onAction} />
    </div>
  );
};


export default compose(

  withActions({}, ACTION_HANDLERS)
)(viewReleaseBoard);
