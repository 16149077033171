import _map from 'lodash/map';
import _filter from 'lodash/filter';


import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const makeProjectOptions = (projectListOptions) => {
  // standardFieldOptionMapper(undefined, projectList, PROJECT_OPTION_MAPPER);
  const options = _map(projectListOptions, element => ({
    label: element.name,
    value: element.id,
    additional:
    { id: element.id, name: element.name },
  }));
  return options;
};

export default makeProjectOptions;
