// Libraries
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import TabbedTestSessions from './organisms/TabbedTestSessions';
// import TestSessionForm from './organisms/TestSessionForm';
import TestResults from './organisms/TestResults';
// import AddTestCase from './organisms/AddTestCase';
import AddTestCaseList from './organisms/AddTestCaseList';
import CreateTestSession from './organisms/CreateTestSession';
import CloneTestSession from './organisms/cloneTestSession';
import EditTestSession from './organisms/editTestSession';

const TestSessions = (props) => {
  const { match } = props;
  const { url: matchUrl } = match;
  return (
    <Switch>
      <Route path={`${matchUrl}/list`} component={TabbedTestSessions} />
      <Route path={`${matchUrl}/create-testsession`} component={CreateTestSession} />
      <Route path={`${matchUrl}/clone/:testsessionId`} component={CloneTestSession} />
      <Route path={`${matchUrl}/edit/:testsessionId`} component={EditTestSession} />
      <Route path={`${matchUrl}/testresults/:productId/:projectId/:testsessionId`} component={TestResults} />
      <Route path={`${matchUrl}/addtestcase/:productId/:projectId/:testsessionId`} component={AddTestCaseList} />
      <Redirect from="*" to={`${matchUrl}/list`} />
    </Switch>
  );
};

TestSessions.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TestSessions;
