// Libraries
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import CreateTestCase from './organisms/createTestCase';
import TabbedTestCases from './organisms/TabbedTestCases';
import TestCaseForm from './organisms/TestCaseForm';
import EditTestCase from './organisms/editTestCase';
import CloneTestCase from './organisms/cloneTestCase';
// import TestResults from './organisms/TestResults';

const TestCases = (props) => {
  const { match } = props;
  const { url: matchUrl } = match;
  return (
    <Switch>
      <Route path={`${matchUrl}/create`} component={CreateTestCase} />
      <Route path={`${matchUrl}/list`} component={TabbedTestCases} />
      <Route path={`${matchUrl}/edit/:testCaseId`} component={EditTestCase} />
      <Route path={`${matchUrl}/clone/:testCaseId`} component={CloneTestCase} />
      {/* <Route path={`${matchUrl}/create-testcase`} component={TestCaseForm} /> */}
      {/* <Route path={`${matchUrl}/testresults`} component={TestResults} /> */}
      <Redirect from="*" to={`${matchUrl}/list`} />
    </Switch>
  );
};

TestCases.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TestCases;
