import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Radio } from 'antd';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import _difference from 'lodash/difference';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import Button from 'tcomponents/atoms/Button';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import moment from 'moment';
import cls from 'classnames';
import Dropdown from 'tcomponents/molecules/DropDown';
import Checkbox from 'tcomponents/atoms/checkbox';
import TimePicker from 'tcomponents/atoms/TimePicker';
import FontIcon from 'tcomponents/atoms/FontIcon';
import { fetchGetPreferences, updateNotification, updateSummary } from '../../../action/TaskManagement.action';
import { makePreferences, makeFetchApiStatus } from './TaskManagement.selector';
import { NOTIFICATION_DAYS, TIME_FIELDS, TIME_FORMAT } from './constants';
import './TaskManagement.scss';

const { MQTT_BASE_URL, PARTNERS_SITE } = process.env;
const email = TokenManager.getItem('email');

// const mqtt = require('mqtt');
// const client = mqtt.connect(MQTT_BASE_URL);

const isPartnerSite = PARTNERS_SITE === 'true';

class TaskManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      days: [],
      scheduleFrom: '',
      scheduleTill: '',
      schedule: false,
      dailySummary: false,
      isPaused: false,
      resume: false,
      showTime: '',
      weekly: false,
      onlyToday: false,
      pauseTime: '',
      ptSummary: false,
      // fromTodayTime: '',
      tasksViewOption: '',
      expandAll: '',
    };
  }

  componentDidMount = () => {
    const { getUserPreferences, apiBaseUrl } = this.props;
    getUserPreferences(apiBaseUrl);

    // client.on('connect', () => {
    //   client.subscribe(`/tm/notification/${email}/status`);
    // });

    // client.on('message', (topic, message) => {
    //   const dataStr = message.toString();
    //   const data = JSON.parse(dataStr);
    //   this.setState({ isPaused: data.isPaused });
    // });
  }

  static getDerivedStateFromProps(props, state) {
    const { perference } = props;
    const { notification, expandAll, tasks_view_option: tasksViewOption } = perference || {};
    const { noticationDetails, daily_summary: dailySummary, pt_summary: ptSummary } = notification || {};
    const {
      pauseWeekDays, from, till, daily, isPaused,
      forceResume, weekly, onlyToday, fromTodayTime,
    } = noticationDetails || {};
    if (!_isEmpty(noticationDetails) && !state.loaded) {
      return {
        loaded: true,
        days: [...(pauseWeekDays || [])],
        scheduleFrom: from !== '' ? moment.utc(from, 'HH:mm').local() : '',
        scheduleTill: till !== '' ? moment.utc(till, 'HH:mm').local() : '',
        schedule: daily,
        dailySummary,
        ptSummary,
        isPaused,
        resume: forceResume,
        weekly,
        onlyToday,
        showTime: moment(fromTodayTime).format('HH:mm'),
        expandAll: expandAll ? __('expandAll') : __('collapseAll'),
        tasksViewOption,
      };
    }
    if (_isEmpty(noticationDetails)) {
      return { loaded: false };
    }
    return null;
  }

  componentWillUnmount() {
    // client.unsubscribe(`/tm/notification/${email}/status`);
  }

  renderActions = () => (
    <Menu onClick={e => this.handleMenuClick(e)}>
      {TIME_FIELDS.map((dropOption) => {
        const {
          label, suffix = '', prefix = '', value,
        } = dropOption;
        const displayLabel = `${__(prefix)} ${__(label)} ${__(suffix)}`.trim();
        return (
          <Menu.Item key={value}>
            {displayLabel}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  setTime = () => {
    const date = moment().add('days', 1);
    date.set({ hour: '09', minute: '00', seconds: '00' });
    const duration = moment.duration(date.diff(moment()));
    const seconds = duration.asSeconds();
    return { seconds, date };
  }

  handleMenuClick = (e) => {
    const tomorrow = this.setTime();
    const pauseTime = e.key === 'tomorrow' ? tomorrow.seconds : parseInt(e.key, 10);
    const toToday = e.key === 'tomorrow' ? tomorrow.date : moment().add(pauseTime, 'seconds');
    this.setState({
      onlyToday: true, resume: false, isPaused: true, showTime: toToday.format('HH:mm'), pauseTime,
    });
  };

  timeFromOnChange = (time, timeString) => {
    const { scheduleTill } = this.state;
    const currentTime = moment();
    const startTime = moment(timeString, 'HH:mm');
    const endTime = moment(scheduleTill, 'HH:mm');
    this.setState({
      scheduleFrom: timeString,
      isPaused: currentTime.isBetween(startTime, endTime),
    });
  };

  timeTillOnChange = (time, timeString) => {
    const { scheduleFrom } = this.state;
    const currentTime = moment();
    const startTime = moment(scheduleFrom, 'HH:mm');
    const endTime = moment(timeString, 'HH:mm');
    this.setState({
      scheduleTill: timeString,
      isPaused: currentTime.isBetween(startTime, endTime),
    });
  };

  onChangeschedule = (e) => {
    this.setState({
      schedule: e.target.checked,
    });
  };

  handlePTSummaryChange = (e) => {
    this.setState({
      ptSummary: e.target.checked,
    });
  }

  onChangeDailySummary = (e) => {
    this.setState({
      dailySummary: e.target.checked,
    });
  };

  handleDays = (e, val) => {
    const { days } = this.state;
    if (parseInt(val, 10) === moment().day()) {
      this.setState({ isPaused: true, weekly: true });
    }
    if (days.includes(val)) {
      const daysVal = days.indexOf(val);
      days.splice(daysVal, 1);
      this.setState({ days, weekly: true });
    } else {
      this.setState({ days: [...days, val], weekly: true });
    }
  };

  resumeNotification = () => {
    this.setState({ resume: true, isPaused: false });
  };

  postUpdatePreferences = (data) => {
    const { isPaused } = this.state;
    const { perference } = this.props;
    const { notification } = perference || {};
    const { noticationDetails = {} } = notification || {};
    const payload = { user: noticationDetails.user || email, isPaused, ...data };
    const { updatePreferences, apiBaseUrl } = this.props;
    return updatePreferences(apiBaseUrl, payload);
  };

  _renderPauseNotification = () => (
    <Dropdown overlay={this.renderActions} trigger={['click']}>
      <Button><FontIcon className="bell-icon">icon-bell</FontIcon> {__('Pause notifications')}</Button>
    </Dropdown>
  );

  _renderResumeNotification = () => {
    const { loading } = this.state;
    return (
      <div>
        <Button onClick={this.resumeNotification}>
          <FontIcon className="bell-icon">icon-bell</FontIcon> {__('Resume notifications')}
        </Button>
        <span>{loading && <Icon type="loading" />}</span>
      </div>
    );
  }

  handleSave = () => {
    const {
      onlyToday, resume, pauseTime, scheduleFrom, scheduleTill, schedule, weekly, days, ptSummary, dailySummary, showTime, tasksViewOption, expandAll,
    } = this.state;
    const { perference, updateSummaries, apiBaseUrl } = this.props;
    const { notification } = perference || {};
    const { noticationDetails, daily_summary, pt_summary } = notification || {};
    const {
      pauseWeekDays, from, till, daily,
      forceResume, weekly: week, fromTodayTime, toTodayTime,
    } = noticationDetails || {};
    const currentTime = moment();
    const startTime = moment(scheduleFrom, 'HH:mm');
    const endTime = moment(scheduleTill, 'HH:mm');
    const prevFromTime = moment(fromTodayTime).add(toTodayTime, 'seconds').format('HH:mm');
    let payload = {};
    let isChanged = false;
    if (resume !== forceResume || showTime !== prevFromTime) {
      isChanged = true;
      if (!resume) {
        payload = {
          fromTodayTime: parseInt(moment(currentTime).add(toTodayTime, 'seconds').format('x')),
          toTodayTime: Math.round(pauseTime),
          onlyToday,
          forceResume: resume,
        };
      } else {
        payload = {
          forceResume: resume,
        };
      }
    }
    const fromTime = startTime.utc().format('HH:mm');
    const tillTime = endTime.utc().format('HH:mm');
    if (from !== fromTime || till !== tillTime || schedule !== daily) {
      isChanged = true;
      payload = {
        ...payload,
        from: fromTime,
        till: tillTime,
        daily: schedule,
      };
    }
    if (!_isEqual(days && days.sort(), pauseWeekDays && pauseWeekDays.sort()) || weekly !== week) {
      isChanged = true;
      payload = {
        ...payload,
        pauseWeekDays: days,
        weekly,
      };
    }
    if (isChanged) {
      this.postUpdatePreferences(payload);
    }
    if ((dailySummary !== daily_summary) || (tasksViewOption) || (ptSummary !== pt_summary)) {
      const summaryPayload = {
        email,
        notification: {
          daily_summary: dailySummary,
          pt_summary: ptSummary,
        },
        tasks_view_option: tasksViewOption,
        expandAll: expandAll === 'expandAll',
      };
      updateSummaries(apiBaseUrl, summaryPayload);
    }
  };

  onTaskStatus = (e) => {
    const { target: { value } } = e;
    this.setState({ tasksViewOption: value });
  }

  onTaskListView = (e) => {
    const { target: { value } } = e;
    this.setState({ expandAll: value });
  }

  render() {
    const {
      days, scheduleFrom, scheduleTill, schedule,
      dailySummary, isPaused, showTime, tasksViewOption, expandAll, ptSummary,
    } = this.state;
    return (
      <React.Fragment>
        <div className="task-container">
          <div className="default-section">
            <h3>{__('Layout Settings')}</h3>
            <h4>{__('Default view')}</h4>
            <div className="status-section">
              <Radio.Group onChange={this.onTaskStatus} value={tasksViewOption}>
                <p><Radio value="all">{__('All Tasks')}</Radio></p>
                <p><Radio value="completed">{__('Completed Tasks')}</Radio></p>
                <p><Radio value="incomplete">{__('Incomplete Tasks')}</Radio></p>
              </Radio.Group>
            </div>
            <div className="expandall-section">
              <Radio.Group onChange={this.onTaskListView} value={expandAll}>
                <p><Radio value="expandAll">{__('Expand all')}</Radio></p>
                <p><Radio value="collapseAll">{__('Collapse all')}</Radio></p>
              </Radio.Group>
            </div>
          </div>
          <div className="notification-section">
            <h3>{__('Notification Settings')}</h3>
            <h4>{__('Do not disturb')}</h4>
            {isPaused && <div>{__('Notifications paused until')} <span>{showTime}</span></div>}
            {!isPaused ? this._renderPauseNotification() : this._renderResumeNotification()}
            <div className="schedule-section">
              <p>{__('Schedule')}</p>
              <div>
                <Checkbox checked={schedule} onChange={this.onChangeschedule} />
                <span className="schedule-txt">{__('Do not notify me from :')} </span>
                <span className="time-select">
                  <TimePicker
                    onChange={this.timeFromOnChange}
                    value={moment(scheduleFrom, 'HH:mm').isValid() ? moment(scheduleFrom, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="from"
                    placeholder={__('From time')}
                  />
                </span>
                <span className="schedule-txt">{__('To:')}</span>
                <span className="time-select">
                  <TimePicker
                    onChange={this.timeTillOnChange}
                    value={moment(scheduleTill, 'HH:mm').isValid() ? moment(scheduleTill, TIME_FORMAT) : ''}
                    format={TIME_FORMAT}
                    disabled={!schedule}
                    id="to"
                    placeholder={__('To time')}
                  />
                </span>
              </div>
            </div>
            <div>{__('Do not disturb me on my days off')}</div>
            <div className="days-off">
              {NOTIFICATION_DAYS.map((daysOff, index) => {
                const daysStyle = cls('days', { active: days.indexOf(daysOff.value) > -1 });
                return (
                  <div
                    className={daysStyle}
                    key={daysOff.label}
                    onClick={e => this.handleDays(e, daysOff.value)}
                    role="button"
                    tabIndex={index - 1}
                  >
                    {__(daysOff.label)}
                  </div>
                );
              })}
            </div>
            <div className="summary-section">
              <Checkbox checked={dailySummary} onChange={this.onChangeDailySummary} />
              <span>{__('Daily summaries')}</span>
              <p>{__('New tasks assigned to you and upcoming due dates')}</p>
            </div>
            {!isPartnerSite && (
              <div className="summary-section">
                <Checkbox checked={ptSummary} onChange={this.handlePTSummaryChange} />
                <span>{__('Program alert')}</span>
                <p>{__('Notify me when each program changes')}</p>
              </div>
            )}
          </div>
        </div>
        <div className="task-settings-footer">
          <Button view="primary" onClick={this.handleSave}>{__('Save')}</Button>
        </div>
      </React.Fragment>

    );
  }
}

/**
 * @description Map the state objects (as props) that are required to render the details in the UI
 */
const mapStateToProps = createStructuredSelector({
  perference: makePreferences(),
  isFetchApiSuccess: makeFetchApiStatus(),
});

/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const getUserPreferences = bindActionCreators(fetchGetPreferences, dispatch);
  const updatePreferences = bindActionCreators(updateNotification, dispatch);
  const updateSummaries = bindActionCreators(updateSummary, dispatch);

  return {
    getUserPreferences,
    updatePreferences,
    updateSummaries,
  };
};

TaskManagement.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getUserPreferences: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  updateSummaries: PropTypes.func.isRequired,
  perference: PropTypes.object,
};

TaskManagement.defaultProps = {
  perference: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagement);
