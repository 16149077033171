import React, { useCallback, useMemo } from 'react';
import _get from 'lodash/get';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';

import useFetchData from 'tbase/customHooks/useFetchData';
import { makeInitialFormValues } from '../../helpers/testSessions.helpers';
import TestSessionForm from '../TestSessionForm';
import { getTestSessionDetails } from '../../services/testSessions.services';
import cloneTestSessionSubmit from './helpers/cloneTestSession.onFormSubmit';
function CloneTestSession(props) {
  const { history, match } = props;
  const testSessionId = _get(match, 'params.testsessionId');

  const [{ isLoading: isTestSessionLoading, data: testSessionResponse }] = useFetchData(getTestSessionDetails, [
    testSessionId,
  ]);

  const initialFormValues = useMemo(() => makeInitialFormValues(testSessionResponse), [testSessionResponse]);

  const handleCloneTestSessionSubmit = useCallback(formValues => cloneTestSessionSubmit(formValues, testSessionId), [
    testSessionId,
  ]);

  const renderFormHeader = () => (
    <PageComponent.Header hasBack>
      <div className="full-width headerContainer">
        <Heading size={1}>{__('Clone Test Session')}</Heading>
      </div>
    </PageComponent.Header>
  );
  return (
    <TestSessionForm
      history={history}
      // headerProps={headerProps}
      onSubmit={handleCloneTestSessionSubmit}
      isTestSessionLoading={isTestSessionLoading}
      formValues={initialFormValues}
      formHeader={renderFormHeader}
    />
  );
}

export default CloneTestSession;
