import React, { useCallback, useMemo } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _first from 'lodash/first';


import colors from '@tekion/tekion-styles-next/scss/variables.scss';
import withActions from 'tcomponents/connectors/withActions';
import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';

import useFetchData from 'tbase/customHooks/useFetchData';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './tabbedTestSession.module.scss';
import ACTION_TYPES from './constant/tabbedTestSession.actionTypes';
import ACTION_HANDLERS from './helpers/tabbedTestSession.actionHandler';
import { checkUserRole } from '../../../../helpers/UserRole/userRole.common';
import getTabs from '../../../../helpers/tabConfig';
import { getTabsApi } from '../../services/testSessions.services';
import TestSessionList from './organisms/TestSessionList/TestSessionList';
import IconInfoList from "./organisms/IconInfoList";


function TabbedTestSessions(props) {
  const {
    onAction, selectedTabId,
  } = props;

  const [{ isLoading, data }] = useFetchData(getTabsApi);
  const tabsData = _get(data, 'data.data');
  // const firstId = _first(tabsData);

  // console.log(isLoading, data);
  const handleOnTabChange = useCallback(
    (newSelectedTab) => {
      onAction({ type: ACTION_TYPES.ON_TAB_CHANGE, payload: { value: newSelectedTab } });
      // console.log( {newSelectedTab});
    },
    [onAction]
  );

  const handleCreateTestSession = useCallback(() => {
    onAction({ type: ACTION_TYPES.REDIRECT_TO_CREATE_TEST_SESSION });
  }, [onAction]);


  const tabs = useMemo(() => getTabs(TestSessionList, tabsData), [tabsData]);

  const tabBarStyle = {
    background: colors.glitter,
    padding: '0 2.4rem',
  };


  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <PageComponent.Header>
        <div className={`full-width ${styles.headerContainer}`}>
          <Heading size={1} className={styles.headingWidth}>{__('Test Sessions')}</Heading>
          <IconInfoList />
          <Button
            view={Button.VIEW.PRIMARY}
            highlightOnHover
            onClick={handleCreateTestSession}
            disabled={checkUserRole()}
            className={styles.buttonWidth}
          >
            {__('Create Test Session')}
          </Button>
        </div>
      </PageComponent.Header>
      <TabbedSections
        onAction={onAction}
        className="full-width"
        onTabChange={handleOnTabChange}
        activeKey={selectedTabId}
        // projectList={projectListByProductId[selectedTabId]}
        tabBarStyle={tabBarStyle}
        tabs={tabs}
        selectedTabId={selectedTabId}
        destroyInactiveTabPane
      />

    </div>
  );
}

// const mapStateToProps = state => ({
//   selectedTabId,
// });

export default compose(withRouter, withActions({}, ACTION_HANDLERS))(TabbedTestSessions);
