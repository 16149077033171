// Constants
import FILTER_TYPES from 'tcomponents/organisms/filterSection/constants/filterSection.filterTypes';

import PRIORITY_OPTIONS from '../../../../../constants/priorityOptions';
import { TESTCASE_STATUS_OPTIONS } from '../../../../../constants/statusOptions';
import AUTOMATED_OPTIONS from '../../../../../constants/automatedOptions';

export const FEATURE = {
  id: 'module_id',
  name: __('Module'),
  type: FILTER_TYPES.MULTI_SELECT,
};

export const STATUS = {
  id: 'is_active',
  name: __('Status'),
  type: FILTER_TYPES.SINGLE_SELECT,
  additional: { options: TESTCASE_STATUS_OPTIONS },
};

export const PRIORITY = {
  id: 'priority',
  name: __('Priority'),
  type: FILTER_TYPES.MULTI_SELECT,
  additional: { options: PRIORITY_OPTIONS },
};

export const AUTOMATED = {
  id: 'is_automated',
  name: __('Automated'),
  type: FILTER_TYPES.SINGLE_SELECT,
  additional: { options: AUTOMATED_OPTIONS },
};

export const DEFAULT_FILTER_TYPES = [FEATURE.id, PRIORITY.id, AUTOMATED.id];
