import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import withSelectionSummary from 'tcomponents/organisms/withSelectionSummary';

import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';

import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import styles from './addTestCaseList.module.scss';

import ACTION_HANDLERS from './helpers/addTestCaseList.actionHandler';
import INITIAL_STATE from './constant/addTestCaseList.initialState';
import { columns } from './constant/addTestcaseList.columns';
import { createFilterProps } from './helpers/addTestCaseList.filterProps';
import { getHeaderProps, makeTableProps } from './helpers/addtestCaseList.general';
import { getTcmsBootstrap, getFeatureListByProjectId } from '../../../../store/selectors/tcms.selectors';


const SelectionSummaryTable = withSelectionSummary(TableManager);
const CheckBoxTable = withCheckboxTable(FixedColumnTable);

const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;

function AddTestCaseList(props) {
  const {
    onAction,
    match,
    currentPage,
    isLoading,
    history,
    totalCount,
    selectedFilters,
    isFetchingNextPage,
    columnConfigurator,
    contentHeight,
    selection,
    searchText,
    sortDetails,
    data,
    selectionCallback,
    tcmsBootStrap,
    featureListByProjectId,
    pageSize = 50,
    isAddTestCaseLoading,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: true,
    showHeader: true,
    showSubHeader: true,
    showTotalCount: false,
    showRefreshIcon: true,
  };

  const productId = _get(match, 'params.productId');
  const projectId = _get(match, 'params.projectId');

  const filterProps = useMemo(() => createFilterProps({
    selectedFilters, tcmsBootStrap, featureListByProjectId, projectId,
  }),
  [selectedFilters, tcmsBootStrap, featureListByProjectId, projectId]);


  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
      pageSize,
      currentPage,
    }),
    [isLoading, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  const headerProps = useMemo(() => getHeaderProps(selection, isAddTestCaseLoading), [selection, isAddTestCaseLoading]);

  return (
    <PageComponent.Body style={{ height: contentHeight + 200 }}>
      <TableManager
        history={history}
        columns={columns}
        data={data}
        headerProps={headerProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={(action) => {
          onAction(action);
        }}
        filterProps={filterProps}
        selection={selection}
        tableComponent={CheckBoxTable}
        tableProps={getTableProps}
      />
    </PageComponent.Body>
  );
}

const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
  featureListByProjectId: getFeatureListByProjectId(state),

});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 1, hasPageFooter: 1 })
)(AddTestCaseList);
