import React from 'react';
import { mapProps } from "recompose";

import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';

import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import CellDateRenderer from 'tcomponents/molecules/CellRenderers/dateRenderer';
import Content from 'tcomponents/atoms/Content';

import { DATE_TIME_FORMATS } from 'tbase/utils/dateUtils';

import styles from '../viewReleaseBoardList.module.scss';
// import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
// import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
// import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';

const isActiveRenderer = (column) => {
  const textToRender = _get(column, 'value');
  return (<Content className={textToRender === 'True' ? styles.greenBox : styles.redBox}>{textToRender}</Content>);
};

const RELEASE_VERSION = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  sortable: true,
};
const CREATED_BY = {
  id: 'createdBy',
  key: 'createdBy',
  accessor: 'createdBy',
  Header: 'Created By',
  Cell: TextRenderer,
  sortable: true,
};
const ACTIVE = {
  id: 'active',
  key: 'active',
  accessor: 'isActiveText',
  Header: 'Active',
  Cell: column => isActiveRenderer(column),
  sortable: true,
};
const MODIFIED_BY = {
  id: 'modifiedBy',
  key: 'modifiedBy',
  accessor: 'modifiedBy',
  Header: 'Modified By',
  Cell: TextRenderer,
  sortable: true,
};
const MODIFIED_AT = {
  id: 'modifiedAt',
  key: 'modifiedAt',
  accessor: 'modifiedAt',
  Header: 'Modified At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};
const CREATED_AT = {
  id: 'createdAt',
  key: 'createdAt',
  accessor: 'createdAt',
  Header: 'Created At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};
const CORN_LAST_RUN = {
  id: 'cronLastRun',
  key: 'cronLastRun',
  accessor: 'cronLastRun',
  Header: 'Cron Last Run',
  Cell: TextRenderer,
  sortable: true,
};
const ACTION = {
  id: 'actionType',
  key: 'actionType',
  accessor: 'actionType',
  Header: 'Action',
  Cell: TextRenderer,
  sortable: true,
};

export const columns = [RELEASE_VERSION, CREATED_BY, ACTIVE, MODIFIED_BY, MODIFIED_AT, CREATED_AT, CORN_LAST_RUN, ACTION];
const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
