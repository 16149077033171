import React, { useMemo ,useEffect} from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';

import withActions from 'tcomponents/connectors/withActions';
import TableManager from 'tcomponents/organisms/TableManager';
import withSize from 'tcomponents/hoc/withSize';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import INITIAL_STATE from '../../../testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.initialState';
import ACTION_HANDLERS from '../../../testCases/organisms/TabbedTestCases/organisms/TestCasesList/helpers/testCasesList.actionHandlers';
import { columns } from './constants/statsTable.columns';
import { makeTableProps} from './helpers/statsTable.general';

import { createFilterProps } from './helpers/executionScreen.filterProps';
import {
  getTcmsBootstrap, getFeatureListByProjectId, getProjectListByProductId, getsubFeatureListByfeatureId,
} from '../../../../store/selectors/tcms.selectors';


import styles from '../../executionScreen.module.scss';

const StatsTable = (props) => {
  const {
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    selection,
    selectedFilters,
    contentHeight,
    pageSize,
    projectListByProductId,
    featureListByProjectId,
    subfeatureListByFeatureId,
    tabId,
    selectedProjectId,
    selectedFeatureId,
    tcmsBootStrap,
  } = props;


  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: false,
    showRefreshIcon: true,
  };

  const tableData = _get(data,'data.data',EMPTY_OBJECT);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      onAction,
      selection,
      sortDetails,
      tableData,
    }),
    [isLoading, isFetchingNextPage, totalCount, onAction, selection, sortDetails,tableData]
  );

  const filterProps = useMemo(() => createFilterProps({
    selectedFilters,
    tcmsBootStrap,
    projectListByProductId,
    featureListByProjectId,
    subfeatureListByFeatureId,
    tabId,
    selectedProjectId,
    selectedFeatureId,
  }), [selectedFilters, tcmsBootStrap]);


  return (
    <PageComponent.Body className={styles.tableStyle} style={{ height: contentHeight }}>
      <TableManager
        columns={columns}
        data={[tableData]}
        tableManagerProps={TABLE_MANAGER_PROPS}
        filterProps={filterProps}
        onAction={onAction}
        selection={selection}
        tableProps={getTableProps}
      />
    </PageComponent.Body>
  );
};
const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
  featureListByProjectId: getFeatureListByProjectId(state),
  projectListByProductId: getProjectListByProductId(state),
  subfeatureListByFeatureId: getsubFeatureListByfeatureId(state),
});


export default compose(
  connect(mapStateToProps),
  withRouter,
 // withActions(INITIAL_STATE),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 }),
)(StatsTable);
