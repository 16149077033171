import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import _get from 'lodash/get';
import {EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING} from '@tekion/tekion-base/app.constants';
import {createDashBoardDTO, createDashBoardDTOForModule} from '../../../constants/automationProgress.request';
import { getDashboardStatsByReleaseVersion } from '../../../services/automationProgress.api';
import { ACTION_TYPES } from '../constants/statsTable.actionTypes';

const handleTableItemsFetch = () => {
  console.log('init');
};

const handleTableItemsFetch1 = async ({ getState, setState }) => {
  setState({
    isLoading: true,
  });
  const { selectedProduct, selectedEnvironment, selectedReleaseVersion, selectedDealer } = getState();
  const dashBoardDTO = createDashBoardDTO(selectedProduct, selectedEnvironment, selectedReleaseVersion, selectedDealer);
  const dashboardTableStats = await getDashboardStatsByReleaseVersion(dashBoardDTO);
  setState({
    data: dashboardTableStats,
    isLoading: false,
  });
};

// const handleOnRowExpandClick = ({ params, getState, setState }) => {
//   console.log('handleOnRowExpandClick');
// };

const handleExpandChange = async ({ params, getState, setState }) => {
  const { rowInfo } = params;
  const { expanded = EMPTY_OBJECT, selectedEnvironment, selectedReleaseVersion, selectedDealer, data, moduleDataMap = EMPTY_OBJECT } = getState();
  const rowIndex = _get(rowInfo, '_index');
  const projectId = _get(rowInfo, ['_original','projectId'], EMPTY_STRING);

  const moduleDashboardDto = createDashBoardDTOForModule(projectId, selectedEnvironment, selectedReleaseVersion, selectedDealer);
  setState({
    isModuleTableLoading: true
  });
  const isRowExpanded = expanded[rowIndex] || false;

  let moduleDataResponse;
  if (!isRowExpanded) {
    moduleDataResponse = await getDashboardStatsByReleaseVersion(moduleDashboardDto);
  }
  const moduleData = _get(moduleDataResponse, 'data.data', EMPTY_OBJECT) || EMPTY_OBJECT;
  setState({
    isModuleTableLoading: false,
    moduleDataMap: {
      ...moduleDataMap,
      [projectId]: moduleData,
    },
    moduleData,
    expanded: {
      ...expanded,
      [rowIndex]: !isRowExpanded,
    },
  });
};

const handleSendEmailClick = ({ params, getState, setState }) => {
  console.log('send email report clicked');
}

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch1,
  [ACTION_TYPES.ON_ROW_EXPAND_CLICK]: handleExpandChange,
  [ACTION_TYPES.HANDLE_EXPAND_CHANGE]: handleExpandChange,
  [ACTION_TYPES.ON_SEND_EMAIL_CLICK]: handleSendEmailClick,
};

export default ACTION_HANDLERS;
