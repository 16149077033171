import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  allCards: EMPTY_ARRAY,
  activeCard: EMPTY_OBJECT,
  addCard: EMPTY_OBJECT,
  loading: {
    getCards: false,
    addCard: false,
    addCardSuccess: false,
    editCard: false,
    editCardSuccess: false,
    deleteCard: false,
    updateCard: false,
  },
};

export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_ALL_CARD_SUCCESS:
      return {
        ...state,
        allCards: action.payload,
      };
    case ACTIONS.FETCH_CARD_SUCCESS:
      return {
        ...state,
        activeCard: action.payload,
      };
    case ACTIONS.FETCH_ADD_CARD_SUCCESS:
      return {
        ...state,
        addCard: action.payload,
      };
    case ACTIONS.SET_LOADER:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    default: return state;
  }
};
