import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import ROW_ACTION_TYPES from './testCasesList.rowActionTypes';

// const EDIT_TEST_SESSION_ACTION = {
//   name: __('Edit'),
//   id: ROW_ACTION_TYPES.EDIT_TEST_SESSION,
// };


const CLONE_TEST_SECTION_ACTION = {
  name: __('Clone'),
  id: ROW_ACTION_TYPES.CLONE_TEST_CASE,
};

const DELETE_TEST_CASE_ACTION = {
  name: __('Delete'),
  id: ROW_ACTION_TYPES.DELETE_TEST_CASE,
};

const MOVE_TEST_CASE_ACTION = {
  name: __('Move'),
  id: ROW_ACTION_TYPES.MOVE_TEST_CASE,
};

const BULK_UPDATE_TEST_CASES = {
  name: __('Update'),
  id: ROW_ACTION_TYPES.BULK_UPDATE_TEST_CASES,
}

export const BULK_ROW_ACTION_TYPES_SUPER_ADMIN_AND_ADMIN = [
  MOVE_TEST_CASE_ACTION,
  DELETE_TEST_CASE_ACTION,
  BULK_UPDATE_TEST_CASES,
];

export const BULK_ACTION_TYPES = [
  BULK_UPDATE_TEST_CASES,
]

export const ROW_ACTION_TYPESS_SUPER_ADMIN_AND_ADMIN = [
  // EDIT_TEST_SESSION_ACTION,
  CLONE_TEST_SECTION_ACTION,
  DELETE_TEST_CASE_ACTION,
  MOVE_TEST_CASE_ACTION,
];

export const ROW_ACTION_TYPESS_OTHES = [
  CLONE_TEST_SECTION_ACTION,
];

export const ROW_ACTION_TYPESS_GUEST = EMPTY_ARRAY;
