// Readers
import _get from 'lodash/get';
import _size from 'lodash/size';
// Utils
import addToRenderOptions from 'tbase/utils/addToRenderOptions';
// import tcmsBootstrapReader from '../../../../readers/TcmsBootstrap';
//
// import { getTcmsBootstrap } from '../services/bootstrap.services';

// Helpers
import _head from 'lodash/head';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import _map from 'lodash/map';
import { value } from 'lodash/seq';
import makeProjectOptions from '../../../../helpers/projectOptions';

// Constants
import {
  PROJECT, MODULE, SUB_MODULE,
} from './moveTestCaseForm.fields';
import FORM_CONFIG from './moveTestCaseForm.formConfigConstant';

export const getFields = ({
  isEdit,
  tabId,
  formValues,
  featureListByProjectId,
  projectListByProductId,
  subfeatureListByFeatureId,
}) => {
  const projectId = _head(_get(formValues, 'project', EMPTY_ARRAY));
  const moduleId = _head(_get(formValues, 'module', EMPTY_ARRAY));
  const projectList = projectListByProductId[tabId];
  console.log(projectList);
  const featureList = featureListByProjectId[projectId];
  console.log(featureList);
  const subfeatureList = subfeatureListByFeatureId[moduleId];
  console.log(subfeatureList);

  const projectOptions = makeProjectOptions(projectList);
  const moduleOptions = _map(featureList, data => ({
    label: data.name,
    value: data.id,
  }));
  const subModuleOptions = _map(subfeatureList, data => ({
    label: data.name,
    value: data.id,
  }));
  return {
    ...FORM_CONFIG,
    [PROJECT.id]: addToRenderOptions(PROJECT, [{ path: 'options', value: projectOptions }]),
    [MODULE.id]: addToRenderOptions(MODULE, [
      { path: 'options', value: moduleOptions },
      { path: 'isDisabled', value: isEdit },
      // { path: 'onCreateOption', value: handleCreateFeature },
    ]),
    [SUB_MODULE.id]: addToRenderOptions(SUB_MODULE, [
      { path: 'options', value: subModuleOptions },
      { path: 'isDisabled', value: isEdit },
      // { path: 'onCreateOption', value: handleCreateSubFeature },
    ]),
  };
};

export default getFields;
