import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _constant from 'lodash/constant';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import Select from '@tekion/tekion-components/src/organisms/FormBuilder/fieldRenderers/select';
import FORM_ACTION_TYPES from '@tekion/tekion-components/src/organisms/FormBuilder/constants/actionTypes'
import { DROPDOWN_STYLES } from './constants/tagInput.constants';
import { tooltipForTags } from './constants/tagInput.helper';
import TYPE_OPTIONS from '../../constants/typeOptions';


export default function TagInput(props) {
  const { id, onAction, additional, value, ...restOfProps } = props;

  const handleChange = useCallback(
    list => {
      onAction({
        type: FORM_ACTION_TYPES.ON_FIELD_CHANGE,
        payload: {
          id,
          value: list,
          additional,
        },
      });
    },
    [additional, id, onAction]
  );

  const getValue = useCallback(_constant(value), [value]);
  return (
    <Select
      mode="tags"
      allowClear
      showArrow={true}
      onChange={handleChange}
      dropdownStyle={DROPDOWN_STYLES}
      getValue={getValue}
      options={TYPE_OPTIONS}
      maxTagPlaceholder={tooltipForTags}
      autoFocus
      {...restOfProps}
    />
  );
}

TagInput.propTypes = {
  id: PropTypes.string,
  onAction: PropTypes.func,
  additional: PropTypes.object,
  value: PropTypes.arrayOf(PropTypes.string),
};

TagInput.defaultProps = {
  id: EMPTY_STRING,
  onAction: _noop,
  additional: EMPTY_OBJECT,
  value: EMPTY_ARRAY,
};
