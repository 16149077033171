export const PARTNERTS_STIE_TABS = [
  { id: 'profile', label: 'Profile', url: '/profile' },
  { id: 'task', label: 'Task Management', url: '/taskmanagement' },
];

export const TABS = [
  { id: 'profile', label: 'Profile', url: '/profile' },
  { id: 'calendar', label: 'Calendar', url: '/calendar' },
  { id: 'task', label: 'Task Management', url: '/taskmanagement' },
  { id: 'supportportal', label: 'Support Portal', url: '/supportportal' },
];
