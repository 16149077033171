import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import TableManager from 'tcomponents/organisms/TableManager';
import withSize from 'tcomponents/hoc/withSize';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';

import _noop from 'lodash/noop';

import withActions from '@tekion/tekion-components/src/connectors/withActions';
import { getColumns } from './constants/historyCollectionList.columns';
import { ACTION_HANDLERS } from './helpers/historyCollectionList.actionHandlers';
import { makeTableProps } from './helpers/historyCollectionList.general';

import styles from './historyCollectionList.module.scss';

const HistoryCollectionList = (props) => {
  const {
    onAction,
    isLoading,
    data,
    totalCount,
    sortDetails,
    selection,
    history,
    contentHeight,
    isHistoryTableLoading,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: true,
    showHeader: false,
    showSubHeader: false,
    showTotalCount: false,
    showRefreshIcon: false,
  };

  // const filterProps = useMemo(() => createFilterProps({
  //   selectedFilters,
  //   tcmsBootStrap,
  //   projectListByProductId,
  //   featureListByProjectId,
  //   subfeatureListByFeatureId,
  //   tabId,
  //   selectedProjectId,
  // }), [selectedFilters, tcmsBootStrap]);

  // TODO : filtering

  const getTableProps = useMemo(
    () => makeTableProps({
      isHistoryTableLoading,
      isLoading,
      totalCount,
      data,
      onAction,
      selection,
      sortDetails,
    }),
    [isLoading, totalCount, onAction, selection, sortDetails, data, isHistoryTableLoading]
  );

  const tableData = _get(data, 'data.data', EMPTY_ARRAY);
  // const tableData = [{
  //   productName: 'product1',
  //   projectName: 'project1',
  //   moduleName: 'module1',
  //   releaseVersion: '2023.test.1',
  //   testCaseId: 'testcase1',
  //   environment: 'PREPROD',
  //   testSessionId: '11111',
  //   dealerId: '4',
  //   dealerName: 'Techmotors',
  //   status: 'PASS',
  //   createdTime: 'thursday',
  // }, {
  //   productName: 'product2',
  //   projectName: 'project2',
  //   moduleName: 'module2',
  //   releaseVersion: '2023.test.1',
  //   testCaseId: 'testcase2',
  //   environment: 'PREPROD',
  //   testSessionId: '11112',
  //   dealerId: '35',
  //   dealerName: 'Ridetime',
  //   status: 'FAIL',
  //   createdTime: 'thursday',
  // }, {
  //   productName: 'product3',
  //   projectName: 'project3',
  //   moduleName: 'module3',
  //   releaseVersion: '2023.test.1',
  //   testCaseId: 'testcase3',
  //   environment: 'PREPROD',
  //   testSessionId: '11112',
  //   dealerId: '35',
  //   dealerName: 'Ridetime',
  //   status: 'PENDING',
  //   createdTime: 'thursday',
  // }];

  return (
    <PageComponent.Body className={styles.tableStyle} style={{ height: contentHeight }}>
      <TableManager
        columns={getColumns(onAction)}
        data={tableData}
        tableManagerProps={TABLE_MANAGER_PROPS}
        // filterProps={filterProps}
        onAction={onAction}
        selection={selection}
        tableProps={getTableProps}
      />
    </PageComponent.Body>
  );
};

HistoryCollectionList.propTypes = {
  onAction: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  totalCount: PropTypes.any,
  sortDetails: PropTypes.any,
  selection: PropTypes.any,
  contentHeight: PropTypes.any,
};

HistoryCollectionList.defaultProps = {
  onAction: _noop(),
  isLoading: false,
  data: EMPTY_ARRAY,
  totalCount: 0,
  sortDetails: _noop,
  selection: _noop,
  contentHeight: 0,
};

export default compose(
  withRouter,
  withSize({ hasPageHeader: 0, hasPageFooter: 0 }),
  withActions({}, ACTION_HANDLERS)
)(HistoryCollectionList);
