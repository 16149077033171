export const CONSTANTS = {
  ACTIONS: {
    FETCH_GET_USER_DETAILS_SUCCESS: 'FETCH_GET_USER_DETAILS_SUCCESS',
    FETCH_GET_USER_DETAILS_ERROR: 'FETCH_GET_USER_DETAILS_ERROR',
    FETCH_UPDATE_USER_DETAILS_SUCCESS: 'FETCH_UPDATE_USER_DETAILS_SUCCESS',
    FETCH_UPDATE_USER_DETAILS_ERROR: 'FETCH_UPDATE_USER_DETAILS_ERROR',
    RESET_USER_DETAIL_API_EVENT: 'RESET_USER_DETAIL_API_EVENT',
    FETCH_GET_USER_PERFERENCE_SUCCESS: 'FETCH_GET_USER_PERFERENCE_SUCCESS',
    FETCH_GET_USER_PERFERENCE_ERROR: 'FETCH_GET_USER_PERFERENCE_ERROR',
    STORE_USER_AVAILABILITY_DATA: 'STORE_USER_AVAILABILITY_DATA',
    FETCH_LISTING_SUCESS: 'FETCH_LISTING_SUCESS',
    FETCH_CARDS_SUCCESS: 'FETCH_CARDS_SUCCESS',
    FETCH_ALL_CARD_SUCCESS: 'FETCH_ALL_CARD_SUCCESS',
    FETCH_CARD_SUCCESS: 'FETCH_CARD_SUCCESS',
    FETCH_ADD_CARD_SUCCESS: 'FETCH_ADD_CARD_SUCCESS',
    FETCH_UPDATE_CARD_SUCCESS: 'FETCH_UPDATE_CARD_SUCCESS',
    FETCH_DELETE_CARD_SUCCESS: 'FETCH_DELETE_CARD_SUCCESS',
    FETCH_LISTING_ERROR: 'FETCH_LISTING_ERROR',
    FETCH_MODULE_SUCESS: 'FETCH_MODULE_SUCESS',
    FETCH_AUTHENTICATION: 'FETCH_AUTHENTICATION',
    RESET_MODULE: 'RESET_MODULE',
    LOADER: 'LOADER',
    SET_LOADER: 'SET_LOADER',
    /** Support Portal actions */
    SET_NOTIFY_SETTING_LOADING: 'GET_USER_GROUPS_LOADING',
    SET_NOTIFY_SETTING_SAVING: 'SET_NOTIFY_SETTING_SAVING',
    HYDRATE_SP_NOTIFY_SETTING: 'HYDRATE_SP_NOTIFY_SETTING',
  },
  TAP_APPLICATIONS: [
    { name: __('Tap Chat'), icon: 'icon-message', link: 'tapchat' },
    { name: __('Release Version'), icon: 'icon-notes', link: 'releaseversion' },
    { name: __('Card Management'), icon: 'icon-card', link: 'cards' },
  ],
};
export const CARD_MANAGEMENT_DEFAULT_COLUMNS = [
  {
    Header: 'Label', id: 'label', accessor: 'label',
  },
  {
    Header: 'Short Label', id: 'slabel', accessor: 'slabel', width: 150,
  },
  {
    Header: 'Role Key', id: 'roleKey', accessor: 'roleKey', width: 350,
  },
  {
    Header: 'App Key', id: 'appKey', accessor: 'appKey', width: 250,
  },
  {
    Header: 'Icon Name', id: 'iconName', accessor: 'iconName', width: 250,
  },
  {
    Header: 'Path', id: 'path', accessor: 'path', width: 250,
  },
  {
    Header: 'Value', id: 'value', accessor: 'value', width: 250,
  },

];
export const CARD_MANAGEMENT_COLUMN_CONFIGURATOR = {
  fixed: 'right', resizable: true, width: 50,
};

export const CARD_MANAGEMENT_ACTION_MENU = [{ key: 'edit', label: __('Edit') }, { key: 'delete', label: __('Delete') }];

export const CARD_MANAGEMENT_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
};
export const CARD_MANAGEMENT_TABLES_STYLES = {
  NO_BORDER: '0',
  BORDER: '2px solid #000',
  CURSOR: 'pointer',
  BACKGROUND: '#e6f3ff',
  WHITE_BACKGROUND: '#fff',
  MISSED: '#da0009',
  NOT_MISSED: '#000',
  HEIGHT: '4rem',
};

export const CARD_MANAGEMENT_TABLE_PAGE = {
  pageSize: 25,
  pageSizeOptions: [25, 50, 75],
  currentPage: 1,
};


export const INIT_COMMUNICATIONS_PAYLOAD = {
  page_info: {
    start: 0,
    rows: 25,
  },
};

// Urls
export const TRIGGERS_URL = '/app/tapapplications/cards';

export const HEADER_HEIGHT = 0;
export const SIDEBAR_WIDTH = 0;
export const FOOTER_HEIGHT = 150;

export const GLOBAL_ACTION_TYPES = {
  ON_STOP_EXECUTION: 'ON_STOP_EXECUTION'
};
