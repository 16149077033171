/* eslint-disable rulesdir/no-inline-functions */
// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';
import _get from 'lodash/get';


// Components
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';

// Constants
import TEST_RESULT_LIST_ACTION_TYPES from '../../constants/testResultsList.actiontypes';

const UploadMediaRenderer = (props) => {
  const { onAction, data } = props;
  const selectedTestResultId = _get(props, 'original.id');

  const handleOnClickUpload = useCallback(() => {
    onAction({
      type: TEST_RESULT_LIST_ACTION_TYPES.OPEN_UPLOAD_MODAL,
      payload: {
        selectedTestResultId,
      },
    });
  }, [onAction, selectedTestResultId]);

  return <IconAsBtn onClick={handleOnClickUpload}>icon-upload</IconAsBtn>;
};

UploadMediaRenderer.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.number,
};

UploadMediaRenderer.defaultProps = {
  onAction: _noop,
  data: undefined,
};

export default makeCellRenderer(UploadMediaRenderer);
