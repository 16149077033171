import { EMPTY_ARRAY } from "@tekion/tekion-base/app.constants";

export const TABLE_ROW_ACTIONS = [
    {
        name: __('Add Existing Test Cases'),
        id: 'ADD_EXISTING_TEST_CASES',
    },
    {
    name: __('Add New Test Cases'),
    id: 'ADD_NEW_TEST_CASES',
    },
    // {
    //     name: __('Fail'),
    //     id: 'FAIL',
    // },
    // {
    //     name: __('Pending'),
    //     id: 'PENDING',
    // },
    // {
    //     name: __('Re Run Pass'),
    //     id: 'RE_RUN_PASS',
    // },
    // {
    //     name: __('Skipped'),
    //     id: 'SKIPPED',
    // },
];
