const ACTION_TYPES = {
  ON_FORM_SUBMIT: 'ON_FORM_SUBMIT',
  SET_MEDIA: 'SET_MEDIA',
  ON_DOWNLOAD_TEMPLATE: 'ON_DOWNLOAD_TEMPLATE',
  CREATE_FEATURE: 'CREATE_FEATURE',
  CREATE_SUBFEATURE: 'CREATE_SUBFEATURE',
  ON_FIELD_CHANGE: 'ON_FIELD_CHANGE',
  ON_DOWNLOAD_REPORT: 'ON_DOWNLOAD_REPORT',
};

export default ACTION_TYPES;
