import React from 'react';
import styles from './PopoverContent.module.scss';

const PopoverContent = ({ tags }) => (
  <div className={styles.popOverContainer}>
    {tags?.map((tag, _) => (
      <div className={styles.popOverContent} key={tag}>
        {tag}
      </div>
    ))}
  </div>
);

export default PopoverContent;
