import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';

import { mapProps } from 'recompose';
import CellDateRenderer from '@tekion/tekion-components/src/molecules/CellRenderers/dateRenderer';

import { DATE_TIME_FORMATS } from 'tbase/utils/dateUtils';

import _property from 'lodash/property';
import _keyBy from 'lodash/keyBy';
import _identity from 'lodash/identity';

import { getStatusProps } from '../helpers/testResultsList.column';

import DisplayMediaRenderer from '../cellRenderers/displayMediaRenderer/DisplayMediaRenderer';
import UploadMediaRenderer from '../cellRenderers/uploadMediaRenderer/UploadMediaRenderer';
import NotesCellRenderer from '../cellRenderers/notesCellRenderer/NotesCellRenderer';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const NAME = {
  id: 'testCaseName',
  key: 'testCaseName',
  accessor: 'testCaseName',
  Header: 'Name',
  Cell: TextWithLink,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const TESTCASE_NAME = {
  id: 'testCaseId',
  key: 'testCaseId',
  accessor: 'testCaseId',
  Header: 'Testcase Id',
  Cell: TextRenderer,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const FEATURE_NAME = {
  id: 'moduleName',
  key: 'moduleName',
  accessor: 'moduleName',
  Header: 'Module Name',
  Cell: TextRenderer,
  sortable: true,
};
const STATUS = {
  id: 'status',
  key: 'status',
  accessor: _property('status'),
  Header: 'Status',
  Cell: StatusCellRenderer,
  getProps: getStatusProps,
};

const CREATED_BY = {
  id: 'createdBy',
  key: 'createdBy',
  accessor: 'createdBy',
  Header: 'Created By',
  Cell: TextRenderer,
  sortable: true,
};

const NOTES = {
  id: 'notes',
  key: 'notes',
  accessor: _identity,
  Header: 'Notes',
  Cell: NotesCellRenderer,
  sortable: true,
};

const CREATED_AT = {
  id: 'createdAt',
  key: 'createdAt',
  accessor: 'createdAt',
  Header: 'Created At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};

const EXECUTED_AT = {
  id: 'modifiedAt',
  key: 'modifiedAt',
  accessor: 'modifiedAt',
  Header: 'Modified At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};
const SCREENSHOT = {
  id: 'mediaAvailable',
  key: 'mediaAvailable',
  accessor: _identity,
  Header: 'Screenshot',
  Cell: DisplayMediaRenderer,
  sortable: true,
};
const EXECUTED_BY = {
  id: 'modifiedBy',
  key: 'modifiedBy',
  accessor: 'modifiedBy',
  Header: 'Modified By',
  Cell: TextRenderer,
  sortable: true,
};
const UPLOAD_MEDIA = {
  id: 'media',
  key: 'media',
  accessor: 'media',
  Header: 'Upload Media',
  Cell: UploadMediaRenderer,
  sortable: true,
};

export const columns = [NAME, TESTCASE_NAME, FEATURE_NAME, STATUS, CREATED_BY, NOTES,
  CREATED_AT, EXECUTED_AT,
  SCREENSHOT, EXECUTED_BY, UPLOAD_MEDIA];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
