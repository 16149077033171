import _get from 'lodash/get';

import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import ACTION_TYPES from '../constants/automatinMetric.actionTypes';
import {
    getAnalyticsProjectData,
    getAnalyticsModuleData,
    getAnalyticsProductData
} from '../../../services/automationMetric.services';

const handleTableItemsFetch = async ({ getState, setState }) => {

};

const handleOnfieldChange = async ({ params, getState, setState }) => {
    const id = _get(params, 'value.payload.id', EMPTY_STRING);
    const { selectedProduct, isProjectDisabled } = getState();
    const selectedValue = _get(params, 'value.payload.value', EMPTY_ARRAY);
    if (id === 'Product') {
      setState({
        selectedProduct: selectedValue,
        chartLoading: true,
        isProjectDisabled: false,
      })
      const searchData = await getAnalyticsProjectData(selectedValue)
      const response = _get(searchData, 'data.data', EMPTY_ARRAY);
      setState({
        chartsData: response,
        chartLoading: false
      })
    } else if (id === 'Project') {
      setState({
        selectedProject: selectedValue,
        chartLoading: true
    });
      const searchData = await getAnalyticsModuleData(selectedProduct, selectedValue)
      const response = _get(searchData, 'data.data', EMPTY_ARRAY);
      setState({
        chartsData: response,
        chartLoading: false
      })
  }
}

const handleResetClick = async ({ getState,setState }) => {
    setState({
        isResetButtonLoading: true,
    })
    const initialData = await getAnalyticsProductData();
    const chartsData = _get(initialData, 'data.data', EMPTY_ARRAY);
    setState({
        selectedProduct: null,
        selectedProject: null,
        isResetButtonLoading: false,
        chartsData,
    })
}

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleOnfieldChange,
  [ACTION_TYPES.ON_RESET_CLICK]: handleResetClick,
};

export default ACTION_HANDLERS;
