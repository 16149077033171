// Libraries
import _head from 'lodash/head';
import _has from 'lodash/has';


// Constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import { PROJECT, MODULE } from '../constants/uploadTestCasesForm.fields';

const makeBaseTestCaseUploadRequestDTO = (formValues = EMPTY_OBJECT, mediaId, mediaList) => {
  const formData = new FormData();
  if (formValues.featureId != undefined && formValues.newsubfeature == 'yes') {
    // existing feature and new subfeature
    console.log('existing feature and new subfeature');
    formData.append('uploadDto', JSON.stringify({
      fileSize: mediaList[0].file.size,
      isCreate: true,
      moduleId: formValues.featureId,
      subModuleDto: {
        moduleId: formValues.featureId,
        name: formValues.subfeatureName[0],
        info: 'this is info',
      },
    }));
    formData.append('file', mediaList[0].file);
  } else if (formValues.subfeatureId != undefined) {
    console.log('existing feature and subfeature');
    // existing feature and subfeature
    formData.append('uploadDto', JSON.stringify({
      fileSize: mediaList[0].file.size,
      isCreate: false,
      subModuleId: formValues.subfeatureId,
    }));
    formData.append('file', mediaList[0].file);
  } else if ((_has(formValues, 'subfeatureName') && _has(formValues, 'featureName'))
  && (formValues.newsubfeature == 'yes' && formValues.newfeature == 'yes')) {
    console.log('new feature and new sub feature');
    // new feature and new sub feature
    formData.append('uploadDto', JSON.stringify({
      fileSize: mediaList[0].file.size,
      isCreate: true,
      moduleDto: {
        projectId: formValues.projectId[0],
        name: formValues.featureName[0],
        info: 'this is info',
      },
      subModuleDto: {
        moduleId: '',
        name: formValues.subfeatureName[0],
        info: 'this is info',
      },
    }));
    formData.append('file', mediaList[0].file);
  } else if (formValues.newfeature != undefined && formValues.newfeature == 'yes') {
    console.log('new feature');
    // new feature
    formData.append('uploadDto', JSON.stringify({
      fileSize: mediaList[0].file.size,
      isCreate: true,
      projectId: formValues.projectId[0],
      moduleDto: {
        projectId: formValues.projectId[0],
        name: formValues.featureName[0],
        info: 'this is info',
      },
    }));
    formData.append('file', mediaList[0].file);
  } else if (formValues.featureId != undefined) {
    console.log('existing feature');
    // existing feature
    formData.append('uploadDto', JSON.stringify({
      fileSize: mediaList[0].file.size,
      isCreate: false,
      moduleId: formValues.featureId,
    }));
    formData.append('file', mediaList[0].file);
  }
  return formData;
};

export default makeBaseTestCaseUploadRequestDTO;

