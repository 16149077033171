import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import _get from 'lodash/get';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FontIcon from 'tcomponents/atoms/FontIcon';
import _noop from 'lodash/noop';
import Modal from 'tcomponents/molecules/Modal';
import FieldLabel from 'tcomponents/organisms/FormBuilder/components/fieldLabel';
import styles from './labelRenderer.module.scss';
import PopoverContent from '../../../../molecules/PopoverContent';
import AddLabelForm from '../../../testCases/organisms/AddLabelForm/AddLabelForm';

class LabelRenderer extends PureComponent {
  state = {
    isOpen: false,
    isModalVisible: false,
    tagValue: [],
    tagStateData: null,
    tagsCollection: [],
  };

  componentDidMount() {
    const { data } = this.props;
    const testCaseId = _get(data, 'testCaseId', EMPTY_STRING);
  }

  componentDidUpdate(tagStateData, _prevState) {
    const { data } = this.props;
    this.setState(()=>({
      tagStateData: data,
    }))
  }
  
  renderAssignmentIcon = ({ disabled, onClick }) => (
    <div className={styles.addTechnicianIcon} id="assignTech">
      <FontIcon
        disabled={disabled}
        onClick={disabled ? _noop : onClick}
        size={16}
      >
        icon-add-person
      </FontIcon>
    </div>
  );

  setTagValue = (value) => {
    this.setState(() => ({
      tagValue: value,
    }));
  };

  renderPopOverContent = (tagStateData, isOpen) => {
    if (tagStateData?.length > 1) {
      return (
        <Popover
          pointed
          adjustVerticalAlign={false}
          position="top"
          align="left"
          popOverClass="show-more-items__extra-items"
          open={isOpen}
          onClose={this.toggleOpen}
          content={<PopoverContent tags={tagStateData.slice(1)} />}
          trigger="click"
        >
          <span className={styles.moreItemsBtn}>
            +{tagStateData.length - 1}
          </span>
        </Popover>
      );
    }
    return null;
  };

  render() {
    const {
      isOpen,
      isModalVisible,
      tagValue,
      tagStateData,
      tagsCollection,
    } = this.state;
    const { data } = this.props;
    // const { testCaseId } = data;
    const testCaseId = _get(data, 'testCaseId', EMPTY_STRING);
  
    return (
      <>
        <div className={styles.technicianContainer}>
          <FieldLabel label={tagStateData?.[0]} required={false} />
          {this.renderPopOverContent(tagStateData, isOpen)}
        </div>
      </>
    );
  }
}


export default LabelRenderer;
