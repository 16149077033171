import _get from 'lodash/get';
import _pick from 'lodash/pick';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import { getFiltersWithOutNullValue } from 'tcomponents/organisms/filterSection/utils/filterSection.utils';
import TEXT_WITH_LINK_ACTION_TYPES from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer/textWithLink.actionTypes';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import TEST_RESULT_LIST_ACTION_TYPES from '../constants/testResultsList.actiontypes';
import {
  updateStatusTestResult, updateBulkStatusTestResult, getTestResultsDTO, filterDTO, uploadMediaDTO, paginationDTO, paginationDTOwithoutFilter,
} from './testResultsList.request';
import {
  getTestResultsByTestSessionId, postUpdateTestResults, postBulkUpdateTestResults, searchtestResultFilter, postMediaTestResult, getMediaTestResult, getTestCaseFromSequenceString, getModuleFilter,
} from '../../../../../services/testSessions.services';
import HandleErrorAndStatus from '../../../../../../../helpers/ErrorFunction/errorFunction';
import executeTestCases from '../../../../../../../organisms/executeTestCases';
import {
  executeTestSuite,executeReRunTestSession
} from '../../../../../../../organisms/executeTestCases/executeTestCasesForm/services/executeTestCasesForm';
import {EMPTY_STRING} from "@tekion/tekion-base/app.constants";
import ACTION_TYPES from '../constants/testResultsList.actiontypes';

const handleSuccessUpdate = (response) => {
  const statusCode = _get(response, 'data.status');
  if (statusCode === 'Success') {
    toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
  }
};

const handleTableItemsFetch = async ({ setState, getState }) => {
  const { match, currentPage, pageSize, selectedFilters } = getState();
  const testSessionId = _get(match, 'params.testsessionId');
  const TestResultDTO = getTestResultsDTO(testSessionId, currentPage, pageSize, selectedFilters);
  setState({ isLoading: true });
  const responseData = await getTestResultsByTestSessionId(TestResultDTO);
  const testResultsData = _get(responseData, 'data.data.hits');
  const countTestResults = _get(responseData, 'data.data.count');
  setState({
    totalCount: countTestResults,
    data: testResultsData,
    isLoading: false,
  });
};

const handleItemsSetFilter = async ({ params, getState, setState }) => {
  const {
    searchText, selectedTabId, sortDetails, currentPage, pageSize,
  } = getState();

  const { match } = getState();
  const testSessionId = _get(match, 'params.testsessionId');
  const { value } = params;
  const filterValues = getFiltersWithOutNullValue(value);
  setState({ isLoading: true });
  const filterReuestDTO = filterDTO(filterValues, testSessionId, 1, pageSize);
  const searchData = await searchtestResultFilter(filterReuestDTO);
  const testResultsData = _get(searchData, 'data.data.hits');
  const countTestResults = _get(searchData, 'data.data.count');
  setState({
    totalCount: countTestResults,
    data: testResultsData,
    selectedFilters: filterValues,
    isLoading: false,
    currentPage: 1,
  });
};

const handleTableItemSelect = ({ params, setState }) => {
  const { value: selection } = params;
  setState({ selection });
};

const handleOpenMedialModal = async ({ setState, params }) => {
  const { selectedTestResultId } = params;
  setState({
    isMediaModalVisible: true,
    selectedTestResultId,
    isDisplayMediaModalLoading: true,
  });
  const response = await getMediaTestResult(selectedTestResultId);
  const mediaArray = _get(response, 'data.data');
  setState({
    mediaArray,
    isDisplayMediaModalLoading: false,
  });
};

const handleCloseMedialModal = ({ setState }) => {
  setState({
    isMediaModalVisible: false,
    // selectedTestResultId,
  });
};

const handleOpenUploadModal = ({ setState, params }) => {
  const { selectedTestResultId, mediaId } = params;
  setState({
    isUploadModalVisible: true,
    selectedTestResultId,
  });
};

const handleCloseUploadModal = ({ setState }) => {
  setState({
    isUploadModalVisible: false,
    // selectedTestResultId,
  });
};

const handleRowAction = async ({ params, setState, getState }) => {
  setState({ isLoading: true });
  const { value, action } = (params);
  const testCaseId = _get(value, 'id');
  const statusDTO = updateStatusTestResult(action, testCaseId);
  await postUpdateTestResults(statusDTO).then(response => handleSuccessUpdate(response));
  handleTableItemsFetch({ setState, getState });
};

const handleRowBulkAction = async ({ params, getState, setState }) => {
  setState({ isLoading: true });
  const { selection, match } = getState();
  const testSessionId = _get(match, ['params', 'testsessionId'], EMPTY_STRING);
  const { action } = params;
  const key = _get(action, 'key');
  const statusDTO = updateBulkStatusTestResult(selection, testSessionId, key);
  await postBulkUpdateTestResults(statusDTO).then(response => handleSuccessUpdate(response));
  handleTableItemsFetch({ setState, getState });
};

const handleUploadMedia = async ({ params, getState, setState }) => {
  const { mediaId, selectedTestResultId, mediaList } = params;
  const formDataDTO = uploadMediaDTO(mediaList);
  try {
    toaster(TOASTER_TYPE.INFO, __('Uploading Media...please wait for a while'));
    setState({
      isUploadModalVisible: false,
    });
    await postMediaTestResult(formDataDTO, selectedTestResultId);
    toaster(TOASTER_TYPE.SUCCESS, __('Media Uploaded Successfully'));
    handleTableItemsFetch({ setState, getState });
  } catch (error) {
    setState({
      isUploadModalVisible: false,
    });
    HandleErrorAndStatus(error);
  }
};

const handlePagination = async ({ params, setState, getState }) => {
  const { match, selectedFilters } = getState();
  const testSessionId = _get(match, 'params.testsessionId');
  const { value } = params;
  const { page, resultsPerPage } = value;
  if (selectedFilters === undefined) {
    const pagination = paginationDTOwithoutFilter(page, resultsPerPage, testSessionId);
    setState({ isLoading: true });
    const responseData = await getTestResultsByTestSessionId(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize: resultsPerPage,
      currentPage: page,
    });
  } else {
    const pagination = paginationDTO(page, resultsPerPage, testSessionId, selectedFilters);
    setState({ isLoading: true });
    const responseData = await getTestResultsByTestSessionId(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize: resultsPerPage,
      currentPage: page,
    });
  }
};

const handleOpenTestCaseDetailsModal = async ({ params, setState }) => {
  const testCaseId = _get(params, 'rowData.testCaseId');
  setState({
    isDetailsModalVisible: true,
    isDetailsModalLoading: true,
    testCaseId,
  });
  const response = await getTestCaseFromSequenceString(testCaseId);
  setState({
    isDetailsModalLoading: false,
    testCaseValue: response,
  });
};

const handleCloseTestCaseDetailsModal = ({ params, setState }) => {
  setState({
    isDetailsModalVisible: false,
  });
};

const handleSortChange = ({ params, setState, getState }) => {
  const { value } = params;
  const sortTypeMap = _get(value, 'sortTypeMap');
  const keyToSort = _get(value, 'column.key',);
  const sortDetails = _pick(sortTypeMap, keyToSort);
  setState({
    sortDetails,
  });
};

const handleReRunTestCase = ({ params, setState }) => {
  const { data } = params;
  setState({
    isConfirmationModalVisible: true,
    reRunData: data,
  });
};

const handleConfirmationModalCancel = ({ setState }) => {
  setState({
    isConfirmationModalVisible: false,
  });
};

const handleConfirmationModalSubmit = async ({ params, getState, setState }) => {
  const { reRunData: data, match } = getState();
  const testSessionId = _get(match, ['params', 'testsessionId'], EMPTY_STRING);
  const failedTestCaseList = _filter(data, ['status', 'FAIL']);
  const isChecked = _get(params, 'isChecked', false);
  const jenkinsPayload = _map(failedTestCaseList, data => ({
    id: data.id,
    releaseVersion: data.releaseVersion,
    testCaseId: data.testCaseId,
    environment: data.environment,
    dealerName: 'Techmotors',
    dealerId: '4',
    projectId: data.projectId,
    productId: data.productId,
    moduleName: data.moduleName,
  }));
  setState({
    isLoading: true,
  });

  if (isChecked) {
    const triggerJenkinsResponseForReRun = await executeReRunTestSession(jenkinsPayload, testSessionId)
        .then(() => {
          toaster(TOASTER_TYPE.SUCCESS, __('Test Cases Re Run successful'))
        });
    setState({
      isLoading: false,
      isConfirmationModalVisible: false,
    })
  } else {
    toaster(TOASTER_TYPE.ERROR, __('Please select the checkbox to continue'));
    setState({
      isLoading: false,
      isConfirmationModalVisible: true,
    })
  }
};

const handleInitialModuleFilter = async ({params, setState}) =>{
  const {testSessionId}  = params;
  const response = await getModuleFilter(testSessionId)
  const moduleMap = _get(response, 'data.data');
  let moduleLists = [];
  for(let key in moduleMap) {
    moduleLists.push({id : key, name : moduleMap[key]})
  }
  setState({modules : moduleLists})
}

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEM_SELECT]: handleTableItemSelect,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleItemsSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePagination,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SORT]: handleSortChange,
  [TEST_RESULT_LIST_ACTION_TYPES.OPEN_MEDIA_MODAL]: handleOpenMedialModal,
  [TEST_RESULT_LIST_ACTION_TYPES.CLOSE_MEDIA_MODAL]: handleCloseMedialModal,
  [TEST_RESULT_LIST_ACTION_TYPES.OPEN_UPLOAD_MODAL]: handleOpenUploadModal,
  [TEST_RESULT_LIST_ACTION_TYPES.CLOSE_UPLOAD_MODAL]: handleCloseUploadModal,
  [TEST_RESULT_LIST_ACTION_TYPES.ROW_ACTION]: handleRowAction,
  [TEST_RESULT_LIST_ACTION_TYPES.ROW_BULK_ACTION]: handleRowBulkAction,
  [TEST_RESULT_LIST_ACTION_TYPES.UPLOAD_MEDIA]: handleUploadMedia,
  [TEXT_WITH_LINK_ACTION_TYPES.TEXT_CLICK]: handleOpenTestCaseDetailsModal,
  [TEST_RESULT_LIST_ACTION_TYPES.CLOSE_TESTCASE_DETAILS_MODAL]: handleCloseTestCaseDetailsModal,
  [TEST_RESULT_LIST_ACTION_TYPES.RE_RUN_CLICK]: handleReRunTestCase,
  [TEST_RESULT_LIST_ACTION_TYPES.CONFIRMATION_MODAL_CANCEL]: handleConfirmationModalCancel,
  [TEST_RESULT_LIST_ACTION_TYPES.CONFIRMATION_MODAL_SUBMIT]: handleConfirmationModalSubmit,
  [ACTION_TYPES.FETCH_MODULE_FILTER] : handleInitialModuleFilter
};

export default ACTION_HANDLERS;
