import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _map from 'lodash/map';

import withSize from 'tcomponents/hoc/withSize';
import colors from '@tekion/tekion-styles-next/scss/variables.scss';
import withActions from 'tcomponents/connectors/withActions';
import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';
import useFetchData from 'tbase/customHooks/useFetchData';
import { withRouter } from 'react-router-dom';
import TableManager from 'tcomponents/organisms/TableManager';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import KebabMenu from '@tekion/tekion-components/src/molecules/KebabMenu';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { makeTableProps } from '../../helper/viewReleaseBoard.general';
import { columns } from './constant/viewReleaseBoardList.columns';
import { getReleaseInfo } from '../../Services/releaseBoard.services';
import ACTION_HANDLERS from '../../helper/viewReleaseBoard.actionHandlers';
import INITIAL_STATE from '../../Constants/viewReleaseBoard.initialState';
import styles from './viewReleaseBoardList.module.scss';
import ACTION_TYPES from '../../Constants/viewReleaseBoard.actionTypes';
import { REVERSE_ROLE_TYPE, ROLE_TYPE } from '../../../../constants/userRole/userRoleMap';
import TokenManager from '@tekion/tap-components/utils/TokenManager';


const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const SortableTableManager = withRowActions(withCustomSortTable(TableManager));


const ViewReleaseBoardList = (props) => {
  const {
    tabId,
    onAction,
    isLoading,
    currentPage,
    history,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    pageSize,
    tableData,
  } = props;

  const tableDataForSanity = _get(tableData, 'data.data.hits', EMPTY_ARRAY);

  const sanitizedData = tableDataForSanity;
  _map(sanitizedData, (element) => {
    if (element.isActive) { element.isActiveText = 'True'; } else { element.isActiveText = 'False'; }
  });

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: false,
    showTotalCount: false,
    showRefreshIcon: false,
  };

  const KEBAB_ELEMENT = <span className="icon-overflow" />;

  const tableMenuItems = [{
    key: 'CLICK_TRUE',
    label: __('True'),
  },
  {
    key: 'CLICK_FALSE',
    label: __('False'),
  },
  ];

  const onKebabClick = (action, payload) => {
    onAction({
      type: ACTION_TYPES.ROW_ACTION,
      payload: { action, payload },
    });
  };

  const getRowActionCell = ({ original }) => {
    const roleHash = TokenManager.getItem('role');
    let isKebabDisabled = false;
    if(ROLE_TYPE[roleHash] === REVERSE_ROLE_TYPE.GUEST){
      return ([])
    } else {
      return(<KebabMenu
        onClickAction={onKebabClick}
        menuItems={tableMenuItems}
        triggerElement={KEBAB_ELEMENT}
        rowInfo={original}
      />)
    }

  };

  const columnsWithConfigurator = _compact([
    ...columns,
    {
      // ...columnConfigurator,
      Cell: getRowActionCell,
      accessor: 'COLUMN_CONFIGURATOR',
      className: styles.noBorder,
      width: 60,
    },
  ]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      onAction,
      selection,
      tableDataForSanity,
    }),
    [isLoading, totalCount, onAction, selection, tableDataForSanity]
  );

  return (
    <PageComponent.Body style={{ height: contentHeight }}>
      <TableManager
        columns={columnsWithConfigurator}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        data={sanitizedData}
        selection={selection}
        tableProps={getTableProps}
        isLoading={isLoading}
      />
    </PageComponent.Body>
  );
};
export default compose(
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(ViewReleaseBoardList);
