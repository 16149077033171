import { createSelector } from 'reselect';
/**
 * @description Selector Function for getting the specified state from reducer
 */
const profileReducer = state => state.profile;

const makeUserDetails = () => createSelector(profileReducer,
  substate => substate.userDetails);

const makeFetchApiStatus = () => createSelector(profileReducer,
  substate => substate.isFetchApiCalled);

export {
  makeUserDetails,
  makeFetchApiStatus,
};
