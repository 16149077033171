/* eslint-disable import/order */
/* eslint-disable rulesdir/no-inline-functions */
// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';

// Utils
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';

// Components
import Modal from 'tcomponents/molecules/Modal';
import UploadTestCasesForm from '../uploadTestCasesForm';

// Constants
import CONTEXT_ID from '../constants/uploadTestCases.general';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import Styles from './uploadTestCasesModal.module.scss';

const UploadTestCasesModal = (props) => {
  const {
    onSubmit, onCancel, isVisible, isUploading,
  } = props;

  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, EMPTY_ARRAY);

  return (
    <Modal
      title={__('Upload Test Cases')}
      visible={isVisible}
      submitBtnText={__('Submit')}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      width={Modal.SIZES.SM}
      loading={isUploading}
      destroyOnClose
    >
      <UploadTestCasesForm onSubmit={onSubmit} />
      <br></br>
      <div className={`${Styles.Instructions}`}>
        <b><center>* INSTRUCTIONS *</center></b>
        <ul>
          <li>Column order & name must be same as template</li>
          <li>All columns highlighted red in template are mandatory fields.</li>
          <li>Number of testcase is limited to 700 per excel file.</li>
          <li>Incase of multiple sheets only first sheet will get upload.</li>
          <li>Excel file format accepted are .xlsx & .xls</li>
          <li>Active, Regression and Automated field values are yes/no.</li>
        </ul>
      </div>
    </Modal>
  );
};

UploadTestCasesModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  isUploading: PropTypes.bool,
};

UploadTestCasesModal.defaultProps = {
  onSubmit: _noop,
  onCancel: _noop,
  isVisible: false,
  isUploading: false,
};

export default UploadTestCasesModal;
