import React from 'react';
import Style from './sessionExpired.module.scss';

import SessionIcon from '../../asset/SessionIcon.png';

function SessionExpired() {
  return (
    <div>
      <div className={`${Style.Main}`}>
        <div className={`${Style.Oops}`}>
    Oops..!
        </div>
        <div className={`${Style.SessionIcon}`}>
          <center>
            <img src={SessionIcon} alt="Session Expired Icon" />
          </center>
        </div>

        <div className={`${Style.PrimaryText}`}>
        Session Expired
        </div>
        <div className={`${Style.SecondaryText}`}>
            Please Login Again
        </div>
      </div>
    </div>
  );
}

export default SessionExpired;
