import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
// import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
// import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
// import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';


// const StatusCellRenderer = makeCellRenderer(StatusRenderer);

// const MODULE = {
//   id: 'moduleName',
//   key: 'moduleName',
//   accessor: 'moduleName',
//   Header: 'Module',
//   Cell: TextRenderer,
//   //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
//   sortable: true,
// };

const PROJECT = {
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Project',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const TOTAL_TEST_CASES = {
  id: 'totalTestCases',
  key: 'totalTestCases',
  accessor: 'totalTestCases',
  Header: 'Total Test Cases',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const TOTAL_TEST_CASES_PASSED = {
  id: 'passed',
  key: 'passed',
  accessor: 'passed',
  Header: 'Total Test Cases Passed',
  Cell: TextRenderer,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TOTAL_TEST_CASES_FAILED = {
  id: 'failed',
  key: 'failed',
  accessor: 'failed',
  Header: 'Total Test Cases Failed',
  Cell: TextRenderer,
  sortable: true,
};

const TOTAL_TEST_CASES_SKIPPED = {
  id: 'skipped',
  key: 'skipped',
  accessor: 'skipped',
  Header: 'Total Test Cases Skipped',
  Cell: TextRenderer,
  sortable: true,
};

const TOTAL_TEST_CASES_PENDING = {
  id: 'pending',
  key: 'pending',
  accessor: 'pending',
  Header: 'Total Test Cases Pending',
  Cell: TextRenderer,
  sortable: true,
};
// const P0_PASSED = {
//   id: 'p0Passed',
//   key: 'p0Passed',
//   accessor: 'p0Passed',
//   Header: 'P0 Passed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
// const P0_FAILED = {
//   id: 'p0Failed',
//   key: 'p0Failed',
//   accessor: 'p0Failed',
//   Header: 'P0 Failed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
// const P1_PASSED = {
//   id: 'p1Passed',
//   key: 'p1Passed',
//   accessor: 'p1Passed',
//   Header: 'P1 Passed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
// const P1_FAILED = {
//   id: 'p1Failed',
//   key: 'p1Failed',
//   accessor: 'p1Failed',
//   Header: 'P1 Failed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
// const P2_PASSED = {
//   id: 'p2Passed',
//   key: 'p2Passed',
//   accessor: 'p2Passed',
//   Header: 'P2 Passed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
// const P2_FAILED = {
//   id: 'p2Failed',
//   key: 'p2Failed',
//   accessor: 'p2Failed',
//   Header: 'P2 Failed',
//   Cell: TextRenderer,
//   // getProps: TextRenderer,
//   sortable: true,
// };
export const columns = [PROJECT, TOTAL_TEST_CASES, TOTAL_TEST_CASES_PASSED,
  TOTAL_TEST_CASES_FAILED, TOTAL_TEST_CASES_PENDING, TOTAL_TEST_CASES_SKIPPED];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
