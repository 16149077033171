import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Dropdown from 'tcomponents/molecules/DropDown';
import Menu from 'tcomponents/molecules/Menu';
import Button from 'tcomponents/atoms/Button';
import FontIcon from 'tcomponents/atoms/FontIcon';

import { KEY_VS_ROUTE, BUTTON_LABEL } from './buttonPopover.constant';
import { MORE_OPTIONS_BUTTON } from './buttonPopover.constant';

import styles from './buttonPopover.module.scss';

class ButtonPopOver extends PureComponent {

  handleClick = key => () => {
    const { history } = this.props;
    console.log(key);
    history.push(`/tcmsuiV2/${KEY_VS_ROUTE[key]}`);
  };

   handleMenuClick = ({ key }) => {
    const { UPLOAD_STATUS, TRACKING_CONTROL, AUTOMATION_METRIC } = MORE_OPTIONS_BUTTON;
    const keyMap = {
      [UPLOAD_STATUS]: this.handleClick(UPLOAD_STATUS),
      [TRACKING_CONTROL]: this.handleClick(TRACKING_CONTROL),
      [AUTOMATION_METRIC]: this.handleClick(AUTOMATION_METRIC),
    };
    keyMap[key]();
  };

  renderUploadStatusItem = () => {
    return (
      <Menu.Item key={MORE_OPTIONS_BUTTON.UPLOAD_STATUS}>
        <span className="cursor-pointer" role="presentation">
          {BUTTON_LABEL[MORE_OPTIONS_BUTTON.UPLOAD_STATUS]}
        </span>
      </Menu.Item>
    );
  };

  renderTrackingControlItem = () => {
    return (
        <Menu.Item key={MORE_OPTIONS_BUTTON.TRACKING_CONTROL}>
        <span className="cursor-pointer" role="presentation">
          {BUTTON_LABEL[MORE_OPTIONS_BUTTON.TRACKING_CONTROL]}
        </span>
        </Menu.Item>
    );
  };

  renderAutomationMetricItem = () => {
    return (
        <Menu.Item key={MORE_OPTIONS_BUTTON.AUTOMATION_METRIC}>
        <span className="cursor-pointer" role="presentation">
          {BUTTON_LABEL[MORE_OPTIONS_BUTTON.AUTOMATION_METRIC]}
        </span>
        </Menu.Item>
    );
  };

  getMenu = () => (
    <Menu onClick={this.handleMenuClick} id="buttonPopver">
      {this.renderUploadStatusItem()}
      {this.renderTrackingControlItem()}
      {this.renderAutomationMetricItem()}
    </Menu>
  );

  render() {
    return (
      <Button view="primary" className={styles.popoverButton}>
        <div className="flex-center">
          <span
            className={styles.primaryButton}
            role="presentation"
            tabIndex={0}
          >
            {'More Options'}
          </span>
          <Dropdown
            overlay={this.getMenu}
            trigger={['click']}
            placement={'bottomRight'}
            overlayClassName={styles.dropdownOverlay}
          >
            <FontIcon className={styles.chevronDownIcon} size={12}>
              icon-chevron-down
            </FontIcon>
          </Dropdown>
        </div>
      </Button>
    );
  }
}

ButtonPopOver.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ButtonPopOver);
