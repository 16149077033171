import _head from 'lodash/head';

// components
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import { EMPTY_OBJECT } from 'tbase/app.constants';

// utils
import { getErrorMessage } from 'tbase/utils/errorUtils';

import {
  TEST_SESSION_NAME, RELEASE, TYPE, STATUS, ENV, PROJECT, PRODUCT,
} from '../../TestSessionForm/helpers/testsessionForm.fields';

import { editTestSession } from '../../../services/testSessions.services';
import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';

const makeTestSessionRequestDTO = (formValues = EMPTY_OBJECT, testSessionId) => {
  const id = testSessionId;
  const name = formValues[TEST_SESSION_NAME.id];
  const releaseVersion = _head(formValues[RELEASE.id]);
  const type = _head(formValues[TYPE.id]);
  const status = _head(formValues[STATUS.id]);
  const environment = _head(formValues[ENV.id]);
  //   const projectId = _head(formValues[PROJECT.id]);
  //   const productId = _head(formValues[PRODUCT.id]);

  const testSessionRequestDTO = {
    id,
    name,
    releaseVersion,
    type,
    status,
    environment,
    // productId,
    modifiedBy: 'Modifier',
  };
  return testSessionRequestDTO;
};

const handleEditTestSessionSuccess = () => {
  toaster(TOASTER_TYPE.SUCCESS, __('Test Session Edited Successfully'));
};

const editTestSessionSubmit = async (formValues, testSessionId) => {
  const testSessionRequestDTO = makeTestSessionRequestDTO(formValues, testSessionId);

  try {
    await editTestSession(testSessionRequestDTO);
    handleEditTestSessionSuccess();
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export default editTestSessionSubmit;
