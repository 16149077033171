export const INFO_ICON_SOURCE_VS_LABEL = {
    MANUAL: 'Manual',
    AUTO: 'Automation',
    EXECUTE: 'TCMS Executor',
    JIRA: 'Jira'
}

export const TEST_SESSION_SOURCE_VS_ICONS = {
    MANUAL: 'icon-user',
    AUTO: 'icon-hat',
    EXECUTE: 'icon-total',
    JIRA: 'icon-archive'
}

export const INFO_ICONS = {
    MANUAL: 'MANUAL',
    AUTO: 'AUTO',
    EXECUTE: 'EXECUTE',
    JIRA: 'JIRA'
}
