import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import TableManager from 'tcomponents/organisms/TableManager';
import withSize from 'tcomponents/hoc/withSize';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';

import { columns } from './constants/statusTable.columns';

import { makeTableProps } from './helpers/statusTable.general';

import styles from '../../integrationHealth.module.scss';


const StatusTable = (props) => {
  const {
    onAction,
    isLoading,
    data,
    totalCount,
    sortDetails,
    selection,
    contentHeight,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: false,
    showTotalCount: false,
    showRefreshIcon: false,
  };

  const tableData = _get(data, 'data.data.hits', EMPTY_ARRAY);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      onAction,
      selection,
      sortDetails,
      tableData,
    }),
    [isLoading, totalCount, onAction, selection, sortDetails, tableData]
  );

  return (
    <PageComponent.Body className={styles.tableStyle} style={{ height: contentHeight }}>
      <TableManager
        columns={columns}
        data={tableData}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        selection={selection}
        tableProps={getTableProps}
      />
    </PageComponent.Body>
  );
};

StatusTable.propTypes = {
  onAction: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  totalCount: PropTypes.any,
  sortDetails: PropTypes.any,
  selection: PropTypes.any,
  contentHeight: PropTypes.any,
};

StatusTable.defaultProps = {
  onAction: _noop(),
  isLoading: false,
  data: EMPTY_ARRAY,
  totalCount: 0,
  sortDetails: _noop,
  selection: _noop,
  contentHeight: 0,
};
export default compose(
  withRouter,
  withSize({ hasPageHeader: 0, hasPageFooter: 0 }),
)(StatusTable);
