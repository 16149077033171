import Http from '../../../services/http';
import URL from '../../../api/urls';

export const getAllProducts = async () => {
  const response = await Http.get('CQA', `${URL.getAllProducts}`);
  return response;
};

export const getAllReleaseVersions = async () => {
  const response = await Http.get('CQA', `${URL.getAllReleaseInfo}`);
  return response;
};

export const getAllProjects = async () => {
  const response = await Http.get('CQA', `${URL.getAllProjects}`);
  return response;
};

export const getAllDealers = async () => {
  const response = await Http.get('CQA', `${URL.getAllDealers}`);
  return response;
};

export const getAllHistoryCollectionInfo = async (productName, projectName, environment, dealer, releaseVersion) => {
  const response = await Http.get('CQA', `${URL.getAllHistoryCollectionData}?productName=${productName}&projectName=${projectName}&environment=${environment}&dealer=${dealer}&releaseVersion=${releaseVersion}`);
  return response;
};
