import Http from '../../../../services/http';
import URL from '../../../../api/urls';

export const executeTestSuite = async (payload) => {
  const response = await Http.post(
    'CQA',
    `${URL.executeTestSuite}`,
    payload.data.map(x => ({
      id: x.id,
      releaseVersion: payload.releaseVersion,
      testCaseId: x.testCaseId,
      environment: payload.environment,
      dealerName: payload.dealerName,
      projectId: payload.projectId,
      productId: payload.productId,
      moduleName: payload.moduleName,
      testSession: payload.testSessionId,
      dealerId: payload.dealerId,
      runType: payload.runType,
    }))
  );
  return response;
};

export const executeReRunTestSession = async (payload, testSessionId) => {
    const response = await Http.post('CQA', `${URL.executeTestSuite}?reRun=true&testSessionId=${testSessionId}`, payload);
    return response;
};

export const getAllReleaseVersionsApi = async () => {
  const response = await Http.get('CQA', `${URL.getAllReleaseVersion}`);
  return response;
};


export const getDealerInfoByENV = async (payload) => {
  const response = await Http.get(
    'CQA',
    `${URL.getDealerInfoByENV}?environment=${payload}`
  );
  return response;
};
