import {
  TEST_CASE_NAME, AUTHOR, PRIORITY, JIRA, BRANCH,
  DESCRIPTION, EXIT_CRITERIA, RELEASE, COMPONENT, TYPE, ACTIVE,
  AUTOMATED, REGRESSION, MISC, STEPS, PRECONDITIONS, PRODUCT,
  PROJECT, FEATURE, SUBFEATURE, TCCATEGORY, UPSTREAMCONSUMPTION, DOWNSTREAMCONSUMPTION, HORIZONTALIMPLEMENTATION
} from './uploadTestCasesForm.fields';

const SECTIONS = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [PRODUCT.id, PROJECT.id],
      },
      {
        columns: [FEATURE.id, SUBFEATURE.id],
      },
      {
        columns: [TEST_CASE_NAME.id, AUTHOR.id],
      },
      {
        columns: [PRIORITY.id, JIRA.id],
      },
      {
        columns: [COMPONENT.id, TYPE.id],
      },
      {
        columns: [DESCRIPTION.id],
      },
      {
        columns: [EXIT_CRITERIA.id],
      },
      {
        columns: [STEPS.id],
      },
      {
        columns: [PRECONDITIONS.id],
      },

      {
        columns: [ACTIVE.id, AUTOMATED.id],
      },
      {
        columns: [RELEASE.id, REGRESSION.id],
      },
      {
        columns: [MISC.id, BRANCH.id],
      },
      {
        columns: [TCCATEGORY.id],
      },
      {
        columns: [UPSTREAMCONSUMPTION.id],
      },
      {
        columns: [DOWNSTREAMCONSUMPTION.id],
      },
      {
        columns: [HORIZONTALIMPLEMENTATION.id],
      },
    ],
  },
];

export default SECTIONS;
