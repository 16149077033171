import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import { isRequiredRule } from 'tbase/utils/formValidators';

import TYPE_OPTIONS from '../../../../../constants/typeOptions';
import { STATUS_OPTIONS } from '../../../../../constants/statusOptions';
import ENV_OPTIONS from '../../../../../constants/envOptions';

export const TEST_SESSION_NAME = {
  id: 'testSessionName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('TestSession Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PRODUCT = {
  id: 'product',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'project',
  renderer: SelectInput,
  renderOptions: {
    label: __('Project Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const RELEASE = {
  id: 'release',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Release'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const TYPE = {
  id: 'type',
  renderer: SelectInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const STATUS = {
  id: 'status',
  renderer: SelectInput,
  renderOptions: {
    label: __('Status'),
    required: true,
    options: STATUS_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const ENV = {
  id: 'env',
  renderer: SelectInput,
  renderOptions: {
    label: __('Environment'),
    required: true,
    options: ENV_OPTIONS,
    validators: [isRequiredRule],
  },
};
