const ACTION_TYPES = {
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
  ROW_ACTION: 'ROW_ACTION',
  TABLE_ITEMS_FETCH: 'TABLE_ITEMS_FETCH',
  MOVE_TEST_CASE_MODAL_ON_CANCEL: 'MOVE_TEST_CASE_MODAL_ON_CANCEL',
  MOVE_TEST_CASE_MODAL_ON_SUBMIT: 'MOVE_TEST_CASE_MODAL_ON_SUBMIT',
  MOVE_TEST_CASE_MODAL_FIELD_CHANGE: 'MOVE_TEST_CASE_MODAL_FIELD_CHANGE',
  BULK_UPDATE_TEST_CASES_ON_CANCEL: 'BULK_UPDATE_TEST_CASES_ON_CANCEL',
  BULK_UPDATE_TEST_CASES_ON_SUBMIT: 'BULK_UPDATE_TEST_CASES_ON_SUBMIT',
  BULK_UPDATE_TEST_CASES_FIELD_CHANGE: 'BULK_UPDATE_TEST_CASES_FIELD_CHANGE',
  ROW_BULK_ACTION: 'ROW_BULK_ACTION',
  ADD_TEST_CASE_TO_JIRA: 'ADD_TEST_CASE_TO_JIRA'
};

export default ACTION_TYPES;
