// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const LOWEST = {
  name: __('P4 - Lowest'),
  id: '4',
};
const LOW = {
  name: __('P3 - Low'),
  id: '3',
};
const MEDIUM = {
  name: __('P2 - Medium'),
  id: '2',
};
const HIGH = {
  name: __('P1 - High'),
  id: '1',
};
const HIGHEST = {
  name: __('P0 - Highest'),
  id: '0',
};

const PRIORITIES = [HIGHEST, HIGH, MEDIUM, LOW, LOWEST];

export const PRIORITY_LABEL_MAP = {
  [HIGHEST.id]: HIGHEST.name,
  [HIGH.id]: HIGH.name,
  [MEDIUM.id]: MEDIUM.name,
  [LOW.id]: LOW.name,
  [LOWEST.id]: LOWEST.name,
};

const PRIORITY_OPTIONS = standardFieldOptionMapper(undefined, PRIORITIES);
export default PRIORITY_OPTIONS;
