import { createSelector } from 'reselect';
import { compose } from 'recompose';

// Lodash
import _property from 'lodash/property';
import _get from 'lodash/property';
import _groupBy from 'lodash/groupBy';

import tcmsBootstrapReader from '../../readers/TcmsBootstrap';

import featureReader from '../../readers/Feature';
import projectReader from '../../readers/Project';


export const getTcmsBootstrap = (state) => {
  const BootStrap = state?.TcmsApp?.tcmsBootstrap?.ApiData;
  return BootStrap;
};
const createFeatureListByProjectId = (tcmsBootstrap) => {
  const featureList = tcmsBootstrapReader.featureList(tcmsBootstrap);
  const featureListByProjectId = _groupBy(featureList, featureReader.projectId);
  return featureListByProjectId;
};
const createsubFeatureListByFeatureId = (tcmsBootstrap) => {
  const subfeatureList = tcmsBootstrapReader.subfeatureList(tcmsBootstrap);
  const subfeatureListByFeatureId = _groupBy(subfeatureList, featureReader.featureId);
  return subfeatureListByFeatureId;
};
const createProjectListByProductId = (tcmsBootstrap) => {
  const projectList = tcmsBootstrapReader.projectList(tcmsBootstrap);
  const projectListByProductId = _groupBy(projectList, projectReader.productId);
  return projectListByProductId;
};

const createReleaseVersion = (tcmsBootstrap) => {
  const releaseVersionList = tcmsBootstrapReader.releaseVersionList(tcmsBootstrap);
  return releaseVersionList;
};

export const getFeatureListByProjectId = createSelector([getTcmsBootstrap], createFeatureListByProjectId);
export const getProjectListByProductId = createSelector([getTcmsBootstrap], createProjectListByProductId);
export const getsubFeatureListByfeatureId = createSelector([getTcmsBootstrap], createsubFeatureListByFeatureId);
export const getReleaseVersion = createSelector([getTcmsBootstrap], createReleaseVersion);
