import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _size from 'lodash/size';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Components
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import Badge from 'tcomponents/atoms/BadgeWrapper';

// Constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import TEST_RESULT_ACTION_TYPES from '../../constants/testResultsList.actiontypes';

// Readers
// import testResultReader from '../../../../readers/TestResult';

// Styles
import styles from '../../../../testResults.module.scss';

function DisplayMediaRenderer(props) {
  const { data: testResult, onAction } = props;
  // const uploads = testResultReader.uploads(testResult);
  // const mediaCount = _size(uploads);
  const selectedTestResultId = _get(props, 'original.id');
  const count = _get(props, 'original.mediaAvailable') === true ? 1 : 0;

  const handleMediaModalOpen = useCallback(() => {
    onAction({ type: TEST_RESULT_ACTION_TYPES.OPEN_MEDIA_MODAL, payload: { selectedTestResultId } });
  }, [onAction, selectedTestResultId]);

  return (
    <Badge count={count} className={styles.dotBadge} dot>
      <IconAsBtn onClick={handleMediaModalOpen}>icon-camera</IconAsBtn>
    </Badge>
  );
}

DisplayMediaRenderer.propTypes = {
  data: PropTypes.object,
  onAction: PropTypes.func,
};

DisplayMediaRenderer.defaultProps = {
  data: EMPTY_OBJECT,
  onAction: _noop,
};

export default makeCellRenderer(DisplayMediaRenderer);
