// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
import subfeatureList from '../readers/Feature';

const SUBFEATURE_OPTION_MAPPER = {
  label: subfeatureList.name,
  value: subfeatureList.name,
};

const makesubFeatureOptions = subfeatureList => standardFieldOptionMapper(undefined, subfeatureList, SUBFEATURE_OPTION_MAPPER);

export default makesubFeatureOptions;
