// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';

// Components
import Content from 'tcomponents/atoms/Content';

// Styles
import styles from './downloadExcelTemplate.module.scss';

function DownloadExcelTemplate(props) {
  const { onDownloadTemplate } = props;

  return (
    <div role="button" tabIndex={0} className="m-t-20 cursor-pointer" onClick={onDownloadTemplate}>
      <Content className={`is-underlined text-center ${styles.downloadTemplate}`}>
        {__('Download Excel Template')}
      </Content>
    </div>
  );
}

DownloadExcelTemplate.propTypes = {
  onDownloadTemplate: PropTypes.func,
};

DownloadExcelTemplate.defaultProps = {
  onDownloadTemplate: _noop,
};

export default DownloadExcelTemplate;
