// utils
import { getErrorMessage } from 'tbase/utils/errorUtils';
import _head from 'lodash/head';
// components
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

// services
import { cloneTestSession } from '../../../services/testSessions.services';

// helpers
import {
  TEST_SESSION_NAME, RELEASE, STATUS, ENV,
} from '../../TestSessionForm/helpers/testsessionForm.fields';
import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';

const handleCloneTestSessionSuccess = () => {
  toaster(TOASTER_TYPE.SUCCESS, __('Test Session Cloned Successfully'));
};

const makeCloneTestSessionRequestDTO = (formValues, testSessionId) => {
  const id = testSessionId;
  const name = formValues[TEST_SESSION_NAME.id];
  const releaseVersion = _head(formValues[RELEASE.id]);
  const status = _head(formValues[STATUS.id]);
  const environment = _head(formValues[ENV.id]);

  const testSessionRequestDTO = {
    id,
    name,
    releaseVersion,
    status,
    environment,
  };
  return testSessionRequestDTO;
};

const cloneTestSessionSubmit = async (formValues, testSessionId) => {
  const testSessionCloneDTO = makeCloneTestSessionRequestDTO(formValues, testSessionId);
  try {
    await cloneTestSession(testSessionCloneDTO, testSessionId);
    handleCloneTestSessionSuccess();
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export default cloneTestSessionSubmit;
