import { segregateChartData } from "./automationMetric.general";

export const getConfig = (chartData, contentHeight) => {
    const { getBarChartCategories, getAutomatedCount, getNonAutomatedCount } = segregateChartData(chartData)
    return({
        chart: {
            type: 'column',
            // inverted: true,
            height: contentHeight - 100 ,
        },
        title: {
            text: 'Automated VS Non-Automated Test Cases',
            align: 'center'
        },
        data: {
            // csv: document.getElementById('csv').innerHTML
        },
        xAxis: {
            categories: getBarChartCategories,
            crosshair: true,
            accessibility: {
                description: 'Products'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: true
            }
        },
        tooltip: {
            valueSuffix: 'TCs'
        },
        plotOptions: {
            // column: {
            //     pointPadding: 0.2,
            //     borderWidth: 0,
            //      dataLabels: {
            //         enabled: true
            //     },
            // },
            // series: {
            //     borderRadius: 6
            // }
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                },
            },
        },
        colors: ['#6ff2d4', '#7d6ff2'],
        series: [
            {
                name: 'Automated',
                data: getAutomatedCount
            },
            {
                name: 'Non-Automated',
                data: getNonAutomatedCount
            }
        ],
        credits: {
            enabled: false
        },
    })
   
};