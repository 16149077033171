import React from 'react';
import { mapProps } from 'recompose';

import _get from "lodash/get";
import _keyBy from 'lodash/keyBy';

import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import TextWithLinkRenderer from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import Content from 'tcomponents/atoms/Content';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import CellDateRenderer from 'tcomponents/molecules/CellRenderers/dateRenderer';
import TPopover from 'tcomponents/molecules/popover';
import Icon, { SIZES } from 'tcomponents/atoms/FontIcon';

import {EMPTY_STRING} from "@tekion/tekion-base/app.constants";
import { DATE_TIME_FORMATS } from 'tbase/utils/dateUtils';

import { getStatusProps } from '../helpers/testSessionList.column';
import DownloadReportsRenderer from '../../../../../../../molecules/FieldRenderer/DownloadReportsRenderer';
import { TEST_SESSION_SOURCE_VS_ICONS } from '../../../constant/infoIconListMap'
import ACTION_TYPES from '../constants/testSessionList.actionTypes';

import Styles from '../testSessionList.module.scss';
import StopExecutionRenderer from '../../../../../../../molecules/FieldRenderer/StopExecutionRenderer/StopExecutionRenderer';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const COLUMN_WIDTH = {
  name: 250,
}

const TEST_SESSION_SOURCE = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
  EXECUTE: 'EXECUTE',
  JIRA: 'JIRA'
}

const getHoverContent = (totalTestCases, totalTestCasesFailed, totalTestCasesPassed, totalTestCasesSkipped) => {
  const completedPercentage = (totalTestCasesPassed + totalTestCasesFailed + totalTestCasesSkipped)/totalTestCases * 100;
  return(
    <Content>
      {
        __('Total Completed Percentage : {{completedPercentage}}',{
          completedPercentage,
        })
      }
    </Content>
  )
}

const getTotalTestCasesCell = (column) => {
  const totalTestCases = _get(column, ['original','totalTestcases'], 0);
  const totalTestCasesPassed = _get(column, ['original', 'totalTestcasesPassed'], 0);
  const totalTestCasesFailed = _get(column, ['original', 'totalTestcasesFailed'], 0);
  const totalTestCasesSkipped = _get(column, ['original', 'totalTestcasesSkipped'], 0);

  return (
      <div className={Styles.iconClass}>
        {totalTestCases}
        <div>
          <TPopover trigger="hover" content={getHoverContent(totalTestCases, totalTestCasesFailed, totalTestCasesPassed, totalTestCasesSkipped)}>
            <Icon className={Styles.infoIcon}>
              icon-progress-bar
            </Icon>
          </TPopover>
        </div>
      </div>
  )
}

const getNameCell = (column, onAction) => {
  const { original } = column;
  const testSessionName = _get(column, ['original', 'name'], EMPTY_STRING);
  const testSessionSource = _get(column, ['original', 'testSessionSource'], EMPTY_STRING);
  const displayIcon = TEST_SESSION_SOURCE_VS_ICONS[testSessionSource];
  return (
      <div className={Styles.nameCell}>
        <TextWithLinkRenderer value={testSessionName} onAction={onAction} actionType={ACTION_TYPES.ON_TEST_SESSION_NAME_CLICK} column={original}/>
        <Icon className={Styles.creationIcon}>
          {displayIcon}
        </Icon>
      </div>
  )
}

const getTestSessionNameField = onAction => ({
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Name',
  width: COLUMN_WIDTH.name,
  Cell: (column) => getNameCell(column, onAction),
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
});

const RELEASE_VERSION = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TOTAL_TESTCASES = {
  id: 'totalTestcases',
  key: 'totalTestcases',
  accessor: 'totalTestcases',
  Header: 'Total Testcases',
  Cell: (column) => getTotalTestCasesCell(column),
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TESTCASES_PASSES = {
  id: 'totalTestcasesPassed',
  key: 'totalTestcasesPassed',
  accessor: 'totalTestcasesPassed',
  Header: 'Passed',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TESTCASES_FAILED = {
  id: 'totalTestcasesFailed',
  key: 'totalTestcasesFailed',
  accessor: 'totalTestcasesFailed',
  Header: 'Failed',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TESTCASES_PENDING = {
  id: 'totalTestcasesPending',
  key: 'totalTestcasesPending',
  accessor: 'totalTestcasesPending',
  Header: 'Pending',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TESTCASES_SKIPPED = {
  id: 'totalTestcasesSkipped',
  key: 'totalTestcasesSkipped',
  accessor: 'totalTestcasesSkipped',
  Header: 'Skipped',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const ENVIRONMENT = {
  id: 'environment',
  key: 'environment',
  accessor: 'environment',
  Header: 'Environment',
  Cell: TextRenderer,
  sortable: true,
};
const STATUS = {
  id: 'status',
  key: 'status',
  accessor: 'status',
  Header: 'Status',
  Cell: StatusCellRenderer,
  getProps: getStatusProps,
  sortable: true,
};

const CREATED_BY = {
  id: 'createdBy',
  key: 'createdBy',
  accessor: 'createdBy',
  Header: 'Created By',
  Cell: TextRenderer,
  sortable: true,
};

const MODIFIED_BY = {
  id: 'modifiedBy',
  key: 'modifiedBy',
  accessor: 'modifiedBy',
  Header: 'Modified By',
  Cell: TextRenderer,
  sortable: true,
};

const MODULE = {
  id: 'projectName',
  key: 'projectName',
  accessor: 'projectName',
  Header: 'Project',
  Cell: TextRenderer,
  sortable: true,
};

const MODIFIED_AT = {
  id: 'modifiedAt',
  key: 'modifiedAt',
  accessor: 'modifiedAt',
  Header: 'Modified At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};

const CREATED_AT = {
  id: 'createdAt',
  key: 'createdAt',
  accessor: 'createdAt',
  Header: 'Created At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};

const STOP_EXECUTION ={
  id:'stopExecution' ,
  key: 'stopExecution',
  accessor: 'stopExecution',
  Header: 'Stop Execution',
  Cell: makeCellRenderer(StopExecutionRenderer),
  sortable: true,
}

const DOWNLOAD_REPORTS = {
  id: 'downloadReports',
  key: 'downloadReports',
  accessor: 'downloadReports',
  Header: 'Download Reports',
  // getProps: TextRenderer,
  Cell: makeCellRenderer(DownloadReportsRenderer),
  sortable: true,
};

export const getColumns = onAction => [getTestSessionNameField(onAction), RELEASE_VERSION, TOTAL_TESTCASES, TESTCASES_PASSES, TESTCASES_FAILED, TESTCASES_PENDING, TESTCASES_SKIPPED,
  ENVIRONMENT, STATUS, CREATED_BY, MODIFIED_BY, MODULE, CREATED_AT, MODIFIED_AT, STOP_EXECUTION, DOWNLOAD_REPORTS];


// const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
// export default COLUMN_CONFIG_BY_KEY;
