// Constants
import FILTER_TYPES from 'tcomponents/organisms/filterSection/constants/filterSection.filterTypes';
import { STATUS_OPTIONS } from './testResultsList.status';

export const FEATURE = {
  id: 'module_id',
  name: __('Module'),
  type: FILTER_TYPES.MULTI_SELECT,
};

export const STATUS = {
  id: 'status',
  name: __('Status'),
  type: FILTER_TYPES.MULTI_SELECT,
  additional: { options: STATUS_OPTIONS },
};

export const EXECUTED_AT = {
  id: 'modified_at',
  name: __('Executed At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const CREATED_AT = {
  id: 'created_at',
  name: __('Created At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const DEFAULT_FILTER_TYPES = [FEATURE.id];
