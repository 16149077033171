// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

export const PASS = {
  name: __('Pass'),
  id: 'PASS',
};
export const FAIL = {
  name: __('Fail'),
  id: 'FAIL',
};
export const PENDING = {
  name: __('Pending'),
  id: 'PENDING',
};

export const RE_RUN_PASS = {
  name: __('Re Run Pass'),
  id: 'RE_RUN_PASS',
};

export const SKIPPED = {
  name: __('Skipped'),
  id: 'SKIPPED',
};

export const STATUS_COLOR_MAP = {
  [PASS.id]: COLOR_MAP.GREEN,
  [FAIL.id]: COLOR_MAP.RED,
  [PENDING.id]: COLOR_MAP.ORANGE,
  [RE_RUN_PASS.id]: COLOR_MAP.BLUE,
  [SKIPPED.id]: COLOR_MAP.GREY,
};

export const STATUS_LABEL_MAP = {
  [PASS.id]: PASS.name,
  [FAIL.id]: FAIL.name,
  [PENDING.id]: PENDING.name,
  [RE_RUN_PASS.id]: RE_RUN_PASS.name,
  [SKIPPED.id]: SKIPPED.name,
};

const STATUSES = [PASS, FAIL, PENDING, RE_RUN_PASS, SKIPPED];

export const STATUS_OPTIONS = standardFieldOptionMapper(undefined, STATUSES);

export default STATUSES;
