import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
// import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
// import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';

import LabelRenderer from '../../../molecules/LabelRenderer/LabelRenderer';


// const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const TEST_CASE_ID = {
  id: 'testCaseId',
  key: 'testCaseId',
  accessor: 'testCaseId',
  Header: 'Test Case ID',
  Cell: makeCellRenderer(LabelRenderer),
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const USER_NAME = {
  id: 'username',
  key: 'username',
  accessor: 'username',
  Header: 'User Name',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const RELEASE_VERSION = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const PROJECT_NAME = {
  id: 'projectName',
  key: 'projectName',
  accessor: 'projectName',
  Header: 'Project Name',
  Cell: TextRenderer,
  sortable: true,
};
const PRODUCT_NAME = {
  id: 'productName',
  key: 'productName',
  accessor: 'productName',
  Header: 'Product Name',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const MODULE_NAME = {
  id: 'moduleName',
  key: 'moduleName',
  accessor: 'moduleName',
  Header: 'Module Name',
  Cell: TextRenderer ,
  // getProps: TextRenderer,
  sortable: true,
};
const TEST_SESSION_ID = {
  id: 'testSessionId',
  key: 'testSessionId',
  accessor: 'testSessionId',
  Header: 'Test Session ID',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const DEALER_ID = {
  id: 'dealerId',
  key: 'executedOn.dealerId',
  accessor: 'executedOn.dealerId',
  Header: 'Dealer ID',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const DEALER_NAME = {
  id: 'dealerName',
  key: 'executedOn.dealerName',
  accessor: 'executedOn.dealerName',
  Header: 'Dealer Name',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const TENANT_ID = {
  id: 'tenantId',
  key: 'executedOn.tenantId',
  accessor: 'executedOn.tenantId',
  Header: 'Tenant ID',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const TENANT_NAME = {
  id: 'tenantName',
  key: 'executedOn.tenantName',
  accessor: 'executedOn.tenantName',
  Header: 'Tenant Name',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const ENVIRONMENT = {
  id: 'environment',
  key: 'executedOn.environment',
  accessor: 'executedOn.environment',
  Header: 'Environment',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
export const columns = [TEST_CASE_ID, USER_NAME, RELEASE_VERSION, PROJECT_NAME, PRODUCT_NAME, MODULE_NAME, TEST_SESSION_ID, DEALER_ID, DEALER_NAME, TENANT_ID, TENANT_NAME, ENVIRONMENT];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
