import React from 'react';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';

import TestResultsList from './organisms/TestResultsList';
import styles from './testResults.module.scss';

function TestResults() {
  return (
    <div>
      <PageComponent.Header>
        <div className={`full-width ${styles.headerContainer}`}>
          <Heading size={1}>{__('Test Results')}</Heading>
        </div>
      </PageComponent.Header>
      <TestResultsList />
    </div>
  );
}

export default TestResults;
