import Http from '../../../services/http';
import URL from '../../../api/urls';

export const getAllReleaseVersionsApi = async () => {
  const response = await Http.get('CQA', `${URL.getAllReleaseVersion}`);
  return response;
};

export const pipelineHealthCheck = async (productName) => {
  const response = await Http.get('CQA', `${URL.pipelineHealthCheck}?productName=${productName}`);
  return response;
};

export const getTestSessions = async (env, projectName, releaseVersion) => {
  const response = await Http.get('CQA', `${URL.testSessionForProject}?environment=${env}&projectName=${projectName}&releaseVersion=${releaseVersion}`);
  return response;
};
