import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function ColumnChart(props) {
    const { config } = props;

  return (
    <div>

    <HighchartsReact
	highcharts={Highcharts}
	options={config}
    />

</div>
  )
}

export default ColumnChart;