export const ACTION_TYPES = {
  HOME_BUTTON: 'HOME_BUTTON',
  TESTCASES: 'TESTCASES',
  TESTSESSION: 'TESTSESSION',
  TESTPLAN: 'TESTPLAN',
  AUTOMATION_PROGRESS: 'AUTOMATION_PROGRESS',
  UPLOAD_STATUS: 'UPLOAD_STATUS',
  RELEASE_BOARD: 'RELEASE_BOARD',
  HISTORY_COLLECTION: 'HISTORY_COLLECTION',
  JIRA_ISSUES: 'JIRA_ISSUES',
};
