/* eslint-disable rulesdir/no-array-functions */
// Constants
import ACTION_TYPES from '../constant/tabbedTestSession.actionTypes';

// Helpers

const handleOnTabChange = ({ params, setState, getState }) => {
  const { state } = getState();
  const { value: selectedTabId } = params;
  setState({
    selectedTabId,
  });
};

const handleCreateTestSession = ({ getState }) => {
  const { history } = getState();
  history.push('/tcmsuiV2/testsession/create-testsession');
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_TAB_CHANGE]: handleOnTabChange,
  [ACTION_TYPES.REDIRECT_TO_CREATE_TEST_SESSION]: handleCreateTestSession,
};

export default ACTION_HANDLERS;
