import _get from 'lodash/get';
import _head from 'lodash/head';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty'
import _uniq from "lodash/uniq";

import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import TEXT_WITH_LINK_ACTION_TYPES from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer/textWithLink.actionTypes';
import { getFiltersWithOutNullValue } from 'tcomponents/organisms/filterSection/utils/filterSection.utils';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import {
  searchTestcaseFilter,
  deleteTestCase,
  moveTestCase,
  bulkDeleteTestCases, bulkMoveTestCase, bulkUpdateTestCases,addTestCaseToJira
} from '../../../../../services/testCases.services';
import ACTION_TYPES from '../constants/testCasesList.actionTypes';
import ROW_ACTION_TYPES from '../constants/testCasesList.rowActionTypes';
import {getTestCaseDTO, getExportDTO, getBulkUpdateDto, getAddToTestCaseDto} from './testCaseList.request';
import { exportTestCase } from '../../../../../services/testCases.downloads';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

// const generateJIRAIdString = () => `DMS-${Math.floor(Math.random() * (200000 - 100000 + 1) + 100000)}`;

// const mapTestCaseDataByTestCaseId = (testCaseData, localData) => testCaseData.map((data) => {
//   const testData = data;
//   testData.tags = {
//     testCaseId: data.testCaseId,
//     tags: localData[data.testCaseId],
//   };
//   return testData;
// });

// const triggerTagsHandlerOnFetch = (searchData) => {
//   let testCasesData = _get(searchData, 'data.data.hits');
//   let localData = JSON.parse(localStorage.getItem('tags'));
//   if (testCasesData && !localData) {
//     const testCaseIds = testCasesData.map(testCase => testCase.testCaseId);
//     const result = {};
//     testCaseIds.forEach((id) => {
//       let tagCount = Math.floor(Math.random() * 9);
//       const tags = [];
//       if (tagCount > 0) {
//         while (tagCount > 0) {
//           tags.push(generateJIRAIdString());
//           tagCount -= 1;
//         }
//       }
//       result[id] = tags;
//     });
//     localStorage.setItem('tags', JSON.stringify(result));
//     localData = JSON.parse(localStorage.getItem('tags'));
//   }
//   if (localData != null && Object.keys(localData)?.length > 0) {
//     testCasesData = mapTestCaseDataByTestCaseId(testCasesData, localData);
//   }
//   return testCasesData;
// };

// const triggerTagsHandlerOnSetFilter = (searchData, filterValues) => {
//   let testCaseData = _get(searchData, 'data.data.hits');
//   const tagsData = JSON.parse(localStorage.getItem('tags'));
//   for (let i = 0; i < filterValues.length; i += 1) {
//     if (filterValues[i].type === 'tags' && testCaseData) {
//       testCaseData = testCaseData?.filter((data) => {
//         const { testCaseId } = data;
//         return tagsData[testCaseId]?.includes(filterValues[i].values[0]);
//       });
//       testCaseData = mapTestCaseDataByTestCaseId(testCaseData, tagsData);
//     }
//   }
//   return testCaseData;
// };

const handleTableItemsFetch = async ({ getState, setState }) => {
  const {
    searchText, currentPage, pageSize, tabId, isLoading, selectedFilters, sort,
  } = getState();

  setState({ isLoading: true });
  const selectedFiltersInLocalStorage = JSON.parse(TokenManager.getItem('selectedFilters')) || EMPTY_ARRAY;
  const selectedFiltersWithLocalStorage = selectedFiltersInLocalStorage.length === null ? selectedFilters : selectedFiltersInLocalStorage;
  const filterReuestDTO = getTestCaseDTO(tabId, searchText, currentPage, pageSize, selectedFiltersWithLocalStorage, sort);

  const searchData = await searchTestcaseFilter(filterReuestDTO);
  // const testCasesData = triggerTagsHandlerOnFetch(searchData);
  const tableData = _get(searchData, 'data.data.hits', EMPTY_ARRAY);
  const countTestCases = _get(searchData, 'data.data.count');
  setState({
    totalCount: countTestCases,
    data: tableData,
    isLoading: false,
    selectedFilters: selectedFiltersWithLocalStorage,
  });
};

const handleItemsSetFilter = async ({ params, getState, setState }) => {
  const {
    match, searchText, tabId, currentPage, pageSize, selectedProjectId, selectedFeatureId, sort
  } = getState();

  const { value } = params;
  const selectedFilters = getFiltersWithOutNullValue(value);
  TokenManager.setItem('selectedFilters', JSON.stringify(selectedFilters));
  setState({ isLoading: true });
  const filterReuestDTO = getTestCaseDTO(tabId, searchText, 1, pageSize, selectedFilters, sort);
  for (let i = 0; i < selectedFilters.length; i++) {
    if (selectedFilters[i].type === 'project_id') {
      const value = _head(selectedFilters[i].values);
      setState({
        selectedProjectId: value,
      });
    } else if (selectedFilters[i].type === 'module_id') {
      const value = _head(selectedFilters[i].values);
      setState({
        selectedFeatureId: value,
      });
    }
  }

  const searchData = await searchTestcaseFilter(filterReuestDTO);
  // const testCaseData = triggerTagsHandlerOnSetFilter(searchData, selectedFilters);
  const testCaseData = _get(searchData, 'data.data.hits', EMPTY_ARRAY);
  const countTestCases = _get(searchData, 'data.data.count');
  setState({
    totalCount: countTestCases,
    data: testCaseData,
    selectedFilters,
    isLoading: false,
    currentPage: 1,
  });
};

const handleTableItemSelect = ({ params, setState, getState }) => {
  const { setSelection, data, setIsAutomated } = getState();
  const { value: selection } = params;

  const selectedData = _filter(data, ({ id }) => _includes(selection, id));
  const automatedValues = _map(selectedData, ['isAutomated', true]);
  setIsAutomated(automatedValues);

  setState({
    selection,
  });
  setSelection(selection);
};

const handleRowActions = ({ params, getState, setState }) => {
  if (params.action == 'EDIT_TEST_SESSION') {
    handleTestCaseEdit({ params, getState });
  } else if (params.action === 'DELETE_TEST_CASE') {
    handleDeleteTestCase({ params, getState, setState });
  } else if (params.action === 'MOVE_TEST_CASE') {
    handleMoveTestCase({ params, getState, setState });
  } else {
    handleTestCaseClone({ params, getState });
  }
};
const handleTestCaseEdit = ({ params, getState }) => {
  const { history } = getState();
  history.push(`/tcmsuiV2/testcase/edit/${params.value.id}`);
};

const handleDeleteTestCase = async ({ params, getState, setState }) => {
  const { value } = params;
  toaster(TOASTER_TYPE.INFO, __('Selected Test Case is being deleted'));
  const testCaseId = _get(value, 'id', EMPTY_STRING);
  const deleteResponse = await deleteTestCase(testCaseId).then(() => {
    toaster(TOASTER_TYPE.SUCCESS, __('Test Case Deleted Successfully'));
  });
  handleTableItemsFetch({ getState, setState });
};

const handleMoveTestCase = ({ params, setState }) => {
  const selectedTestCaseId = _get(params, ['value', 'id'], EMPTY_STRING);
  setState({
    selectedTestCaseId,
    showMoveTestCaseModal: true,
  });
};
const handleTestCaseClone = ({ params, getState }) => {
  const { history } = getState();
  history.push(`/tcmsuiV2/testcase/clone/${params.value.id}`);
};

const handlePagination = async ({ params, setState, getState }) => {
  const {
    tabId, selectedFilters, searchText, sort,
  } = getState();
  const { value } = params;
  const { page: currentPage, resultsPerPage: pageSize } = value;
  if (selectedFilters == undefined) {
    const pagination = getTestCaseDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
    setState({ isLoading: true });
    const responseData = await searchTestcaseFilter(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  } else {
    const pagination = getTestCaseDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
    setState({ isLoading: true });
    const responseData = await searchTestcaseFilter(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  }
};

const handleRedirectToEditTestCase = ({ params, getState }) => {
  const { history } = getState();
  const rowData = _get(params, 'rowData');
  const testCaseId = _get(rowData, 'id');
  // history.push(`/tcmsuiV2/testcase/edit/${testCaseId}`);
  window.open(`/tcmsuiV2/testcase/edit/${testCaseId}`, '_blank');
};

const handleDownloadExcel = async ({ params, getState }) => {
  const { selection } = getState();
  if (_isEmpty(selection)) {
    toaster(TOASTER_TYPE.ERROR, __('Select any testcase for Exporting it as Excel'));
  } else {
    const requestDTO = getExportDTO(selection);
    toaster(TOASTER_TYPE.INFO, __('Please Wait ... Generating Excelsheet'));
    await exportTestCase(requestDTO);
  }
};

const handleSearch = async ({ params, getState, setState }) => {
  const { value: searchText } = params;
  const {
    tabId, currentPage, pageSize, selectedFilters, sort,
  } = getState();
  setState({ isLoading: true });
  const filterReuestDTO = getTestCaseDTO(tabId, searchText, 1, pageSize, selectedFilters, sort);
  const searchData = await searchTestcaseFilter(filterReuestDTO);
  const testCasesData = _get(searchData, 'data.data.hits');
  const countTestCases = _get(searchData, 'data.data.count');
  setState({
    totalCount: countTestCases,
    data: testCasesData,
    isLoading: false,
    searchText,
    currentPage: 1,
  });
};

const handleSortChange = ({ params, getState, setState }) => {
  const { value } = params;
  const sortTypeMap = _get(value, 'sortTypeMap');
  const keyToSort = _get(value, 'column.key',);
  const sortDetails = _pick(sortTypeMap, keyToSort);
  setState({
    sortDetails,
  });
};

const handleMoveTestCaseOnCancel = ({ setState }) => {
  setState({
    showMoveTestCaseModal: false,
  });
};

const handleMoveTestCaseOnSubmit = async ({ params, getState, setState }) => {
  setState({
    showMoveTestCaseModal: false,
  });
  const isBulkAction = _get(getState(), 'isBulkAction', false);
  toaster(TOASTER_TYPE.INFO, __('Please Wait ... Moving Test Case'));
  const { formValues } = params;
  const moveTestCaseObject = {};

  moveTestCaseObject.testCaseId = _get(getState(), 'selectedTestCaseId', EMPTY_STRING);
  if (_get(formValues, 'project') !== undefined) {
    moveTestCaseObject.projectId = _head(_get(formValues, 'project'));
  }
  if (_get(formValues, 'module') !== undefined) {
    moveTestCaseObject.moduleId = _head(_get(formValues, 'module'));
  }
  if (_get(formValues, 'subModule') !== undefined) {
    moveTestCaseObject.subModuleId = _head(_get(formValues, 'subModule'));
  }

  if (!isBulkAction) {
    await moveTestCase(moveTestCaseObject).then(() => {
      toaster(TOASTER_TYPE.SUCCESS, __('Successfully moved the Test Case'));
    });
  } else {
    moveTestCaseObject.testCaseId = _get(getState(), 'selectedTestCaseIds', EMPTY_ARRAY);
    await bulkMoveTestCase(moveTestCaseObject).then(() => {
      toaster(TOASTER_TYPE.SUCCESS, __('Successfully moved the Test Cases'));
    });
  }
  window.location.reload();
};

const handleRowBulkActions = async ({ params, getState, setState }) => {
  const { action, selection } = params;
  const actionType = _get(action, 'key', EMPTY_STRING);

  if (actionType === ROW_ACTION_TYPES.MOVE_TEST_CASE) {
    setState({
      showMoveTestCaseModal: true,
      isBulkAction: true,
      selectedTestCaseIds: selection,
    });
  }
  if (actionType === ROW_ACTION_TYPES.DELETE_TEST_CASE) {
    toaster(TOASTER_TYPE.INFO, __('Selected Test Cases are being deleted'));
    const bulkDeleteDto = {
      testCaseId: selection,
    };
    const deleteResponse = await bulkDeleteTestCases(bulkDeleteDto).then(() => {
      toaster(TOASTER_TYPE.SUCCESS, __('Selected Test Cases are successfully deleted'));
    });
    handleTableItemsFetch({ getState, setState });
  }
  if (actionType === ROW_ACTION_TYPES.BULK_UPDATE_TEST_CASES) {
    setState({
      showBulkUpdateTestCaseModal: true,
      selectedTestCaseIds: selection,
    })
  }
};

const handleBulkUpdateTestCaseModalOnCancel = ({ setState }) => {
  setState({
    showBulkUpdateTestCaseModal: false,
  })
}

const handleBulkUpdateTestCaseModalOnSubmit = async ({ params, getState, setState }) => {
  setState({
    showBulkUpdateTestCaseModal: false,
  });
  toaster(TOASTER_TYPE.INFO, __('Please Wait ... Updating the selected test cases'));
  const { formValues } = params;
  const { selection } = getState();

  const bulkUpdateDto = getBulkUpdateDto(formValues, selection);
  const bulkUpdateResult = await bulkUpdateTestCases(bulkUpdateDto).then(() => {
    toaster(TOASTER_TYPE.SUCCESS, __('Selected Test Cases are successfully updated'));
  }).catch(() => {
    toaster(TOASTER_TYPE.ERROR, __('Cannot update the selected Test Cases'));
  });
  window.location.reload();
}


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEM_SELECT]: handleTableItemSelect,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleItemsSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePagination,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SORT]: handleSortChange,
  [TEXT_WITH_LINK_ACTION_TYPES.TEXT_CLICK]: handleRedirectToEditTestCase,
  [ACTION_TYPES.ROW_ACTION]: handleRowActions,
  [ACTION_TYPES.DOWNLOAD_EXCEL]: handleDownloadExcel,
  [ACTION_TYPES.MOVE_TEST_CASE_MODAL_ON_CANCEL]: handleMoveTestCaseOnCancel,
  [ACTION_TYPES.MOVE_TEST_CASE_MODAL_ON_SUBMIT]: handleMoveTestCaseOnSubmit,
  [ACTION_TYPES.ROW_BULK_ACTION]: handleRowBulkActions,
  [ACTION_TYPES.BULK_UPDATE_TEST_CASES_ON_CANCEL]: handleBulkUpdateTestCaseModalOnCancel,
  [ACTION_TYPES.BULK_UPDATE_TEST_CASES_ON_SUBMIT]: handleBulkUpdateTestCaseModalOnSubmit,
 // [ACTION_TYPES.ADD_TEST_CASE_TO_JIRA]:handleAddTestCaseToJira
 
};

export default ACTION_HANDLERS;
