import _map from 'lodash/map';


import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const makeProductOptions = (productList) => {
  // standardFieldOptionMapper(undefined, projectList, PROJECT_OPTION_MAPPER);
  const options = _map(productList, element => ({
    label: element.name,
    value: element.id,
    additional:
    { id: element.id, name: element.name },
  }));
  return options;
};

export default makeProductOptions;
