export const NOTIFICATION_OPTION_LABELS = {
  ticket_create: 'Ticket Create',
  ticket_comment: 'Ticket Comment',
};

export const NOTIFICATION_TYPES = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'webapp',
    label: 'Web App',
  },
];

export const EMPTY_GROUPS_TEXT = {
  TITLE: __('Not Associated with any group '),
  DESC1: __('You have not been assigned to a User group'),
  DESC2: __('This page is used to configure group level notifications'),
};
