/* eslint-disable no-shadow */
/* eslint-disable rulesdir/no-inline-functions */
import React, { useCallback, useState } from 'react';
import PropTypes, { element } from 'prop-types';

// Lodash
import _size from 'lodash/size';
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _get from 'lodash/get';


// Components
import Loader from 'tcomponents/molecules/loader';
import Badge from 'tcomponents/atoms/BadgeWrapper';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn/IconAsBtn';
import Modal from 'tcomponents/molecules/Modal';
// Constants
import { EMPTY_ARRAY } from 'tbase/utils/constant';
import Note from 'tcomponents/molecules/Note';
// Styles
import styles from '../../testResultsList.module.scss';
import { NotesDTO } from './helpers/notesPopover.request';
import { postTestResultsComment, getTestResultComment } from '../../../../../../services/testSessions.services';


const NotesPopover = (props) => {
  const {
    assetType, assetId, titleLabel, notes, onAction,
  } = props;

  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [testCaseId, setTestCaseId] = useState(assetId);
  const [tesResultNotes, setTestResultNotes] = useState(notes);
  const [loading, setLoading] = useState(false);

  const notesCount = _size(notes);
  const handleMediaModalOpen = useCallback(() => {
    setIsCommentModalVisible(true);
  }, []);

  const handleClose = () => {
    setIsCommentModalVisible(false);
  };

  const handleAddNote = async (type, text) => {
    const notesBody = NotesDTO(text, testCaseId, tesResultNotes);
    setLoading(true);
    await postTestResultsComment(notesBody);
    const response = await getTestResultComment(testCaseId);
    const seletedTestResultComment = _get(response, 'data.data');
    setTestResultNotes(seletedTestResultComment);
    setLoading(false);
  };

  const notesMapper = () => {
    _map(tesResultNotes, element => (
      <div>
        <ul>
          <br />
          <li>{element}</li>
        </ul>
      </div>
    ));
  };

  return (
    <div>
      <Badge count={notesCount} className={styles.dotBadge} dot>
        <IconAsBtn onClick={handleMediaModalOpen}>
        icon-notes
        </IconAsBtn>
      </Badge>
      <Modal
        title={titleLabel}
        visible={isCommentModalVisible}
        onCancel={handleClose}
        hideCancel
        submitBtnText={__('Done')}
        showFooter={false}
        destroyOnClose
        // onSubmit={handleSubmit}
      >
        <Note displayUserImage={false} handleAddNewNote={handleAddNote}></Note>
        <div>
          {
            loading ? <Loader></Loader> : (_map(tesResultNotes, element => (
              <div>
                <ul>
                  <br />
                  <li>{element}</li>
                </ul>
              </div>
            )))
          }
        </div>
      </Modal>

    </div>
  );
};

NotesPopover.propTypes = {
  titleLabel: PropTypes.string,
  assetType: PropTypes.string,
  assetId: PropTypes.string,
  notes: PropTypes.array,
  onNotesUpdate: PropTypes.func,
};

NotesPopover.defaultProps = {
  titleLabel: undefined,
  assetType: undefined,
  assetId: undefined,
  notes: EMPTY_ARRAY,
  onNotesUpdate: _noop,
};

export default NotesPopover;
