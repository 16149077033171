import { EMPTY_ARRAY } from 'tbase/app.constants';

const INITIAL_STATE = {
  selection: EMPTY_ARRAY,
  selectedFilters: EMPTY_ARRAY,
  currentPage: 1,
  pageSize: 50,
};

export default INITIAL_STATE;
