import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import ACTION_TYPES from '../constants/historyCollection.actionTypes';
import { getAllHistoryCollectionInfo } from '../services/historyCollection.api';


const handleFieldChange = async ({ params, getState, setState }) => {
  const id = _get(params, 'value.payload.id', EMPTY_STRING);
  const { selectedProduct, selectedProject, selectedEnvironment, selectedDealer } = getState();
  const selectedValue = _get(params, 'value.payload.value', EMPTY_ARRAY);
  if (id === 'Product') {
    setState({
      selectedProduct: selectedValue,
    });
  } else if (id === 'Project') {
    setState({
      selectedProject: selectedValue,
    });
  } else if (id === 'Environment') {
    setState({
      selectedEnvironment: selectedValue,
    });
  } else if (id === 'DealerId') {
    setState({
      selectedDealer: selectedValue,
    });
  } else {
    setState({
      isHistoryTableLoading: true,
      selectedReleaseVersion: selectedValue,
    });
    const historyCollectionInfo = await getAllHistoryCollectionInfo(selectedProduct, selectedProject, selectedEnvironment, selectedDealer, selectedValue);
    setState({
      historyCollectionInfo,
      isHistoryTableLoading: false,
    });
  }
};

export const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
};
