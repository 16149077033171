// import _get from 'lodash/get';
import _constant from 'lodash/constant';
import { STATUS_COLOR_MAP, STATUS_LABEL_MAP } from '../constants/testCasesList.status';
import { AUTOMATED_COLOR_MAP, AUTOMATED_LABEL_MAP } from '../constants/testCasesList.automated';
import { PRIORITY_LABEL_MAP } from '../constants/testCasesList.priority';

export const getStatusProps = _constant({
  labelMap: STATUS_LABEL_MAP,
  colorMap: STATUS_COLOR_MAP,
});

export const getAutomatedProps = _constant({
  labelMap: AUTOMATED_LABEL_MAP,
  colorMap: AUTOMATED_COLOR_MAP,
});

export const getPriorityProps = _constant({
  labelMap: PRIORITY_LABEL_MAP,
});
