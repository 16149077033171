import React, { useMemo, useCallback } from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';

import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';

import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Modal from 'tcomponents/molecules/Modal';

import ACTION_TYPES from './constants/testSessionList.actionTypes';
import ACTION_HANDLERS from './helpers/testSessionList.actionHandlers';
import INITIAL_STATE from './constants/testSessionList.initialState';
import { makeTableProps, getSubHeaderProps, CONTEXT_ID } from './helpers/testSessionList.general';
import { getColumns } from './constants/testSessionList.columns';
import { createFilterProps } from './helpers/testSessionList.filterProps';
import { getTcmsBootstrap, getProjectListByProductId } from '../../../../../../store/selectors/tcms.selectors';
import ExecutionScreenModal from "../../../../../../organisms/ExecutionScreenModal";
import TestSessionForm from '../../../TestSessionForm';
import editTestSessionSubmit from '../../../editTestSession/helpers/editTestSession.onFormSubmit';

const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const SortableTableManager = withCustomSortTable(TableManager);
const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;

function TestSessionList(props) {
  // console.log(props);
  const {
    tabId,
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    projectListByProductId,
    testSessionId,
    pageSize = 50,
    searchText,
    initialFormValues,
    newFormValues,
    executionScreenId,
    isExecutionScreenModalVisible,
    isModalVisible,
    rowInfoSelected,
    onHide
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: true,
    showFilter: true,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
  };

  const handleClose = useCallback(() => {
    onAction({type : ACTION_TYPES.ON_TEST_SESSION_MODAL_CANCEL})
  }, [onAction]);

  const handleBulkAction = useCallback(
    (action) => {
      onAction({
        type: ACTION_TYPES.ROW_BULK_ACTION,
        payload: {
          action,
        },
      });
    },
    [onAction]
  );

  const handleExecutionModalCancel = useCallback(
      (action) => {
          onAction({
              type: ACTION_TYPES.ON_CANCEL_EXECUTION_MODAL,
              payload: {
                  action,
              },
          });
      },
      [onAction]
  );

  const filterProps = useMemo(() => createFilterProps({
    selectedFilters, tcmsBootStrap, projectListByProductId, tabId,
  }),
  [selectedFilters, tcmsBootStrap, projectListByProductId, tabId,
  ]);
  const subHeaderProps = useMemo(() => getSubHeaderProps(handleBulkAction, selection), [onAction, selection]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
      pageSize,
      currentPage,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  const setNewFormValues = useCallback((formValues) => {
    console.log('formValues ', formValues)
    onAction({type : ACTION_TYPES.HANDLE_NEW_FORM_VALUES, payload : {formValues}})
  })

  const handleEditTestSessionSubmit = useCallback(() =>  {
    editTestSessionSubmit(newFormValues, testSessionId)
    onAction({type : ACTION_TYPES.ON_TEST_SESSION_MODAL_CANCEL})
  }, [ newFormValues, testSessionId ]);

  return (
    <PageComponent.Body style={{ height: contentHeight - 50 }}>
      <TableManager
        columns={getColumns(onAction)}
        data={data}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableComponent={CheckBoxTable}
        onAction={onAction}
        filterProps={filterProps}
        selection={selection}
        tableProps={getTableProps}
        subHeaderProps={subHeaderProps}
        sortDetails={sortDetails}
        isMultiSort
      />
      <ExecutionScreenModal
          executionId={executionScreenId}
          isExecutionScreenModalVisible={isExecutionScreenModalVisible}
          onCancel={handleExecutionModalCancel}
      />
      <Modal
        visible = {isModalVisible}
        centered
        title={__('Edit Test Session')}
        onCancel={handleClose}
        onSubmit={handleEditTestSessionSubmit}
        submitBtnText={__('Save')}
        width={Modal.SIZES.S}
        destroyOnClose
        >
        <TestSessionForm
          onSubmit={handleEditTestSessionSubmit}
          formValues={initialFormValues}
          formHeader={null}
          formFooter={null}
          isFromTestSessionList={true}
          setNewFormValues={setNewFormValues}
        />
      </Modal>
    </PageComponent.Body>
  );
}

const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
  projectListByProductId: getProjectListByProductId(state),

});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(TestSessionList);
