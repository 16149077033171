import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import Loader from 'tcomponents/molecules/loader';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import FormPage from 'tcomponents/pages/formPage';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import styles from './createTestSession.module.scss';

// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';
import SECTIONS from './helpers/createTestsession.sections';
import { getFields } from './helpers/createTestSession.formConfig';
import { INITIAL_STATE } from './constant/createTestSession.general';
import { ACTION_HANDLERS } from './helpers/createTestSession.actionHandlers';
import ACTION_TYPES from './constant/createTestSession.actionTypes';
import { getTcmsBootstrap, getProjectListByProductId, getReleaseVersion } from '../../../../store/selectors/tcms.selectors';

const CreateTestSession = (props) => {
  const {
    history,
    onAction,
    formValues,
    errors,
    isEdit,
    tcmsBootstrap,
    releaseVersion,
    projectListByProductId,
    projectListOptions,
    releaseVersionListOptions,
    isTestSessionLoading,
    contentHeight,
  } = props;

  console.log(contentHeight);
  // console.log(projectListOptions);


  const handleCreateRelease = useCallback(
    (newReleaseVersion) => {
      onAction({ type: ACTION_TYPES.CREATE_RELEASE_VERSION, payload: { newReleaseVersion } });
    },
    [onAction]
  );

  const renderFormHeader = () => (
    <PageComponent.Header hasBack>
      <div className="full-width headerContainer">
        <Heading size={1}>{__('Create Test Session')}</Heading>
      </div>
    </PageComponent.Header>
  );

  const fields = useMemo(
    () => getFields({
      tcmsBootstrap,
      handleCreateRelease,
      projectListOptions,
      projectListByProductId,
      releaseVersion,
      isEdit,
    }),
    [tcmsBootstrap, isEdit, projectListByProductId, projectListOptions, handleCreateRelease, releaseVersion]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmit = useCallback(() => {
    triggerSubmit('Test Session Form');
  }, EMPTY_ARRAY);

  if (isTestSessionLoading) {
    return <Loader />;
  }
  return (
    <PageComponent.Body>
      <FormPage
        sections={SECTIONS}
        headerComponent={renderFormHeader}
        onSubmit={handleSubmit}
        onClose={handleCancel}
        contentHeight={contentHeight + 80}
        fields={fields}
        onAction={onAction}
        values={formValues}
        contextId="Test Session Form"
      />

    </PageComponent.Body>
  );
};

const mapStateToProps = state => ({
  tcmsBootstrap: getTcmsBootstrap(state),
  projectListByProductId: getProjectListByProductId(state),
  releaseVersion: getReleaseVersion(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withSize({ hasPageFooter: 1, hasPageHeader: 1 }),
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CreateTestSession);
