import Http from '../../../services/http';
import URL from '../../../api/urls';

export const getTrackingControls = async () => {
  const response = await Http.get('CQA', `${URL.insertTrackingControl}`);
  return response;
};
export const addTrackingControls = async (addTrackingControlBody) => {
  const response = await Http.post('CQA', `${URL.insertTrackingControl}`, addTrackingControlBody);
  return response;
};