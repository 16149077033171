import _head from 'lodash/head';

export const createDashBoardDTO = (selectedProduct, selectedEnvironment, selectedValue, selectedDealer) => ({
  productId: _head(selectedProduct),
  releaseVersion: _head(selectedValue),
  environment: _head(selectedEnvironment),
  dealerInfo: _head(selectedDealer),
});

export const createDashBoardDTOForModule = (selectedProject, selectedEnvironment, selectedValue, selectedDealer) => ({
  projectId: selectedProject,
  releaseVersion: _head(selectedValue),
  environment: _head(selectedEnvironment),
  dealerInfo: _head(selectedDealer),
});
