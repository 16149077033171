import React from 'react';
import _constant from 'lodash/constant';

import Button from '@tekion/tekion-components/src/atoms/Button';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import {REVERSE_ROLE_TYPE, ROLE_TYPE} from "../../../../../../../constants/userRole/userRoleMap";
import {
  TABLE_ROW_ACTIONS
} from "./issueTable.rowAction";
import ACTION_TYPES
  from "../../../../../../testSessions/organisms/TestResults/organisms/TestResultsList/constants/testResultsList.actiontypes";

// export const getSubHeaderProps = () => ({
//   subHeaderRightActions: [
//     {
//       renderer: Button,
//       // action: REPORT_CUSTOM_ACTIONS.DOWNLOAD_EXCEL,
//       renderOptions: {
//         view: Button.VIEW.SECONDARY,
//         label: 'Create Jira Request',
//         // className: styles.exportIcon,
//       },
//     },
//   ],
// });

const rowActions = () => {
  // const rolehash = localStorage.role || undefined;
  // if(ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.GUEST){
  //   return (TABLE_ROW_ACTIONS_GUEST)
  // }

  return (TABLE_ROW_ACTIONS);
};

const handleRowAction = onAction => (action, payload) => {
  onAction({
    type: ACTION_TYPES.ROW_ACTION,
    payload: {
      value: payload,
      action,
    },
  });
};

export const getHeaderProps = () => ({
  label: __('Issue List'),
});

export const makeTableProps = ({
  isLoading,
  selection,
  columnConfigurator,
  totalCount,
  onAction,
  sortDetails,
  pageSize,
  currentPage,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  columnConfigurator,
  showActions: true,
  getRowActions: rowActions,
  onRowActionClick: handleRowAction(onAction),
  totalNumberOfEntries: totalCount,
  type: TABLE_TYPES.FIXED_COLUMN,
  getTdProps: _constant({ onAction }),
  checkboxHeaderClassName: 'm-l-8',
  sortDetails,
  shouldAdaptSortDetails: false,
  showPagination: true,
  pageSize,
  currentPage,
});
