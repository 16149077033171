import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';


const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const TESTPLAN_NAME = {
  id: 'TestPlan Name',
  key: 'TestPlan Name',
  accessor: 'TestPlan Name',
  Header: 'TestPlan Name',
  Cell: TextWithLink,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const TESTPLAN_ID = {
  id: 'TestPlan ID',
  key: 'TestPlan ID',
  accessor: 'TestPlan ID',
  Header: 'Release Version',
  Cell: TextWithLink,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const INFO = {
  id: 'Info',
  key: 'Info',
  accessor: 'Info',
  Header: 'Info',
  Cell: TextRenderer,
  sortable: true,
};
const JIRA = {
  id: 'Jira',
  key: 'Jira',
  accessor: 'Jira',
  Header: 'Jira',
  Cell: StatusCellRenderer,
  getProps: TextRenderer,
  sortable: true,
};


export const columns = [TESTPLAN_NAME, TESTPLAN_ID, INFO, JIRA];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
