/* eslint-disable react/prop-types */
import React from 'react';

import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import Input from 'tcomponents/atoms/Input';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';
import { deepClone } from '@tekion/tap-components/utils/helper';
import { addCard, updateCard, activeCard } from '../../../action/CardManagement.action';
import { addNewCard, getCard, loading } from './CardManagement.selector';
import styles from '../TapApplications.module.scss';
class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [
        {
          id: 'appKey', label: 'App Key', value: '', isEditable: false, placeholder: 'App Key value must be New and Unique', required: true,
        },
        {
          id: 'iconName', label: 'Icon Name', value: '', isEditable: false, required: true,
        },
        {
          id: 'label', label: 'Label', value: '', isEditable: true, required: true,
        },
        {
          id: 'path', label: 'Path', value: '', isEditable: true, required: true,
        },
        {
          id: 'slabel', label: 'Short Label', value: '', isEditable: true, required: true,
        },
        {
          id: 'value', label: 'Value', value: '', isEditable: false, required: true,
        },
        {
          id: 'roleKey', label: 'Role Key', value: '', isEditable: true,
        },
      ],
      editModule: false,
      loaded: false,
      disableDelete: true,
    };
  }

  componentDidMount() {
    const { history: { location: { pathname } } } = this.props;
    const { getIndividualCard, apiBaseUrl } = this.props;
    if (pathname.endsWith('edit') && pathname.includes('cards')) {
      const { history: { location: { state: { cardData: { appKey } } } } } = this.props;
      getIndividualCard(apiBaseUrl, appKey);
      this.setState({ editModule: true });
    }
    if (pathname.endsWith('create')) {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate() {
    const { history, loading } = this.props;
    if (loading.addCardSuccess) {
      history.push('/app/tapapplications/cards');
    }
    if (loading.updateCard) {
      history.push('/app/tapapplications/cards');
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { getCardResponse, location } = props;
    const { formData } = state;
    if (getCardResponse.data) {
      const {
        appKey,
      } = getCardResponse.data;
      if (location?.state?.cardData?.appKey === appKey && appKey !== state.formData[0].id && !state.loaded) {
        const newData = formData.map(data => (
          {
            ...data,
            value: getCardResponse.data[data.id],
          }
        ));
        return {
          formData: newData,
          editModule: true,
          loaded: true,
        };
      }
    }
    return null;
  }

   handleSave = () => {
     const { formData, editModule } = this.state;
     const payload = formData.reduce((obj, item) => ({
       ...obj,
       [item.id]: item.value,
     }), {});
     const {
       apiBaseUrl, addNewCard, history, updateOldCard,
     } = this.props;
     if (editModule) {
       updateOldCard(apiBaseUrl, payload);
       history.push('/app/tapapplications/cards');
       this.setState({ loaded: false });
     } else {
       addNewCard(apiBaseUrl, payload);
     }
   }

   formValidate = (formData) => {
     const invalid = formData.some(data => data.required ? !data.value : false);
     return invalid;
   }

   onChange = (target, index) => {
     const { formData } = this.state;
     const newData = deepClone(formData);
     newData[index].value = target.value;
     this.setState({ formData: newData, disableDelete: this.formValidate(newData) });
   }

   onCancel = () => {
     const {
       history,
     } = this.props;
     history.push('/app/tapapplications/cards');
   }

   render() {
     const {
       formData, loaded, editModule, disableDelete,
     } = this.state;
     const { loading } = this.props;
     return (
       <React.Fragment>
         {!loaded ? <Loader />
           : (
             <div className={styles.version__control}>
               <div className={styles.create__module}>
                 <Row>
                   <Col xs={24} md={24} sm={24} lg={11}>
                     {formData.map((data, index) => (
                       <Row className={styles.mb24} align="middle" gutter={16} key={data.b}>
                         <Col xs={24} md={10} sm={24} lg={7}>
                           {data.label}{data.required ? ' *' : ''}
                         </Col>
                         <Col span={12}>
                           <Input
                             id={data.value}
                             value={data.value}
                             name={data.value}
                             disabled={editModule && !data.isEditable}
                             placeholder={data.placeholder || `Please enter ${data.label}`}
                             onChange={e => this.onChange(e.target, index)}
                           />
                         </Col>
                       </Row>
                     ))}
                   </Col>
                 </Row>
               </div>
               <div className={styles.module__footer}>
                 <Button
                   style={{ marginRight: '25px' }}
                   onClick={this.onCancel}
                 >
                   {__('Cancel')}
                 </Button>
                 <Button
                   view="primary"
                   onClick={this.handleSave}
                   disabled={disableDelete}
                   loading={loading.addCard}
                 >
                   {__('Save')}
                 </Button>
               </div>
             </div>
           )}
       </React.Fragment>
     );
   }
}

const mapStateToProps = createStructuredSelector({
  getCardResponse: getCard(),
  loading: loading(),
  addCard: addNewCard(),
});

const mapDispatchToProps = (dispatch) => {
  const getIndividualCard = bindActionCreators(activeCard, dispatch);
  const addNewCard = bindActionCreators(addCard, dispatch);
  const updateOldCard = bindActionCreators(updateCard, dispatch);
  return {
    getIndividualCard,
    addNewCard,
    updateOldCard,
  };
};
CreateForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  getIndividualCard: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,

};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
);
