import React, { useCallback } from 'react';
import _head from 'lodash/head';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import Icon from 'tcomponents/atoms/FontIcon';
import TPopover from 'tcomponents/molecules/popover';
import { PropertyControlledComponent } from "tcomponents/molecules";

import styles from './downloadReportsRenderer.module.scss';
import ACTION_TYPES from '../../../pages/testSessions/organisms/TabbedTestSessions/organisms/TestSessionList/constants/testSessionList.actionTypes';
import {COMPLETED_BY_STATUS, EXECUTED_BY_TCMS} from './DownloadReportsRenderer.constants';

const DownloadReportsRenderer = (props) => {
  const { original, onAction } = props;
  const { executionId } = original;
  const {status} = original;

  let hasExecutionId;

  if(executionId !== undefined)
    hasExecutionId = (executionId.length === 1 && _head(executionId) === 'N/A') || (status != 'COMPLETED') ;
  else
    hasExecutionId = false;

const renderHoverPopOver = () =>(status != 'COMPLETED' ? COMPLETED_BY_STATUS : EXECUTED_BY_TCMS)   

  const handleMediaModalOpen = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ON_DOWNLOAD_REPORT,
      payload: {
        executionId,
      },
    });
  }, []);

  return (
    <div className={styles.downloadReportsIcon}>
      <IconAsBtn onClick={handleMediaModalOpen} disabled={hasExecutionId}>icon-download1</IconAsBtn>
      <PropertyControlledComponent controllerProperty={hasExecutionId}>
        <TPopover trigger="hover" content={renderHoverPopOver()}>
          <Icon className={styles.infoIcon}>
            icon-info
          </Icon>
        </TPopover>
      </PropertyControlledComponent>
    </div>
  );
};

export default DownloadReportsRenderer;
