import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { getDatabaseHealthCheckData } from '../services/databaseHealthCheckTable.api';

const handleTableItemsFetch = async ({ getState, setState }) => {
  setState({ isLoading: true });
  const tableData = await getDatabaseHealthCheckData();
  setState({
    data: tableData,
    isLoading: false,
  });
};

export const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
};
