import React, { useCallback } from 'react';
import Modal from 'tcomponents/molecules/Modal';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import AddTrackingControlForm from '../AddTrackingControlForm/AddTrackingControlForm';


const AddTrackingControlModal = (props) => {
  const {
    isVisible, isUploading, onAction, onCancel, onSubmit, formValues, isTrackingControlModalLoading
  } = props;
  const CONTEXT_ID = 'UPLOAD_TESTCASES_FORM';


  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, EMPTY_ARRAY);

  return (
    <div>
      <Modal
        title={__('Add Tracking Control')}
        visible={isVisible}
        submitBtnText={__('Submit')}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        loading={isTrackingControlModalLoading}
        width={Modal.SIZES.SM}
        onAction={onAction}
        destroyOnClose
      >

        <AddTrackingControlForm formValues={formValues} onSubmit={onSubmit} visible={isVisible} />

      </Modal>


    </div>

  );
};

AddTrackingControlModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  isUploading: PropTypes.bool,
};
AddTrackingControlModal.defaultProps = {
  onSubmit: _noop,
  onCancel: _noop,
  isVisible: false,
  isUploading: false,
};

export default AddTrackingControlModal;
