import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import withActions from 'tcomponents/connectors/withActions';
// import { BasicTable } from 'twidgets/appServices/accounting/organisms/table';

import TableManager from 'tcomponents/organisms/TableManager';
import withSize from 'tcomponents/hoc/withSize';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ACTION_HANDLERS from './helpers/statsTable.actionHandlers';
import INITIAL_STATE from './constants/statsTable.initialState';
import { columns } from './constants/statsTable.columns';
import {getProjectData, makeTableProps} from './helpers/statsTable.general';
import {
  getSubHeaderProps
} from "./helpers/statsTable.general";
import { ACTION_TYPES } from './constants/statsTable.actionTypes';

import styles from '../../automationProgress.module.scss';
import ModuleStatsTable from '../ModuleStatsTable';

const StatsTable = (props) => {
  const {
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    selection,
    selectedFilters,
    contentHeight,
    pageSize,
    selectedProduct,
    selectedEnvironment,
    selectedReleaseVersion,
    expanded,
    moduleData,
    moduleDataMap,
    isModuleTableLoading,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: false,
    showRefreshIcon: true,
  };

  const getCustomSubComponent = () => ({
    // subComponentWrapperClassName: 'flex',
    SubComponent: subComponentProps => <ModuleStatsTable onAction={onAction} statsTableHeight={contentHeight} moduleData={moduleData} moduleDataMap={moduleDataMap} subComponentProps={subComponentProps} isLoading={isModuleTableLoading}/>,
  });

  const tableData = _get(data, 'data.data', EMPTY_ARRAY);

  const subHeaderProps = useMemo(() => getSubHeaderProps(onAction), [onAction]);

  const projectWiseTableData = getProjectData(tableData);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      projectWiseTableData,
      onAction,
      selection,
      sortDetails,
      expanded,
    }),
    [isLoading, isFetchingNextPage, totalCount, onAction, selection, sortDetails, projectWiseTableData, expanded]
  );

  return (
    <PageComponent.Body className={styles.tableStyle} style={{ height: contentHeight }}>
      <TableManager
        columns={columns}
        data={projectWiseTableData}
        subHeaderProps={subHeaderProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        selection={selection}
        tableProps={getTableProps}
        // tableComponent={BasicTable}
        {...getCustomSubComponent()}
      />
    </PageComponent.Body>
  );
};

export default compose(
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withRouter,
  withSize({ hasPageHeader: 0, hasPageFooter: 0 }),
)(StatsTable);
