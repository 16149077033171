import getDataFromResponse from 'tbase/utils/getDataFromResponse';
import _property from 'lodash/property';

import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, STATUS, ENV, TYPE, RELEASE,
} from '../organisms/TestSessionForm/helpers/testsessionForm.fields';

const name = _property('name');
const productName = _property('productId');
const projectName = _property('projectId');
const status = _property('status');
const environment = _property('environment');
const type = _property('type');
const releaseVersion = _property('releaseVersion');

export const makeInitialFormValues = (testSessionResponse) => {
  const testSession = getDataFromResponse(testSessionResponse);
  const formValues = {
    [TEST_SESSION_NAME.id]: name(testSession),
    [PRODUCT.id]: [productName(testSession)],
    [PROJECT.id]: [projectName(testSession)],
    [STATUS.id]: [status(testSession)],
    [ENV.id]: [environment(testSession)],
    [TYPE.id]: [type(testSession)],
    [RELEASE.id]: [releaseVersion(testSession)],
  };
  console.log(formValues);
  return formValues;
};
