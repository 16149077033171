import React, { useCallback } from "react";
import _head from 'lodash/head';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import Icon from 'tcomponents/atoms/FontIcon';
import TPopover from 'tcomponents/molecules/popover';
import { PropertyControlledComponent } from "tcomponents/molecules";

import styles from './stopExecutionRenderer.module.scss';
import {COMPLETED_BY_STATUS, EXECUTED_BY_TCMS} from './StopExecutionRenderer.constants';
import { GLOBAL_ACTION_TYPES } from "../../../constants/Constants";


const StopExecutionRenderer = (props) =>{
    const{original, onAction } = props;
    const{executionId} = original;
    const {status} = original;

    let hasExecutionId;

    if(executionId !== undefined && status!== undefined){
       hasExecutionId = (executionId.length === 1 && _head(executionId) === 'N/A') || ((status) === 'COMPLETED');
    }else    
       hasExecutionId = false;    

const renderHoverPopOver = () =>(status === 'COMPLETED' ? COMPLETED_BY_STATUS : EXECUTED_BY_TCMS)

const handleStopExecution = useCallback(()=>{
  return(
    onAction({
      type: GLOBAL_ACTION_TYPES.ON_STOP_EXECUTION,
      payload: {
        executionId,
      },
    })
  )
},[]);

return(
    <div className={styles.executionRendererIcon}>
        <IconAsBtn onClick={handleStopExecution} disabled={hasExecutionId}>icon-caution1</IconAsBtn>
        <PropertyControlledComponent controllerProperty={hasExecutionId}>
        <TPopover trigger="hover" content={renderHoverPopOver()}>
          <Icon className={styles.infoIcon}>
            icon-info
          </Icon>
        </TPopover>
      </PropertyControlledComponent>
    </div>
    );
}
export default StopExecutionRenderer;