import _head from 'lodash/head';

// constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import {
  PRODUCT, PROJECT, TYPE, STATUS, TEST_SESSION_NAME, RELEASE, ENV,
} from '../organisms/TestSessionForm/helpers/testsessionForm.fields';

export const makeTestSessionRequestDTO = (formValues = EMPTY_OBJECT) => {
  console.log(formValues)
  const name = formValues[TEST_SESSION_NAME.id];
  const releaseVersion = _head(formValues[RELEASE.id]);
  const type = _head(formValues[TYPE.id]);
  const status = _head(formValues[STATUS.id]);
  const environment = _head(formValues[ENV.id]);
  const projectId = _head(formValues[PROJECT.id]);
  const productId = _head(formValues[PRODUCT.id]);

  const testSessionRequestDTO = {
    projectId,
    name,
    releaseVersion,
    type,
    status,
    environment,
    productId,
    createdBy: 'TESTING_UI',
  };
  return testSessionRequestDTO;
};
