import React from 'react';

import Button from 'tcomponents/atoms/Button';
import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';


import { mapProps } from 'recompose';
import CellDateRenderer from '@tekion/tekion-components/src/molecules/CellRenderers/dateRenderer';

import { DATE_TIME_FORMATS } from 'tbase/utils/dateUtils';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import _get from 'lodash/get';
import ACTION_TYPES from './historyCollectionList.actionTypes';
import { getStatusProps } from '../helpers/historyCollectionList.statusMap';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const renderTestSessionCell = (column, onAction) => {
  const handleOnClick = () => {
    onAction({
      type: ACTION_TYPES.CLICK_TEST_SESSION,
      payload: {
        column,
      },
    });
  };

  const data = _get(column, 'value', EMPTY_STRING);

  return (
    <Button
      view={Button.VIEW.TERTIARY}
      onClick={handleOnClick}
      // disabled={checkIfDisabled(appointmentStatus)}
      // id={`automation_selector_${COLUMN_KEYS.ACTION}_${appointmentId}`}
    >
      {data}
    </Button>
  );
};

const PRODUCT_NAME = {
  id: 'productName',
  key: 'productName',
  accessor: 'productName',
  Header: 'Product Name',
  Cell: TextRenderer,
  sortable: true,
};

const PROJECT_NAME = {
  id: 'projectName',
  key: 'projectName',
  accessor: 'projectName',
  Header: 'Project Name',
  Cell: TextRenderer,
  sortable: true,
};

const MODULE_NAME = {
  id: 'moduleName',
  key: 'moduleName',
  accessor: 'moduleName',
  Header: 'Module Name',
  Cell: TextRenderer,
  sortable: true,
};

const RELEASE_VERSION = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
};

const TEST_CASE_ID = {
  id: 'testCaseId',
  key: 'testCaseId',
  accessor: 'testCase',
  Header: 'Test Case Id',
  Cell: TextRenderer,
  sortable: true,
};

const ENV = {
  id: 'environment',
  key: 'environment',
  accessor: 'env',
  Header: 'Environment',
  Cell: TextRenderer,
  sortable: true,
};

const getTestSessionId = onAction => ({
  id: 'testSessionId',
  key: 'testSessionId',
  accessor: 'testSessionId',
  Header: 'Test Session Id',
  Cell: column => renderTestSessionCell(column, onAction),
  sortable: true,
});

const DEALER_ID = {
  id: 'dealerId',
  key: 'dealerId',
  accessor: 'dealerId',
  Header: 'Dealer Id',
  Cell: TextRenderer,
  sortable: true,
};

const DEALER_NAME = {
  id: 'dealerName',
  key: 'dealerName',
  accessor: 'dealerName',
  Header: 'Dealer Name',
  Cell: TextRenderer,
  sortable: true,
};

const STATUS = {
  id: 'status',
  key: 'status',
  accessor: 'status',
  Header: 'Status',
  Cell: StatusCellRenderer,
  getProps: getStatusProps,
  sortable: true,
};

const CREATED_TIME = {
  id: 'createdTime',
  key: 'createdTime',
  accessor: 'createdAt',
  Header: 'Created Time',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};

// export const columns = [PRODUCT_NAME, PROJECT_NAME, MODULE_NAME, RELEASE_VERSION, TEST_CASE_ID, ENV, TEST_SESSION_ID, DEALER_ID, DEALER_NAME, STATUS, CREATED_TIME];

export const getColumns = onAction => [PRODUCT_NAME, PROJECT_NAME, MODULE_NAME, RELEASE_VERSION, TEST_CASE_ID, ENV, getTestSessionId(onAction), DEALER_ID, DEALER_NAME, STATUS, CREATED_TIME];


// const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
// export default COLUMN_CONFIG_BY_KEY;
