// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const TEST = { id: 'TST', name: __('Test') };
const STAGE = { id: 'STAGE', name: __('Stage') };
const PREPROD = { id: 'PREPROD', name: __('Preprod') };
const PROD = { id: 'PROD', name: __('Prod') };

const ENV_TYPES = [TEST, STAGE, PREPROD, PROD];

export const ENV_TYPES_LABEL = {
  [TEST.id]: TEST.name,
  [STAGE.id]: STAGE.name,
  [PREPROD.id]: PREPROD.name,
  [PROD.id]: PROD.name,
};

const ENV_OPTIONS = standardFieldOptionMapper(undefined, ENV_TYPES);

export default ENV_OPTIONS;
