// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const NOT_STARTED = {
  name: __('Not Started'),
  id: 'NOT_STARTED',
};
const COMPLETED = {
  name: __('Completed'),
  id: 'COMPLETED',
};
const IN_PROGRESS = {
  name: __('In Progress'),
  id: 'IN_PROGRESS',
};

export const STATUS_COLOR_MAP = {
  [COMPLETED.id]: COLOR_MAP.GREEN,
  [NOT_STARTED.id]: COLOR_MAP.RED,
  [IN_PROGRESS.id]: COLOR_MAP.ORANGE,
};

export const STATUS_LABEL_MAP = {
  [COMPLETED.id]: COMPLETED.name,
  [NOT_STARTED.id]: NOT_STARTED.name,
  [IN_PROGRESS.id]: IN_PROGRESS.name,
};

const STATUSES = [COMPLETED, NOT_STARTED, IN_PROGRESS];

export const STATUS_OPTIONS = standardFieldOptionMapper(undefined, STATUSES);

export default STATUSES;
