import { EMPTY_OBJECT } from 'tbase/app.constants';
import APP_ACTION_TYPES from './constants/tcms.actionTypes';

const ACTIONS = APP_ACTION_TYPES;
const initialState = {
  tcmsBootstrap: EMPTY_OBJECT,
  tcmsMetadata: EMPTY_OBJECT,
};

export default (prevState, action) => {
  const state = prevState || initialState;
  // console.log(action);
  switch (action.type) {
    case ACTIONS.SAVE_TCMS_BOOTSTRAP:
      return {
        ...state,
        tcmsBootstrap: action.payload,
      };
    default: return state;
  }
};
