import React, { useCallback, useMemo } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _first from 'lodash/first';

import colors from '@tekion/tekion-styles-next/scss/variables.scss';
import withActions from 'tcomponents/connectors/withActions';
import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';
import Loader from 'tcomponents/molecules/loader';

import useFetchData from 'tbase/customHooks/useFetchData';
import { withRouter } from 'react-router-dom';
import ACTION_TYPES from './constants/viewTestPlan.actionTypes';
import ACTION_HANDLERS from './helpers/viewTestPlan.actionHandlers';
import styles from './viewTestPlan.module.scss';
import getTabs from '../../../../helpers/tabConfig';
import { getTabsApi } from '../../services/testPlan.services';
import ViewTestPlanList from './organisms/ViewTestPlanList/ViewTestPlanList';
import AddTestPlanModal from '../../../../organisms/addTestPlan/addTestPlanModal';

function ViewTestPlan(props) {
  const {
    onAction, selectedTabId, isAddTestPlanModalVisible,
  } = props;

  const [{ isLoading, data }] = useFetchData(getTabsApi);
  const tabsData = _get(data, 'data.data');

  const handleOnTabChange = useCallback(
    (newSelectedTab) => {
      onAction({ type: ACTION_TYPES.ON_TAB_CHANGE, payload: { value: newSelectedTab } });
    },
    [onAction]
  );

  const onCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.CLOSE_ADD_TEST_PLAN_MODAL });
  }, [onAction]);

  const handleEditTestPlan = useCallback(() => {
    onAction({ type: ACTION_TYPES.REDIRECT_TO_EDIT_TEST_PLAN });
  }, [onAction]);

  const handleViewTestPlan = useCallback(() => {
    onAction({ type: ACTION_TYPES.REDIRECT_TO_ADD_TEST_PLAN });
  }, [onAction]);


  const tabs = useMemo(() => getTabs(ViewTestPlanList, tabsData), [tabsData]);

  const tabBarStyle = {
    background: colors.glitter,
    padding: '0 2.4rem',
  };


  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <PageComponent.Header>
        <div className={`full-width ${styles.tabbedTestPlanListHeader}`}>
          <Heading size={1}>{__('Test Plan')}</Heading>
          <div>
            {/* <Button
              label={__('Edit TestPlan')}
              view={Button.VIEW.PRIMARY}
              className="m-r-16"
              onClick={handleEditTestPlan}
            /> */}
            <Button
              label={__('Add TestPlan')}
              view={Button.VIEW.PRIMARY}
              onClick={handleViewTestPlan}
            />
          </div>
        </div>
      </PageComponent.Header>
      <TabbedSections
        onAction={onAction}
        className="full-width"
        onTabChange={handleOnTabChange}
        activeKey={selectedTabId}
        tabBarStyle={tabBarStyle}
        tabs={tabs}
        selectedTabId={selectedTabId}
        destroyInactiveTabPane
      />
      <AddTestPlanModal
        isVisible={isAddTestPlanModalVisible}
        onCancel={onCancel}
      />

    </div>
  );
}

// const mapStateToProps = state => ({
//   selectedTabId,
// });

export default compose(withRouter, withActions({}, ACTION_HANDLERS))(ViewTestPlan);
