import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';

export const makeTableProps = ({
  isLoading,
  selection,
  data,
  isHistoryTableLoading,
  totalCount,
  sortDetails,
}) => ({
  loading: isHistoryTableLoading,
  selection,
  rowHeight: 50,
  pageSize: data.length,
  resultsPerPage: data.length,
  totalNumberOfEntries: data.length,
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
  showPagination: false,
});
