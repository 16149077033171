import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import ROW_ACTION_TYPES from './testSessionList.rowActionTypes';

const EDIT_TEST_SESSION_ACTION = {
  name: __('Edit'),
  id: ROW_ACTION_TYPES.EDIT_TEST_SESSION,
};

// const DELETE_TEST_SESSION_ACTION = {
//   name: __('Delete'),
//   id: ROW_ACTION_TYPES.OPEN_DELETE_CONFIRMATION_DIALOG,
// };

const CLONE_TEST_SECTION_ACTION = {
  name: __('Clone'),
  id: ROW_ACTION_TYPES.CLONE_TEST_SESSION,
};

const ADD_TEST_CASE_ACTION = {
  name: __('Add Test Cases'),
  id: ROW_ACTION_TYPES.REDIRECT_TO_ADD_TEST_CASES,
};

const EXECUTION_SCREEN = {
  name: __('Execution Screen'),
  id: ROW_ACTION_TYPES.REDIRECT_TO_EXECUTION_SCREEN,
};

// const DOWNLOAD_EXCEL_ACTION = {
//   name: __('Download Excel'),
//   id: ROW_ACTION_TYPES.DOWNLOAD_TEST_RESULTS,
// };

export const ROW_ACTION_TYPESS = [
  EDIT_TEST_SESSION_ACTION,
  // DELETE_TEST_SESSION_ACTION,
  CLONE_TEST_SECTION_ACTION,
  ADD_TEST_CASE_ACTION,
  EXECUTION_SCREEN,
  // DOWNLOAD_EXCEL_ACTION,
];

export const ROW_ACTION_TYPESS_GUEST = EMPTY_ARRAY;
