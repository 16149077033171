/* eslint-disable import/order */
/* eslint-disable rulesdir/no-array-functions */
// Readers
import _get from 'lodash/get';
// Utils
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

// Constants
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import { EMPTY_OBJECT, EMPTY_STRING } from 'tbase/app.constants';
import ROW_ACTION_TYPES from '../constants/testSessionList.rowActionTypes';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { makeInitialFormValues } from '../../../../../helpers/testSessions.helpers';


const handleTestResultsDownload = ({ params }) => {
  console.log(params);
};

const handleRedirectToAddTestCases = ({ params, getState }) => {
  const { tabId, history } = getState();
  const { value } = params;
  const projectId = _get(value, 'projectId');
  const testsessionId = _get(value, 'id');
  history.push(`/tcmsuiV2/testsession/addtestcase/${tabId}/${projectId}/${testsessionId}`);
};

const handleCloneTestSession = ({ getState, params }) => {
  const { history } = getState();
  const testSessionId = _get(params, 'value.id');
  history.push(`/tcmsuiV2/testsession/clone/${testSessionId}`);
};

const handleEditTestSession = ({ setState, getState, params }) => {
  // const { history } = getState();
  const testSessionId = _get(params, 'value.id');
  setState({ isModalVisible : true, rowInfoSelected : params, initialFormValues :  makeInitialFormValues({data : {data : params.value}}), testSessionId})
  // history.push(`/tcmsuiV2/testsession/edit/${testSessionId}`);
};
const handleExecutionScreen = ({ getState, setState, params }) => {
  const { history, data } = getState();
  const { value } = params;
  const testSessionName = _get(value, 'name', EMPTY_STRING);

  const executionScreenObject = _find(data, (dataObject) => {
    if (_get(dataObject, 'name', EMPTY_STRING) === testSessionName) { return _get(dataObject, 'executionId', EMPTY_STRING); }
    return null;
  });

  const executionScreenId = _get(executionScreenObject, 'executionId', EMPTY_STRING);
  setState({
    executionScreenId,
    isExecutionScreenModalVisible: true
  })
  // if (_isEmpty(executionScreenId)) { toaster(TOASTER_TYPE.ERROR, __('Execution Id not present for this Test Session')); } else { history.push({ pathname: '/tcmsuiV2/executionscreen', executionScreenId }); }
};

const ROW_ACTION_HANDLERS = {
  [ROW_ACTION_TYPES.REDIRECT_TO_ADD_TEST_CASES]: handleRedirectToAddTestCases,
  [ROW_ACTION_TYPES.DOWNLOAD_TEST_RESULTS]: handleTestResultsDownload,
  [ROW_ACTION_TYPES.CLONE_TEST_SESSION]: handleCloneTestSession,
  [ROW_ACTION_TYPES.EDIT_TEST_SESSION]: handleEditTestSession,
  [ROW_ACTION_TYPES.REDIRECT_TO_EXECUTION_SCREEN]: handleExecutionScreen,
};
export default ROW_ACTION_HANDLERS;
