import ACTION_TYPES from '../constants/viewTestPlan.actionTypes';

const handleOnTabChange = () => {

};

const handleRedirectEditTestPlan = () => {
  console.log('redirect');
};

const handleRedirectAddTestPlan = ({ setState, getState, params }) => {
  setState({ isAddTestPlanModalVisible: true });
};

const handleCloseTestPlanModal = ({ setState, getState, params }) => {
  setState({ isAddTestPlanModalVisible: false });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_TAB_CHANGE]: handleOnTabChange,
  [ACTION_TYPES.REDIRECT_TO_EDIT_TEST_PLAN]: handleRedirectEditTestPlan,
  [ACTION_TYPES.REDIRECT_TO_ADD_TEST_PLAN]: handleRedirectAddTestPlan,
  [ACTION_TYPES.CLOSE_ADD_TEST_PLAN_MODAL]: handleCloseTestPlanModal,
};

export default ACTION_HANDLERS;
