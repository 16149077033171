import React, { useCallback } from 'react';
import Modal from 'tcomponents/molecules/Modal';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import MoveTestCasesForm from '../moveTestCasesForm/moveTestCasesForm';

const MoveTestCasesModal = ({
  showMoveTestCaseModal,
  onCancel,
  onSubmit,
  projectListByProductId,
  featureListByProjectId,
  subfeatureListByFeatureId,
  tabId,
}) => {
  const handleSubmit = useCallback(() => {
    triggerSubmit('MOVE_TEST_CASE_FORM');
  }, EMPTY_ARRAY);

  return (
    <Modal
      title={__('Move Test Case')}
      submitBtnText={__('Move')}
      visible={showMoveTestCaseModal}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      width={Modal.SIZES.SM}
      destroyOnClose
    >
      <MoveTestCasesForm
        projectListByProductId={projectListByProductId}
        featureListByProjectId={featureListByProjectId}
        subfeatureListByFeatureId={subfeatureListByFeatureId}
        tabId={tabId}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default MoveTestCasesModal;
