import Http from '../../../services/http';
import URL from '../../../api/urls';

export const getReleaseInfo = async () => {
  const response = await Http.get('CQA', `${URL.getAllReleaseInfoEntries}`);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const addRelease = async (addReleaseBody) => {
  const response = await Http.post('CQA', `${URL.createReleaseVersion}`, addReleaseBody);
  return response;
};

export const updateRelease = async (updateReleaseBody) => {
  const response = await Http.post('CQA', `${URL.updateReleaseVersion}`, updateReleaseBody);
  return response;
};
