import Http from '../../../services/http';
import URL from '../../../api/urls';
import HandleErrorAndStatus from '../../../helpers/ErrorFunction/errorFunction';

export const getTestcases = async (payload) => {
  try {
    const response = await Http.get('CQA', `${URL.getTestcases}?featureId=24`, payload);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }

};

export const searchTestcaseFilter = async (payload) => {
  try {
    const response = await Http.post('CQA', `${URL.filterTestcases}`, payload);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }

};

export const createTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.createTestCase}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const deleteTestCase = async (id) => {
  const response = await Http.post('CQA', `${URL.deleteTestCase}/${id}`);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const moveTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.moveTestCase}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const bulkMoveTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.bulkMoveTestCase}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const bulkDeleteTestCases = async (payload) => {
  const response = await Http.post('CQA', `${URL.bulkDeleteTestCases}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const bulkUpdateTestCases = async (payload) => {
  const response = await Http.post('CQA', `${URL.bulkUpdateTestCases}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
}
export const addTestCaseToJira = async (jiraId, testCaseId) => {
    try {
      const response = await Http.get('CQA', `${URL.addTestCaseToJira}?jiraId=${jiraId}&id=${testCaseId}`);
      return response;
    } catch (error) {
      HandleErrorAndStatus(error);
    }
};
