
import _get from 'lodash/get';
import _size from 'lodash/size';
// Utils
import addToRenderOptions from 'tbase/utils/addToRenderOptions';

import useFetchData from '@tekion/tekion-base/customHooks/useFetchData';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { TRACKING_ENABLED, RELEASE_TAG } from '../constant/AddTrackingControlForm.fields';
import FORM_CONFIG from '../constant/AddTrackingControlForm.formConfig';
import { getAllReleaseVersionsApi } from '../../../../integrationHealth/services/integrationHealth.api';
import { getReleaseVersionOptions } from '../../../../integrationHealth/constants/releaseVersionOptions';


const isTrackingEnabled = [{ label: 'True', value: 'True' }, { label: 'False', value: 'False' }];
// const releaseTag = [{ label: 'release:09.09.2023', value: 'release:09.09.2023' }, { label: 'release:07.07.2023', value: 'release:07.07.2023' }];


export const getFields = releaseVersionOptions => ({
  ...FORM_CONFIG,
  [TRACKING_ENABLED.id]: addToRenderOptions(TRACKING_ENABLED, [{ path: 'options', value: isTrackingEnabled }]),
  [RELEASE_TAG.id]: addToRenderOptions(RELEASE_TAG, [{ path: 'options', value: releaseVersionOptions }]),
});
