import {
  TEST_CASE_NAME, AUTHOR, PRIORITY, JIRA, BRANCH, DESCRIPTION,
  EXIT_CRITERIA, RELEASE, COMPONENT, TYPE, ACTIVE,
  AUTOMATED, REGRESSION, MISC, STEPS, PRECONDITIONS,
  TCCATEGORY, UPSTREAMCONSUMPTION, DOWNSTREAMCONSUMPTION, HORIZONTALIMPLEMENTATION,
} from './testCasesForm.fields';

const SECTIONS = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [TEST_CASE_NAME.id, AUTHOR.id],
      },
      {
        columns: [PRIORITY.id, JIRA.id],
      },
      {
        columns: [EXIT_CRITERIA.id],
      },
      {
        columns: [STEPS.id],
      },
      {
        columns: [PRECONDITIONS.id],
      },
      {
        columns: [DESCRIPTION.id],
      },
      {
        columns: [ACTIVE.id, AUTOMATED.id, REGRESSION.id],
      },
      {
        columns: [COMPONENT.id, RELEASE.id],
      },
      {
        columns: [TYPE.id],
      },
      {
        columns: [MISC.id, BRANCH.id],
      },
      {
        columns: [TCCATEGORY.id],
      },
      {
        columns: [UPSTREAMCONSUMPTION.id],
      },
      {
        columns: [DOWNSTREAMCONSUMPTION.id],
      },
      {
        columns: [HORIZONTALIMPLEMENTATION.id],
      },
    ],
  },
];

export default SECTIONS;
