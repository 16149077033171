import _get from 'lodash/get';
import _size from 'lodash/size';
import _pick from 'lodash/pick';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import TEXT_WITH_LINK_ACTION_TYPES from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer/textWithLink.actionTypes';
// import { getFiltersWithOutNullValue } from 'tcomponents/organisms/filterSection/utils/filterSection.utils';

import ACTION_TYPES from '../constants/issueTable.actionTypes';
import ROW_ACTION_HANDLERS from "./issueTable.rowActionHandlers";
import {EMPTY_STRING} from "@tekion/tekion-base/app.constants";
import _split from "lodash/split";
import { getTestSessionJiraIssueData } from "../../../../../services/jiraIssues.services";
import {
  handleUploadTestCaseModalOnSubmit
} from "../../../../../../testCases/organisms/TabbedTestCases/helpers/tabbedTestCases.actionHandler";

// const handleSuccessUpdate = (response) => {
//   const statusCode = _get(response, 'data.status');
//   if (statusCode === 'Success') {
//     toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
//   }
// };

const handleTableItemsFetch = async ({ setState, getState }) => {
  const { history } = getState();
  const { location } = history;
  const testSessionId = _split(_get(location, 'pathname', EMPTY_STRING),'/')[3];
  setState({ isLoading: true });
  const testSessionDataResponse = await getTestSessionJiraIssueData(testSessionId);
  // const {
  //   tabId, searchText, currentPage, pageSize, selectedFilters, sort,
  // } = getState();
  // setState({ isLoading: true });
  // const getTestSessionDTO = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
  // const responseData = await getTestSession(getTestSessionDTO);
  // const testSessionsData = _get(responseData, 'data.data.hits', []);
  // console.log(testSessionsData);
  // const countTestSessions = _get(responseData, 'data.data.count', 0);
  // console.log(countTestSessions);
  // const pageCount = _get(responseData, 'data.data.pageCount');
  const count = _get(testSessionDataResponse, ['data','data','data','count'], 0);
  setState({
    totalCount: count,
    data: testSessionDataResponse,
    isLoading: false,
    // pageCount,
  });
};

// const handleItemsSetFilter = async ({ params, getState, setState }) => {
//   const {
//     searchText, tabId, currentPage, pageSize, sort,
//   } = getState();
//
//   const { value } = params;
//   const selectedFilters = getFiltersWithOutNullValue(value);
//   setState({ isLoading: true });
//   const filterReuestDTO = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
//   const searchData = await searchTestSessionFilter(filterReuestDTO);
//   const testSessionsData = _get(searchData, 'data.data.hits');
//   const countTestSessions = _size(testSessionsData);
//   setState({
//     totalCount: countTestSessions,
//     data: testSessionsData,
//     selectedFilters,
//     isLoading: false,
//   });
// };
//
// const handleTableItemSelect = ({ params, setState }) => {
//   const { value: selection } = params;
//   setState({ selection });
// };
//
const handleRowActions = ({ params, setState, getState }) => {
  const { action } = params;
  // console.log(action);
  const actionHandler = ROW_ACTION_HANDLERS[action];
  actionHandler({ setState, getState, params });
};

// const handleBulkRowAction = async ({ params, getState, setState }) => {
//   setState({ isLoading: true });
//   const { selection } = getState();
//   const action = _get(params, 'action');
//   const key = _get(action, 'key');
//   const statusDTO = updateBulkTestSessions(selection, key);
//   await postBulkUpdateTestSessions(statusDTO).then(response => handleSuccessUpdate(response));
//   handleTableItemsFetch({ setState, getState });
// };
//
const handleRedirectToIssueList = ({ params, getState }) => {
  const { history } = getState();
  const rowData = _get(params, 'rowData');
  const testSessionId = _get(rowData, 'id');
  history.push(`/tcmsuiV2/jiraissues/list/${testSessionId}`);
};

const handleUploadTestCaseModalOnCancel = ({ params, getState, setState }) => {
  setState({
    isUploadModalVisible: false,
    isUploadModalLoading: false,
  })
}

const handleSearch = ({ params, getState, setState }) => {
  console.log(params)
  console.log('search')
}
// const handlePagination = async ({ params, setState, getState }) => {
//   const {
//     tabId, selectedFilters, searchText, sort,
//   } = getState();
//   const { value } = params;
//   const { page: currentPage, resultsPerPage: pageSize } = value;
//   if (selectedFilters === undefined) {
//     const pagination = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
//     setState({ isLoading: true });
//     const responseData = await getTestSession(pagination);
//     const testCaseData = _get(responseData, 'data.data.hits');
//     setState({
//       data: testCaseData,
//       isLoading: false,
//       pageSize,
//       currentPage,
//     });
//   } else {
//     const pagination = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
//     setState({ isLoading: true });
//     const responseData = await getTestSession(pagination);
//     const testCaseData = _get(responseData, 'data.data.hits');
//     setState({
//       data: testCaseData,
//       isLoading: false,
//       pageSize,
//       currentPage,
//     });
//   }
// };
//
// const handleSortChange = ({ params, setState, getState }) => {
//   const { value } = params;
//   const sortTypeMap = _get(value, 'sortTypeMap');
//   const keyToSort = _get(value, 'column.key',);
//   const sortDetails = _pick(sortTypeMap, keyToSort);
//   setState({
//     sortDetails,
//   });
// };
//
// const handleSearch = async ({ params, setState, getState }) => {
//   const { value: searchText } = params;
//   const {
//     tabId, currentPage, pageSize, selectedFilters, sort,
//   } = getState();
//   setState({ isLoading: true });
//   const filterReuestDTO = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
//   const searchData = await getTestSession(filterReuestDTO);
//   const testSessionData = _get(searchData, 'data.data.hits');
//   const countTestSessions = _get(searchData, 'data.data.count');
//   setState({
//     totalCount: countTestSessions,
//     data: testSessionData,
//     isLoading: false,
//     searchText,
//   });
// };
//
// const handleOnDownloadReport = async ({ params, setState, getState }) => {
//   const { executionId } = params;
//   try {
//     toaster(TOASTER_TYPE.INFO, __('Generating Report'));
//     const responseData = await getTestSessionReport(executionId);
//     const reportLink = _get(responseData, 'data.data');
//     toaster(TOASTER_TYPE.SUCCESS, __('Report Generation Successfull...Check Downloads'));
//     window.open(reportLink, '_self');
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  // [TABLE_ACTION_TYPES.TABLE_ITEM_SELECT]: handleTableItemSelect,
  // [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleItemsSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  // [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePagination,
  // [TABLE_ACTION_TYPES.TABLE_ITEMS_SORT]: handleSortChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TEXT_WITH_LINK_ACTION_TYPES.TEXT_CLICK]: handleRedirectToIssueList,
  [ACTION_TYPES.ROW_ACTION]: handleRowActions,
  [ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_CANCEL]: handleUploadTestCaseModalOnCancel,
  [ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_SUBMIT]: handleUploadTestCaseModalOnSubmit,
  // [ACTION_TYPES.ROW_BULK_ACTION]: handleBulkRowAction,
  // [ACTION_TYPES.ON_DOWNLOAD_REPORT]: handleOnDownloadReport,
};

export default ACTION_HANDLERS;
