import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';

import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'tcomponents/molecules/richTextEditor/RichTextEditorWithTable';

import { EMPTY_OBJECT } from 'tbase/app.constants';


export const getEditorStateFromRawContent = (rawContent) => {
  switch (true) {
    case _isEmpty(rawContent):
    case _isNil(rawContent):
      return EditorState.createEmpty();
    case _isString(rawContent):
      try {
        return getEditorStateFromRawContent(JSON.parse(rawContent));
      } catch (e) {
        return EMPTY_OBJECT;
      }
    case rawContent instanceof ContentState:
      return EditorState.createWithContent(rawContent);
    default:
      try {
        return EditorState.createWithContent(convertFromRaw(rawContent));
      } catch (e) {
        return EditorState.createEmpty();
      }
  }
};

export const getRawContentFromEditorContent = (editorContent) => {
  switch (true) {
    case _isEmpty(editorContent):
    case _isNil(editorContent):
      return EMPTY_OBJECT;
    case editorContent instanceof EditorState:
      return convertToRaw(editorContent.getCurrentContent());
    case editorContent instanceof ContentState:
      return convertToRaw(editorContent);
    default:
      return editorContent;
  }
};
