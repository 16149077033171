import React, { useCallback, useState } from 'react';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import PropertyControlledComponent from 'tcomponents/molecules/PropertyControlledComponent';
import { isRequiredRule } from 'tbase/utils/formValidators';
import { compose } from 'recompose';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from 'tcomponents/connectors/withActions';
import useFetchData from '@tekion/tekion-base/customHooks/useFetchData';
import Checkbox from 'tcomponents/atoms/checkbox';
import ENV_OPTIONS from '../../../pages/integrationHealth/constants/environmentOptions';
import ACTION_HANDLERS from './helpers/executeTestCasesForm.actionHandlers';
import styles from './executeTestCasesForm.module.scss';
import { getDealerInfoByENV, getAllReleaseVersionsApi } from './services/executeTestCasesForm';
import _head from "lodash/head";
// import Checkbox from 'tcomponents/atoms/checkbox';


const ExecuteTestCasesForm = ({
  onAction,
  selectedEnvironment,
  setSelectedEnvironment,
  selectedDealer,
  setSelectedDealer,
  selectedReleaseVersion,
  setSelectedReleaseVersion,
  isAutomated,
  setIsChecked,
  isChecked,
}) => {
  const [dealerData, setDealerData] = useState([]);
  const [isDealerLoading, setIsDealerLoading] = useState(false);
  // const [releaseVersionData, setReleaseVersionData] = useState([]);
  const releaseVersionResponse = useFetchData(getAllReleaseVersionsApi);
  const [{ data, isLoading }] = releaseVersionResponse;
  console.log(isLoading)

  const onFieldChange = useCallback(
    async (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        setSelectedEnvironment(value.payload.value);
        try {
          setIsDealerLoading(true);
          const responseData = await getDealerInfoByENV(_head(value.payload.value));
          if (responseData?.status === 200) {
            const dealerInfoList = _get(responseData, 'data.data', EMPTY_ARRAY);
            setDealerData(dealerInfoList.map(dealer => ({ label: dealer, value: dealer })));
            setIsDealerLoading(false);
          } else setDealerData([]);
        } catch (e) {
          setDealerData([]);
        }
      }
    },
    [onAction]
  );


  const onDealerFieldChange = useCallback(
    async (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        setSelectedDealer(value.payload.value);
      }
    },
    [onAction]
  );

  const onReleaseVersionChange = useCallback(
    async (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        setSelectedReleaseVersion(value.payload.value);
      }
    },
    [onAction]
  );

  const getReleaseVersionOptions = () => {
    const releaseVersion = _get(releaseVersionResponse, '0.data.data.data.hits', EMPTY_ARRAY);
    const releaseVersionOptions = _map(releaseVersion, data => ({
      value: data.releaseVersion,
      label: data.releaseVersion,
    }));
    // releaseVersionOptions.push({
    //   label: 'General',
    //   value: 'General',
    // });
    return releaseVersionOptions;
  };
  const checkAutomated = (isAutomated.includes(false) === true);
  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <SelectInput
        isLoading={isLoading}
        id="Environment"
        label={__('Environment')}
        options={ENV_OPTIONS}
        onAction={onFieldChange}
        value={selectedEnvironment}
        placeholder={__('Select')}
        required
        validators={[isRequiredRule]}
        className={styles.execution_select_dropdown}
      />
      <SelectInput
        isLoading={isLoading || isDealerLoading}
        id="Dealer"
        label={__('Dealer')}
        options={dealerData}
        onAction={onDealerFieldChange}
        value={selectedDealer}
        placeholder={__('Select')}
        required
        validators={[isRequiredRule]}
        className={styles.execution_select_dropdown}
      />
      <SelectInput
        isLoading={isLoading}
        id="releaseVersion"
        label={__('Release Version')}
        options={getReleaseVersionOptions()}
        onAction={onReleaseVersionChange}
        value={selectedReleaseVersion}
        placeholder={__('Select')}
        // required
        // validators={[isRequiredRule]}
        className={styles.execution_select_dropdown}
      />
      <PropertyControlledComponent controllerProperty={checkAutomated}>
        <div>
          {/* <Alert */}
          {/*   type="error" */}
          {/*   message="You have selected non automated test case. Please select the checkbox to proceed the execution. */}
          {/*   NOTE : TestSession has to be closed manually" */}
          {/* > */}
          {/* </Alert> */}
          <div className={styles.checkedBox}>
            <Checkbox checked={isChecked} onChange={handleCheckBoxChange} />
            <span>You have selected non automated test case. Please select the checkbox to proceed the execution. <br />NOTE : TestSession has to be closed manually
            </span>
          </div>
        </div>
      </PropertyControlledComponent>
    </div>
  );
};

export default compose(
  withRouter,
  withActions({}, ACTION_HANDLERS)
)(ExecuteTestCasesForm);
