import React, {useMemo} from "react";

import { compose } from 'recompose';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from 'tcomponents/connectors/withActions';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import FormBuilder from 'tcomponents/organisms/FormBuilder';

import SECTIONS from "../constants/addTestCaseToJira.sections";
import ACTION_HANDLERS from "../helpers/addTestCaseToJira.actionHandlers";
import getFields from "../constants/addTestCaseToJira.formConfig";

const FormWithSubmission = withEventSubmission(FormBuilder);

const AddTestCaseToJiraForm = ({onAction, formValues,showAddTestCaseToJira})=>{
    const fields = useMemo(
        () => getFields({
          formValues,showAddTestCaseToJira
        }),
        [formValues,showAddTestCaseToJira]
      );
    const CONTEXT_ID = 'ADD_JIRA_TO_TEST_CASE_SUBMIT_FORM';

    return(
      <div>
        <FormWithSubmission
          fields={getFields(jira)}
          sections={SECTIONS}
          values={formValues}
          onAction={onAction}
          contextId={CONTEXT_ID}
        />
      </div>
    );
}

export default compose(
    withRouter,
     withActions({}, ACTION_HANDLERS)
     )(AddTestCaseToJiraForm);


