// Lodash
import _property from 'lodash/property';

const featureList = _property('moduleDtoList');
const subfeatureList = _property('subModuleList');
const productList = _property('productList');
const projectList = _property('projectList');
const releaseVersionList = _property('releaseVersion');

const READER = {
  featureList,
  productList,
  projectList,
  subfeatureList,
  releaseVersionList,
};

export default READER;
