export const MORE_OPTIONS_BUTTON = {
  UPLOAD_STATUS: 'uploadStatus',
  TRACKING_CONTROL: 'trackingControl',
  AUTOMATION_METRIC: 'automationMetric',
};

export const KEY_VS_ROUTE = {
  [MORE_OPTIONS_BUTTON.UPLOAD_STATUS]: 'uploadstatus',
  [MORE_OPTIONS_BUTTON.TRACKING_CONTROL]: 'trackingControl',
  [MORE_OPTIONS_BUTTON.AUTOMATION_METRIC]: 'automationmetric/Chart',
};

export const BUTTON_LABEL = {
  [MORE_OPTIONS_BUTTON.UPLOAD_STATUS]: __('Upload Status'),
  [MORE_OPTIONS_BUTTON.TRACKING_CONTROL]: __('Tracking Control'),
  [MORE_OPTIONS_BUTTON.AUTOMATION_METRIC]: __('Automation Metric'),
};
