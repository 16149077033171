// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'tbase/app.constants';

const INITIAL_STATE = {
  formValues: EMPTY_OBJECT,
  mediaList: EMPTY_ARRAY,
  errors: EMPTY_OBJECT,
};

export default INITIAL_STATE;
