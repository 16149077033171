import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { fetchPreferencesApi, updateNotificationApi, updateSummaryApi } from '../api/TaskManagement';
import { CONSTANTS } from '../constants/Constants';
const { ACTIONS } = CONSTANTS;

export const fetchGetPreferences = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: null });
    return fetchPreferencesApi(baseUrl).then((response) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: response });
    }).catch((error) => {
      dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    });
  }
);

export const updateNotification = (baseUrl, data) => (
  (dispatch) => {
    return new Promise((resolve, reject) => {
      updateNotificationApi(baseUrl, data).then(() => {
        dispatch(fetchGetPreferences(baseUrl));
        resolve();
        toaster('success', __('Preference details updated successfully'));
      }).catch((error) => {
        dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
        toaster('error', __('Failed to update Preference details'));
      });
    });
  }
);

export const updateSummary = (baseUrl, data) => (dispatch, getState) => {
  const { task = {} } = getState();
  const { perference = {} } = task;
  return updateSummaryApi(baseUrl, data).then((response = {}) => {
    const { expandAll, tasks_view_option: tasksViewOption } = data;
    const updatedPreference = {
      ...perference, expandAll, tasks_view_option: tasksViewOption,
    };
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS, payload: updatedPreference });
    const { msg = __('Changes are saved') } = response;
    toaster('success', msg);
  }).catch((error) => {
    dispatch({ type: ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR, payload: error });
    toaster('error', __('Failed to update'));
  });
};
