// Constants
import { PROJECT, MODULE, SUB_MODULE } from './moveTestCaseForm.fields';

const FORM_CONFIG = {
  [PROJECT.id]: PROJECT,
  [MODULE.id]: MODULE,
  [SUB_MODULE.id]: SUB_MODULE,
};

export default FORM_CONFIG;
