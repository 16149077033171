import _map from 'lodash/map';


import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const makeCreatedByOptions = (data) => {
  const options = _map(data, element => ({
    label: element.createdBy,
    value: element.createdBy,
    additional:
    { id: element.createdBy, name: element.createdBy },
  }));
  return options;
};

export default makeCreatedByOptions;
