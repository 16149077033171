import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
// Utils
import { isRequiredRule } from 'tbase/utils/formValidators';

import PRIORITY_OPTIONS
  from "../../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.priority";
import ACTIVE_OPTIONS from "../../../../pages/testCases/organisms/TestCaseForm/constant/testCasesForm.active";
import AUTOMATED_OPTIONS from "../../../../pages/testCases/organisms/TestCaseForm/constant/testCasesForm.automated";

export const PRIORITY = {
  id: 'priority',
  renderer: SelectInput,
  renderOptions: {
    label: __('Priority'),
    // required: true,
    options: PRIORITY_OPTIONS,
  },
};

export const RELEASE = {
  id: 'releaseVersion',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Release Version'),
  },
};

export const ACTIVE = {
  id: 'isActive',
  renderer: SelectInput,
  renderOptions: {
    label: __('Active'),
    options: ACTIVE_OPTIONS,
  },
};
export const AUTOMATED = {
  id: 'isAutomated',
  renderer: SelectInput,
  renderOptions: {
    label: __('Automated'),
    options: AUTOMATED_OPTIONS,
  },
};

