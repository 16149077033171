import React, { useMemo, useCallback } from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';

import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';

import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';

import ACTION_TYPES from './constants/jiraIssueList.actionTypes';
import ACTION_HANDLERS from './helpers/jiraIssueList.actionHandlers';
import INITIAL_STATE from './constants/jiraIssueList.initialState';
import {makeTableProps, getSubHeaderProps, getHeaderProps} from './helpers/jiraIssueList.general';
import { getColumns } from './constants/jiraIssueList.columns';
import {EMPTY_ARRAY} from "@tekion/tekion-base/app.constants";
import _get from "lodash/get";
import styles from "./jiraIssueList.module.scss";

const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const SortableTableManager = withCustomSortTable(TableManager);
const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;

const JiraIssueList = (props) => {
  // console.log(props);
  const {
    tabId,
    onAction,
    currentPage,
    isLoading,
    history,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    projectListByProductId,
    pageSize = 50,
    searchText,
      data,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: true,
    showFilter: false,
    showHeader: true,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
  };

  const subHeaderProps = useMemo(() => getSubHeaderProps(), [onAction]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
      pageSize,
      currentPage,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  const tableData = _get(data, 'data.data.data.hits', EMPTY_ARRAY);

  const headerProps = useMemo(() => getHeaderProps(), EMPTY_ARRAY);

  // const data = [{
  //     name: 'Test Session 1',
  //     id: '11111',
  //     totalTestCases: '100',
  //     totalTestCasesExecuted: '99'
  // }, {
  //     name: 'Test Session 2',
  //     id: '11112',
  //     totalTestCases: '100',
  //     totalTestCasesExecuted: '99'
  // }, {
  //     name: 'Test Session 3',
  //     id: '11113',
  //     totalTestCases: '100',
  //     totalTestCasesExecuted: '99'
  // }, {
  //     name: 'Test Session 4',
  //     id: '11114',
  //     totalTestCases: '100',
  //     totalTestCasesExecuted: '99'
  // }]

  return (
    <PageComponent.Body style={{ height: contentHeight - 50 }}>
      <TableManager
        columns={getColumns(onAction)}
        data={tableData}
        headerProps={headerProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        // tableComponent={CheckBoxTable}
        onAction={onAction}
        // filterProps={filterProps}
        selection={selection}
        tableProps={getTableProps}
        subHeaderProps={subHeaderProps}
        sortDetails={sortDetails}
        isMultiSort
      />
    </PageComponent.Body>
  );
}

// const mapStateToProps = state => ({
//   tcmsBootStrap: getTcmsBootstrap(state),
//   projectListByProductId: getProjectListByProductId(state),
//
// });

export default compose(
  // connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(JiraIssueList);
