// utils
import getDataFromResponse from 'tbase/utils/getDataFromResponse';

// constants
import {
  TEST_CASE_NAME,
  AUTOMATED,
  PRIORITY,
  ACTIVE,
  BRANCH,
  COMPONENT,
  DOWNSTREAMCONSUMPTION,
  EXIT_CRITERIA,
  HORIZONTALIMPLEMENTATION,
  MISC,
  PRECONDITIONS,
  STEPS,
  RELEASE,
  REGRESSION,
  TCCATEGORY,
  TYPE,
  UPSTREAMCONSUMPTION,
  DESCRIPTION,
  COMMENTS,
  PRODUCT,
  PROJECT,
  JIRA,
  AUTHOR,
} from '../organisms/TestCaseForm/helpers/testCasesForm.fields';

// readers
import testCaseReader from '../readers/TestCase';

const makeInitialFormValues = (response) => {
  let testCase = getDataFromResponse(response);
  console.log(testCase)
  if (testCase != undefined) {
    const featureid = testCase.moduleId;
    // testCase = testCase.testcase;
    const formValues = {
      featureId: featureid,
      [TEST_CASE_NAME.id]: testCaseReader.name(testCase),
      [AUTHOR.id]: testCaseReader.author(testCase),
      [COMPONENT.id]: testCaseReader.component(testCase),
      [RELEASE.id]: testCaseReader.targetRelease(testCase),
      [BRANCH.id]: testCaseReader.branch(testCase),
      [JIRA.id]: testCaseReader.jira(testCase),
      [PRECONDITIONS.id]: testCaseReader.preConditions(testCase),
      [STEPS.id]: testCaseReader.steps(testCase),
      [ACTIVE.id]: [testCaseReader.active(testCase)],
      [AUTOMATED.id]: [testCaseReader.automated(testCase)],
      [REGRESSION.id]: [testCaseReader.regression(testCase)],
      [UPSTREAMCONSUMPTION.id]: testCaseReader.upstreamConsumption(testCase),
      [DOWNSTREAMCONSUMPTION.id]: testCaseReader.downstreamConsumption(testCase),
      [HORIZONTALIMPLEMENTATION.id]: testCaseReader.horizontalImplementation(testCase),
      [TCCATEGORY.id]: testCaseReader.tcCategory(testCase),
      [DESCRIPTION.id]: testCaseReader.description(testCase),
      [EXIT_CRITERIA.id]: testCaseReader.exitCriteria(testCase),
      [TYPE.id]: (testCaseReader.type(testCase) || "").split(","),
      [MISC.id]: testCaseReader.misc(testCase),
      // [COMMENTS.id]: testCaseReader.comments(testCase),
      // [PRODUCT.id]: [testCaseReader.productId(testCase)],
      // [PROJECT.id]: [testCaseReader.projectId(testCase)],
      [PRIORITY.id]: [testCaseReader.priority(testCase)],

    };
    return formValues;
  }
};

export default makeInitialFormValues;
