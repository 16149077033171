import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _first from 'lodash/first';
import FORM_PAGE_ACTION_TYPES from 'tcomponents/pages/formPage/constants/actionTypes';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import {PRODUCT, RELEASE_TAG,MODULE ,PROJECT,TRACKING_ENABLED} from '../constant/AddTrackingControlForm.fields';
import ACTION_TYPES from '../constant/AddTrackingControlForm.actionTypes';

// const handleProductFieldChange = ({
//   id, value, formValues, setState,
// }) => {
//   setState({ formValues: { ...formValues, [id]: value } });
// };
// const handleReleaseTagFieldChange = ({
//   id, value, formValues, setState,
// }) => {
//   setState({ formValues: { ...formValues, [id]: value } });
// };

// const handleModuleFieldChange = ({
//   id, value, formValues, setState,
// }) => {
//   setState({ formValues: { ...formValues, [id]: value } });
// };
// const handleProjectFieldChange = ({
//   id, value, formValues, setState,
// }) => {
//   setState({ formValues: { ...formValues, [id]: value } });
// };
// const handleTrackingEnabledFieldChange = ({
//   id, value, formValues, setState,
// }) => {
//   setState({ formValues: { ...formValues, [id]: value } });
// };

// const FIELD_CHANGE_MAP = {
//   [PRODUCT.id]: handleProductFieldChange,
//     [RELEASE_TAG.id]: handleReleaseTagFieldChange,
//     [MODULE.id]: handleModuleFieldChange,
//     [PROJECT.id]: handleProjectFieldChange,
//     [TRACKING_ENABLED.id]: handleTrackingEnabledFieldChange,
// };
const handleOnFieldChange = ({ params, getState, setState }) => {
    const { formValues } = getState();
  console.log(formValues);
  console.log(getState());
  const { id, value, option } = params;
  setState({formValues:{...formValues,[id]:value}})
  // const fieldChangeHandler = FIELD_CHANGE_MAP[id];
  // if (fieldChangeHandler) {
  //   fieldChangeHandler({
  //     id, value, formValues, setState,
  //   });
  // }
  // else if (id === 'product') {
  //     setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // }
  // else if (id === 'releaseTag') {
  //     setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // }
  // else if (id === 'module') {
  //     setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // }
  // else if (id === 'project') {
  //     setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // }
  // else if (option !== undefined && value === 'True') {
  //   setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // } 
  // else if (option !== undefined && value === 'False') {
  //   setState({
  //     formValues: {
  //       ...formValues, [id]: value,
  //     },
  //   });
  // }  else {
  //   setState({ formValues: { ...formValues, [id]: value } });
  // }
};

const handleFormSubmit = ({ getState }) => {
  const { onSubmit, formValues } = getState();
    onSubmit(formValues);
    console.log(formValues)
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
};

export default ACTION_HANDLERS;
