import _get from 'lodash/get';
import _head from 'lodash/head';

import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import ACTION_TYPES from '../Constants/viewReleaseBoard.actionTypes';
import ROW_ACTION_HANDLERS from '../organisms/ViewReleaseBoardList/helper/viewReleaseBoardList.rowActionHandlers';
import { addRelease, getReleaseInfo } from '../Services/releaseBoard.services';
import { IS_ACTIVE, RELEASE_VERSION } from '../organisms/AddReleaseForm/constants/AddReleaseForm.fields';
import HandleErrorAndStatus from '../../../helpers/ErrorFunction/errorFunction';

const handleTableItemsFetch = async ({ setState }) => {
  setState({ isLoading: true });
  const tableData = await getReleaseInfo();
  setState({ isLoading: false, tableData });
};

const handleOpenAddReleaseModal = ({ setState, getState }) => {
  setState({ isVisible: true });
};

const handleCloseAddReleaseModal = ({ setState, getState }) => {
  setState({ isVisible: false });
};

const handleSubmit = async ({ params, setState, getState }) => {
  const releaseVersion = _get(params, 'formValues.releaseVersion', EMPTY_STRING);
  const isActive = _head(_get(params, 'formValues.isActive', EMPTY_ARRAY));
  setState({ isLoading: true });
  const addReleaseBody = {
    releaseVersion,
    isActive,
  };
  try {
    const response = await addRelease(addReleaseBody).then(() => {
      toaster(TOASTER_TYPE.SUCCESS, __('Created Successfully'));
    });
    setState({ isVisible: false, isLoading: false });
    window.location.reload();
  } catch (error) {
    HandleErrorAndStatus(error);
    setState({ isVisible: false, isLoading: false });
  }
};

const handleRowActions = ({ params, getState, setState }) => {
  const { action } = params;
  const actionHandler = ROW_ACTION_HANDLERS[action];
  actionHandler({ setState, getState, params });
};


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [ACTION_TYPES.OPEN_ADD_RELEASE_MODAL]: handleOpenAddReleaseModal,
  [ACTION_TYPES.CLOSE_MODAL]: handleCloseAddReleaseModal,
  [ACTION_TYPES.FORM_SUBMIT_MODAL]: handleSubmit,
  [ACTION_TYPES.ROW_ACTION]: handleRowActions,

};

export default ACTION_HANDLERS;
