const ACTION_TYPES = {
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
  ROW_BULK_ACTION: 'ROW_BULK_ACTION',
  ROW_ACTION: 'ROW_ACTION',
  ON_DOWNLOAD_REPORT: 'ON_DOWNLOAD_REPORT',
  ON_CANCEL_EXECUTION_MODAL: 'ON_CANCEL_EXECUTION_MODAL',
  ON_TEST_SESSION_NAME_CLICK: 'ON_TEST_SESSION_NAME_CLICK',
  ON_TEST_SESSION_MODAL_CANCEL : 'ON_TEST_SESSION_MODAL_CANCEL',
  HANDLE_NEW_FORM_VALUES : 'HANDLE_NEW_FORM_VALUES'
};

export default ACTION_TYPES;
