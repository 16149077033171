// Constants
import FILTER_TYPES from 'tcomponents/organisms/filterSection/constants/filterSection.filterTypes';

import PRIORITY_OPTIONS from '../../../../../../../constants/priorityOptions';
import ACTIVE_OPTIONS from '../../../../TestCaseForm/constant/testCasesForm.active';
import AUTOMATED_OPTIONS from '../../../../TestCaseForm/constant/testCasesForm.automated';

export const PROJECT = {
  id: 'project_id',
  name: __('Project'),
  type: FILTER_TYPES.SINGLE_SELECT,
};

export const FEATURE = {
  id: 'module_id',
  name: __('Module'),
  type: FILTER_TYPES.SINGLE_SELECT,
};

export const TAG = {
  id: 'tags',
  name: __('Tag'),
  type: FILTER_TYPES.SINGLE_SELECT,
};

export const SUBFEATURE = {
  id: 'submodule_id',
  name: __('SubModule'),
  type: FILTER_TYPES.SINGLE_SELECT,
};

export const PRIORITY = {
  id: 'priority',
  name: __('Priority'),
  type: FILTER_TYPES.SINGLE_SELECT,
  additional: { options: PRIORITY_OPTIONS },
};

export const ACTIVE = {
  id: 'is_active',
  name: __('Active'),
  type: FILTER_TYPES.SINGLE_SELECT,
  additional: { options: ACTIVE_OPTIONS },
};

export const AUTOMATED = {
  id: 'is_automated',
  name: __('Automated'),
  type: FILTER_TYPES.SINGLE_SELECT,
  additional: { options: AUTOMATED_OPTIONS },
};

export const EXECUTED_AT = {
  id: 'executed_at',
  name: __('Executed At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const CREATED_AT = {
  id: 'created_at',
  name: __('Created At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const DEFAULT_FILTER_TYPES = [FEATURE.id, PROJECT.id, TAG.id];
