import React, {useMemo, useCallback, useState,useEffect} from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';
import _get from 'lodash/get';
import _filter from "lodash/filter";
import _map from "lodash/map";
import _isEqual from "lodash/isEqual";

import { withRouter } from 'react-router-dom';

import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';
import ConfirmationDialog from 'tcomponents/molecules/confirmationDialog';

import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';

import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';

import ACTION_HANDLERS from './helpers/testResultsList.actionHandlers';
import INITIAL_STATE from './constants/testResultsList.initialState';
import { columns } from './constants/testResultsList.columns';
import { createFilterProps } from './helpers/testResultsList.filterProps';
import { makeTableProps, getSubHeaderProps } from './helpers/testResultsList.general';
import ACTION_TYPES from './constants/testResultsList.actiontypes';

import { FAIL } from "./constants/testResultsList.status";
import UploadMediaModal from './organisms/uploadMediaModal';
import DisplayMediaModal from './organisms/displayMediaModal/DisplayMediaModal';
import TestCaseDetailsModal from './organisms/testCaseDetailsModal/TestCaseDetailsModal';
import TestResultsL3Header from './molecules/testResultsL3Header';
import NonAutomatedWarningMessage from "./organisms/nonAutomatedWarningMessage";

const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const SortableTableManager = withCustomSortTable(TableManager);

const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;

function TestResultsList(props) {
  const {
    onAction,
    currentPage,
    isLoading,
    history,
    match,
    location,
    selection,
    totalCount,
    selectedFilters,
    isFetchingNextPage,
    columnConfigurator,
    selectedTestResultId,
    contentHeight,
    sortDetails,
    isUploadModalVisible,
    isMediaModalVisible,
    isDetailsModalVisible,
    isDisplayMediaModalLoading,
    isDetailsModalLoading,
    testCaseValue,
    testCaseId,
    data,
    modules,
    mediaArray,
    pageSize = 50,
    isConfirmationModalVisible,
  } = props;

  const [isChecked, setIsChecked] = useState(false);


  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: true,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
    showL3Header: true,

  };

  const projectId = _get(match, 'params.projectId');

  useEffect(() => {
    onAction({ type: ACTION_TYPES.FETCH_MODULE_FILTER, payload: { testSessionId : _get(match, 'params.testsessionId') } });

}, [onAction]);

  const handleBulkAction = useCallback(
    (action) => {
      onAction({
        type: ACTION_TYPES.ROW_BULK_ACTION,
        payload: {
          action,
        },
      });
    },
    [onAction, selection]
  );

  const handleConfirmationModalCancel = useCallback(
    (action) => {
      onAction({
        type: ACTION_TYPES.CONFIRMATION_MODAL_CANCEL,
        payload: {
          action,
        },
      });
    },
    [onAction, selection]
  );
  

  const handleConfirmationModalSubmit = useCallback(
    (action) => {
      onAction({
        type: ACTION_TYPES.CONFIRMATION_MODAL_SUBMIT,
        payload: {
          action,
          isChecked
        },
      });
    },
    [onAction, selection, isChecked]
  );

  const filterProps = useMemo(() => createFilterProps({
    selectedFilters,
    modules
  }), [selectedFilters, modules]);
  const subHeaderProps = useMemo(() => getSubHeaderProps(handleBulkAction, selection), [onAction, selection]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isFetchingNextPage,
      isLoading,
      totalCount,
      // rowHeight,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
      pageSize,
      currentPage,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  const selectedData = _filter(data, ({ status }) => _isEqual(FAIL.id, status));
  const automatedValues = _map(selectedData, ['isAutomated', true]);
  const isNonAutomatedSelected = automatedValues.includes(false);
  // const isAutomated =
  // console.log(COLUMNS);
  return (
    <div>
      <PageComponent.Body style={{ height: contentHeight + 140 }}>
        <TableManager
          columns={columns}
          data={data}
          tableManagerProps={TABLE_MANAGER_PROPS}
          onAction={onAction}
          filterProps={filterProps}
          selection={selection}
          tableComponent={CheckBoxTable}
          tableProps={getTableProps}
          subHeaderProps={subHeaderProps}
          sortDetails={sortDetails}
          l3HeaderProps={{
            render: TestResultsL3Header,
            className: 'subHeaderContainer',
            isConfiguratorVisible: false,
            onAction,
          }}
          isMultiSort
        />
      </PageComponent.Body>
      <UploadMediaModal
        isUploadModalVisible={isUploadModalVisible}
        onAction={onAction}
        selectedTestResultId={selectedTestResultId}
      />
      <DisplayMediaModal
        mediaArray={mediaArray}
        selectedTestResultId={selectedTestResultId}
        onAction={onAction}
        isMediaModalVisible={isMediaModalVisible}
        isLoading={isDisplayMediaModalLoading}
      />
      <TestCaseDetailsModal
        onAction={onAction}
        testCaseId={testCaseId}
        isDetailsModalVisible={isDetailsModalVisible}
        isDetailsModalLoading={isDetailsModalLoading}
        testCaseValue={testCaseValue}
      />
      <ConfirmationDialog
        isVisible={isConfirmationModalVisible}
        onCancel={handleConfirmationModalCancel}
        onSubmit={handleConfirmationModalSubmit}
        loading={isLoading}
        submitBtnText={__('Yes')}
        secondaryBtnText={__('No')}
        title={__('Test Case Re Run')}
        content={<NonAutomatedWarningMessage isNonAutomatedSelected={isNonAutomatedSelected} isChecked={isChecked} setIsChecked={setIsChecked}/>}
      />
    </div>
  );
}

const mapStateToProps = state => ({});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 1, hasPageFooter: 1 })
)(TestResultsList);
