import React from 'react';
//  Lodash
import _map from 'lodash/map';
import _property from 'lodash/property';


const tabbs = [
  {
    id: '1',
    name: __('Automotive Retail Cloud'),
    // renderer: () => <h1>ARC</h1>, /* called tablerenderer with paramater as arc */
  },
  {
    id: '2',
    name: __('DealerShip Management system'),
    // renderer: () => <h1>DMS</h1>,
  },
  {
    id: '3',
    name: __('Digital Retail PPlatform'),
    // renderer: () => <h1>DRP</h1>,
  },
];

const makeTab = (TabContent, setSelection, setIsAutomated) => (product) => {
  //   console.log(product);
  const { id, name } = product;
  // console.log(id, name);
  return {
    id,
    name,
    renderer: props => <TabContent {...props} tabId={id} setSelection={setSelection} setIsAutomated={setIsAutomated} />,
  };
};
const getTabs = (TabContent, tabsData, setSelection, setIsAutomated) => {
  const tabs = _map(tabsData, makeTab(TabContent, setSelection, setIsAutomated));
  return tabs;
};

export default getTabs;
