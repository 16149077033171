import _get from "lodash/get";
import {EMPTY_STRING} from "@tekion/tekion-base/app.constants";

import ROW_ACTION_TYPES
    from "../constants/issueTable.rowActionTypes";

const handleUploadTestCases = ({ params, getState, setState }) => {
    const { value } = params;
    const { history } = getState();
    const productId = _get(value, 'productId', EMPTY_STRING);
    const projectId = _get(value, 'projectId', EMPTY_STRING);
    const testSessionId = _get(value, 'testSessionId', EMPTY_STRING);
    const key = _get(value, 'key', EMPTY_STRING);
    history.push({pathname: `/tcmsuiV2/testsession/addtestcase/${productId}/${projectId}/${testSessionId}`, value: {key}});
}

const handleAddNewTestCases = ({ params, getState, setState }) => {
    setState({
        isUploadModalVisible: true,
    });
}

const ROW_ACTION_HANDLERS = {
    [ROW_ACTION_TYPES.ADD_EXISTING_TEST_CASES]: handleUploadTestCases,
    [ROW_ACTION_TYPES.ADD_NEW_TEST_CASES]: handleAddNewTestCases,

};
export default ROW_ACTION_HANDLERS;
