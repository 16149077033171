import React, { useCallback, useMemo, useState } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from 'tcomponents/connectors/withActions';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import FormBuilder from 'tcomponents/organisms/FormBuilder';

import ACTION_HANDLERS from './helpers/bulkUpdateTestCasesForm.actionHandlers';
import SECTIONS from './constants/bulkUpdateTestCasesForm.sections';
import getFields from './constants/bulkUpdateTestCasesForm.formConfig';

const FormWithSubmission = withEventSubmission(FormBuilder);

const BulkUpdateTestCaseForm = ({
  onAction,
  formValues,
}) => {

  const CONTEXT_ID = 'BULK_UPDATE_TEST_CASES_FORM';

  const fields = useMemo(
    () => getFields({
      formValues,
    }),

    [formValues]
  );

  return (
    <div>
      <FormWithSubmission
        fields={fields}
        sections={SECTIONS}
        values={formValues}
        onAction={onAction}
        contextId={CONTEXT_ID}
      />
    </div>
  );
};

export default compose(
  withRouter,
  withActions({}, ACTION_HANDLERS)
)(BulkUpdateTestCaseForm);
