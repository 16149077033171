import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';
import { mapProps } from 'recompose';
import { getAutomatedProps } from '../helpers/addTestCaseList.column';
import PriorityRenderer from '../cellRenderers/priorityRenderer/PriorityRenderer';
import DownloadReportsRenderer from '../../../../../molecules/FieldRenderer/DownloadReportsRenderer';

const AutomatedCellRenderer = makeCellRenderer(StatusRenderer);
const PriorityCellRenderer = makeCellRenderer(PriorityRenderer);

const NAME = {
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Name',
  Cell: TextWithLink,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

// const TESTCASE_ID = {
//   id: 'testCaseId',
//   key: 'testCaseId',
//   accessor: 'testCaseId',
//   Header: 'TestCase Id',
//   Cell: TextWithLink,
//   getProps: () => ({
//     // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
//   }),
// };

const FEATURE = {
  id: 'moduleName',
  key: 'moduleName',
  accessor: 'moduleName',
  Header: 'Module',
  Cell: TextRenderer,
  sortable: true,
};
// const STATUS = {
//   id: 'status',
//   key: 'status',
//   accessor: 'status',
//   Header: 'Status',
//   Cell: StatusCellRenderer,
// //   getProps: getStatusProps,
//   sortable: true,
// };

const PRIORITY = {
  id: 'priority',
  key: 'priority',
  accessor: 'priority',
  Header: 'Priority',
  Cell: PriorityCellRenderer,
  sortable: true,
};

const TARGET_RELEASE = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  sortable: true,
};

const INFO = {
  id: 'info',
  key: 'info',
  accessor: 'info',
  Header: 'Info',
  Cell: TextRenderer,
  sortable: true,
};
const AUTOMATED = {
  id: 'automated',
  key: 'automated',
  accessor: 'isAutomated',
  Header: 'Automated',
  Cell: AutomatedCellRenderer,
  getProps: getAutomatedProps,
  sortable: true,
};

const DOWNLOAD_REPORTS = {
  id: 'downloadReports',
  key: 'downloadReports',
  accessor: 'downloadReports',
  Header: 'Download Reports',
  // getProps: TextRenderer,
  Cell: makeCellRenderer(DownloadReportsRenderer),
  sortable: true,
};

export const columns = [NAME, FEATURE, TARGET_RELEASE, PRIORITY, INFO, AUTOMATED, DOWNLOAD_REPORTS];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
