import React from 'react';
import _constant from 'lodash/constant';
import _size from 'lodash/size';
import _map from 'lodash/map';


import Button from '@tekion/tekion-components/src/atoms/Button';
import Menu from '@tekion/tekion-components/src/molecules/Menu';
import Dropdown from '@tekion/tekion-components/src/molecules/DropDown';
import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import ACTION_TYPES from '../constants/viewTrackingControl.actionTypes';

export const makeTableProps = ({
  isLoading,
  selection,
  totalCount,
  tableDataTrackingControl,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: tableDataTrackingControl.length,
  resultsPerPage: tableDataTrackingControl.length,
  totalNumberOfEntries: tableDataTrackingControl.length,
  type: TABLE_TYPES.FIXED_COLUMN,
  showPagination: false,
});
