import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import _get from 'lodash/get';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import _first from 'lodash/first';
import FORM_PAGE_ACTION_TYPES from 'tcomponents/pages/formPage/constants/actionTypes';
import getCompactList from '../../../../../utils/getCompactList';
import ACTION_TYPES from '../constant/createJiraRequest.actionTypes';
// import { makeTestSessionRequestDTO } from '../../../helpers/testSessions.request';
import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, RELEASE, TYPE, STATUS, ENV,
} from './createJiraRequest.fields';
import {makeJiraIssueRequestDTO, makeValidateJqlDto} from "./createJiraRequest.general";
import {createJiraIssue, validateJqlQuery} from "../../../services/jiraIssues.services";
// import { createTestSession } from '../../../services/testSessions.services';
// import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';

const handleProductFieldChange = ({
  id, value, formValues, setState, projectListByProductId,
}) => {
  const selectedId = _first(value);
  const projectListOptions = _get(projectListByProductId, selectedId);

  setState({ formValues: { ...formValues, [id]: value, [PROJECT.id]: undefined }, projectListOptions });
};

const handleFieldChange = ({ params, getState, setState }) => {
  const { projectListByProductId } = getState();
  const { id, value } = params;

  const { formValues } = getState();
  if (id === 'product') {
    handleProductFieldChange({
      id, value, formValues, setState, projectListByProductId,
    });
  } else {
    setState({ formValues: { ...formValues, [id]: value } });
  }
};

// const handleSubmitSuccess = (response) => {
//   // const statusCode = _get(response, 'status');
//   // if (statusCode === 'Success') {
//   toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
//   // }
// };
//
// const handleFailedTestSession = () => {
//   toaster(TOASTER_TYPE.ERROR, __('Error occured'));
// };
//
const handleFormSubmit = async ({ params, setState, getState }) => {
  const { formValues, history } = getState();
  const jiraIssueCreateDto = makeJiraIssueRequestDTO(formValues);

  try {
    toaster(TOASTER_TYPE.INFO, __('Creating Jira Issue...Please Wait for a while'));
    await createJiraIssue(jiraIssueCreateDto)
    .then(response => toaster(TOASTER_TYPE.SUCCESS, __('Successfully created the Jira Issue')));
    // handleSubmitSuccess();
    history.goBack();
  } catch (error) {
    console.log(error);
  }
};

// const handleCreateRelease = ({ params, getState, setState }) => {
//   const { formValues, releaseVersion } = getState();
//   const { newReleaseVersion } = params;
//   const updatedReleaseVersion = getCompactList(releaseVersion, newReleaseVersion);
//   setState({
//     formValues: { ...formValues, [RELEASE.id]: [newReleaseVersion] },
//     releaseVersion: updatedReleaseVersion,
//   });
// };

// const handleFormErrors = ({ params, setState }) => {
//   const { payload } = params;
//   const { errors } = payload;
//   setState({ errors });
// };

const handleValidateJqlClick = async ({ params, getState }) => {
  const { formValues } = getState();
  const validateQueryJqlDto = makeValidateJqlDto(formValues);
  try {
    toaster(TOASTER_TYPE.INFO, __('Validating JQL Query...Please Wait for a while'));
    await validateJqlQuery(validateQueryJqlDto)
        .then(response => toaster(TOASTER_TYPE.SUCCESS, __('Successfully validated the query')))
        // .catch(response => toaster(TOASTER_TYPE.FAILED, __('Entered JQL Query is invalid')))
    // handleSubmitSuccess();
    // history.goBack();
  } catch (error) {
    console.log(error);
  }
}

export const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.VALIDATE_JQL_CLICK]: handleValidateJqlClick,
  // // [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  // [ACTION_TYPES.HANDLE_FORM_SUBMIT]: handleFormSubmit,
  // [ACTION_TYPES.CREATE_RELEASE_VERSION]: handleCreateRelease,
};
