/* eslint-disable import/order */
/* eslint-disable rulesdir/no-inline-functions */
// Libraries
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

// Lodash
import _head from 'lodash/head';
import _noop from 'lodash/noop';

// Containers
import withSize from 'tcomponents/hoc/withSize';
import withActions from 'tcomponents/connectors/withActions';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
// Selectors
// import {
//   getTcmsBootstrap,
//   getFeatureListByProjectId,
//   getProjectListByProductId,
// } from 'reducers/selectors/app.selectors';
import styles from './uploadTestCasesForm.module.scss';

import {
  getTcmsBootstrap, getFeatureListByProjectId, getProjectListByProductId, getsubFeatureListByfeatureId,
} from '../../../store/selectors/tcms.selectors';

// Components
import FormBuilder from 'tcomponents/organisms/FormBuilder';
import FormPage from 'tcomponents/pages/formPage';
import UploadMedia from '../../uploadMedia/UploadMedia';
import DownloadExcelTemplate from './atoms/downloadExcelTemplate';

// Constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import { EXCEL_FILE_TYPES } from 'constants/uploadFileTypes';
import SECTIONS from './constants/uploadTestCasesForm.sections';
import ACTION_TYPES from './constants/uploadTestCasesForm.actionTypes';
import CONTEXT_ID from '../constants/uploadTestCases.general';
import INITIAL_STATE from './constants/uploadTestCasesForm.initialState';
import { PRODUCT, PROJECT } from './constants/uploadTestCasesForm.fields';

// Helpers
import ACTION_HANDLERS from './helpers/uploadTestCasesForm.actionHandlers';
import getFields from './helpers/uploadTestCasesForm.formConfig';
import { checkUserRoleForm } from '../../../helpers/UserRole/userRole.common';

const FormWithSubmission = withEventSubmission(FormBuilder);

const UploadTestCasesForm = (props) => {
  const {
    onAction, formValues, errors, tcmsBootstrap, featureListByProjectId,
    projectListByProductId, subfeatureListByFeatureId, isEdit, contentHeight, history,
  } = props;


  const renderFormHeader = () => (
    <PageComponent.Header hasBack>
      <div className="full-width headerContainer">
        <Heading size={1}>{__('Create Test Case')}</Heading>
      </div>
    </PageComponent.Header>
  );

  const selectedProductId = _head(formValues[PRODUCT.id]);
  const selectedProjectId = _head(formValues[PROJECT.id]);
  const selectedFeatureId = formValues.featureId;

  const handleCreateFeature = useCallback(
    (featureName) => {
      onAction({ type: ACTION_TYPES.CREATE_FEATURE, payload: { featureName } });
    },
    [onAction]
  );

  const handleCreateSubFeature = useCallback(
    (subfeatureName) => {
      onAction({ type: ACTION_TYPES.CREATE_SUBFEATURE, payload: { subfeatureName } });
    },
    [onAction]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const fields = useMemo(
    () => getFields({
      isEdit,
      tcmsBootstrap,
      selectedProductId,
      selectedProjectId,
      selectedFeatureId,
      featureListByProjectId,
      projectListByProductId,
      subfeatureListByFeatureId,
      handleCreateFeature,
      handleCreateSubFeature,
    }),

    [isEdit, tcmsBootstrap, selectedProductId, selectedProjectId, selectedFeatureId, featureListByProjectId, projectListByProductId, subfeatureListByFeatureId, handleCreateFeature, handleCreateSubFeature]
  );

  return (
    <div>
      <FormPage
        fields={fields}
        headerComponent={renderFormHeader}
        sections={SECTIONS}
        onClose={handleCancel}
        contentHeight={contentHeight - 60}
        values={formValues}
        onAction={onAction}
        errors={errors}
        contextId={CONTEXT_ID}
        footerProps={checkUserRoleForm()}   
      />
      {/* <UploadMedia onMediaChange={onMediaChange} acceptFileTypes={EXCEL_FILE_TYPES} />

      <DownloadExcelTemplate onDownloadTemplate={onDownloadTemplate} /> */}
    </div>
  );
};

UploadTestCasesForm.propTypes = {
  onAction: PropTypes.func,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  isEdit: PropTypes.bool,
  tcmsBootstrap: PropTypes.object.isRequired,
  featureListByProjectId: PropTypes.object,
  projectListByProductId: PropTypes.object,
  subfeatureListByFeatureId: PropTypes.object,
};

UploadTestCasesForm.defaultProps = {
  onAction: _noop,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  isEdit: false,
  featureListByProjectId: EMPTY_OBJECT,
  projectListByProductId: EMPTY_OBJECT,
  subfeatureListByFeatureId: EMPTY_OBJECT,

};

const mapStateToProps = state => ({
  tcmsBootstrap: getTcmsBootstrap(state),
  featureListByProjectId: getFeatureListByProjectId(state),
  projectListByProductId: getProjectListByProductId(state),
  subfeatureListByFeatureId: getsubFeatureListByfeatureId(state),
});

export default compose(connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }))(UploadTestCasesForm);
