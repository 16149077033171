import _property from 'lodash/property';

const id = _property('id');
const name = _property('name');
const priority = _property('priority');
const active = _property('isActive');
const automated = _property('isAutomated');
const description = _property('description');
const author = _property('createdBy');
const steps = _property('steps');
const exitCriteria = _property('exitCriteria');
const targetRelease = _property('releaseVersion');
const component = _property('component');
const branch = _property('branch');
const preConditions = _property('precondition');
const affectedComponents = _property('affectedComponents');
const tcCategory = _property('tcCategory');
const upstreamConsumption = _property('upstreamConsumption');
const downstreamConsumption = _property('downstreamConsumption');
const horizontalImplementation = _property('horizontalImplementation');
const type = _property('type');
const misc = _property('misc');
const projectId = _property('projectId');
const productId = _property('productId');
const jira = _property('jira');
const comments = _property('comments');
const fileName = _property('fileName');
const createdByUserId = _property('createdByUserId');
const createdTime = _property('createdTime');
const dealerId = _property('dealerId');
const deleted = _property('deleted');
const featureId = _property('featureId');
const info = _property('info');
const jiraKey = _property('jiraKey');
const modifiedByUserId = _property('modifiedByUserId');
const modifiedTime = _property('modifiedTime');
const prUrl = _property('prUrl');
const tenantId = _property('tenantId');
const projectName = _property('projectName');
const regression = _property('regression');

const READER = {
  id,
  name,
  priority,
  active,
  automated,
  regression,
  description,
  author,
  steps,
  exitCriteria,
  targetRelease,
  component,
  branch,
  preConditions,
  affectedComponents,
  tcCategory,
  upstreamConsumption,
  downstreamConsumption,
  horizontalImplementation,
  type,
  misc,
  projectId,
  productId,
  jira,
  comments,
  fileName,
  createdByUserId,
  createdTime,
  jiraKey,
  prUrl,
  tenantId,
  modifiedByUserId,
  modifiedTime,
  info,
  featureId,
  deleted,
  dealerId,
  projectName,
};

export default READER;
