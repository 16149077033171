import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';
import LabelRenderer from '../../../../../../../molecules/FieldRenderer/LabelRenderer';
import { getStatusProps, getAutomatedProps, getPriorityProps } from '../helpers/testCasesList.column';
const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const TESTCASE = {
  id: 'testCaseId',
  key: 'testCaseId',
  accessor: 'testCaseId',
  Header: 'Testcase',
  Cell: TextWithLink,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const NAME = {
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Name',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const PRIORITY = {
  id: 'priority',
  key: 'priority',
  accessor: 'priority',
  Header: 'Priority',
  Cell: StatusCellRenderer,
  getProps: getPriorityProps,
};
const AUTOMATED = {
  id: 'isAutomated',
  key: 'isAutomated',
  accessor: 'isAutomated',
  Header: 'Automated',
  Cell: StatusCellRenderer,
  getProps: getAutomatedProps,
  sortable: true,
};
const TARGET_RELEASE = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  sortable: true,
};
const ACTIVE = {
  id: 'isActive',
  key: 'isActive',
  accessor: 'isActive',
  Header: 'Active',
  Cell: StatusCellRenderer,
  getProps: getStatusProps,
  sortable: true,
};

// const PRODUCT_NAME = {
//   id: 'product',
//   key: 'product',
//   accessor: 'product',
//   Header: 'Product Name',
//   Cell: TextRenderer,
//   sortable: true,
// };


const LABEL_NAME = {
  id: 'tags',
  key: 'tags',
  accessor: 'tags',
  Header: 'Tags',
  Cell: makeCellRenderer(LabelRenderer),
  sortable: true,
};

const FEATURE_NAME = {
  id: 'moduleName',
  key: 'moduleName',
  accessor: 'moduleName',
  Header: 'Module Name',
  Cell: TextRenderer,
  sortable: true,
};

const SUBFEATURE_NAME = {
  id: 'submoduleName',
  key: 'submoduleName',
  accessor: 'subModuleName',
  Header: 'SubModule Name',
  Cell: TextRenderer,
  sortable: true,
};

export const columns = [TESTCASE, NAME, PRIORITY, TARGET_RELEASE, ACTIVE, AUTOMATED, LABEL_NAME, FEATURE_NAME, SUBFEATURE_NAME];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
