exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "[dir=ltr] :export{brightOrange:#fc602b}[dir=rtl] :export{bleftOrange:#fc602b}[dir=ltr] :export{brightOrange:#fc602b}[dir=rtl] :export{bleftOrange:#fc602b}.automationProgress_releaseDetails__1is4I{display:flex;justify-content:space-around}[dir] .automationProgress_releaseDetails__1is4I{padding-top:2rem;padding-bottom:4rem;margin:2rem}[dir] .automationProgress_buttonStyle__3fXk9{margin-top:2.3rem}[dir] .automationProgress_tableStyle__3KU9m{margin-top:-4rem}[dir=ltr] .automationProgress_pageStyle__2meLe{margin-left:3rem}[dir=rtl] .automationProgress_pageStyle__2meLe{margin-right:3rem}", ""]);

// Exports
exports.locals = {
	"black": "#161616",
	"white": "#fff",
	"tekionGreen": "#00bfa5",
	"denim": "#4285f4",
	"platinum": "#d4d5d6",
	"gunMetal": "#1f2532",
	"atomic": "#444f5c",
	"ashGray": "#969aa3",
	"aliceBlue": "#ebf4ff",
	"glitter": "#edeef0",
	"lightGray": "#f4f5f6",
	"mordantRed": "#f52908",
	"parisGreen": "#60d156",
	"carrotOrange": "#f80",
	"ceruleanBlue": "#00b5da",
	"azure": "#0060ff",
	"vibrantYellow": "#ffdc00",
	"darkCyan": "#008f85",
	"violetBlue": "#a162a3",
	"salmon": "#ff8370",
	"bizzare": "#f2e3e1",
	"tranquil": "#e5f1f0",
	"selago": "#f2edf3",
	"caribbeanGreen": "#03bfa5",
	"linkWater": "#d9e6f8",
	"hoki": "#6285a3",
	"stormGray": "#737884",
	"jetBlack": "#000",
	"whiteSmoke": "#f4f4f4",
	"dimGray": "#686868",
	"dodgerBlue": "#007bfe",
	"dodgerBlueLight": "#4285f4",
	"torchRed": "#ff0027",
	"keppel": "#40b38e",
	"wisteria": "#8b67ab",
	"sunshade": "#ff9b2b",
	"fountainBlue": "#5da2c5",
	"burningOrange": "#ff7030",
	"sushi": "#8b9e3a",
	"moodyBlue": "#6e6fd4",
	"carnation": "#f04f64",
	"font-size-small": "1.2rem",
	"fontFamilySemiBold": "\"Proxima-Nova-Semibold\"",
	"polarGreen": "#ebfaf8",
	"statusErrorBase": "#ffd3d3",
	"statusSuccess": "#06bc75",
	"statusError": "#f94643",
	"orangeRed": "#f22",
	"grey80": "#ccc",
	"lavendarViolet": "#b483ff",
	"majorelleBlue": "#525fff",
	"screamingGreen": "#72ff7d",
	"aquaBlue": "#3cdeff",
	"atomicTangerine": "#ffa065",
	"bitterSweetRed": "#f66",
	"lightningYellow": "#ffc613",
	"glowingturquoise": "#29ffbf",
	"outerSpaceGray": "#303943",
	"cometGray": "#5c6470",
	"shuttleGray": "#515e6d",
	"osloGray": "#828a96",
	"riverBedGray": "#3f4757",
	"cornFlowerBlue": "#648de6",
	"bigStone": "#152532",
	"bananaMania": "#ffe9b6",
	"whiteWhite": "#fafafa",
	"wildWatermelon": "#ff626d",
	"chardonnay": "#ffc371",
	"moderateYellow": "#d5ba68",
	"darkOrange": "#f80",
	"eastSideLavendar": "#b394d1",
	"coldPurple": "#939ed6",
	"deYorkgreen": "#83c188",
	"sinBlue": "#92ccd6",
	"goldOrange": "#e3a285",
	"tonysRed": "#e58e8c",
	"apacheYellow": "#dbb668",
	"bakedGreen": "#8cccc3",
	"buttercupYellow": "#f5a623",
	"sinYellow": "#ffb528",
	"lightPink": "#fdeaf1",
	"lightGreen": "#e9f8f9",
	"lightPurple": "#e9edfd",
	"cerisePink": "#f45191",
	"turquoiseGreen": "#46cad4",
	"royalBlue": "#4f68f1",
	"pattensBlue": "#dedfe0",
	"blackRussian": "#22232e",
	"blackRock": "#2f313e",
	"manatee": "#97989d",
	"blueZodiac": "#44464f",
	"lightGrey": "#eeeff0",
	"lilacLight": "#f3f2ff",
	"heliotrope": "#e831f8",
	"jumbo": "#818389",
	"hintOfRed": "#f6f3f3",
	"greenYellow": "#acd942",
	"cloudBurst": "#38404f",
	"pictonBlue": "#2abfe9",
	"lightSkyBlue": "#e9ecfc",
	"blueMarguerite": "#7a64c1",
	"indigo": "#426cc8",
	"blueTurquoise": "#1bdcea",
	"supernovaYellow": "#ffb83c",
	"greyLight3": "#ededed",
	"pastelPink": "#eea4da",
	"pastolViolet": "#cfb8ff",
	"bluePurple": "#8760c9",
	"ebonyBlack": "#43474e",
	"fossilGrey": "#6d6f76",
	"anakiwa": "#a3c8ff",
	"lightMordantRed": "#fff1f1",
	"gold": "#ffd500",
	"lightYellow": "#fff4c5",
	"foamBlue": "#e7f7fc",
	"wildWillow": "#c3c971",
	"redOrange": "#ff2c37",
	"jade": "#00c06e",
	"perfumeViolet": "#bba8f6",
	"bostonBlue": "#4987a9",
	"fauxLavender": "#e9e3fc",
	"turquioseBlue": "#c8eef9",
	"sandyOrange": "#ffeac6",
	"pippinOrange": "#ffe1e1",
	"altoGrey": "#d2d2d2",
	"canary": "#cbfa5b",
	"parisDaisy": "#fff270",
	"sundown": "#ffb6b4",
	"charade": "#262932",
	"emeraldGreen": "#6dd400",
	"pastelGreen": "#62d46a",
	"lightRed": "#fee8e9",
	"ottomanGreen": "#edf9ee",
	"peachCream": "#fff0e1",
	"geraldineaPink": "#ff8485",
	"spineColor": "#00e0ed",
	"lavenderRose": "#fb9fdd",
	"melrose": "#c0a5fc",
	"citrusGreen": "#9fdc00",
	"fordGray": "#979797",
	"mustard": "#ffdc4a",
	"red": "red",
	"osloGrayDark": "#838588",
	"dodgerLightBlue": "#2584fc",
	"ebonyClay": "#1f2532",
	"ultraLightGrey": "#e6e5eb",
	"wsBlue": "#0055e2",
	"amethyst": "rgba(135,96,201,.1215686275)",
	"cyanBlue": "#bcd0e0",
	"alabasterGrey": "#fbfbfb",
	"citrus": "#91cc17",
	"smokeWhite": "#f5f5f5",
	"bridesMaidPink": "#fee9e7",
	"rubyRed": "#e02020",
	"lightOrange": "#ffb23c",
	"releaseDetails": "automationProgress_releaseDetails__1is4I",
	"buttonStyle": "automationProgress_buttonStyle__3fXk9",
	"tableStyle": "automationProgress_tableStyle__3KU9m",
	"pageStyle": "automationProgress_pageStyle__2meLe"
};