import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';


const handleOnFormInit = () => {

};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleOnFormInit,
  // [ACTION_TYPES.ON_FORM_SUBMIT]: handleOnFormSubmit,

};

export default ACTION_HANDLERS;
