// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

export const NOT_STARTED = { id: 'NOT_STARTED', name: __('Not Started') };
export const IN_PROGRESS = { id: 'IN_PROGRESS', name: __('In Progress') };
export const COMPLETED = { id: 'COMPLETED', name: __('Completed') };

const STATUSES = [NOT_STARTED, IN_PROGRESS, COMPLETED];

export const STATUS_COLOR_MAP = {
  [COMPLETED.id]: COLOR_MAP.GREEN,
  [NOT_STARTED.id]: COLOR_MAP.RED,
  [IN_PROGRESS.id]: COLOR_MAP.ORANGE,
};

export const STATUS_LABEL_MAP = {
  [COMPLETED.id]: COMPLETED.name,
  [NOT_STARTED.id]: NOT_STARTED.name,
  [IN_PROGRESS.id]: IN_PROGRESS.name,
};

export const STATUS_OPTIONS = standardFieldOptionMapper(undefined, STATUSES);


const ACTIVE = { id: 'Active', name: __('Active') };
const INACTIVE = { id: 'Inactive', name: __('Inactive') };

const TESTCASE_STATUS = [ACTIVE, INACTIVE];

export const TESTCASE_STATUS_OPTIONS = standardFieldOptionMapper(undefined, TESTCASE_STATUS);
