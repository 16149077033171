// Readers
import _get from 'lodash/get';
import _size from 'lodash/size';
// Utils
import addToRenderOptions from 'tbase/utils/addToRenderOptions';
import tcmsBootstrapReader from '../../../../readers/TcmsBootstrap';

import { getTcmsBootstrap } from '../services/bootstrap.services';

// Helpers
import makeProductOptions from '../../../../helpers/productOptions';
import makeProjectOptions from '../../../../helpers/projectOptions';
import makeFeatureOptions from '../../../../helpers/featureOptions';
import makesubFeatureOptions from '../../../../helpers/subfeatureOptions';

// Constants
import {
  PRODUCT, PROJECT, FEATURE, SUBFEATURE,
} from '../constants/uploadTestCasesForm.fields';
import FORM_CONFIG from '../constants/uploadTestCasesForm.formConfig';

export const getFields = ({
  isEdit,
  handleCreateFeature,
  handleCreateSubFeature,
  tcmsBootstrap,
  selectedProductId,
  selectedProjectId,
  selectedFeatureId,
  featureListByProjectId,
  projectListByProductId,
  subfeatureListByFeatureId,
}) => {
  const productList = tcmsBootstrapReader.productList(tcmsBootstrap);
  const featureList = featureListByProjectId[selectedProjectId];
  const projectList = projectListByProductId[selectedProductId];
  const subfeatureList = subfeatureListByFeatureId[selectedFeatureId];

  const productOptions = makeProductOptions(productList);
  const projectOptions = makeProjectOptions(projectList);
  const featureOptions = makeFeatureOptions(featureList);
  const subfeatureOptions = makesubFeatureOptions(subfeatureList);
  console.log(subfeatureList);
  return {
    ...FORM_CONFIG,
    [PRODUCT.id]: addToRenderOptions(PRODUCT, [{ path: 'options', value: productOptions }]),
    [PROJECT.id]: addToRenderOptions(PROJECT, [{ path: 'options', value: projectOptions }]),
    [FEATURE.id]: addToRenderOptions(FEATURE, [
      { path: 'options', value: featureOptions },
      { path: 'isDisabled', value: isEdit },
      { path: 'onCreateOption', value: handleCreateFeature },
    ]),
    [SUBFEATURE.id]: addToRenderOptions(SUBFEATURE, [
      { path: 'options', value: subfeatureOptions },
      { path: 'isDisabled', value: isEdit },
      { path: 'onCreateOption', value: handleCreateSubFeature },
    ]),
  };
};

export default getFields;
