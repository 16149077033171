// Libraries
import compose from 'recompose/compose';
import _round from 'lodash/round';

// Readers
import fileReader from './readers/File';

const getRoundedFileUploadProgess = compose(_round, fileReader.progress);

export default getRoundedFileUploadProgess;
