// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
import projectReader from 'readers/Project';

const PROJECT_OPTION_MAPPER = {
  label: projectReader.name,
  value: projectReader.id,
};
const makeProjectOptions = projectList => standardFieldOptionMapper(undefined, projectList, PROJECT_OPTION_MAPPER);

export default makeProjectOptions;
