import _get from 'lodash/get';
import makeProductOptions from '../../../../../constants/productsOptions';
import makeProjectOptions from '../../../../../constants/projectsOptions';

export const productOptionsProps = ({
    tcmsBootStrap,
}) => {
    const products = _get(tcmsBootStrap, 'productList');
    const productOptions = makeProductOptions(products);
    return productOptions
}

export const projectOptionsProps = ({
    projectListByProductId, selectedProduct
}) => {
    const projects = projectListByProductId[selectedProduct]
    const projectOptions = makeProjectOptions(projects);
    return projectOptions;
}