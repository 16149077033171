import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _head from 'lodash/head';
import _get from 'lodash/get';

export const segregateChartData = (chartData) => {
    let getBarChartCategories = [];
    let getAutomatedCount = [];
    let getNonAutomatedCount = [];
    if(_get(_head(chartData),'projectId')===null && 
    _get(_head(chartData),'moduleId')===null && 
    _get(_head(chartData),'productId')!=null){
        _map(chartData, element => {
            getBarChartCategories.push(element.productName)
            getAutomatedCount.push(element.automatedTestCases)
            getNonAutomatedCount.push(element.nonAutomatedTestCases)
        })
    } 
     if(_get(_head(chartData),'projectId')!=null && 
    _get(_head(chartData),'moduleId')===null && 
    _get(_head(chartData),'productId')!=null){
        _map(chartData, element => {
            getBarChartCategories.push(element.projectName)
            getAutomatedCount.push(element.automatedTestCases)
            getNonAutomatedCount.push(element.nonAutomatedTestCases)
        })
    }
    if(_get(_head(chartData),'projectId')!=null && 
    _get(_head(chartData),'moduleId')!=null && 
    _get(_head(chartData),'productId')!=null){
        console.log('3')

        _map(chartData, element => {
            getBarChartCategories.push(element.moduleName)
            getAutomatedCount.push(element.automatedTestCases)
            getNonAutomatedCount.push(element.nonAutomatedTestCases)
        })
    }
    return({ getBarChartCategories, getAutomatedCount, getNonAutomatedCount })
};
