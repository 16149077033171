// Constants
import { PROJECT, MODULE, SUB_MODULE } from './moveTestCaseForm.fields';

// Styles
// import styles from '../uploadTestCasesForm.module.scss';

const SECTIONS = [
  {
    rows: [
      {
        columns: [PROJECT.id],
      },
      {
        columns: [MODULE.id],
      },
      {
        columns: [SUB_MODULE.id],
      },
    ],
  },
];

export default SECTIONS;
