import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import _get from 'lodash/get';
import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import FontIcon from 'tcomponents/atoms/FontIcon';
import _noop from 'lodash/noop';
import Modal from 'tcomponents/molecules/Modal';
import FieldLabel from 'tcomponents/organisms/FormBuilder/components/fieldLabel';
import styles from './labelRenderer.module.scss';
import PopoverContent from '../../PopoverContent';
import AddLabelForm from '../../../pages/testCases/organisms/AddLabelForm/AddLabelForm';
import { updateTestCase } from './services/LabelRenderer.api';
import { checkUserRole } from '../../../helpers/UserRole/userRole.common';

class LabelRenderer extends PureComponent {
  state = {
    isOpen: false,
    isModalVisible: false,
    tagValue: [],
    tagStateData: null,
    tagsCollection: [],
    isLoading: false,
  };

  // componentDidMount() {
  //   const { data } = this.props;
  //   // const { testCaseId } = data;
  //   const testCaseId = _get(data, 'testCaseId', EMPTY_STRING);
  //   if (testCaseId) this.triggerTagsHandler();
  // }
  //
  // componentDidUpdate(prevProps, _prevState) {
  //   const { data } = this.props;
  //   if (prevProps.data !== data) this.triggerTagsHandler();
  // }
  //
  // triggerTagsHandler = () => {
  //   let tags = JSON.parse(localStorage?.getItem('tags'));
  //   const { data: testData } = this.props;
  //   const testCaseId = _get(testData, 'testCaseId', EMPTY_STRING);
  //   // const { testCaseId } = testData;
  //   if (tags) {
  //     if (tags != null) tags = Object.values(tags);
  //     const tagData = new Set();
  //     if (tags != null) {
  //       tags.forEach((lData) => {
  //         tagData.add(...lData);
  //       });
  //     }
  //     const result = [];
  //     tagData.forEach((d) => {
  //       if (d) {
  //         result.push({
  //           value: d,
  //           label: d,
  //         });
  //       }
  //     });
  //     const tagsCollection = JSON.parse(localStorage.getItem('tags'))[testCaseId];
  //     this.setState(() => ({
  //       tagStateData: Array.from(new Set(tagsCollection)),
  //       tagsCollection: result,
  //     }));
  //   }
  // }

  toggleOpen = (event) => {
    event.stopPropagation();
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleModalVisibility = (event) => {
    event.stopPropagation();
    this.setState(prevState => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  handleAddModelSubmit = async (event, data) => {
    // const tags = JSON.parse(localStorage.getItem('tags'));
    const { tagValue } = this.state;
    const { original } = this.props;
    const id = _get(original, 'id', EMPTY_STRING);
    const previousTags = _get(original, 'tags', EMPTY_ARRAY);
    // // const { testCaseId } = testData;
    // const testCaseId = _get(testData, 'testCaseId', EMPTY_STRING);
    // const tagsCollection = [...data, tagValue?.[0]];
    // tags[testCaseId] = tagsCollection;
    // localStorage.setItem('tags', JSON.stringify(tags));
    // this.setState(() => ({
    //   tagStateData: Array.from(new Set(tagsCollection)),
    //   tagValue: null,
    // }));
    const tags = previousTags !== null ? tagValue.concat(previousTags) : tagValue;
    const updateTestCaseDto = {
      id,
      tags,
    };
    this.setState(() => ({
      isLoading: true,
    }));
    const updateTestCaseResponse = await updateTestCase(updateTestCaseDto);
    this.setState(() => ({
      tagStateData: tagValue,
      tagValue: null,
      isLoading: false,
    }));
    this.toggleModalVisibility(event);
    window.location.reload();
  };

  handleAddModelOnClose = (event) => {
    this.toggleModalVisibility(event);
  };

  renderAssignmentIcon = ({ disabled, onClick }) => (
    <div className={styles.addTechnicianIcon} id="assignTech">
      <FontIcon
        disabled={disabled}
        onClick={disabled ? _noop : onClick}
        size={16}
      >
        icon-add-person
      </FontIcon>
    </div>
  );

  setTagValue = (value) => {
    this.setState(() => ({
      tagValue: value,
    }));
  };

  renderPopOverContent = (tagStateData, isOpen) => {
    if (tagStateData?.length > 1) {
      return (
        <Popover
          pointed
          adjustVerticalAlign={false}
          position="top"
          align="left"
          popOverClass="show-more-items__extra-items"
          open={isOpen}
          onClose={this.toggleOpen}
          content={<PopoverContent tags={tagStateData.slice(1)} />}
          trigger="click"
        >
          <span className={styles.moreItemsBtn}>
            +{tagStateData.length - 1}
          </span>
        </Popover>
      );
    }
    return null;
  };

  render() {
    const {
      isOpen,
      isModalVisible,
      tagValue,
      tagStateData,
      tagsCollection,
      isLoading,
    } = this.state;
    const { data } = this.props;

    return (
      <>
        <div className={styles.technicianContainer}>
          {this.renderAssignmentIcon({
            disabled: false,
            onClick: this.toggleModalVisibility,
          })}
          <FieldLabel label={data?.[0]} required={false} />
          {this.renderPopOverContent(data, isOpen)}
        </div>

        <div>
          <Modal
            title={__('Add Tag')}
            submitBtnText={__('Submit')}
            visible={isModalVisible}
            onSubmit={e => this.handleAddModelSubmit(e, tagStateData)}
            onCancel={this.handleAddModelOnClose}
            primaryBtnDisabled={checkUserRole()}
            width={Modal.SIZES.SM}
            destroyOnClose
            loading={isLoading}
          >
            <AddLabelForm
              tagValue={tagValue}
              setTagValue={this.setTagValue}
              tagsList={tagsCollection}
            />
          </Modal>
        </div>
      </>
    );
  }
}

export default LabelRenderer;
