/* eslint-disable rulesdir/no-inline-functions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';


// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';
import SIZES from 'tcomponents/molecules/Modal/constants/modal.sizes';
import TEST_RESULT_LIST_ACTION_TYPES from '../../constants/testResultsList.actiontypes';
import { BODY_STYLE } from './constant/displayMediaModal.general';

// Components
import Modal from 'tcomponents/molecules/Modal';
import Loader from 'tcomponents/molecules/loader';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import useFetchData from 'tbase/customHooks/useFetchData';

// Readers
import mediaReader from 'tbase/readers/Media';

// Helpers
import styles from './displayMediaModal.module.scss';
import { getMediaTestResult } from '../../../../../../services/testSessions.services';

const renderImageWithIcon = mediaURL => (
  <div className={`${styles.showMedia} p-b-16`}>
    <img className={styles.imageWithDeleteIcon} src={mediaURL} alt={__('Test Result Media')} />
  </div>
);

function DisplayMediaModal(props) {
  const {
    selectedTestResultId, isMediaModalVisible, onAction, mediaArray, isLoading,
  } = props;
  // console.log(selectedTestResultId);

  // const [{ isLoading, data }] = useFetchData(getMediaTestResult, [selectedTestResultId]);
  const mediaList = mediaArray;

  const handleMediaModalCancel = useCallback(() => {
    onAction({ type: TEST_RESULT_LIST_ACTION_TYPES.CLOSE_MEDIA_MODAL });
  }, [onAction]);

  return (
    <Modal
      title={__('Test Result Images')}
      visible={isMediaModalVisible}
      onCancel={handleMediaModalCancel}
      hideSubmit
      bodyStyle={BODY_STYLE}
      width={SIZES.L}
      destroyOnClose
    >
      {isLoading ? (
        <Loader />
      ) : _size(mediaList) ? (
        _map(mediaList, renderImageWithIcon)
      ) : (
        __('No images available')
      )}
    </Modal>
  );
}

DisplayMediaModal.propTypes = {
  isMediaModalVisible: PropTypes.bool,
  selectedTestResultId: PropTypes.number,
  mediaList: PropTypes.array,
  onAction: PropTypes.func,
  isLoading: PropTypes.bool,
};

DisplayMediaModal.defaultProps = {
  isMediaModalVisible: false,
  selectedTestResultId: undefined,
  mediaList: EMPTY_ARRAY,
  onAction: _noop,
  isLoading: false,
};

export default makeCellRenderer(DisplayMediaModal);
