import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd';
import TextInput from 'tcomponents/molecules/TextInput';
import Select from 'tcomponents/molecules/Select';
import Switch from 'tcomponents/molecules/Switch';
import Checkbox from 'tcomponents/atoms/checkbox';
import Button from 'tcomponents/atoms/Button';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import TimePicker from 'tcomponents/atoms/TimePicker';
import { getCookie } from '@tekion/tap-components/utils/helper';
import Loader from 'tcomponents/molecules/loader';
import TabGroup from '../../../molecules/TabGroup';
import './Calendar.scss';
import { getUserAvailability, saveUserAvailability } from '../../../action/Calendar.action';
import { getUserAvailabilityStatus, addUserAvailability, loader } from './Calendar.selector';
import momentTimezone from 'moment-timezone';

const tabData = [
  { key: 'Availability', label: __('Availability') },
];

export const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    const slug = getCookie('email') ? getCookie('email').replace('@tekion.com', '') : '';
    this.state = {
      dateFormats: [{ label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' }, { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' }],
      timeFormats: [{ label: '24 Hours', value: '24 Hours' }, { label: '12 Hour (AM / PM)', value: '12 Hour (AM / PM)' }],
      weeks: [
        {
          dayLabel: __('Sunday'), day: 'Sunday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Monday'), day: 'Monday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Tuesday'), day: 'Tuesday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Wednesday'), day: 'Wednesday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Thursday'), day: 'Thursday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Friday'), day: 'Friday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
        {
          dayLabel: __('Saturday'), day: 'Saturday', type: 'wkday', intervals: [{ from: '09:00', to: '18:00' }],
        },
      ],
      selectedFields: {},
      addnewleaves: false,
      leaves: [{ month: 'Mar', days: 25, reason: 'Personal' }, { month: 'April', days: 14, reason: 'Busy' }],
      timeFormat: 'HH:mm',
      currentTab: 'Availability',
      isDataAvailable: false,
      calendarAddFields: {
        dateformat: 'MM/DD/YYYY', timeformat: '24 Hours', slug, availability: { rules: [] },
      },
      loaded: false,
    };
    this.zerothTime = moment('00:00', 'HH:mm');
  }

  componentDidMount() {
    const { apiBaseUrl, fetchUserAvailability } = this.props;
    fetchUserAvailability(apiBaseUrl);
  }


  static getDerivedStateFromProps(props, state) {
    const { userAvailability } = props;
    if (userAvailability && Object.keys(userAvailability).length > 0 && !state.loaded) {
      const rules = userAvailability.availability && userAvailability.availability.rules === null;
      if (rules) {
        userAvailability.availability.rules = [];
      }
      return {
        loaded: true,
        isDataAvailable: true,
        calendarAddFields: userAvailability,
      };
    }

    return null;
  }

  createOptionsForDropDown = options => (
    options.map(option => ({
      label: __(option.value),
      value: option.value,
    }))
  );

  handleOnSelect = (value, type) => {
    const { calendarAddFields } = this.state;
    if (value && value.target && value.target.type === 'text') {
      calendarAddFields[type] = value.target.value;
    } else {
      calendarAddFields[type] = value;
    }
    this.setState({ calendarAddFields });
  }

  createOptionsForTimeZone = () => (
    this.sortedTimeZone().map(option => ({
      label: option.label,
      value: option.value,
    }))
  )

  createOptionsForMonths = data => (
    data.map(option => ({
      label: option,
      value: option,
    }))
  )

  sortedTimeZone = () => {
    const sortedArray = moment.tz.names().map((option, index) => ({
      label: `(GMT${moment.tz(moment.tz.names()[index]).format('Z')}) ${option}`,
      value: option,
    }));
    sortedArray.sort((a, b) => {
      let returnValue = 0;
      const re = /^\(GMT([+-]\d{1,2}):(\d{1,2})\).*$/;
      const aOffset = parseFloat(a.label.replace(re, '$1.$2'));
      const bOffset = parseFloat(b.label.replace(re, '$1.$2'));
      if (aOffset < bOffset) {
        returnValue = -1;
      } else if (aOffset > bOffset) {
        returnValue = 1;
      }
      return returnValue;
    });
    return sortedArray;
  }

  checkedWeekdays = (day) => {
    const { calendarAddFields } = this.state;
    const checkFindIndex = calendarAddFields && calendarAddFields.availability && calendarAddFields.availability.rules && calendarAddFields.availability.rules.findIndex(obj => obj.day === day);
    if (checkFindIndex !== undefined && checkFindIndex !== -1) {
      return true;
    }
    return false;
  }

  loadWeekDays = () => {
    const { weeks } = this.state;
    return weeks.map(obj => (
      <div className="mb10" key={obj.day}>
        <Checkbox checked={this.checkedWeekdays(obj.day)} onChange={(e) => { this.onChangeWeekDays(e, obj); }}>{obj.dayLabel}</Checkbox>
      </div>
    ));
  }

  onChangeWeekDays = (e, obj) => {
    const { calendarAddFields } = this.state;
    if (calendarAddFields.availability === undefined) {
      calendarAddFields.availability = {
        name: 'Default Hours',
        rules: [],
      };
    }
    if (e.target.checked) {
      calendarAddFields.availability.rules.push(obj);
    } else {
      const index = calendarAddFields && calendarAddFields.availability && calendarAddFields.availability.rules.findIndex(val => val.day === obj.day);
      calendarAddFields.availability.rules.splice(index, 1);
    }
    this.setState({ calendarAddFields });
  }

  onClickAddLeave = (flag) => {
    this.setState({ addnewleaves: flag });
  }

  displayMonths = () => {
    const { selectedFields } = this.state;
    return (
      <Select
        showSearch
        value={selectedFields.month === undefined || !selectedFields.month ? moment().format('MMM') : selectedFields.month}
        onChange={(value) => { this.handleOnSelect(value, 'month'); }}
        options={this.createOptionsForMonths(moment.monthsShort())}
        style={{ minWidth: '6rem' }}
        filterOption
        allowClear
      />
    );
  }

  displayMonthDates = () => {
    const { selectedFields } = this.state;
    const getSelectedMonthDays = moment(`${moment().format('Y')}-${selectedFields.month === undefined || !selectedFields.month ? moment().format('MMM') : selectedFields.month}`);
    return (
      <Select
        showSearch
        value={selectedFields.days}
        onChange={(value) => { this.handleOnSelect(value, 'days'); }}
        options={this.createOptionsForMonths(Array.from(Array(getSelectedMonthDays.daysInMonth()), (_, i) => i + 1))}
        style={{ minWidth: '6rem' }}
        filterOption
        allowClear
      />
    );
  }

  displayReasonTextBox = () => {
    const { selectedFields } = this.state;
    return <TextInput value={selectedFields.reason} onChange={(value) => { this.handleOnSelect(value, 'reason'); }} style={{ minWidth: '5rem' }} />;
  }

  onClickSaveLeave = () => {
    const { selectedFields, leaves } = this.state;
    const obj = {
      month: selectedFields.month === undefined || !selectedFields.month ? moment().format('MMM') : selectedFields.month,
      days: selectedFields.days,
      reason: selectedFields.reason,
    };
    selectedFields.month = '';
    selectedFields.days = '';
    selectedFields.reason = '';
    this.setState({ leaves: [...leaves, obj], selectedFields, addnewleaves: false });
  }

  displayLeaveInfo = () => {
    const { leaves } = this.state;
    return leaves && leaves.length > 0 && leaves.map(data => (
      <div key={`${data.month}_${data.days}`}>{data.month} {data.days} - {data.reason}</div>
    ));
  }

  statusChange = (value) => {
    const { calendarAddFields } = this.state;
    calendarAddFields.status = value;
    this.setState({ calendarAddFields });
  }

  updateUserSettings = () => {
    const { updateUserAvailability, apiBaseUrl } = this.props;
    const { timeFormat, calendarAddFields } = this.state;
    const { slug } = calendarAddFields;
    if (!slug) {
      calendarAddFields.slug = getCookie('email').replace('@tekion.com', '');
    }
    if (calendarAddFields.timezone === undefined) {
      calendarAddFields.timezone = moment.tz.guess();
    }
    calendarAddFields.name = getCookie('uname') || slug;
    calendarAddFields.calendar = {
      outlook: true,
    };
    calendarAddFields.slug = calendarAddFields.slug.toLowerCase().replace(/[^a-z]/g, '');
    if (calendarAddFields && calendarAddFields.availability && calendarAddFields.availability.rules) {
      const newRules = calendarAddFields.availability.rules.map(data => (
        { ...data, intervals: [{ from: moment(calendarAddFields.starttime).format(timeFormat), to: moment(calendarAddFields.endtime).format(timeFormat) }] }
      ));
      calendarAddFields.availability.rules = newRules;
      calendarAddFields.availability.timezone = calendarAddFields.timezone;
    }
    const payload = Object.assign({}, calendarAddFields);
    delete payload.starttime;
    delete payload.endtime;
    updateUserAvailability(apiBaseUrl, payload);
  }

  addZoomLink = (e) => {
    const { calendarAddFields } = this.state;
    calendarAddFields.location = {
      meeting_link: e.target.value,
    };
    this.setState({ calendarAddFields });
  }

  addUserName = (e) => {
    const { calendarAddFields } = this.state;
    calendarAddFields.slug = e.target.value.toLowerCase().replace(/[^a-z]/g, '');
    this.setState({ calendarAddFields });
  }

  onTabChangeHandler = (event) => {
    this.setState({ currentTab: event });
  }

  _renderCalendarSettings = () => {
    const {
      selectedFields, dateFormats, timeFormats, addnewleaves,
      leaves, timeFormat, calendarAddFields, currentTab, isDataAvailable,
    } = this.state;
    const starttime = calendarAddFields.starttime ? calendarAddFields.starttime : this.zerothTime;
    const endtime = calendarAddFields.endtime ? calendarAddFields.endtime : this.zerothTime;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <div className="calendar-container">
          {loading ? <Loader />
            : (
              <Row>
                <Col xs={24} md={24} sm={24} lg={14}>
                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Date Format')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <Select
                        showSearch
                        value={calendarAddFields.dateformat}
                        onChange={(value) => { this.handleOnSelect(value, 'dateformat'); }}
                        options={this.createOptionsForDropDown(dateFormats)}
                        style={{ width: '100%' }}
                        filterOption
                        allowClear
                      />
                    </Col>
                  </Row>
                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Time Format')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <Select
                        showSearch
                        value={calendarAddFields.timeformat}
                        onChange={(value) => { this.handleOnSelect(value, 'timeformat'); }}
                        options={this.createOptionsForDropDown(timeFormats)}
                        style={{ width: '100%' }}
                        filterOption
                        allowClear
                      />
                    </Col>
                  </Row>

                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Time Zone')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <Select
                        showSearch
                        value={calendarAddFields.timezone === undefined ? moment.tz.guess() : calendarAddFields.timezone}
                        onChange={(value) => { this.handleOnSelect(value, 'timezone'); }}
                        options={this.createOptionsForTimeZone()}
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={filterOption}
                      />
                    </Col>
                  </Row>

                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Meeting Link')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <TextInput value={calendarAddFields && calendarAddFields.location && calendarAddFields.location.meeting_link} onChange={this.addZoomLink} />
                    </Col>
                  </Row>

                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Calendar Username')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <TextInput className="calendarUserName" disabled={isDataAvailable} value={calendarAddFields.slug} onChange={this.addUserName} />
                    </Col>
                  </Row>

                  <Row className="mb20" align="middle" gutter={16}>
                    <Col xs={24} md={10} sm={24} lg={7}>{__('Allow Meetings to be booked')}</Col>
                    <Col xs={24} md={10} sm={24} lg={12}>
                      <Switch checked={calendarAddFields.status} onChange={this.statusChange} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={10} sm={24} lg={10} className="mb20">
                  <div className="scheduling-content">
                    <TabGroup
                      initialTab={currentTab}
                      onChange={this.onTabChangeHandler}
                      tabList={tabData}
                    />
                    {currentTab === 'Availability' && (
                      <div className="mt10" style={{ padding: '0 15px' }}>
                        {__('WORK WEEK')}
                        {this.loadWeekDays()}
                        <div className="mb10">{__('MEETING HOURS')}</div>
                        <div className="scheduling-part mb20">
                          <div>{__('START TIME')}
                            <TimePicker
                              onChange={(value) => { this.handleOnSelect(value, 'starttime'); }}
                              format={timeFormat}
                              id="from"
                              placeholder={__('Select a Time')}
                              value={starttime}
                            />
                          </div>
                          <div>{__('END TIME')}
                            <TimePicker
                              onChange={(value) => { this.handleOnSelect(value, 'endtime'); }}
                              format={timeFormat}
                              id="to"
                              placeholder={__('Select a Time')}
                              style={{ width: '100%' }}
                              value={endtime}
                            />
                          </div>
                        </div>
                        <div className="text-right">{__('User Time Zone :')} {calendarAddFields.timezone === undefined ? moment.tz.guess() : calendarAddFields.timezone}</div>
                      </div>
                    )}
                    <div style={{ display: 'none' }}>
                      <div className="mt10">
                        {__('HOLIDAYS')}
                        <div>{__('Jan 1 - New Year')}</div>
                        <div>{__('Dec 25 - Christmas')}</div>
                        <Button view="primary">{__('+ Add Holiday')}</Button>
                      </div>
                      <div className="mt10">
                        {leaves && leaves.length > 0 && (
                          <>
                            {__('LEAVES / BUSY')}
                            {this.displayLeaveInfo()}
                          </>
                        )}
                        { addnewleaves && (
                          <div className="scheduling-part">
                            {this.displayMonths()}&nbsp;&nbsp;
                            {this.displayMonthDates()}&nbsp;&nbsp;
                            {this.displayReasonTextBox()}&nbsp;&nbsp;
                            <Button view="primary" onClick={this.onClickSaveLeave} disabled={!selectedFields.days || !selectedFields.reason}>{__('Add')}</Button>&nbsp;&nbsp;
                            <Button view="secondary" onClick={() => { this.onClickAddLeave(false); }}>{__('Cancel')}</Button>
                          </div>
                        )
                        }
                        <Button view="primary" onClick={() => { this.onClickAddLeave(true); }} disabled={addnewleaves}>{__('+ Add Leave / Busy')}</Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )
          }
        </div>
        <div className="calendar-settings-footer">
          <Button disabled={loading} view="primary" onClick={this.updateUserSettings}>{__('Save')}</Button>
        </div>
      </React.Fragment>
    );
  };

  _renderErrorComponent = () => (<ErrorPage notFound />)

  render() {
    const { PARTNERS_SITE } = process.env;

    return (
      <React.Fragment>
        {PARTNERS_SITE === 'true' ? this._renderErrorComponent() : this._renderCalendarSettings()}
      </React.Fragment>
    );
  }
}

/**
 * @description Map the state objects (as props) that are required to render the details in the UI
 */
const mapStateToProps = createStructuredSelector({
  userStatus: getUserAvailabilityStatus(),
  userAvailability: addUserAvailability(),
  loading: loader(),
});

/**
 * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const fetchUserAvailability = bindActionCreators(getUserAvailability, dispatch);
  const updateUserAvailability = bindActionCreators(saveUserAvailability, dispatch);
  return {
    fetchUserAvailability,
    updateUserAvailability,
  };
};

Calendar.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  fetchUserAvailability: PropTypes.func.isRequired,
  updateUserAvailability: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
