import _get from 'lodash/get';

import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ROW_ACTION_TYPES } from '../constant/viewReleaseBoardList.rowActionTypes';
import { updateRelease } from '../../../Services/releaseBoard.services';
import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';


const handleClick = async ({ params, getState, setState }) => {
  setState({ isLoading: true });
  const { action, payload } = params;
  const isActive = action === 'CLICK_TRUE';
  const updateReleaseBody = {
    releaseVersion: _get(payload, 'releaseVersion', EMPTY_STRING),
    isActive,
  };
  try {
    const response = await updateRelease(updateReleaseBody);
    window.location.reload();
  } catch (error) {
    HandleErrorAndStatus(error);
  }
  setState({ isLoading: false });
};

const ROW_ACTION_HANDLERS = {
  [ROW_ACTION_TYPES.CLICK_TRUE]: handleClick,
  [ROW_ACTION_TYPES.CLICK_FALSE]: handleClick,
};
export default ROW_ACTION_HANDLERS;
