/* eslint-disable rulesdir/no-inline-functions */
// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';

// Components
import Modal from 'tcomponents/molecules/Modal';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import UploadMedia from 'organisms/uploadMedia';
import withActions from 'tcomponents/connectors/withActions';

// Constants
import { IMAGE_FILE_TYPES } from 'constants/uploadFileTypes';
import TEST_RESULT_LIST_ACTION_TYPES from '../../constants/testResultsList.actiontypes';

// Helpers
import { handleMediaChange } from './helpers/uploadMediaModal.actionHandlers';
import Style from './uploadMediaModal.module.scss';

const UploadMediaModal = (props) => {
  const { onAction, isUploadModalVisible, selectedTestResultId } = props;
  const onMediaChange = useCallback(mediaList => handleMediaChange({ mediaList, onAction, selectedTestResultId }), [
    onAction,
    selectedTestResultId,
  ]);


  const handleOnCancelUploadModal = useCallback(() => {
    onAction({ type: TEST_RESULT_LIST_ACTION_TYPES.CLOSE_UPLOAD_MODAL });
  }, [onAction]);

  return (
    <>
      <Modal
        title={__('Upload Images')}
        visible={isUploadModalVisible}
        onCancel={handleOnCancelUploadModal}
        showFooter={false}
        width={Modal.SIZES.SM}
        destroyOnClose
      >
        <UploadMedia onMediaChange={onMediaChange} acceptFileTypes={IMAGE_FILE_TYPES} />

        <br />
        <div className={`${Style.Warning}`}>
          <center>
          Max File Size : 1MB
          </center>
        </div>
      </Modal>
    </>
  );
};

UploadMediaModal.propTypes = {
  onAction: PropTypes.func,
  isUploadModalVisible: PropTypes.bool,
  selectedTestResultId: PropTypes.number,
};

UploadMediaModal.defaultProps = {
  onAction: _noop,
  isUploadModalVisible: false,
  selectedTestResultId: undefined,
};

export default makeCellRenderer(UploadMediaModal);
