import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import updateDirection from '@tekion/tekion-i18n/utils/updateDirection';
import translate from '@tekion/tekion-i18n/translate';
import { rebind } from '@tekion/tekion-i18n/utils';
import { setCookie } from '@tekion/tap-components/utils/helper';

const LanguageSelector = ({ i18n }) => {
  useEffect(() => {
    document.addEventListener('userLanguageChanged', changeLang, false);
    return () => {
      document.removeEventListener('userLanguageChanged', changeLang, false);
    };
  }, []);

  const changeLang = (event) => {
    const language = event && event.detail;
    if (i18n && i18n.changeLanguage && language) {
      i18n.changeLanguage(language, () => rebind(i18n));
      updateDirection(i18n);
      setCookie('locale', language);
    }
  };

  return (
    <></>
  );
};
LanguageSelector.defaultProps = {
  i18n: {},
};

LanguageSelector.propTypes = {
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
  }),
};

// extended main view with translate hoc
export default translate()(LanguageSelector);
