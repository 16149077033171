import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import { isRequiredRule } from 'tbase/utils/formValidators';
import PRIORITY_OPTIONS from '../../TabbedTestCases/organisms/TestCasesList/constants/testCasesList.priority';
import AUTOMATED_OPTIONS from '../constant/testCasesForm.automated';
import ACTIVE_OPTIONS from '../constant/testCasesForm.active';
import REGRESSION_OPTIONS from '../constant/testCasesForm.regression';
import RichTextEditorFieldRenderer from '../molecules/FieldRenderer/RichTextEditorFieldRenderer';
import TYPE_OPTIONS from "../constant/typeOptions";
import TagInput from '../../../../../components/TagInput';

export const TEST_CASE_NAME = {
  id: 'testcaseName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('TestCase Name'),
    required: true,
  },
};

export const AUTHOR = {
  id: 'author',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Author'),
    disabled: true,
  },
};
export const PRIORITY = {
  id: 'priority',
  renderer: SelectInput,
  renderOptions: {
    label: __('Priority'),
    required: true,
    options: PRIORITY_OPTIONS,
  },
};
export const COMPONENT = {
  id: 'component',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Component'),
  },
};

export const JIRA = {
  id: 'jira',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Jira'),
    disabled: true,
  },
};

export const BRANCH = {
  id: 'branch',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Branch'),
  },
};

export const DESCRIPTION = {
  id: 'description',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Description'),
  },
};
export const PRECONDITIONS = {
  id: 'precondition',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Precondition'),
  },
};


export const EXIT_CRITERIA = {
  id: 'exitCriteria',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Exit Criteria'),
  },
};

export const RELEASE = {
  id: 'releaseVersion',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Release Version'),
  },
};

export const TYPE = {
  id: 'type',
  renderer: TagInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const ACTIVE = {
  id: 'isActive',
  renderer: SelectInput,
  renderOptions: {
    label: __('Active'),
    options: ACTIVE_OPTIONS,
  },
};
export const AUTOMATED = {
  id: 'isAutomated',
  renderer: SelectInput,
  renderOptions: {
    label: __('Automated'),
    options: AUTOMATED_OPTIONS,
  },
};

export const REGRESSION = {
  id: 'regression',
  renderer: SelectInput,
  renderOptions: {
    label: __('Regression'),
    options: REGRESSION_OPTIONS,
  },
};

export const MISC = {
  id: 'misc',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Misc'),
  },

};
export const STEPS = {
  id: 'steps',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Steps'),
    required: true,
  },
};

export const TCCATEGORY = {
  id: 'tcCategory',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Tc Category'),
    placeholder: __('Select'),
  },
};

export const UPSTREAMCONSUMPTION = {
  id: 'upstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Upstream Consumption'),
    placeholder: __('Select'),
  },
};

export const DOWNSTREAMCONSUMPTION = {
  id: 'downstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Downstream Consumption'),
    placeholder: __('Select'),
  },
};

export const HORIZONTALIMPLEMENTATION = {
  id: 'horizontalImplementation',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Horizontal Implementation'),
    placeholder: __('Select'),
  },
};
