import React, { useCallback } from 'react';
import Modal from 'tcomponents/molecules/Modal';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import BulkUpdateTestCaseForm from "../bulkUpdateTestCasesForm/bulkUpdateTestCaseForm";

const BulkUpdateTestCaseModal = ({
  showBulkUpdateTestCaseModal,
  onCancelBulkUpdate,
  onSubmitBulkUpdate,
}) => {

  const handleSubmit = useCallback(() => {
    triggerSubmit('BULK_UPDATE_TEST_CASES_FORM');
  }, EMPTY_ARRAY);

  return (
    <Modal
      title={__('Bulk Update Test Cases')}
      submitBtnText={__('Bulk Update')}
      visible={showBulkUpdateTestCaseModal}
      onSubmit={handleSubmit}
      onCancel={onCancelBulkUpdate}
      width={Modal.SIZES.SM}
      destroyOnClose
    >
      <BulkUpdateTestCaseForm
        onSubmit={onSubmitBulkUpdate}
      />
    </Modal>
  );
};

export default BulkUpdateTestCaseModal;
