import React, { useMemo, useCallback } from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';
import _get from "lodash/get";

import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';

import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import {EMPTY_ARRAY} from "@tekion/tekion-base/app.constants";

import ACTION_TYPES from './constants/issueTable.actionTypes';
import ACTION_HANDLERS from './helpers/issueTable.actionHandlers';
import INITIAL_STATE from './constants/issueTable.initialState';
import { makeTableProps, getSubHeaderProps, getHeaderProps } from './helpers/issueTable.general';
import { columns } from './constants/issueTable.columns';
import UploadTestCasesModal from "../../../../../../organisms/uploadTestCases";
import styles from './issueTable.module.scss';
// import TABBED_TEST_CASE_LIST_ACTION_TYPES
//     from "../../../../../testCases/organisms/TabbedTestCases/constant/tabbedTestCases.actionTypes";
//
//
// const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
// const SortableTableManager = withCustomSortTable(TableManager);
// const PAGE_HEADER_HEIGHT = 64;
// const PAGE_TAB_HEIGHT = 60;

const IssueTable = (props) => {
  // console.log(props);
  const {
    tabId,
    onAction,
    currentPage,
    isLoading,
    history,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    projectListByProductId,
    pageSize = 50,
    searchText,
      isUploadModalVisible,
      isUploadModalLoading,
      data,
  } = props;

  const TABLE_MANAGER_PROPS = {
    showSearch: true,
    showFilter: false,
    showHeader: true,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
  };

    const headerProps = useMemo(() => getHeaderProps(), EMPTY_ARRAY);
  // const subHeaderProps = useMemo(() => getSubHeaderProps(), [onAction]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
      pageSize,
      currentPage,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  const tableData = _get(data, 'data.data.data.hits', EMPTY_ARRAY);

    const onCancel = useCallback(() => {
        onAction({
            type: ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_CANCEL,
        });
    }, [onAction]);

    const onSubmit = useCallback(
        (mediaId, formValues, mediaList) => {
            onAction({
                type:
                ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_SUBMIT,
                payload: {
                    mediaId,
                    formValues,
                    mediaList,
                },
            });
        },
        [onAction]
    );

  return (
    <PageComponent.Body style={{ height: contentHeight - 50 }}>
      <TableManager
        columns={columns}
        data={tableData}
        headerProps={headerProps}
        tableManagerProps={TABLE_MANAGER_PROPS}
        // tableComponent={CheckBoxTable}
        onAction={onAction}
        // filterProps={filterProps}
        selection={selection}
        tableProps={getTableProps}
        // subHeaderProps={subHeaderProps}
        sortDetails={sortDetails}
        isMultiSort
      />
        <UploadTestCasesModal
            isVisible={isUploadModalVisible}
            isUploading={isUploadModalLoading}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onAction={onAction}
        />
    </PageComponent.Body>
  );
}

// const mapStateToProps = state => ({
//   tcmsBootStrap: getTcmsBootstrap(state),
//   projectListByProductId: getProjectListByProductId(state),
//
// });

export default compose(
  // connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(IssueTable);
