// Constants
import FILTER_TYPES from 'tcomponents/organisms/filterSection/constants/filterSection.filterTypes';

import { STATUS_OPTIONS } from '../../../../../../../constants/statusOptions';
import ENV_OPTIONS from '../../../../../../../constants/envOptions';

export const MODULE = {
  id: 'project_id',
  name: __('Project'),
  type: FILTER_TYPES.MULTI_SELECT,
};

export const STATUS = {
  id: 'testsession_status',
  name: __('Status'),
  type: FILTER_TYPES.MULTI_SELECT,
  additional: { options: STATUS_OPTIONS },
};

export const ENVIRONMENT = {
  id: 'environment',
  name: __('Environment'),
  type: FILTER_TYPES.MULTI_SELECT,
  additional: { options: ENV_OPTIONS },
};

export const MODIFIED_AT = {
  id: 'modified_at',
  name: __('Modified At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const CREATED_AT = {
  id: 'created_at',
  name: __('Created At'),
  type: FILTER_TYPES.DATE_WITHOUT_PRESET,
};

export const CREATED_BY = {
  id: 'created_by',
  name: __('Created By'),
  type: FILTER_TYPES.SINGLE_SELECT,
};

export const DEFAULT_FILTER_TYPES = [MODULE.id];
