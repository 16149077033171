// Services
import Http from '../../../../services/http';
import URL from '../../../../api/urls';
// const BASE_URL = 'tcms-service/u/testcases';

export const getTcmsBootstrap = async (payload) => {
    const response = await Http.get('CQA', `${URL.getAllList}`, payload);
    console.log({response});
    return response;
    // .then(({ data }) => console.log(data) // );
};