
import moment from 'moment';
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { fetchUserGetAvailability, saveUserGetAvailability } from '../api/Calendar';
import { CONSTANTS } from '../constants/Constants';
import { getUserAvailable, storeUserAvailable } from '../api/urls';
const { ACTIONS } = CONSTANTS;

export const getUserAvailability = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    const url = `${baseUrl}${getUserAvailable}`;
    return fetchUserGetAvailability(url).then((response) => {
      const obj = { ...response };
      obj.availability = response.availability && response.availability.length > 0 ? response.availability[0] : {};
      if (obj.availability && obj.availability.rules && obj.availability.rules.length > 0 && obj.availability.rules[0] && obj.availability.rules[0].intervals) {
        obj.starttime = moment(obj.availability.rules[0].intervals[0].from, 'HH:mm');
      }
      if (obj.availability && obj.availability.rules && obj.availability.rules.length > 0 && obj.availability.rules[0] && obj.availability.rules[0].intervals) {
        obj.endtime = moment(obj.availability.rules[0].intervals[0].to, 'HH:mm');
      }
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.STORE_USER_AVAILABILITY_DATA, payload: obj });
    }).catch(() => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.STORE_USER_AVAILABILITY_DATA, payload: {} });
    });
  }
);


export const saveUserAvailability = (baseUrl, data) => ((dispatch) => {
  const url = `${baseUrl}${storeUserAvailable}`;
  dispatch({ type: ACTIONS.LOADER, payload: true });
  return saveUserGetAvailability(url, data).then((response) => {
    dispatch({ type: ACTIONS.LOADER, payload: false });
    toaster('success', `${response.msg}`);
  }).catch(() => {
    dispatch({ type: ACTIONS.LOADER, payload: false });
    toaster('error', __('Failed to update user availability details'));
  });
});
