import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';

import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _map from 'lodash/map';

import withSize from 'tcomponents/hoc/withSize';
// import colors from '@tekion/tekion-styles-next/scss/variables.scss';
// import withActions from 'tcomponents/connectors/withActions';
// import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
// import Heading from 'tcomponents/atoms/Heading';
// import Button from 'tcomponents/atoms/Button';
// import Loader from 'tcomponents/molecules/loader';
// import useFetchData from 'tbase/customHooks/useFetchData';
import { withRouter } from 'react-router-dom';
import TableManager from 'tcomponents/organisms/TableManager';
// import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
// import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
// import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import KebabMenu from '@tekion/tekion-components/src/molecules/KebabMenu';
// import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import { makeTableProps } from '../../helper/viewTrackingControl.general';
import { getColumns } from './constants/viewTrackingControlList.columns';
// import { getReleaseInfo } from '../../Services/releaseBoard.services';
// import ACTION_HANDLERS from './helper/viewTrackingControlList.actionHandlers';
// import INITIAL_STATE from '../../constants/viewTrackingControl.initialState';
import styles from './viewTrackingControlList.module.scss';
import ACTION_TYPES from '../../constants/viewTrackingControl.actionTypes';
// import { ROW_ACTION_TYPES } from './constants/viewTrackingControlList.rowActionTypes';
// const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
// const SortableTableManager = withRowActions(withCustomSortTable(TableManager));


const ViewTrackingControlList = (props) => {
  const {
    tabId,
    onAction,
    isLoading,
    currentPage,
    history,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    pageSize,
    tableData,
    isVisible,
    values,
    releaseColumn,
  } = props;

  const tableDataTrackingControl = _get(tableData, 'data.data', EMPTY_ARRAY);

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: false,
    showTotalCount: false,
    showRefreshIcon: false,
  };

  const KEBAB_ELEMENT = <span className="icon-overflow" />;

  const tableMenuItems = [{
    key: 'CLICK_EDIT',
    label: __('Edit'),
  },
  ];

  const onKebabClick = (action, payload) => {
    onAction({
      type: ACTION_TYPES.ROW_ACTION,
      payload: { action, payload },

    });
  };

  const getRowActionCell = ({ original }) => (
    <KebabMenu
      onClickAction={onKebabClick}
      menuItems={tableMenuItems}
      triggerElement={KEBAB_ELEMENT}
      rowInfo={original}
    />
  );

  const columnsWithConfigurator = _compact([
    ...getColumns(onAction),
    {
      // ...columnConfigurator,
      Cell: getRowActionCell,
      accessor: 'COLUMN_CONFIGURATOR',
      className: styles.noBorder,
      width: 60,
    },
  ]);


  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      onAction,
      selection,
      tableDataTrackingControl,
    }),
    [isLoading, totalCount, onAction, selection, tableDataTrackingControl]
  );

  //   const tableDataForSanity = _get(tableData, 'data.data.hits', EMPTY_ARRAY);

  // const sanitizedData = tableDataForSanity;
  // _map(sanitizedData, (element) => {
  //   if (element.isActive) { element.isActiveText = 'True'; } else { element.isActiveText = 'False'; }
  // });

  return (
    <PageComponent.Body style={{ height: contentHeight }}>
      <TableManager
        columns={columnsWithConfigurator}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        data={tableDataTrackingControl}
        // selection={selection}
        tableProps={getTableProps}
        isLoading={isLoading}
      />
    </PageComponent.Body>
  );
};
export default compose(
  withRouter,
  // withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(ViewTrackingControlList);
