// Constants
// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

export const AUTOMATED = {
  name: __('Yes'),
  id: true,
};

export const UNAUTOMATED = {
  name: __('No'),
  id: false,
};

export const AUTOMATED_LABEL_MAP = {
  [AUTOMATED.id]: AUTOMATED.name,
  [UNAUTOMATED.id]: UNAUTOMATED.name,
};

export const AUTOMATED_COLOR_MAP = {
  [AUTOMATED.id]: COLOR_MAP.GREEN,
  [UNAUTOMATED.id]: COLOR_MAP.GREY,
};

const AUTOMATED_STATUSES = [AUTOMATED, UNAUTOMATED];

const AUTOMATED_OPTIONS = standardFieldOptionMapper(undefined, AUTOMATED_STATUSES);

export default AUTOMATED_OPTIONS;
