export const userRoleMap = {
  0: '6b3ed960a59851884efb0601013c433457f0ce12d6be661c50cd841661b61119', /* SUPER ADMIN */
  1: '4c75d13aa97f1c279a8e88855592a4a6bc892954e657f3ceff22053a786c4e0b', /* ADMIN */
  2: '37ab532ee376e0787f2cc46650a7feadb9a226edc7386c1baa784bd2f10c479e', /* USER */
  3: '26fa539930fa4eebb730a93e336eb1f5f75693499d8a3e373d39d677fd2648c2',
  4: 'b8680385f8ecf994a014567e76636ad471da856f41de63ccb6d5841671c0e5fb', /* AUTO USER */
  5: '6593e02ecc3487e561ade581f42cf625b291437393ed4b6f1d8b72a60a95ddfa', /* TCMS USER */
  6: '51d55e6d77d4e46382b02e09b42ad45d1d1bbb3a2718365053ac51062861aa59', /* SUPPORT USER */
  8: '98308a25d51f19bdc248beabd7e7647d9dec222855d6c8e826ba63398f82c938', /* FQA */
  11: 'fb83fdd38285a4e3a6ae28453f93c39af47e16489f6630e178aecb6e7410a68b',
};


export const ROLE_TYPE = {
  '6b3ed960a59851884efb0601013c433457f0ce12d6be661c50cd841661b61119': '0',
  '4c75d13aa97f1c279a8e88855592a4a6bc892954e657f3ceff22053a786c4e0b': '1',
  '37ab532ee376e0787f2cc46650a7feadb9a226edc7386c1baa784bd2f10c479e': '2',
  '26fa539930fa4eebb730a93e336eb1f5f75693499d8a3e373d39d677fd2648c2': '3',
  'b8680385f8ecf994a014567e76636ad471da856f41de63ccb6d5841671c0e5fb': '4',
  '6593e02ecc3487e561ade581f42cf625b291437393ed4b6f1d8b72a60a95ddfa': '5',
  '51d55e6d77d4e46382b02e09b42ad45d1d1bbb3a2718365053ac51062861aa59': '6',
  '98308a25d51f19bdc248beabd7e7647d9dec222855d6c8e826ba63398f82c938': '8',
  'fb83fdd38285a4e3a6ae28453f93c39af47e16489f6630e178aecb6e7410a68b': '11',

};

export const REVERSE_ROLE_TYPE = {
  SUPER_ADMIN: '0',
  ADMIN: '1',
  USER: '2',
  REJECT: '3',
  AUTO_USER: '4',
  TCMS_USER: '5',
  SUPPORT_USER: '6',
  DELETE_USER: '7',
  FQA: '8',
  GUEST: '11',
};

export const ROLE_TYPE_LIST = [
  {
    value: REVERSE_ROLE_TYPE.SUPER_ADMIN,
    label: 'SUPER_ADMIN',
  },
  {
    value: REVERSE_ROLE_TYPE.ADMIN,
    label: 'ADMIN',
  },
  {
    value: REVERSE_ROLE_TYPE.USER,
    label: 'USER',
  },
  {
    value: REVERSE_ROLE_TYPE.REJECT,
    label: 'REJECT',
  },
  {
    value: REVERSE_ROLE_TYPE.AUTO_USER,
    label: 'AUTO_USER',
  },
  {
    value: REVERSE_ROLE_TYPE.TCMS_USER,
    label: 'TCMS_USER',
  },
  {
    value: REVERSE_ROLE_TYPE.SUPPORT_USER,
    label: 'SUPPORT_USER',
  },
  {
    value: REVERSE_ROLE_TYPE.DELETE_USER,
    label: 'DELETE_USER',
  },
  {
    value: REVERSE_ROLE_TYPE.FQA,
    label: 'FQA',
  },
  {
    value: REVERSE_ROLE_TYPE.GUEST,
    label: 'GUEST',
  },
];
