// Constants
// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

export const YES = {
  name: __('Yes'),
  id: true,
};

export const NO = {
  name: __('No'),
  id: false,
};

export const AUTOMATED_LABEL_MAP = {
  [YES.id]: YES.name,
  [NO.id]: NO.name,
};

export const AUTOMATED_COLOR_MAP = {
  [YES.id]: COLOR_MAP.GREEN,
  [NO.id]: COLOR_MAP.GREY,
};

const REGRESSION_STATUSES = [YES, NO];

const REGRESSION_OPTIONS = standardFieldOptionMapper(undefined, REGRESSION_STATUSES);

export default REGRESSION_OPTIONS;
