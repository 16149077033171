import _map from "lodash/map";
// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
// import tagReader from '../readers/Tag';
//
// const TAG_FEATURE_OPTION_MAPPER = {
//   label: tagReader.name,
//   value: tagReader.name,
// };

const makeTagFeatureOptions = tagfeatureList => _map(tagfeatureList, (tag) => ({
  label: tag,
  value: tag,
}));

export default makeTagFeatureOptions;
