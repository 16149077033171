import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import { isRequiredRule } from 'tbase/utils/formValidators';

// export const PRODUCT = {
//   id: 'product',
//   renderer: TextInputRenderer,
//   renderOptions: {
//     label: __('Product'),
//     placeholder: __(''),
//     required: true,
//     validators: [isRequiredRule],
//   },
// };

export const RELEASE_TAG = {
  id: 'releaseVersion',
  renderer: SelectInput,
  renderOptions: {
    label: __('Release Version'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

// export const MODULE = {
//   id: 'module',
//   renderer: TextInputRenderer,
//   renderOptions: {
//     label: __('Module'),
//     placeholder: __(''),
//     required: true,
//     validators: [isRequiredRule],
//   },
// };

export const PROJECT = {
  id: 'projectName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Project'),
    placeholder: __(''),
    required: true,
    validators: [isRequiredRule],
  },
};

export const TRACKING_ENABLED = {
  id: 'trackingEnabled',
  renderer: SelectInput,
  renderOptions: {
    label: __('Tracking Enabled'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

