import _get from 'lodash/get';
import addOptionsToAdditional from 'tbase/utils/addOptionsToAdditional';


import {
  FEATURE, STATUS, EXECUTED_AT, CREATED_AT, DEFAULT_FILTER_TYPES,
} from '../constants/testResultsList.filterTypes';
import makefeatureOptions from '../../../../../../../constants/featureOptions';

const createFilterTypes = (modules) => {
  // const featureList = _get(tcmsBootStrap, 'featureList');
  const featureOptions = makefeatureOptions(modules);
  const featureFilter = addOptionsToAdditional(FEATURE, featureOptions);
  const filterTypes = [featureFilter, STATUS, EXECUTED_AT, CREATED_AT];
  return filterTypes;
};

export const createFilterProps = ({
  selectedFilters, modules
}) => {
  const filterTypes = createFilterTypes(modules);
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    selectedFilters,
    shouldHideSaveFilterGroup: true,
    showFilterGroupName: false,
    shouldHideFilterGroup: true,
    showResetToDefaultGroup: false,
  };
};
