import React, { useMemo } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import withActions from 'tcomponents/connectors/withActions';
// import { BasicTable } from 'twidgets/appServices/accounting/organisms/table';

import TableManager from 'tcomponents/organisms/TableManager';
import withSize from 'tcomponents/hoc/withSize';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withTableShortcutKeys from 'tcomponents/organisms/inputTable/containers/withTableShortcutKeys';
import InputTable from 'tcomponents/organisms/inputTable';

import ACTION_HANDLERS from './helpers/moduleStatsTable.actionHandlers';
import INITIAL_STATE from './constants/moduleStatsTable.initialState';
import { columns } from './constants/moduleStatsTable.columns';
import { makeTableProps } from './helpers/moduleStatsTable.general';

import styles from './moduleStatsTable.module.scss';

const InputTableWithShortcutKeys = withTableShortcutKeys(InputTable);


const StatsTable = (props) => {
  const {
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    selection,
    selectedFilters,
    contentHeight,
    pageSize,
    selectedProduct,
    selectedEnvironment,
    selectedReleaseVersion,
    height,
    rowInfo,
    moduleData,
    statsTableHeight,
    moduleDataMap,
    subComponentProps,
  } = props;

  const count = _get(moduleData, 'moduleCount', EMPTY_STRING);
  const projectId = _get(subComponentProps, ['original', 'projectId'], EMPTY_STRING);

  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: false,
    showHeader: false,
    showSubHeader: false,
    showTotalCount: false,
    showRefreshIcon: false,
  };

  const getModuleData = (dataFormat) => {
    let count = _get(dataFormat, 'count', EMPTY_STRING);
    const projectData = [];
    while (count > 0) {
      projectData.push(_get(dataFormat, ['moduleDashboardList', `module-${count}`], EMPTY_OBJECT));
      count -= 1;
    }
    return projectData;
  };

  let tableDataForMap;
  tableDataForMap = moduleDataMap === undefined ? moduleData : moduleDataMap[projectId];
  const tableData = getModuleData(tableDataForMap);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      tableData,
      onAction,
      selection,
      sortDetails,
    }),
    [isLoading, isFetchingNextPage, totalCount, onAction, selection, sortDetails, tableData]
  );

  return (
    <PageComponent.Body className={styles.tableStyle} style={{ height: contentHeight -( statsTableHeight- (200 * (count+1))) }}>
      <TableManager
        columns={columns}
        data={tableData}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        selection={selection}
        tableProps={getTableProps}
        className={styles.tableStyle}
        loading={isLoading}
      />
    </PageComponent.Body>
  );
};

export default compose(
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withRouter,
  withSize({ hasPageHeader: 0, hasPageFooter: 0 }),
)(StatsTable);
