// Readers
import _get from 'lodash/get';
import _size from 'lodash/size';
// Utils
import addToRenderOptions from 'tbase/utils/addToRenderOptions';

// Helpers
import _head from 'lodash/head';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import _map from 'lodash/map';

// Constants
import { JIRA } from './addTestCaseToJira.fields';
import FORM_CONFIG from './addTestCaseToJira.formConfigConstants';

export const getFields = ({
  formValues
}) => {
  const jira = _get(formValues, 'jira', EMPTY_ARRAY);
  
  return {
    ...FORM_CONFIG,
    [JIRA.id]: addToRenderOptions(JIRA, [{path: 'isDisabled', values: jira }]),
  };
};

export default getFields;