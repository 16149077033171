import React from 'react';
import _constant from 'lodash/constant';
import _size from 'lodash/size';
import _map from 'lodash/map';


import Button from '@tekion/tekion-components/src/atoms/Button';
import Menu from '@tekion/tekion-components/src/molecules/Menu';
import Dropdown from '@tekion/tekion-components/src/molecules/DropDown';
import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
// import ACTION_TYPES from '../constants/testSessionList.actionTypes';
// import ROW_ACTIONS from '../constants/testSessionList.rowActions';
// import { TABLE_ROW_ACTIONS } from './testSessionList.rowAction';

// const getBulkActionsMenu = handleBulkAction => (
//   <Menu onClick={handleBulkAction}>
//     {_map(TABLE_ROW_ACTIONS, ({ name, id }) => (
//       <Menu.Item key={id}>{name}</Menu.Item>
//     ))}
//   </Menu>
// );

// export const getSubHeaderProps = (handleBulkAction, selection) => ({
//   subHeaderRightActions: [
//     {
//       renderer: Dropdown,
//       renderOptions: {
//         overlay: getBulkActionsMenu(handleBulkAction),
//         trigger: ['click'],
//         disabled: !_size(selection),
//         children: (
//           <Button view={Button.VIEW.SECONDARY}>
//             {__('Bulk Actions')}
//           </Button>
//         ),
//       },
//     },
//   ],
// });

// const handleRowAction = onAction => (action, payload) => {
//   onAction({
//     type: ACTION_TYPES.ROW_ACTION,
//     payload: {
//       value: payload,
//       action,
//     },
//   });
// };

export const makeTableProps = ({
  isLoading,
  selection,
  columnConfigurator,
  totalCount,
  onAction,
  sortDetails,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  showActions: true,
//   getRowActions: _constant(ROW_ACTIONS),
//   onRowActionClick: handleRowAction(onAction),
  columnConfigurator,
  totalNumberOfEntries: totalCount,
  type: TABLE_TYPES.FIXED_COLUMN,
//   getTdProps: _constant({ onAction }),
  checkboxHeaderClassName: 'm-l-8',
  sortDetails,
  shouldAdaptSortDetails: false,
  showPagination: true,
});
