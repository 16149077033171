import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import FormBuilder from 'tcomponents/organisms/FormBuilder';

import { FormWithSubmission } from '@tekion/tekion-components/src/pages/formPage';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import Loader from 'tcomponents/molecules/loader';
import PropTypes from 'prop-types';

// import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
// import { CONTEXT_ID } from './constants/testCaseDetails.general';
import SECTIONS from './helpers/testCaseDetails.sections';
import { FORM_CONFIG } from './helpers/testCaseDetails.formConfig';
import INITIAL_STATE from './constants/testCaseDetails.initialState';
import ACTION_HANDLERS from './helpers/testCaseDetails.actionHandlers';
import makeInitialFormValues from '../../pages/testCases/helpers/testCases.initialValues';

function TestCaseForm(props) {
  const {
    values, isTestCaseLoading, formValues, isDetailsModalLoading, onAction
  } = props;

  const initialFormValues = useMemo(() => makeInitialFormValues(values), [values]);


  if (isDetailsModalLoading) {
    return <Loader />;
  }
  return (
    <div>

      <FormWithSubmission
        sections={SECTIONS}
        fields={FORM_CONFIG}
        onAction={onAction}
        values={initialFormValues}
      />

    </div>
  );
}

TestCaseForm.propTypes = {
  isTestCaseLoading: PropTypes.bool,
};

TestCaseForm.defaultProps = {
  isTestCaseLoading: false,
};

export default compose(withRouter,
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE, ACTION_HANDLERS))(TestCaseForm);
