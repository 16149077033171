import React, { useState, useCallback, useMemo} from 'react';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/map';
import _head from 'lodash/head';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import FormPage from 'tcomponents/pages/formPage';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import Loader from 'tcomponents/molecules/loader';
import PropTypes from 'prop-types';
import Button from 'tcomponents/atoms/Button';
import {PropertyControlledComponent} from "tcomponents/molecules";
import styles from './testCaseForm.module.scss';
import AddTestCaseToJiraModal from '../editTestCase/addTestCaseToJira/AddTestCaseToJiraModal';


import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import { CONTEXT_ID } from '../../constants/testCases.general';
import SECTIONS from './helpers/testCasesForm.sections';
import { FORM_CONFIG } from './helpers/testCasesForm.formConfig';
import { INITIAL_STATE } from './constant/testCasesForm.general';
import { ACTION_HANDLERS } from './helpers/testCasesForm.actionHandlers';
import { checkUserRoleForm } from '../../../../helpers/UserRole/userRole.common';
import ACTION_TYPES from './constant/testCasesForm.actionTypes';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

function TestCaseForm(props) {
  const {
    onAction, values, isTestCaseLoading, formValues, propheader, history,testCaseId, showAddTestCaseToJira,
  } = props;

  const [isAddTestCaseToJiraButtonLoading, setIsAddTestCaseToJiraButtonLoading] = useState(false);
  const addTestCaseToJiraHeader = propheader != undefined ? 'Clone Test Case' : isTestCaseLoading != undefined ? 'Edit Test Case' : 'Create Test Case';
  const renderFormHeader = () => (
    <PageComponent.Header hasBack>
      <div className={styles.tabbedTestCaseListHeader} >
        <Heading size={1}>{__(propheader != undefined ? 'Clone Test Case' : isTestCaseLoading != undefined ? 'Edit Test Case' : 'Create Test Case')}</Heading>
        {addTestCaseToJiraHeader === 'Edit Test Case' && ( 
        <PropertyControlledComponent controllerProperty={addTestCaseToJiraHeader}>
          <div className={styles.addTestCaseToJiraBtn}>
              <Button label={__('Add Test Case To Jira')}  view={Button.VIEW.PRIMARY} className="m-r-16" onClick={onClick}
          />
          </div>
        </PropertyControlledComponent>
        )}
      </div>
    </PageComponent.Header>
  );
 
  const onCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_CANCEL,
    });
  }, [onAction]);

  const onSubmit = useCallback(
    (formValues) => {
      onAction({
        type:
        ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_SUBMIT,
        payload: {
          formValues,
        },
      });
    },
    [onAction]
  );

  const onClick = useCallback(() => {
    (formValues)
    onAction({
      type: ACTION_TYPES.ADD_JIRA_TO_TEST_CASE_CLICK,
    });
  }, [onAction]);
 
  const handleSubmit = useCallback(() => {
    triggerSubmit(CONTEXT_ID);
  }, EMPTY_ARRAY);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isTestCaseLoading) {
    return <Loader />;
  }

  return (
    <div>

      <FormPage
        sections={SECTIONS}
        headerComponent={renderFormHeader}
        onSubmit={handleSubmit}
        onClose={handleCancel}
        fields={FORM_CONFIG}
        onAction={onAction}
        values={formValues}
        footerProps={checkUserRoleForm()}   
         />
         <AddTestCaseToJiraModal
          onSubmit={onSubmit}
          onCancel={onCancel}
          showAddTestCaseToJira={showAddTestCaseToJira}
          history= {history}
          formValues={formValues}
          onAction={onAction}
          testCaseId={testCaseId}
          isAddTestCaseToJiraButtonLoading={isAddTestCaseToJiraButtonLoading}
          setIsAddTestCaseToJiraButtonLoading={setIsAddTestCaseToJiraButtonLoading}
           />

    </div>
  );
}

TestCaseForm.propTypes = {
  // headerProps: PropTypes.object,
  // onAction: PropTypes.func,
  // history: PropTypes.object.isRequired,
  // formValues: PropTypes.object,
  // errors: PropTypes.object,
  isTestCaseLoading: PropTypes.bool,
  // tcmsBootstrap: PropTypes.object,
  // isEdit: PropTypes.bool,
  // featureListByProjectId: PropTypes.object,
  // projectListByProductId: PropTypes.object,
  // tcmsMetadata: PropTypes.object,
  // tcmsUserDefinedMetadata: PropTypes.object,
  // isLoading: PropTypes.bool,
};

TestCaseForm.defaultProps = {
  // headerProps: EMPTY_OBJECT,
  // onAction: _noop,
  // formValues: EMPTY_OBJECT,
  // errors: EMPTY_OBJECT,
  isTestCaseLoading: false,
  // tcmsBootstrap: EMPTY_OBJECT,
  // isEdit: false,
  // featureListByProjectId: EMPTY_OBJECT,
  // projectListByProductId: EMPTY_OBJECT,
  // tcmsMetadata: EMPTY_OBJECT,
  // tcmsUserDefinedMetadata: EMPTY_OBJECT,
  // isLoading: false,
};

export default compose(withRouter,
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE, ACTION_HANDLERS))(TestCaseForm);
