const SAVE_TCMS_BOOTSTRAP = 'SAVE_TCMS_BOOTSTRAP';
const SAVE_TCMS_METADATA = 'SAVE_TCMS_METADATA';
const SAVE_TCMS_USER_DEFINED_METADATA = 'SAVE_TCMS_USER_DEFINED_METADATA';

const APP_ACTION_TYPES = {
  SAVE_TCMS_BOOTSTRAP,
  SAVE_TCMS_METADATA,
  SAVE_TCMS_USER_DEFINED_METADATA,
};

export default APP_ACTION_TYPES;
