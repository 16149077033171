import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'tcomponents/molecules/Modal';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import { INITIAL_STATE } from '../../TestCaseForm/constant/testCasesForm.general';

import AddTestCaseToJiraForm from './AddTestCaseToJiraForm';
// import { addTestCaseToJira } from '../../../services/testCases.services';
import { handleAddTestCaseToJiraSubmit } from '../helpers/editTestCase.onFormSubmit';
import ACTION_HANDLERS from '../helpers/addTestCaseToJira.actionHandlers';

import ACTION_TYPES from '../constants/addTestCaseToJira.actionTypes';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';


const AddTestCaseToJiraModal = ({
  showAddTestCaseToJira,
  onCancel,
  onSubmit,
  formValues,
  testCaseId,
  setIsAddTestCaseToJiraButtonLoading,
  isAddTestCaseToJiraButtonLoading,
  onAction,
  isTestCaseLoading
}) => {
  const handleSubmit = useCallback(async() => {
    triggerSubmit('ADD_JIRA_TO_TEST_CASE_SUBMIT_FORM'); 
  }, [EMPTY_ARRAY]);

  const jira = _get(formValues,'jira', EMPTY_ARRAY);

  return (
    <div>
    <Modal
      title={__('Add Test Case To Jira')}
      submitBtnText={__('Add')}
      visible={showAddTestCaseToJira}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      primaryBtnDisabled={!jira}
      loading={isAddTestCaseToJiraButtonLoading}
     // hideSubmit={isJiraIdDisabled}
      width={Modal.SIZES.SM}
      destroyOnClose
    >

    <AddTestCaseToJiraForm
      onSubmit={onSubmit}
      showAddTestCaseToJira={showAddTestCaseToJira}
      onAction={onAction}
      formValues={formValues}
      jira={jira}
      testCaseId={testCaseId}
      history={history}
    />
    </Modal>

    </div>
  );
};

export default AddTestCaseToJiraModal;




