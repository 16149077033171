class Feature {
  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.projectId = undefined;
    this.createdByUserId = undefined;
    this.createdTime = undefined;
    this.dealerId = undefined;
    this.hasTest = false;
    this.info = undefined;
    this.modifiedByUserId = undefined;
    this.modifiedTime = undefined;
    this.tenantId = undefined;
    this.countTestCase = 0;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setProjectId(projectId) {
    this.projectId = projectId;
    return this;
  }

  setFeatureId(featureId) {
    this.featureId = featureId;
    return this;
  }

  setTenantId(tenantId) {
    this.tenantId = tenantId;
    return this;
  }

  setDealerId(dealerId) {
    this.dealerId = dealerId;
    return this;
  }

  setInfo(info) {
    this.info = info;
    return this;
  }

  setCreatedByUserId(createdByUserId) {
    this.createdByUserId = createdByUserId;
    return this;
  }

  setCreatedTime(createdTime) {
    this.createdTime = createdTime;
    return this;
  }

  setModifiedTime(modifiedTime) {
    this.modifiedTime = modifiedTime;
    return this;
  }

  setModefiedByUserId(modifiedByUserId) {
    this.modifiedByUserId = modifiedByUserId;
    return this;
  }

  setHasTest(hasTest) {
    this.hasTest = hasTest;
    return this;
  }

  setCountTestCase(countTestCase) {
    this.countTestCase = countTestCase;
    return this;
  }
}

export default Feature;
