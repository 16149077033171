import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _first from 'lodash/first';
import FORM_PAGE_ACTION_TYPES from 'tcomponents/pages/formPage/constants/actionTypes';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import { IS_ACTIVE, RELEASE_VERSION } from '../constants/AddReleaseForm.fields';
import ACTION_TYPES from '../constants/AddReleaseForm.actionTypes';

const handleIsActiveFieldChange = ({
  id, value, formValues, setState,
}) => {
  setState({ formValues: { ...formValues, [id]: value } });
};
const handleReleaseVersion = ({
  id, value, formValues, setState,
}) => {
  setState({ formValues: { ...formValues, [id]: value } });
};

const FIELD_CHANGE_MAP = {
  [IS_ACTIVE.id]: handleIsActiveFieldChange,
  [RELEASE_VERSION.id]: handleReleaseVersion,
};
const handleOnFieldChange = ({ params, getState, setState }) => {
  const { formValues } = getState();
  const { id, value, option } = params;
  const fieldChangeHandler = FIELD_CHANGE_MAP[id];
  if (fieldChangeHandler) {
    fieldChangeHandler({
      id, value, formValues, setState,
    });
  } else if (option !== undefined && value === 'True') {
    setState({
      formValues: {
        ...formValues, [id]: value,
      },
    });
  } else if (option !== undefined && value === 'False') {
    setState({
      formValues: {
        ...formValues, [id]: value,
      },
    });
  } else if (option !== undefined && id === 'releaseVersion') {
    setState({
      formValues: {
        ...formValues, [id]: value,
      },
    });
  } else {
    setState({ formValues: { ...formValues, [id]: value } });
  }
};

const handleFormSubmit = ({ getState }) => {
  const { onSubmit, formValues } = getState();
  onSubmit(formValues);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleFormSubmit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
};

export default ACTION_HANDLERS;
