// utils
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

// helpers
import makeTestCaseRequestDTO,{makeAddTestCaseToJiraDto} from '../../../helpers/testCasesEdit.requests';

// services
import { editTestCase } from '../../../services/testCases.form';
import { addTestCaseToJira } from '../../../services/testCases.services';

import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';

const handleEditTestCaseSuccess = () => {
  toaster(TOASTER_TYPE.SUCCESS, __('Test Case Edited Successfully'));
};

const editTestCaseSubmit = async ({ formValues, testCaseId, history }) => {
  const testCaseRequestDTO = makeTestCaseRequestDTO(formValues, testCaseId);
  toaster(TOASTER_TYPE.INFO, __('Updating Changes to Backend..Please wait for a while'));
  try {
    await editTestCase(testCaseRequestDTO, testCaseId);
    handleEditTestCaseSuccess();
    history.push('/tcmsuiV2/testcase/list');
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};



export default editTestCaseSubmit;
