import _get from 'lodash/get';
import _size from 'lodash/size';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import TEXT_WITH_LINK_ACTION_TYPES from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer/textWithLink.actionTypes';

const handleTableItemsFetch = async ({ setState, getState }) => {
console.log('on table init');
};


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
};

export default ACTION_HANDLERS;
