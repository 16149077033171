// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const ACTIVE = { id: true, name: __('Active') };
const INACTIVE = { id: false, name: __('Inactive') };

export const STATUS_COLOR_MAP = {
  [ACTIVE.id]: COLOR_MAP.GREEN,
  [INACTIVE.id]: COLOR_MAP.RED,
};

export const STATUS_LABEL_MAP = {
  [ACTIVE.id]: ACTIVE.name,
  [INACTIVE.id]: INACTIVE.name,
};

const STATUSES = [ACTIVE, INACTIVE];

const STATUS_OPTIONS = standardFieldOptionMapper(undefined, STATUSES);

export default STATUS_OPTIONS;
