import _map from 'lodash/map';


import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const makeReleaseVersionOptions = (releaseVersion) => {
  // standardFieldOptionMapper(undefined, projectList, PROJECT_OPTION_MAPPER);
  const options = _map(releaseVersion, element => ({
    label: element,
    value: element,
    additional:
    { id: element, name: element },
  }));
  return options;
};

export default makeReleaseVersionOptions;
