module.exports = {
  getUserProfile: '/tapapi/v2/usermanagement/getuserprofile',
  updateUserProfile: '/tapapi/v2/usermanagement/updateuserprofile',
  getUserPreference: '/tapapi/iotIncidentManagement/v1/public/userpreferences/get?email=',
  postUpdateNotification: '/tapapi/iotIncidentManagement/v1/public/updateNotification',
  postUpdateSummary: '/tapapi/iotIncidentManagement/v1/public/userpreferences/update',
  getUserAvailable: '/tapapi/tap_calendar/internal/user',
  storeUserAvailable: '/tapapi/tap_calendar/internal/user/settings',
  getListing: '/tapapi/v2/common/{feature}/list',
  saveModule: '/tapapi/v2/common/tapfeature/version',
  fetchModule: '/tapapi/v2/common/{feature}/{module}/version',
  releaseVersion: '/tapapi/v2/common/releasenotes/TAP/{version}',
  getUserRole: '/tapapi/v2/usermanagement/getuserrole',
  /** Support Portal urls */
  getUserGroups: '/tapapi/userservice/v1/public/groups/filter',
  updateNotifyInUserGroups: '/tapapi/userservice/v1/public/groups/<group_id>',
  /** Cards Management Urls */
  getAllCards: '/tapapi/v2/cardmanagement/cards',
  getIndividualCard: '/tapapi/v2/cardmanagement/card/{appKey}',
  addCard: '/tapapi/v2/cardmanagement/addcard',
  deleteCard: '/tapapi/v2/cardmanagement/deletecard',
  updateCard: '/tapapi/v2/cardmanagement/updatecard',
  getTestSession: '/trr/p/testsessionbyproductid',
  getProductsTab: '/trr/p/product',
  getTestCaseByProductId: '/trr/p/testcasesproductid',
  getTestResultsByTestSessionId: '/trr/p/testresults',
  postUpdateTestResults: '/trr/p/test-result/update',
  postAddTestCase: '/trr/p/test-result/bulk',
  getExportTestSession: '/trr/p/exporttestsession',
  postBulkUpdateTestResults: '/trr/p/test-result/bulkupdate',
  postBulkUpdateTestSessions: '/trr/p/test-session/bulkupdate',
  getTestcases: '/trr/p/testcases',
  getBootStrap: '/trr/p/getAll',
  searchTestSessionFilter: '/trr/p/test-session/search',
  searchTestResultFilter: '/trr/p/test-result/search',
  searchTestCaseFilter: '/trr/p/test-case/search',
  uploadTestcases: '/trr/p/bbfeature',
  createFeatureUpload: '/trr/p/test-case/upload',
  getAllList: '/trr/p/getAll',
  filterTestcases: '/trr/p/test-case/search',
  getTestCaseDetail: 'trr/p/test-case',
  editTestCase: '/trr/p/test-case/update',
  createTestCase: '/trr/p/test-case/create',
  moveTestCase: '/trr/p/test-case/move',
  bulkMoveTestCase: '/trr/p/test-case/bulkmove',
  deleteTestCase: '/trr/p/test-case',
  bulkDeleteTestCases: '/trr/p/test-case/bulkdelete',
  bulkUpdateTestCases: '/trr/p/test-case/bulkupdate',
  postCreateTestSession: '/trr/p/test-session',
  getTestSessionDetails: '/trr/p/test-session/details',
  postCloneTestSession: '/trr/p/test-session/clone',
  postUpdateTestSession: '/trr/p/test-session/update',
  getTestResultComments: '/trr/p/test-result/comment',
  postMediaInTestResult: '/trr/p/test-result/upload',
  getMediaInTestResult: '/trr/p/test-result/download',
  createTestCaseTemplatePresignedUrl: '/trr/p/template',
  getAllReleaseInfo: '/trr/p/release/getall',
  getAllReleaseInfoEntries: '/trr/p/release/all',
  projectListByReleaseVersion: '/trr/p/dashboard/projectlistbyreleaseversion',
  dashboardStatsByReleaseVersion: '/trr/p/dashboard/v2',
  getAllProducts: '/trr/p/product',
  getAllHistoryCollectionData: '/trr/p/history-collection/getbyparams',
  getAllProjects: '/trr/p/history-collection/projects',
  getAllDealers: '/trr/p/history-collection/dealers',
  getAllHistoryData: '/trr/p/history-collection/getbyparams',
  getAllReleaseVersion: '/trr/p/release/getall',
  pipelineHealthCheck: '/trr/p/auto/pipelinehealthcheck',
  testSessionForProject: '/trr/p/dashboard/testsessionforproject',
  exportTestCaseExcel: '/trr/p/test-case/download',
  createReleaseVersion: '/trr/p/release',
  updateReleaseVersion: '/trr/p/release/update',
  executeTestSuite: '/trr/p/auto/v2/triggerjenkinsjob',
  getDealerInfoByENV: '/trr/p/dealer',
  getDatabaseHealthCheck: '/trr/p/databasehealth',
  insertTrackingControl: '/trr/p/tracking-control',
  getExecutionScreenData: '/trr/p/auto/getExecutionInfo',
  getTestSessionReport: '/trr/p/test-session/generatereport?executionId=',
  getJiraIssueList: '/trr/p/jira/testsession',
  getTestSessionJiraData: '/trr/p/jira',
  createJiraIssue: '/trr/p/jira/issue',
  validateJqlQuery: '/trr/p/jira/parse',
  getAnalyticsData: '/trr/p/analytics/automationmetrics',
  stopExecution: '/trr/p/auto/abort',
  addTestCaseToJira: '/trr/p/test-case/jira',
  moduleFilterTestResult: '/trr/p/test-result/module'
};
