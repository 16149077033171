import React, { useCallback } from 'react';
import Button from 'tcomponents/atoms/Button';
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import Icon, { SIZES } from 'tcomponents/atoms/FontIcon';
import { PropertyControlledComponent } from '@tekion/tekion-components/src/molecules';
import { STATUS_COLOR_MAP, STATUS_LABEL_MAP } from '../../constants/testResultsList.status';

import Styles from './testResultsL3Header.module.scss';
import { getTestCasesCount } from './helpers/testResultsL3Header.general';
import ACTION_TYPES from '../../constants/testResultsList.actiontypes';

function TestResultsL3Header(props) {
  const { data, onAction } = props;
  console.log(props);

  const
    {
      totalTestcases, pass, fail, skipped, pending, reRunPass,
    } = getTestCasesCount(data);

  const handleReRunClick = () => {
    onAction({
      type: ACTION_TYPES.RE_RUN_CLICK,
      payload: {
        data,
      },
    });
  };

  const isFailed = fail > 0;

  return (
    <div className={Styles.testResultmain}>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1">
        icon-information-filled
        </Icon>
        TOTAL TESTCASES - <b>{`${totalTestcases}`}</b>
      </div>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1" color={STATUS_COLOR_MAP.PASS}>
        icon-information-filled
        </Icon>
        PASS - <b>{`${pass}`}</b>
      </div>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1" color={STATUS_COLOR_MAP.FAIL}>
        icon-information-filled
        </Icon>
        FAIL - <b className={Styles.boldStyle}>{`${fail}`}</b>
        <PropertyControlledComponent controllerProperty={isFailed}>
          <IconAsBtn size={SIZES.XL} containerClassName={Styles.reRunIcon} color={STATUS_COLOR_MAP.PASS} onClick={handleReRunClick}>
          icon-play
          </IconAsBtn>
        </PropertyControlledComponent>
      </div>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1" color={STATUS_COLOR_MAP.SKIPPED}>
        icon-information-filled
        </Icon>
        SKIPPED - <b>{`${skipped}`}</b>
      </div>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1" color={STATUS_COLOR_MAP.PENDING}>
        icon-information-filled
        </Icon>
        PENDING - <b>{`${pending}`}</b>
      </div>

      <div className={Styles.passed}>
        <Icon size={SIZES.XL} className="mb-1" color={STATUS_COLOR_MAP.RE_RUN_PASS}>
        icon-information-filled
        </Icon>
        RE RUN PASS - <b>{`${reRunPass}`}</b>
      </div>

    </div>
  );
}

export default TestResultsL3Header;
