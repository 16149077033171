import React, { useState } from 'react';

// import { Editor, EditorState } from 'draft-js';

import RichTextEditor from 'tcomponents/molecules/richTextEditor/RichTextEditorWithTable';
import FieldLabel from 'tcomponents/organisms/FormBuilder/components/fieldLabel';
import { getEditorStateFromRawContent, getRawContentFromEditorContent } from './helpers/richTextEditorFieldRenderer.general';

import ACTION_TYPES from '../../../organisms/uploadTestCases/uploadTestCasesFormCreate/constants/uploadTestCasesForm.actionTypes';

import styles from './richTextEditorFieldRenderer.module.scss';

function RichTextEditorFieldRenderer(props) {
  const {
    onAction, id, label, value, match
  } = props;

  const editorState = getEditorStateFromRawContent(value);
  const [editorContent, setEditorContent] = useState(editorState);

  const onChange = (editorValue) => {
    const updatedEditorValue = getRawContentFromEditorContent(editorValue);
    setEditorContent(updatedEditorValue);
    onAction({
      type: ACTION_TYPES.ON_FIELD_CHANGE,
      payload: {
        id,
        updatedEditorValue,
      },
    });
  };

  const isRequired = () => {
    const requiredvalue = label == 'Description' ? true : false;
    return requiredvalue;
  };

  return (
    <div>
      <br />
      <FieldLabel label={label} required={isRequired()} />
      <RichTextEditor
        handleChange={onChange}
        editorState={editorContent}
        className={styles.richTextEditor}
      />
      <br />
    </div>
  );
}

export default RichTextEditorFieldRenderer;
