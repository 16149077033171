import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
// import { isRequiredRule } from 'tbase/utils/formValidators';
import PRIORITY_OPTIONS from '../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.priority';
import AUTOMATED_OPTIONS from '../../../pages/testCases/organisms/TestCaseForm/constant/testCasesForm.automated';
import ACTIVE_OPTIONS from '../../../pages/testCases/organisms/TestCaseForm/constant/testCasesForm.active';
import REGRESSION_OPTIONS from '../../../pages/testCases/organisms/TestCaseForm/constant/testCasesForm.regression';
import RichTextEditorFieldRenderer from '../../../molecules/FieldRenderer/RichTextEditorFieldRenderer';

export const TEST_CASE_NAME = {
  id: 'testcaseName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('TestCase Name'),
    required: true,
    disabled: true,
  },
};

export const AUTHOR = {
  id: 'author',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Author'),
    disabled: true,
  },
};
export const PRIORITY = {
  id: 'priority',
  renderer: SelectInput,
  renderOptions: {
    label: __('Priority'),
    required: true,
    options: PRIORITY_OPTIONS,
    disabled: true,
  },
};
export const COMPONENT = {
  id: 'component',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Component'),
    disabled: true,
  },
};

export const JIRA = {
  id: 'jira',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Jira'),
    disabled: true,
  },
};

export const BRANCH = {
  id: 'branch',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Branch'),
    disabled: true,
  },
};

export const DESCRIPTION = {
  id: 'description',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Description'),
    disabled: true,
  },
};
export const PRECONDITIONS = {
  id: 'precondition',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Precondition'),
    disabled: true,
  },
};


export const EXIT_CRITERIA = {
  id: 'exitCriteria',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Exit Creteria'),
    disabled: true,
  },
};

export const RELEASE = {
  id: 'release',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Release'),
    disabled: true,
  },
};

export const TYPE = {
  id: 'type',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Type'),
    required: true,
    disabled: true,
  },
};

export const ACTIVE = {
  id: 'active',
  renderer: SelectInput,
  renderOptions: {
    label: __('Active'),
    options: ACTIVE_OPTIONS,
  },
};
export const AUTOMATED = {
  id: 'automated',
  renderer: SelectInput,
  renderOptions: {
    label: __('Automated'),
    options: AUTOMATED_OPTIONS,
  },
};

export const REGRESSION = {
  id: 'regression',
  renderer: SelectInput,
  renderOptions: {
    label: __('Regression'),
    options: REGRESSION_OPTIONS,
  },
};

export const MISC = {
  id: 'misc',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Misc'),
    disabled: true,
  },

};
export const STEPS = {
  id: 'steps',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Steps'),
    required: true,
    disabled: true,
  },
};

export const TCCATEGORY = {
  id: 'tcCategory',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Tc Category'),
    placeholder: __('Select'),
    disabled: true,
  },
};

export const UPSTREAMCONSUMPTION = {
  id: 'upstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Upstream Consumption'),
    placeholder: __('Select'),
    disabled: true,
  },
};

export const DOWNSTREAMCONSUMPTION = {
  id: 'downstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Downstream Consumption'),
    placeholder: __('Select'),
    disabled: true,
  },
};

export const HORIZONTALIMPLEMENTATION = {
  id: 'horizontalImplementation',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Horizontal Implementation'),
    placeholder: __('Select'),
    disabled: true,
  },
};
