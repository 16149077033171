import React from 'react';

import _nth from 'lodash/nth';
import _get from 'lodash/get';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import Button from 'tcomponents/atoms/Button';
import { EMPTY_OBJECT, EMPTY_STRING } from "@tekion/tekion-base/app.constants";

import { ACTION_TYPES } from '../constants/statsTable.actionTypes';

const onRowExpandClick = onAction => (rowInfo) => {
  // const { onAction } = props;
  onAction({
    type: ACTION_TYPES.ON_ROW_EXPAND_CLICK,
    payload: { rowInfo },
  });
};

const handleExpandedChange = onAction => (newExpanded, index, event, sortedData) => {
  const indexToGet = index[0];
  const rowInfo = _nth(sortedData, indexToGet);

  // const { onAction } = this.props;
  onAction({
    type: ACTION_TYPES.HANDLE_EXPAND_CHANGE,
    payload: { rowInfo },
  });
};

export const SUB_HEADER_BUTTON = (onAction) => ({
  subHeaderRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Send Report in Email'),
        view: Button.VIEW.PRIMARY,
        disabled: true,
      },
      action: ACTION_TYPES.ON_SEND_EMAIL_CLICK,
    },
  ],
});

export const getSubHeaderProps = (onAction) => {
    return (SUB_HEADER_BUTTON(onAction))
};

export const makeTableProps = ({
  isLoading,
  selection,
  projectWiseTableData,
  totalCount,
  onAction,
  sortDetails,
  expanded,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: projectWiseTableData.length,
  resultsPerPage: projectWiseTableData.length,
  totalNumberOfEntries: projectWiseTableData.length,
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
  onExpanderClick: onRowExpandClick(onAction),
  onExpandedChange: handleExpandedChange(onAction),
  expanded,
});

export const getProjectData = (dataFormat) => {
  let count = _get(dataFormat, 'count', EMPTY_STRING);
  const projectData = [];
  while (count > 0) {
    projectData.push(_get(dataFormat, ['projectDashboardList', `project-${count}`], EMPTY_OBJECT));
    count -= 1;
  }
  return projectData;
};
