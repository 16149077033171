import EventEmitter from 'eventemitter3';

import EVENT_TYPES from '../constant/tabbedTestCaseList.eventTypes';

const tabbedTestCaseListEvent = new EventEmitter();

export const refetchTestCaseList = () => {
  console.log("refresh")
  tabbedTestCaseListEvent.emit(EVENT_TYPES.REFETCH_TEST_CASE_LIST);
};

export default tabbedTestCaseListEvent;
