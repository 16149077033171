// Lodash
import _property from 'lodash/property';

const file = _property('file');
const name = _property('name');
const progress = _property('progress');
const isUploading = _property('isUploading');
const data = _property('data');
const headers = _property('headers');
const documentId = _property('documentId');
const withCredentials = _property('withCredentials');

const READER = {
  file,
  name,
  progress,
  isUploading,
  data,
  headers,
  documentId,
  withCredentials,
};

export default READER;
