import React, { useCallback, useMemo, useState } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import withActions from 'tcomponents/connectors/withActions';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import FormBuilder from 'tcomponents/organisms/FormBuilder';

import ACTION_TYPES
  from '../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.actionTypes';
import ACTION_HANDLERS from './helpers/moveTestCasesForm.actionHandlers';
import SECTIONS from './constants/moveTestCaseForm.sections';
import getFields from './constants/moveTestCaseForm.formConfig';

const FormWithSubmission = withEventSubmission(FormBuilder);

const MoveTestCasesForm = ({
  onAction,
  project,
  module,
  subModule,
  projectListByProductId,
  featureListByProjectId,
  subfeatureListByFeatureId,
  tabId,
  formValues,
}) => {

  const CONTEXT_ID = 'MOVE_TEST_CASE_FORM';

  const fields = useMemo(
    () => getFields({
      tabId,
      formValues,
      featureListByProjectId,
      projectListByProductId,
      subfeatureListByFeatureId,
    }),

    [formValues, featureListByProjectId, projectListByProductId, subfeatureListByFeatureId, tabId]
  );

  return (
    <div>
      <FormWithSubmission
        fields={fields}
        sections={SECTIONS}
        values={formValues}
        onAction={onAction}
        contextId={CONTEXT_ID}
      />
    </div>
  );
};

export default compose(
  withRouter,
  withActions({}, ACTION_HANDLERS)
)(MoveTestCasesForm);
