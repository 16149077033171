import _get from 'lodash/get';
import _find from "lodash/find";
import _head from "lodash/head";

import addOptionsToAdditional from 'tbase/utils/addOptionsToAdditional';
import { EMPTY_ARRAY } from "@tekion/tekion-base/app.constants";

import makeProjectOptions from '../../../../../../../helpers/projectOptions';
import makeFeatureOptions from './featureOptions';
import {
  FEATURE, PRIORITY, TAG, EXECUTED_AT, CREATED_AT, PROJECT, SUBFEATURE, DEFAULT_FILTER_TYPES, ACTIVE, AUTOMATED,
} from '../constants/testCasesList.filterTypes';
import makeSubFeatureOptions from './subFeatureOptions';
import makeTagFeatureOptions from '../../../../../../../helpers/tagOptions';

const createFilterTypes = (tcmsBootStrap, projectListByProductId, featureListByProjectId,
  subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId, uniqueTagValues, selectedFilters) => {
  // const projectList = _get(tcmsBootStrap, 'projectList');
  const projectList = projectListByProductId[tabId];

  // const featureList = _get(tcmsBootStrap, 'featureList');
  let projectIdFromFilters;
  if(selectedProjectId === undefined) {
     projectIdFromFilters = _find(selectedFilters, filter => {
      if (filter.type === 'project_id')
        return filter.values;
    })
  }

  const projectId = selectedProjectId === undefined ? _head(_get(projectIdFromFilters, 'values', EMPTY_ARRAY)) : selectedProjectId;
  const featureList = featureListByProjectId[projectId];
  const subFeatureList = subfeatureListByFeatureId[selectedFeatureId];

  const projectOptions = makeProjectOptions(projectList);
  const featureOptions = makeFeatureOptions(featureList);
  const subFeatureOptions = makeSubFeatureOptions(subFeatureList);
  const tagOptions = makeTagFeatureOptions(uniqueTagValues);
  const projectFilter = addOptionsToAdditional(PROJECT, projectOptions);
  const featureFilter = addOptionsToAdditional(FEATURE, featureOptions);
  const tagFilter = addOptionsToAdditional(TAG, tagOptions);
  const subFeatureFilter = addOptionsToAdditional(SUBFEATURE, subFeatureOptions);
  const filterTypes = [projectFilter, featureFilter, subFeatureFilter, tagFilter, PRIORITY, EXECUTED_AT, CREATED_AT, ACTIVE, AUTOMATED];
  return filterTypes;
};

export const createFilterProps = ({
  selectedFilters, tcmsBootStrap, projectListByProductId,
  featureListByProjectId,
  subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId, uniqueTagValues,
}) => {
  const filterTypes = createFilterTypes(tcmsBootStrap, projectListByProductId,
    featureListByProjectId, subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId, uniqueTagValues, selectedFilters);
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    selectedFilters,
    shouldHideSaveFilterGroup: true,
    showFilterGroupName: false,
    shouldHideFilterGroup: true,
    showResetToDefaultGroup: false,
  };
};
