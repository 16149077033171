import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _size from 'lodash/size';
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Components
import IconAsBtn from 'tcomponents/atoms/iconAsBtn';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import Badge from 'tcomponents/atoms/BadgeWrapper';

// Constants
import { EMPTY_OBJECT } from 'tbase/app.constants';


function DisplayMediaRenderer(props) {
  const { data: testResult, onAction } = props;

  const priority = _get(props, 'original.priority');

  const priorityDisplay = (priority) => {
    if (priority === 0) {
      return ('P0 - Highest');
    }
    if (priority === 1) {
      return ('P1 - High');
    }
    if (priority === 2) {
      return ('P2 - Medium');
    }
    if (priority === 3) {
      return ('P3 - Low');
    }
    if (priority === 4) {
      return ('P4 - Lowest');
    }
  };
  return (
    <div>{priorityDisplay(priority)}</div>
  );
}

DisplayMediaRenderer.propTypes = {
  data: PropTypes.object,
  onAction: PropTypes.func,
};

DisplayMediaRenderer.defaultProps = {
  data: EMPTY_OBJECT,
  onAction: _noop,
};

export default makeCellRenderer(DisplayMediaRenderer);
