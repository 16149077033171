import { deepClone } from '@tekion/tap-components/utils/helper';
import { NOTIFICATION_OPTION_LABELS } from './SupportPortal.constants';

export const populateHash = (list = [], hashKeyProp = 'id') => {
  const listHash = {};
  list.forEach((item) => {
    const { [hashKeyProp]: id } = item;
    listHash[id] = deepClone(item);
  });
  return listHash;
};

export const structureNotifiySetting = (groups) => {
  const notifySettingData = {};
  const notifySettingHeaders = [];
  Object.keys(groups).forEach((key) => {
    const { notify, id, name } = groups[key];
    notifySettingHeaders.push({
      key: id,
      label: name,
      accessor: id,
    });
    const userNotifySetting = notify[0];
    const {
      ticket_comment: ticketComment = false,
      ticket_create: ticketCreate = false,
      app_ticket_create: webpappTicketCreate = false,
      app_ticket_comment: webpappTicketComment = false,
    } = userNotifySetting;
    notifySettingData[id] = [{
      key: 'ticket_create',
      label: NOTIFICATION_OPTION_LABELS.ticket_create,
      rawData: userNotifySetting,
      actions: [
        {
          type: 'email',
          value: ticketCreate || false,
          disabled: false,
        },
        {
          type: 'webapp',
          value: webpappTicketCreate,
          disabled: false,
        },
      ],
    },
    {
      key: 'ticket_comment',
      label: NOTIFICATION_OPTION_LABELS.ticket_comment,
      rawData: userNotifySetting,
      actions: [
        {
          type: 'email',
          value: ticketComment,
          disabled: false,
        },
        {
          type: 'webapp',
          value: webpappTicketComment,
          disabled: false,
        },
      ],
    }];
  });
  return {
    notifySettingData, notifySettingHeaders,
  };
};

let differedData = {};
export const compareNotificationSettingDiff = (prevData, nextData) => {
  differedData = {};
  Object.keys(nextData).forEach((key) => {
    const nextContents = nextData[key];
    const prevContents = prevData[key];
    checkContentDiff(prevContents, nextContents, key);
  });
  return differedData;
};

const checkContentDiff = (prevContents, nextContents, key) => {
  nextContents.forEach((nextContent, index) => {
    const prevContent = prevContents[index];
    const { actions: prevActions } = prevContent;
    const { actions: nextActions } = nextContent;
    if (checkActionsDiff(prevActions, nextActions, differedData)) {
      differedData[key] = nextContents;
    }
  });
};

const checkActionsDiff = (prevActions, nextActions) => nextActions.some((action, index) => {
  const { value: nextValue } = action;
  const { value: prevValue } = prevActions[index];
  if (nextValue !== prevValue) {
    return true;
  }
  return false;
});

export const constructPayloads = (differedData) => {
  const payloads = [];
  Object.keys(differedData).forEach((key) => {
    const allActions = differedData[key].map(({ actions }) => actions);
    const { rawData } = differedData[key][0];
    payloads.push({
      groupId: key,
      payload: {
        notify: [
          {
            email: rawData.email,
            name: rawData.name,
            ticket_create: allActions[0][0].value || false,
            app_ticket_create: allActions[0][1].value || false,
            ticket_comment: allActions[1][0].value || false,
            app_ticket_comment: allActions[1][1].value || false,
          },
        ],
      },
    });
  });
  return payloads;
};

export const recomposeGroupsHash = (existingHash, newPayloads) => {
  const updatedHash = { ...existingHash };
  newPayloads.forEach(({ groupId, payload }) => {
    updatedHash[groupId] = {
      ...updatedHash[groupId],
      notify: [...deepClone(payload.notify)],
    };
  });
  return updatedHash;
};
