import { createSelector } from 'reselect';
/**
 * @description Selector Function for getting the specified state from reducer
 */
const CalendarReducer = state => state.calender;

const getUserAvailabilityStatus = () => createSelector(CalendarReducer,
  substate => substate.userDetails);

const addUserAvailability = () => createSelector(CalendarReducer,
  substate => substate.userAvailability);


const loader = () => createSelector(CalendarReducer,
  substate => substate.loading);

export {
  getUserAvailabilityStatus,
  addUserAvailability,
  loader,
};
