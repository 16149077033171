// Lodash
import _head from 'lodash/head';

// Readers
import mediaReader from 'tbase/readers/Media';

// Constants
import TEST_RESULT_LIST_ACTION_TYPES from '../../../constants/testResultsList.actiontypes';

export const handleMediaChange = ({ onAction, mediaList, selectedTestResultId }) => {
  const mediaId = mediaReader.mediaId(_head(mediaList));
  console.log(mediaList);

  if (!mediaId) {
    onAction({
      type: TEST_RESULT_LIST_ACTION_TYPES.UPLOAD_MEDIA,
      payload: { mediaId, selectedTestResultId, mediaList },
    });
  }
};
