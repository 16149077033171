// Constants
import { PRODUCT, RELEASE_TAG,MODULE ,PROJECT,TRACKING_ENABLED} from './AddTrackingControlForm.fields';

// Styles


const SECTIONS = [
  {
    rows: [
      // {
      //   columns: [PRODUCT.id],
      // },
      {
        columns: [RELEASE_TAG.id],
          },
      // {
      //   columns: [MODULE.id],
      //     },
      {
        columns: [PROJECT.id],
          },
      {
        columns: [TRACKING_ENABLED.id],
      },

    ],
  },
];

export default SECTIONS;
