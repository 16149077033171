// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
import subFeatureReader from '../../../../../../../readers/SubFeature';

const FEATURE_OPTION_MAPPER = {
  label: subFeatureReader.name,
  value: subFeatureReader.id,
};

const makeSubFeatureOptions = featureList => standardFieldOptionMapper(undefined, featureList, FEATURE_OPTION_MAPPER);

export default makeSubFeatureOptions;
