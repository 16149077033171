import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { compose } from 'recompose';

import {
  withRouter,
} from 'react-router-dom';

import withActions from 'tcomponents/connectors/withActions';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import Button from 'tcomponents/atoms/Button';
import { isRequiredRule } from 'tbase/utils/formValidators';
import useFetchData from 'tbase/customHooks/useFetchData';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import IntegrationHealthTable from './organisms/IntegrationHealthTable';
import StatusTable from './organisms/StatusTable';
import { ACTION_HANDLERS } from './helpers/IntegrationHealth.actionHandlers';
import ENV_OPTIONS from './constants/environmentOptions';
import ACTION_TYPES from './constants/IntegrationHealth.actionTypes';
import { getProjectOptions } from './constants/projectOptions';
import { getReleaseVersionOptions } from './constants/releaseVersionOptions';

import styles from './integrationHealth.module.scss';
import { getAllReleaseVersionsApi, pipelineHealthCheck } from './services/integrationHealth.api';


const IntegrationHealth = (props) => {
  const {
    onAction, selectedProject, selectedEnvironment, selectedReleaseVersion, isResetClicked, testSessionResponse,
  } = props;

  const pipelineResponse = useFetchData(pipelineHealthCheck, ['arc']);
  const releaseVersionResponse = useFetchData(getAllReleaseVersionsApi);

  const isLoading = _get(pipelineResponse, '0.isLoading', false);
  const projectsData = _get(pipelineResponse, '0.data.data.data.hits', EMPTY_ARRAY);
  const releaseVersionData = _get(releaseVersionResponse, '0.data.data.data.hits', EMPTY_ARRAY);

  const isLoadingStatus = _get(testSessionResponse, '0.isLoading', false);

  const projectsOptions = getProjectOptions(projectsData);
  const releaseVersionOptions = getReleaseVersionOptions(releaseVersionData);

  const onFieldChange = useCallback(
    (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        onAction({
          type: ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            value, selectedProject, selectedEnvironment, selectedReleaseVersion,
          },
        });
      }
    },
    [onAction]
  );

  const handleSearchClick = useCallback(
    (value) => {
      onAction({ type: ACTION_TYPES.ON_SEARCH_CLICK, payload: { value } });
    },
    [onAction]
  );

  const handleResetClick = useCallback(
    (value) => {
      onAction({ type: ACTION_TYPES.ON_RESET_CLICK, payload: { value } });
    },
    [onAction]
  );

  return (
    <>
      <div className={styles.releaseDetails}>
        <SelectInput
          id="Project"
          label={__('Project')}
          options={projectsOptions}
          onAction={onFieldChange}
          value={selectedProject}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          id="Environment"
          label={__('Environment')}
          options={ENV_OPTIONS}
          onAction={onFieldChange}
          value={selectedEnvironment}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          id="ReleaseVersion"
          label={__('Release Version')}
          placeholder={__('Select')}
          options={releaseVersionOptions}
          onAction={onFieldChange}
          value={selectedReleaseVersion}
          required
          validators={[isRequiredRule]}
        />

        <Button
          view="primary"
          className={styles.buttonStyle}
          onClick={handleSearchClick}
        >
          {__('Search')}
        </Button>

        <Button
          view="primary"
          className={styles.buttonStyle}
          onClick={handleResetClick}
        >
          {__('Reset')}
        </Button>

      </div>
      {
        isResetClicked
          ? <IntegrationHealthTable data={projectsData} isLoading={isLoading} onAction={onAction} /> : <StatusTable data={testSessionResponse} onAction={onAction} isLoading={isLoadingStatus} />
      }
    </>
  );
};

IntegrationHealth.propTypes = {
  isResetClicked: PropTypes.bool,
  onAction: PropTypes.func,
  selectedProject: PropTypes.array,
  selectedEnvironment: PropTypes.array,
  selectedReleaseVersion: PropTypes.array,
  testSessionResponse: PropTypes.any,
};

IntegrationHealth.defaultProps = {
  isResetClicked: true,
  onAction: _noop(),
  selectedProject: EMPTY_ARRAY,
  selectedEnvironment: EMPTY_ARRAY,
  selectedReleaseVersion: EMPTY_ARRAY,
  testSessionResponse: EMPTY_OBJECT,
};


export default compose(withRouter, withActions({}, ACTION_HANDLERS))(IntegrationHealth);
