import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';

const handleTableItemsFetch = () => {
  // console.log('init');
};


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
};

export default ACTION_HANDLERS;
