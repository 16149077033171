import _get from 'lodash/get';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { ROW_ACTION_TYPES } from '../constants/viewTrackingControlList.rowActionTypes';
import { getTrackingControls } from '../../../services/viewTrackingControl.api';
import  ACTION_TYPES  from '../constants/viewTrackingControlList.actionTypes'


const handleClick = ({ setState, getState, params }) => {
  const { handleEdit } = getState();
  console.log(params);
    const { action, payload } = params;
  handleEdit(true);
   
};

const ROW_ACTION_HANDLERS = {
  [ROW_ACTION_TYPES.CLICK_EDIT]: handleClick,
};
export default ROW_ACTION_HANDLERS;
