/* eslint-disable import/order */
/* eslint-disable rulesdir/no-inline-functions */
// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';

// Utils
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';

// Components
import Modal from 'tcomponents/molecules/Modal';

// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';
import AddTestPlanForm from '../addTestPlanForm/AddTestPlanForm';

const AddTestPlanModal = (props) => {
  const {
    onSubmit, onCancel, isVisible, isUploading,
  } = props;

  const handleSubmit = useCallback(() => {
    // triggerSubmit(CONTEXT_ID);
  }, EMPTY_ARRAY);

  return (
    <Modal
      title={__('ADD TESTPLAN')}
      visible={isVisible}
      submitBtnText={__('Submit')}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      width={Modal.SIZES.SM}
      loading={isUploading}
      destroyOnClose
    >
      <AddTestPlanForm></AddTestPlanForm>
    </Modal>
  );
};

AddTestPlanModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  isUploading: PropTypes.bool,
};

AddTestPlanModal.defaultProps = {
  onSubmit: _noop,
  onCancel: _noop,
  isVisible: false,
  isUploading: false,
};

export default AddTestPlanModal;
