import _get from "lodash/get";
import _head from "lodash/head";
import {EMPTY_ARRAY, EMPTY_STRING} from "@tekion/tekion-base/app.constants";

export const getTestCaseDTO = (tabId, searchText, currentPage, pageSize, selectedFiltersWithLocalStorage, sort) => {
  // console.log(searchText);
  const DEFAULT_BODY = {
    type: 'product_id',
    operator: 'IN',
    values: [
      tabId,
    ],
  };

  const objectBody = ({
    filter: [DEFAULT_BODY, ...selectedFiltersWithLocalStorage],
    size: pageSize,
    page: currentPage,
    searchText,
    sort,
  });
  return objectBody;
};

// export const filterDTO = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const filterValue = [...selectedFilters];
//   const DEFAULT_BODY = ({
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   });

//   filterValue.push(DEFAULT_BODY);
//   const objectBody = ({
//     filter: filterValue,
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });
//   return objectBody;
// };

// export const paginationDTO = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const DEFAULT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   };
//   const objectBody = ({
//     filter: [...selectedFilters, DEFAULT_BODY],
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });

//   return objectBody;
// };

// export const paginationDTOwithoutFilter = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const DEFAULT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   };

//   const objectBody = ({
//     filter: [...selectedFilters, DEFAULT_BODY],
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });

//   return objectBody;
// };

export const getExportDTO = selection => ({
  testCaseIdList: selection,
  name: 'TESTCASE_DOWNLOAD',
});

export const getBulkUpdateDto = (formValues, selection) => {
    const returnObject = {
        idList: selection,
    };
    const releaseVersion = _get(formValues, 'releaseVersion', EMPTY_STRING);
    const isAutomated = _get(formValues, 'isAutomated', EMPTY_ARRAY);
    const isActive = _get(formValues, 'isActive', EMPTY_ARRAY);
    const priority = _get(formValues, 'priority', EMPTY_ARRAY);

    if (releaseVersion !== EMPTY_STRING) {
        returnObject.releaseVersion = releaseVersion;
    }
    if (isAutomated !== EMPTY_ARRAY) {
        returnObject.isAutomated = _head(isAutomated);
    }
    if (isActive !== EMPTY_ARRAY) {
        returnObject.isActive = _head(isActive);
    }
    if (priority !== EMPTY_ARRAY) {
        returnObject.priority = _head(priority);
    }
    return returnObject;
};
