import React from 'react';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';


export const makeTableProps = ({
  isLoading,
  selection,
  tableData,
  // totalCount,
  onAction,
  sortDetails,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: tableData.length,
  resultsPerPage: tableData.length,
  totalNumberOfEntries: tableData.length,
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
});
