// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
import featureReader from '../../../../../../../readers/Feature';

const FEATURE_OPTION_MAPPER = {
  label: featureReader.name,
  value: featureReader.id,
};

const makeFeatureOptions = featureList => standardFieldOptionMapper(undefined, featureList, FEATURE_OPTION_MAPPER);

export default makeFeatureOptions;
