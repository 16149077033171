import React, { useCallback, useMemo } from 'react';
import _get from 'lodash/get';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import useFetchData from 'tbase/customHooks/useFetchData';
import { getTestSessionDetails } from '../../services/testSessions.services';
import TestSessionForm from '../TestSessionForm';
import { makeInitialFormValues } from '../../helpers/testSessions.helpers';
import editTestSessionSubmit from './helpers/editTestSession.onFormSubmit';

function EditTestSession(props) {
  const { history, match } = props;
  const testSessionId = _get(match, 'params.testsessionId');
  const [{ isLoading: isTestSessionLoading, data: testSessionResponse }] = (useFetchData(getTestSessionDetails, [testSessionId]));

  // console.log(testSessionResponse);
  const handleEditTestSessionSubmit = useCallback(formValues => editTestSessionSubmit(formValues, testSessionId), [
    testSessionId,
  ]);

  const initialFormValues = useMemo(() => makeInitialFormValues(testSessionResponse), [testSessionResponse]);

  const renderFormHeader = () => (
    <PageComponent.Header hasBack>
      <div className="full-width headerContainer">
        <Heading size={1}>{__('Edit Test Session')}</Heading>
      </div>
    </PageComponent.Header>
  );
  return (
    <TestSessionForm
      history={history}
      isEdit
      onSubmit={handleEditTestSessionSubmit}
      isTestSessionLoading={isTestSessionLoading}
      formHeader={renderFormHeader}
      formValues={initialFormValues}
    />
  );
}

export default EditTestSession;
