import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// constants
import { EMPTY_ARRAY } from 'tbase/app.constants';

// hooks
import useFetchData from 'tbase/customHooks/useFetchData';

// containers
import withRouteParams from 'tcomponents/connectors/withRouteParams';

// components
import TestCaseForm from '../TestCaseForm';

// helpers
import cloneTestCaseSubmit from './helpers/cloneTestCase.onFormSubmit';
import makeInitialFormValues from '../../helpers/testCases.initialValues';
// import getHeaderProps from './helpers/editTestCase.headerProps';

// services
import { getTestCase } from '../../services/testCases.form';

const CloneTestCase = (props) => {
  const { history, testCaseId } = props;
  const [{ isLoading: isTestCaseLoading, data: testCaseResponse }] = useFetchData(getTestCase, [testCaseId]);

  const initialFormValues = useMemo(() => makeInitialFormValues(testCaseResponse), [testCaseResponse]);

  const handleCloneTestCaseSubmit = useCallback(
    formValues => cloneTestCaseSubmit({ formValues, testCaseId, history }),
    [testCaseId]
  );

  return (
    <TestCaseForm
      testCaseId={testCaseId}
      history={history}
      isTestCaseLoading={isTestCaseLoading}
      isEdit
      // headerProps={headerProps}
      onSubmit={handleCloneTestCaseSubmit}
      formValues={initialFormValues}
      propheader="clone"
    />
  );
};

CloneTestCase.propTypes = {
  testCaseId: PropTypes.string,
  history: PropTypes.object.isRequired,
};

CloneTestCase.defaultProps = {
  testCaseId: undefined,
};

export default withRouteParams(CloneTestCase);
