import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Header from '@tekion/tap-components/molecules/Header';
import Footer from '@tekion/tap-components/molecules/Footer';
import LeftNav from '@tekion/tap-components/molecules/LeftNav';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import { clearAllCookies } from '@tekion/tap-components/utils/helper';
import Loader from '@tekion/tap-components/atoms/Loader';
import { APP_RELEASE_VERSION } from '../../../config/Meta';

const { BASE_URL } = process.env;

const Layout = (props) => {
  const [loadComponent, setloadComponent] = useState(false);
  const { children, location } = props;
  useEffect(() => {
    const accessToken = TokenManager.getItem(TokenManager.accessTokenKey);
    if (accessToken) {
      setloadComponent(true);
    } else {
      let redirectUrl = '/login';
      if (location && location.pathname) {
        redirectUrl = `/login?url=${location.pathname}`;
      }
      clearAllCookies(redirectUrl);
    }
  }, []);
  const _renderLayout = () => (
    <React.Fragment>
      <Header apiBaseUrl={BASE_URL} />
      <section>
        <LeftNav modules={['/app']} apiBaseUrl={BASE_URL} />
      </section>
      <React.Fragment>
        {children}
      </React.Fragment>
      <section>
        <Footer apiBaseUrl={BASE_URL} version={APP_RELEASE_VERSION} disableFSRecording />
      </section>
    </React.Fragment>
  );
  const _renderLoad = () => (
    <Loader id="Layout" />
  );
  return (
    <React.Fragment>
      {!loadComponent ? _renderLoad() : _renderLayout()}
    </React.Fragment>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};
export default Layout;
