import Http from "../../../services/http";
import URL from '../../../api/urls';

export const getAnalyticsProductData = async () => {
    const response = await Http.get('CQA', `${URL.getAnalyticsData}`);
    return response;
};

export const getAnalyticsProjectData = async (selectedProduct) => {
    const response = await Http.get('CQA', `${URL.getAnalyticsData}?productId=${selectedProduct}`);
    return response;
};

export const getAnalyticsModuleData = async (selectedProduct, selectedProject) => {
    const response = await Http.get('CQA', `${URL.getAnalyticsData}?productId=${selectedProduct}&projectId=${selectedProject}`);
    return response;
};