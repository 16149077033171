import React from 'react';
import { NavLink} from 'react-router-dom';
import { LogoutOutlined } from "@ant-design/icons"
import { Spin, Card } from "antd"
import { APP_BASE_PATH } from '../../config/Meta'
//import { useAlert } from 'react-alert'
//import CustomAlert from '../utilities/Alert'
class HomePage extends React.Component{
     returnTestcase = () => {
          return(
              <NavLink to={`${APP_BASE_PATH}/testcase`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>TestCase</Card.Grid></NavLink>
          )
     }

    returntestsessions = () => {
         return (
              <NavLink to={`${APP_BASE_PATH}/testsession`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>TestSessions</Card.Grid></NavLink>
         )
    }
    returnJiraissues = () => {
         return (
              <NavLink to="/dataui/Approve" activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Jiraissues</Card.Grid></NavLink>
         )
    }
    returntestplan = () => {
         return (
              <NavLink to="/dataui/Users" activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}> testPlan</Card.Grid></NavLink>
         )
    }

  returnAutomationProgress = () => {
    return (
      <NavLink to={`${APP_BASE_PATH}/executionprogress`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Execution Progress</Card.Grid></NavLink>
    )
  }

    returnUploadStatus = () => {
          return(
               <NavLink to={`${APP_BASE_PATH}/uploadstatus`} activeClassName="is-active"><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}> Upload Status</Card.Grid></NavLink>
          )
    }

  returnReleaseBoard= () => {
    return(
      <NavLink to={`${APP_BASE_PATH}/releaseBoard`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Release Board</Card.Grid></NavLink>
    )
  }

  returnHistoryCollection = () => {
    return (
      <NavLink to={`${APP_BASE_PATH}/historycollection`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>History Collection</Card.Grid></NavLink>
    )
  }

  returndbHealthCheck = () => {
    return(
      <NavLink to={`${APP_BASE_PATH}/dbhealthcheck`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Database Health Check</Card.Grid></NavLink>
    )
  }

  returnTrackingControl = () => {
    return(
      <NavLink to={`${APP_BASE_PATH}/trackingControl`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Tracking Control</Card.Grid></NavLink>
    )
  }
  returnExecutionSCreen= () => {
     return(
       <NavLink to={`${APP_BASE_PATH}/executionscreen`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Execution Screen</Card.Grid></NavLink>
     )
   }

   returnJiraIssues = () => {
       return(
           <NavLink to={`${APP_BASE_PATH}/jiraissues`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Jira Issues</Card.Grid></NavLink>
       )
   }

   returnAutomationMetric = () => {
    return(
        <NavLink to={`${APP_BASE_PATH}/automationmetric`} activeClassName="is-active" ><Card.Grid style={{border:'1px solid #D3D3D3',margin:"20px",width:"22%",textAlign:"center",margin: "20px"}}>Automation Metric</Card.Grid></NavLink>
    )
}

     render(){
          return(
          <div>
               <div>
               <header>
                    <h1>TestCase Management Suite</h1>
                    <div>
                         {<this.returnTestcase/>}
                    </div>
                    <div>
                         {<this.returntestsessions/>}
                    </div>
                    <div>
                         {<this.returnJiraissues/>}
                    </div>
                    <div>
                         {<this.returntestplan/>}
                    </div>
                    <div>
                         {<this.returnAutomationProgress/>}
                    </div>
                    <div>
                         {<this.returnUploadStatus/>}
                    </div>
                    <div>
                      {<this.returnReleaseBoard/>}
                    </div>
                    <div>
                      {<this.returnHistoryCollection/>}
                    </div>
                    <div>
                      {<this.returndbHealthCheck/>}
                    </div>
                 <div>
                   {<this.returnTrackingControl/>}
                 </div>
                 <div>
                      {<this.returnExecutionSCreen/>}
                    </div>
                   <div>
                       {<this.returnJiraIssues/>}
                   </div>
                   <div>
                       {<this.returnAutomationMetric/>}
                   </div>
               </header>
               </div>
          </div>
          )
     }
}
export default HomePage;
