import React, { useMemo, useCallback } from 'react';

import { connect } from 'react-redux';
import _noop from 'lodash/noop';

import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';

import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';
import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';

// import ACTION_TYPES from './constants/testSessionList.actionTypes';
import ACTION_HANDLERS from './helpers/viewTestPlanList.actionHandlers';
import INITIAL_STATE from './constants/viewTestPlanList.initialState';
import { makeTableProps, getSubHeaderProps } from './helpers/viewTestPlanList.general';
import { columns } from './constants/viewTestPlanList.columns';
import { getTcmsBootstrap } from '../../../../../../store/selectors/tcms.selectors';

const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;

function ViewTestPlanList(props) {
  // console.log(props);
  const {
    tabId,
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    contentHeight,
    tcmsBootStrap,
    pageSize,
  } = props;


  const TABLE_MANAGER_PROPS = {
    showSearch: false,
    showFilter: true,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
  };

//   const handleBulkAction = useCallback(
//     (action) => {
//       onAction({
//         type: ACTION_TYPES.ROW_BULK_ACTION,
//         payload: {
//           action,
//         },
//       });
//     },
//     [onAction, selection]
//   );


//   const filterProps = useMemo(() => createFilterProps({ selectedFilters, tcmsBootStrap }), [selectedFilters, tcmsBootStrap]);
//   const subHeaderProps = useMemo(() => getSubHeaderProps(handleBulkAction, selection), [onAction, selection]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isLoading,
      totalCount,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails]
  );

  return (
    <PageComponent.Body style={{ height: contentHeight }}>
      <TableManager
        columns={columns}
        data={data}
        tableManagerProps={TABLE_MANAGER_PROPS}
        tableComponent={CheckBoxTable}
        onAction={onAction}
        // filterProps={filterProps}
        selection={selection}
        tableProps={getTableProps}
        // subHeaderProps={subHeaderProps}
        // pageSize={pageSize}
      />
    </PageComponent.Body>
  );
}

const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 0, hasPageFooter: 0 })
)(ViewTestPlanList);
