import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import useFetchData from '@tekion/tekion-base/customHooks/useFetchData';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import Heading from 'tcomponents/atoms/Heading';
import Loader from 'tcomponents/molecules/loader';
import Button from 'tcomponents/atoms/Button';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { productOptionsProps, projectOptionsProps} from './helpers/automationMetric.options';

import { getAnalyticsProductData } from '../../services/automationMetric.services';
import ACTION_HANDLERS from './helpers/automationMetric.actionHandlers';
import styles from './automationMetricPage.module.scss';
import { getConfig } from './helpers/automationMetric.config';
import ColumnChart from '../../../../components/Charts/ColumnChart';
import ACTION_TYPES from './constants/automatinMetric.actionTypes';
import { getProjectListByProductId, getTcmsBootstrap } from '../../../../store/selectors/tcms.selectors';

const AutomationMetricPage =(props) => {
  const {
    onAction,
    contentHeight,
    tcmsBootStrap,
    projectListByProductId,
    selectedProduct,
    selectedProject,
    chartsData,
    chartLoading,
    isProjectDisabled,
    isResetButtonLoading,
  } =props;

  const [ changeData, setChangedData ] =useState([]);
  const chartsProductData = useFetchData(getAnalyticsProductData);
  const [{ data, isLoading }] = chartsProductData;
  let chartData = _get(data, 'data.data');

  useEffect(() => {
    if(chartsData === undefined){
      setChangedData(chartData);
    }else {
      setChangedData(chartsData)
    }

  }, [changeData, chartsData, chartData])

  const onFieldChange = useCallback(
    (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        onAction({
          type: ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            value, selectedProduct
          },
        });
      }
    },
    [onAction]
  );

  const handleResetButtonClick = useCallback(
      (value) => {
          onAction({
            type: ACTION_TYPES.ON_RESET_CLICK,
            payload: {
              value
            },
          });
        },
      [onAction]
  );

  const productOptions = useMemo(() => productOptionsProps({
    tcmsBootStrap,
  }), [tcmsBootStrap]);

  const projectOptions = useMemo(() => projectOptionsProps({
    projectListByProductId, selectedProduct
  }), [projectListByProductId, selectedProduct])

  return (
    <div>
      <PageComponent.Header >
        <div className={`full-width ${styles.headerContainer}`}>
          <Heading size={1}>{__('Automation Metric')}</Heading>
        </div>
      </PageComponent.Header>
      <div className={styles.dropdowns}>
      <SelectInput
          id="Product"
          label={__('Product')}
          options={productOptions}
          onAction={onFieldChange}
          value={selectedProduct}
          placeholder={__('Select')}
          // required
          // validators={[isRequiredRule]}
        />

        <SelectInput
          id="Project"
          label={__('Project')}
          options={projectOptions}
          onAction={onFieldChange}
          value={selectedProject}
          placeholder={__('Select')}
          isDisabled={isProjectDisabled}
          // required
          // validators={[isRequiredRule]}
        />

        <div className={styles.buttonContainer}>
          <Button
              label={__('Reset')}
              view={Button.VIEW.PRIMARY}
              onClick={handleResetButtonClick}
              loading={isResetButtonLoading}
          />
        </div>
      </div>
      <div>
      {
        chartLoading || isLoading ? <center><Loader/></center> : <ColumnChart config={getConfig(changeData,contentHeight)}/>
      }
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
  projectListByProductId: getProjectListByProductId(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions({ isProjectDisabled: true }, ACTION_HANDLERS),
  withSize({ hasPageHeader: 1, hasPageFooter: 1 })
)(AutomationMetricPage);
