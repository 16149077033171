import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import Layout from '../../molecules/Layout/Layout';

const TapApplications = (props) => {
  const { PARTNERS_SITE } = process.env;
  const { children } = props;
  const _renderModules = () => (
    <Layout>
      {children}
    </Layout>
  );
  const _renderErrorComponent = () => <ErrorPage notFound />;
  return (
    <React.Fragment>
      {PARTNERS_SITE === 'true'
        ? _renderErrorComponent()
        : _renderModules()}
    </React.Fragment>
  );
};
TapApplications.propTypes = {
  children: PropTypes.object.isRequired,
};
export default withRouter(TapApplications);
