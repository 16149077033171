import React, { useCallback, useMemo, useState } from 'react';

import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import SelectInputCellRenderer from '@tekion/tekion-components/src/organisms/inputTable/cellRenderers/SelectInputCellRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import classNames from 'classnames';
import styles from '../viewTrackingControlList.module.scss';

// import { ROW_ACTION_TYPES } from './viewTrackingControlList.rowActionTypes';
import ACTION_TYPES from '../../../constants/viewTrackingControl.actionTypes';

const getTrackingEnabledColumn = (column) => {
  const displayValue = _get(column, 'original.isTrackingEnabled') === true ? 'True' : 'False';
  return (<div className={displayValue === 'True' ? styles.true : styles.false}>{displayValue}</div>);
};
// const releaseTag = [{ label: 'release:09.09.2023', value: 'release:09.09.2023' }, { label: 'release:07.07.2023', value: 'release:07.07.2023' }];

// function getReleaseVersionOptions(releaseColumn) {
//   return {
//     releaseColumn,
//     menuPosition: 'fixed',
//     menuPlacement: 'auto',
//     menuPortalTarget: null,
//     styles: {
//       menuPortal: base => ({ ...base, zIndex: 9999 }),
//     },
//     options: releaseTag,
//     placeholder: __('Select Release Version'),
//   };
// }
//
// const handleChange = onAction => (params) => {
//   onAction({
//     type: ACTION_TYPES.HANDLE_RELEASE_VERSION_CHANGE,
//     payload: { params },
//   });
// };


// const getReleaseColumn = (releaseColumn, onAction) => {
//   // const { value } = releaseColumn;
//   const value = _get(releaseColumn, 'original.releaseVersion', EMPTY_STRING);
//   console.log(releaseColumn)
//   console.log(value)
//
//   return (
//     <div>
//       <SelectInputCellRenderer
//         {...getReleaseVersionOptions({ ...releaseColumn })}
//         onChange={handleChange(onAction)}
//         value={[value]}
//       />
//     </div>
//   );
// };


// const PRODUCT = {
//   id: 'product',
//   key: 'product',
//   accessor: 'product',
//   Header: 'Product',
//   Cell: TextRenderer,
//   sortable: true,
// };
const PROJECT = {
  id: 'project',
  key: 'project',
  accessor: 'projectName',
  Header: 'Project',
  Cell: TextRenderer,
  sortable: true,
};
// const MODULE = {
//   id: 'module',
//   key: 'module',
//   accessor: 'module',
//   Header: 'Module',
//   Cell: TextRenderer,
//   sortable: true,
// };
const getReleaseTag = onAction => ({
  id: 'releaseTag',
  key: 'releaseTag',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  sortable: true,
});
const TRACKING_ENABLED = {
  id: 'trackingEnabled',
  key: 'trackingEnabled',
  accessor: 'trackingEnabled',
  Header: 'Tracking Enabled',
  Cell: column => getTrackingEnabledColumn(column),
  sortable: true,
};

// export const columns = [PRODUCT, RELEASE_TAG, MODULE, PROJECT,TRACKING_ENABLED];

export const getColumns = onAction => [getReleaseTag(onAction), PROJECT, TRACKING_ENABLED];
// const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
// export default COLUMN_CONFIG_BY_KEY;
