import _size from 'lodash/size';
import { REVERSE_ROLE_TYPE, ROLE_TYPE } from "../../constants/userRole/userRoleMap";
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const roleHash = TokenManager.getItem('role');

export const checkUserRole = () => {

    if (ROLE_TYPE[roleHash] === REVERSE_ROLE_TYPE.GUEST) {
        return true
    } else {
        return false;
    }
};

export const checkUserRoleWithSelection = (selection) => {

    if (ROLE_TYPE[roleHash] === REVERSE_ROLE_TYPE.GUEST) {
        return true
    } else {
        return !_size(selection);
    }
};

export const checkUserRoleForm = () => { 
    if (ROLE_TYPE[roleHash] === REVERSE_ROLE_TYPE.GUEST) {
        return ({ isPrimaryDisabled: true })
    } else {
        return ({ isPrimaryDisabled: false });
    }
};
