// Constants
import { PRIORITY, AUTOMATED, ACTIVE, RELEASE } from './bulkUpdateTestCasesForm.fields';

const FORM_CONFIG = {
  [PRIORITY.id]: PRIORITY,
  [AUTOMATED.id]: AUTOMATED,
  [ACTIVE.id]: ACTIVE,
  [RELEASE.id]: RELEASE,
};

export default FORM_CONFIG;
