import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, RELEASE, TYPE, STATUS, ENV,
} from './createTestsession.fields';

const SECTIONS = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [TEST_SESSION_NAME.id],
      },
      {
        columns: [PRODUCT.id],
      },
      {
        columns: [PROJECT.id],
      },
      {
        columns: [RELEASE.id],
      },
      {
        columns: [TYPE.id, STATUS.id],
      },
      {
        columns: [ENV.id],
      },
    ],
  },
];

export default SECTIONS;
