import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';

export const makeTableProps = ({
  isLoading,
  selection,
  totalCount,
  sortDetails,
  tableData,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  pageSize: tableData.length,
  resultsPerPage: tableData.length,
  totalNumberOfEntries: totalCount,
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
  showPagination: true,
});
