// Services
import Http from '../../../../services/http';
import URL from '../../../../api/urls';
// const BASE_URL = 'tcms-service/u/testcases';

export const addUploadedTestCases = async (payload) => {
  const response = await Http.post('CQA', `${URL.createFeatureUpload}`, payload);
  return response;
};

export const downloadExcelTemplate = async () => {
  const response = await Http.get('CQA', `${URL.createTestCaseTemplatePresignedUrl}`);
  // console.log(response);
  return response;
};
