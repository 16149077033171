import { createSelector } from 'reselect';

const skeleton = state => state.skeleton;
const listingReducer = state => state.CardManagement;
const authenticationReducer = state => state.Authentication;

const getAllCards = () => createSelector(
  listingReducer,
  substate => substate?.allCards
);

const getCard = () => createSelector(
  listingReducer,
  substate => substate?.activeCard
);

const addNewCard = () => createSelector(
  listingReducer,
  substate => substate?.addCard
);

const updateOldCard = () => createSelector(
  listingReducer,
  substate => substate?.updateCard
);

const deleteOldCard = () => createSelector(
  listingReducer,
  substate => substate?.deleteCard
);

const fetchAuthenticationSelector = () => createSelector(
  authenticationReducer,
  substate => substate.notAuthenticated
);

const loading = () => createSelector(
  listingReducer,
  substate => substate?.loading
);

const getWorkSpaceSize = () => createSelector(
  skeleton,
  substate => substate?.workSpaceSize
);

export {
  getAllCards, getCard, loading, fetchAuthenticationSelector, addNewCard, deleteOldCard, updateOldCard, getWorkSpaceSize,
};
