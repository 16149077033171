import TokenManager from "@tekion/tap-components/utils/TokenManager";

export const updateBulkTestSessions = (selection, key) => ({
  id: selection,
  status: key,
});

export const testSessionDTO = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
  const DEFAULT_BODY = {
    type: 'product_id',
    operator: 'IN',
    values: [
      tabId,
    ],
  };
  const objectBody = ({
    filter: [DEFAULT_BODY, ...selectedFilters],
    size: pageSize,
    page: currentPage,
    searchText,
    sort,
  });

  return objectBody;
};

export const stopExecutionDTO = (executionId) =>({
  executionId: executionId[0],
  userName: TokenManager.getItem('email').split('@')[0] || 'cqauser',
  applicationName : 'TCMS_EXECUTOR'
});

// export const filterDTO = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const filterValue = [...selectedFilters];
//   const DEFAULT_BODY = ({
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   });

//   filterValue.push(DEFAULT_BODY);

//   const objectBody = ({
//     filter: filterValue,
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });

//   return objectBody;
// };

// export const paginationDTO = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const DEFAULT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   };

//   const objectBody = ({
//     filter: [...selectedFilters, DEFAULT_BODY],
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });

//   return objectBody;
// };

// export const paginationDTOwithoutFilter = (tabId, searchText, currentPage, pageSize, selectedFilters, sort) => {
//   const DEFAULT_BODY = {
//     type: 'product_id',
//     operator: 'IN',
//     values: [
//       tabId,
//     ],
//   };

//   const objectBody = ({
//     filter: [DEFAULT_BODY],
//     size: pageSize,
//     page: currentPage,
//     searchText,
//     sort,
//   });

//   return objectBody;
// };
