import {
  SERVICE_NAME, PRODUCT, PROJECT, WORKFLOW_SCREEN, ATTACHED_DESIGN, MODULES_AFFECTED, AFFECTED_UPSTREAM_SERVICES,
  AFFECTED_DOWNSTREAM_SERVICES, JIRA_LINKS, INFO, ANY_CHANGE_IN_SCHEMA, ANY_DEALER_PROPERTY_TO_BE_SET,
  ANY_RESTRICTED_API_NEED_TO_SETUP, ANY_PERMISSIONS_ASSOCIATED, AREA_OF_REGRESSION, AUTOMATED,
} from './createTestPlan.fields';


const SECTIONS = [
  {
    rows: [
      {
        columns: [SERVICE_NAME.id],
      },
      {
        columns: [PRODUCT.id, PROJECT.id],
      },
      {
        columns: [WORKFLOW_SCREEN.id],
      },
      {
        columns: [ATTACHED_DESIGN.id],
      },
      {
        columns: [MODULES_AFFECTED.id],
      },
      {
        columns: [JIRA_LINKS.id, INFO.id],
      },
      {
        columns: [AFFECTED_UPSTREAM_SERVICES.id, AFFECTED_DOWNSTREAM_SERVICES.id],
      },
      {
        columns: [ANY_CHANGE_IN_SCHEMA.id],
      },
      {
        columns: [ANY_DEALER_PROPERTY_TO_BE_SET.id],
      },
      {
        columns: [ANY_RESTRICTED_API_NEED_TO_SETUP.id],
      },
      {
        columns: [ANY_PERMISSIONS_ASSOCIATED.id],
      },
      {
        columns: [AREA_OF_REGRESSION.id, AUTOMATED.id],
      },
    ],
  },
];

export default SECTIONS;
