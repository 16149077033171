import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';

// constants
import { EMPTY_ARRAY } from 'tbase/app.constants';

// hooks
import useFetchData from 'tbase/customHooks/useFetchData';

// containers
import withRouteParams from 'tcomponents/connectors/withRouteParams';

// components
import TestCaseForm from '../TestCaseForm';

// helpers
import editTestCaseSubmit from './helpers/editTestCase.onFormSubmit';
import makeInitialFormValues from '../../helpers/testCases.initialValues';
// import getHeaderProps from './helpers/editTestCase.headerProps';

// services
import { getTestCase } from '../../services/testCases.form';

const EditTestCase = (props) => {
  const { history, testCaseId , onAction} = props;
  const [{ isLoading: isTestCaseLoading, data: testCaseResponse }] = useFetchData(getTestCase, [testCaseId]);
  const initialFormValues = useMemo(() => makeInitialFormValues(testCaseResponse), [testCaseResponse]);
  console.log(initialFormValues)

  const handleEditTestCaseSubmit = useCallback(
    formValues => editTestCaseSubmit({ formValues, testCaseId, history }),
    [testCaseId]
  );
  return (
    <>
    <TestCaseForm
      testCaseId={testCaseId}
      history={history}
      isTestCaseLoading={isTestCaseLoading}
      isEdit
      // headerProps={headerProps}
      onSubmit={handleEditTestCaseSubmit}
      formValues={initialFormValues}
      onAction={onAction}
    />  
  </>
  );
};

EditTestCase.propTypes = {
  testCaseId: PropTypes.string,
  history: PropTypes.object.isRequired,
};

EditTestCase.defaultProps = {
  testCaseId: undefined,
};

export default withRouteParams(EditTestCase);
