/* eslint-disable rulesdir/no-inline-functions */
/* eslint-disable import/order */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';
import _get from 'lodash/get';

// Constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
// import { TEST_RESULT_ASSET_TYPE } from '../../constants/testResultList.assetType';
// import TEST_RESULT_LIST_ACTION_TYPES from '../../constants/testResultsList.actiontypes';

// Components
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import NotesPopover from '../../molecules/notesPopover';

// Readers
// import testResultReader from '../../../../readers/TestResult';

function NotesCellRenderer(props) {
  const { data: testResult, onAction } = props;
  const assetId = _get(testResult, 'id');
  const titleLabel = _get(testResult, 'testCaseId');
  const notes = _get(testResult, 'comments');
  const TEST_RESULT_ASSET_TYPE = 'testResult';

  return (
    <NotesPopover
      assetId={assetId}
      assetType={TEST_RESULT_ASSET_TYPE}
      titleLabel={titleLabel}
      notes={notes}
    />

  );
}

NotesCellRenderer.propTypes = {
  data: PropTypes.object,
  onAction: PropTypes.func,
};

NotesCellRenderer.defaultProps = {
  data: EMPTY_OBJECT,
  onAction: _noop,
};

export default makeCellRenderer(NotesCellRenderer);
