import _constant from 'lodash/constant';
import _size from 'lodash/size';
import _map from 'lodash/map';

import Dropdown from '@tekion/tekion-components/src/molecules/DropDown';
import Button from '@tekion/tekion-components/src/atoms/Button';
import React from 'react';
import Menu from '@tekion/tekion-components/src/molecules/Menu';

// import ROUTE_MODES from 'tbusiness/appServices/qa/constants/qa.routeModes';
import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import DownloadFileMenu, { DOWNLOAD_FORMATS } from 'tcomponents/molecules/downloadFileMenu';
import { checkUserRoleWithSelection } from '../../../../../../../helpers/UserRole/userRole.common';
import ACTION_TYPES from '../constants/testCasesList.actionTypes';
import {
  ROW_ACTION_TYPESS_SUPER_ADMIN_AND_ADMIN,
  ROW_ACTION_TYPESS_OTHES,
  BULK_ROW_ACTION_TYPES_SUPER_ADMIN_AND_ADMIN,
  BULK_ACTION_TYPES,
  ROW_ACTION_TYPESS_GUEST,
} from '../constants/testCasesList.rowActions';
import { ROLE_TYPE, REVERSE_ROLE_TYPE } from '../../../../../../../constants/userRole/userRoleMap';
import tabbedTestCaseListEvent from '../../../helpers/tabbedTestCaseList.event';
import EVENT_TYPES from '../../../constant/tabbedTestCaseList.eventTypes';
import styles from '../../../tabbedTestCases.module.scss';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

export const DOWNLOAD_OPTIONS = [{ downloadFormat: DOWNLOAD_FORMATS.EXCEL_FORMAT }];

const handleRowAction = onAction => (action, payload) => {
  onAction({
    type: ACTION_TYPES.ROW_ACTION,
    payload: {
      value: payload,
      action,
    },
  });
};

const getBulkActionsMenu = (handleBulkAction, selection) => {
  let BULK_ACTIONS_BASED_ON_PERMISSIONS;
  const rolehash = TokenManager.getItem('role') || undefined;
  if (ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.SUPER_ADMIN || ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.ADMIN || rolehash === undefined) {
    BULK_ACTIONS_BASED_ON_PERMISSIONS = BULK_ROW_ACTION_TYPES_SUPER_ADMIN_AND_ADMIN;
  } else {
    BULK_ACTIONS_BASED_ON_PERMISSIONS = BULK_ACTION_TYPES;
  }
  return (
      <Menu onClick={handleBulkAction(selection)}>
        {_map(BULK_ACTIONS_BASED_ON_PERMISSIONS, ({ name, id }) => (
            <Menu.Item key={id}>{name}</Menu.Item>
        ))}
      </Menu>
  );
}

export const BULK_ACTIONS = (handleBulkAction, selection) => ({
  subHeaderRightActions: [
    {
      renderer: DownloadFileMenu,
      renderOptions: {
        downloadOptions: DOWNLOAD_OPTIONS,
        className: 'm-l-8',
        disabled: !_size(selection),
      },
      action: ACTION_TYPES.DOWNLOAD_EXCEL,
    },
    {
      renderer: Dropdown,
      renderOptions: {
        overlay: getBulkActionsMenu(handleBulkAction, selection),
        trigger: ['click'],
        disabled: checkUserRoleWithSelection(selection),
        children: (
          <Button className={styles.bulkActionContainer} view={Button.VIEW.SECONDARY}>
            {__('Bulk Actions')}
          </Button>
        ),
      },
    },
  ],
});

// export const BULK_ACTION_TYPES_OTHERS = (handleBulkAction, selection) => ({
//   subHeaderRightActions: [
//     {
//       renderer: DownloadFileMenu,
//       renderOptions: {
//         downloadOptions: DOWNLOAD_OPTIONS,
//         className: 'm-l-8',
//         disabled: !_size(selection),
//       },
//       action: ACTION_TYPES.DOWNLOAD_EXCEL,
//     },
//     {
//       renderer: Dropdown,
//       renderOptions: {
//         overlay: getBulkActionsMenu(handleBulkAction, selection),
//         trigger: ['click'],
//         disabled: checkUserRoleWithSelection(selection),
//         children: (
//             <Button className={styles.bulkActionContainer} view={Button.VIEW.SECONDARY}>
//               {__('Bulk Actions')}
//             </Button>
//         ),
//       },
//     },
//   ],
// });

export const getSubHeaderProps = (handleBulkAction, selection) =>  (BULK_ACTIONS(handleBulkAction, selection));


const rowActions = () => {
  const rolehash = TokenManager.getItem('role') || undefined;
  if (ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.SUPER_ADMIN || ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.ADMIN || rolehash === undefined) {
    return (ROW_ACTION_TYPESS_SUPER_ADMIN_AND_ADMIN);
  }
  if(ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.GUEST){
    return (ROW_ACTION_TYPESS_GUEST)
  }

  return (ROW_ACTION_TYPESS_OTHES);
};

export const makeTableProps = ({
  isLoading,
  selection,
  columnConfigurator,
  totalCount,
  onAction,
  sortDetails,
  pageSize,
  currentPage,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  showActions: true,
  getRowActions: rowActions,
  onRowActionClick: handleRowAction(onAction),
  columnConfigurator,
  totalNumberOfEntries: totalCount,
  getTdProps: _constant({ onAction }),
  checkboxHeaderClassName: 'm-l-8',
  sortDetails,
  shouldAdaptSortDetails: false,
  showPagination: true,
  type: TABLE_TYPES.FIXED_COLUMN,
  pageSize,
  currentPage,
});

const refetchTableItems = onAction => () => onAction({
  type: ACTION_TYPES.TABLE_ITEMS_FETCH,
});

export const addEventListeners = (onAction) => {
  console.log('eventListener');
  tabbedTestCaseListEvent.on(EVENT_TYPES.REFETCH_TEST_CASE_LIST, refetchTableItems(onAction));
};

export const removeEventListeners = (onAction) => {
  tabbedTestCaseListEvent.removeListener(EVENT_TYPES.REFETCH_TEST_CASE_LIST, refetchTableItems(onAction));
};
