import standardFieldOptionMapper from '@tekion/tekion-base/utils/optionMappers/standardFieldMapper';

const SMOKE = { label: 'SMOKE', value: __('Smoke') };
const SANITY = { label: 'SANITY', value: __('Sanity') };
const REGRESSION = { label: 'REGRESSION', value: __('Regression') };
const ADHOC = { label: 'AD_HOC', value: __('Adhoc') };

const TYPE_OPTIONS = [SMOKE, SANITY, REGRESSION, ADHOC];

// const TYPE_OPTIONS = standardFieldOptionMapper(undefined, TYPES);

export default TYPE_OPTIONS;
