import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
// import ACTION_TYPES from '../constants/viewTestPlan.actionTypes';


const handleTableItemsFetch = () => {

};

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
};

export default ACTION_HANDLERS;
