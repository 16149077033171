import React, { useCallback, useMemo, useState } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _split from 'lodash/split';
import _size from 'lodash/size';

import { withRouter } from 'react-router-dom';

import colors from '@tekion/tekion-styles-next/scss/variables.scss';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
// import Page from 'tcomponents/molecules/pageComponent';
import withActions from 'tcomponents/connectors/withActions';
import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Button from 'tcomponents/atoms/Button';
import Heading from 'tcomponents/atoms/Heading';
import Loader from 'tcomponents/molecules/loader';
import useFetchData from 'tbase/customHooks/useFetchData';
import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import TABBED_TEST_CASE_LIST_ACTION_TYPES from './constant/tabbedTestCases.actionTypes';
import ACTION_HANDLERS from './helpers/tabbedTestCases.actionHandler';
import styles from './tabbedTestCases.module.scss';

import getTabs from '../../../../helpers/tabConfig';
import { createTestSession, getTabsApi, postAddTestCases } from '../../../testSessions/services/testSessions.services';
import TestCasesList from './organisms/TestCasesList/TestCasesList';
import UploadTestCasesModal from '../../../../organisms/uploadTestCases';
import ExecuteTestCasesModal from '../../../../organisms/executeTestCases';
import { executeTestSuite } from '../../../../organisms/executeTestCases/executeTestCasesForm/services/executeTestCasesForm';
import { checkUserRole, checkUserRoleWithSelection } from '../../../../helpers/UserRole/userRole.common';

function TabbedTestCases(props) {
  const {
    onAction,
    selectedTabId,
    tabId,
    isUploadModalVisible,
    isUploadModalLoading,
    isLoading,
    location,
    history,
  } = props;


  const [showExecuteTestSuiteModal, setShowExecuteTestSuiteModal] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState([]);
  const [selectedReleaseVersion, setSelectedReleaseVersion] = useState([]);
  const [selection, setSelection] = useState([]);
  // const [selectionButtonVisible, setSelectionButtonVisible] = useState(false);
  const [isExecutionModalLoading, setIsExecutionModalLoading] = useState(false);
  const [isAutomated, setIsAutomated] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleOnTabChange = useCallback(
    (newSelectedTab) => {
      onAction({
        type: TABBED_TEST_CASE_LIST_ACTION_TYPES.ON_TAB_CHANGE,
        payload: { value: newSelectedTab },
      });
    },
    [onAction]
  );

  const handleOpenUploadTestCaseModal = useCallback(() => {
    onAction({
      type: TABBED_TEST_CASE_LIST_ACTION_TYPES.OPEN_UPLOAD_TEST_CASE_MODAL,
    });
  }, [onAction]);

  const onCancel = useCallback(() => {
    onAction({
      type: TABBED_TEST_CASE_LIST_ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_CANCEL,
    });
  }, [onAction]);

  const onSubmit = useCallback(
    (mediaId, formValues, mediaList) => {
      onAction({
        type:
          TABBED_TEST_CASE_LIST_ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_SUBMIT,
        payload: {
          mediaId,
          formValues,
          mediaList,
        },
      });
    },
    [onAction]
  );

  const onExecutionModalCancel = useCallback(() => {
    setShowExecuteTestSuiteModal(false);
    setSelectedDealer([]);
    setSelectedEnvironment([]);
    setSelectedReleaseVersion([]);
    setIsAutomated([]);
    setIsChecked(false);
  }, [onAction]);

  const [{ isLoading: isTestCaseListLoading, data }] = useFetchData(getTabsApi);
  const tabsData = _get(data, 'data.data');
  const tabs = useMemo(() => getTabs(TestCasesList, tabsData, setSelection, setIsAutomated), [tabsData]);


  const handleCreateTestCase = useCallback(() => {
    onAction({
      type: TABBED_TEST_CASE_LIST_ACTION_TYPES.REDIRECT_TO_CREATE_TEST_CASE,
    });
  }, [onAction]);

  const triggerTestSuiteExecution = async () => {
    const localData = JSON.parse(TokenManager.getItem('selections'));
    // const tagsData = JSON.parse(localStorage.getItem('tags'));
    const moduleId = TokenManager.getItem('selectedModule');
    const dealerName = _split(selectedDealer?.[0], '_')[0];
    const dealerId = _split(selectedDealer?.[0], '_')[1];
    const testSuiteData = {
      data: localData.map(x => ({
        // tags: tagsData[x.testCaseId],
        testCaseId: x.testCaseId,
        id: x.id,
      })),
      environment: selectedEnvironment?.[0],
      dealerName,
      dealerId,
      releaseVersion: selectedReleaseVersion?.[0],
      projectId: _get(localData, '0.projectId', EMPTY_STRING),
      productId: _get(localData, '0.productId', EMPTY_STRING),
      moduleName: _get(localData, '0.moduleName', EMPTY_STRING),
      runType: 'FRESH',
    };

    if (localData) await executeTestSuite(testSuiteData);
  };

  const handleTestSuiteExecutionOnSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExecutionModalLoading(true);
    await triggerTestSuiteExecution().then(() => {
      toaster(TOASTER_TYPE.SUCCESS, __('TestCases executed Successfully'));
      history.push('/tcmsuiV2/testsession/list');
    }).catch(() => {
      toaster(TOASTER_TYPE.FAILED, __('TestCases did not execute'));
    });
    setIsExecutionModalLoading(false);
    setSelectedEnvironment([]);
    setSelectedDealer([]);
    setSelectedReleaseVersion([]);
    setShowExecuteTestSuiteModal(false);
  };

  const handleTestSuiteExecution = useCallback(() => {
    setShowExecuteTestSuiteModal(true);
  }, [onAction]);

  const tabBarStyle = {
    background: colors.glitter,
    padding: '0 2.4rem',
  };
  if (isTestCaseListLoading) {
    return <Loader />;
  }

  const selectedTabIdFromHistory = _split(_get(history, ['location','pathname'], EMPTY_STRING),'/')[4];
  const getActiveKey = () => selectedTabIdFromHistory === EMPTY_STRING ? selectedTabId : selectedTabIdFromHistory;

  return (
    <div>
      <PageComponent.Header>
        <div className={`full-width ${styles.tabbedTestCaseListHeader}`}>
          <Heading>{__('Test Cases')}</Heading>
          <div>
            <Button
              label={__('Execute')}
              view={Button.VIEW.PRIMARY}
              className="m-r-16"
              onClick={handleTestSuiteExecution}
              disabled={checkUserRoleWithSelection(selection)}
            />
            <Button
              label={__('Upload Test Cases')}
              view={Button.VIEW.PRIMARY}
              className="m-r-16"
              onClick={handleOpenUploadTestCaseModal}
              disabled={checkUserRole()}
            />
            <Button
              label={__('Create Test Case')}
              view={Button.VIEW.PRIMARY}
              onClick={handleCreateTestCase}
              disabled={checkUserRole()}
            />
          </div>
        </div>
      </PageComponent.Header>
      <TabbedSections
        onAction={onAction}
        className="full-width"
        onTabChange={handleOnTabChange}
        activeKey={getActiveKey()}
        // projectList={projectListByProductId[selectedTabId]}
        tabBarStyle={tabBarStyle}
        tabs={tabs}
        selectedTabId={selectedTabId}
        destroyInactiveTabPane
      />
      <UploadTestCasesModal
        isVisible={isUploadModalVisible}
        isUploading={isUploadModalLoading}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onAction={onAction}
      />
      <ExecuteTestCasesModal
        showExecuteTestSuiteModal={showExecuteTestSuiteModal}
        onSubmit={handleTestSuiteExecutionOnSubmit}
        onCancel={onExecutionModalCancel}
        isExecutionModalLoading={isExecutionModalLoading}
        selectedDealer={selectedDealer}
        setSelectedDealer={setSelectedDealer}
        selectedEnvironment={selectedEnvironment}
        setSelectedEnvironment={setSelectedEnvironment}
        selectedReleaseVersion={selectedReleaseVersion}
        setSelectedReleaseVersion={setSelectedReleaseVersion}
        isAutomated={isAutomated}
        setIsAutomated={setIsAutomated}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    </div>
  );
}

export default compose(
  withRouter,
  withActions({}, ACTION_HANDLERS)
)(TabbedTestCases);
