import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import {getDashboardStatsByReleaseVersion, getDealerInfoByENV} from '../services/automationProgress.api';
import ACTION_TYPES from '../constants/automationProgress.actionTypes';
import { createDashBoardDTO } from '../constants/automationProgress.request';


const handleFieldChange = async ({ params, getState, setState }) => {
  const id = _get(params, 'value.payload.id', EMPTY_STRING);
  const { selectedEnvironment, selectedProduct, selectedDealer, selectedReleaseVersion } = getState();
  const selectedValue = _get(params, 'value.payload.value', EMPTY_ARRAY);
  if (id === 'Product') {
    setState({
      selectedProduct: selectedValue,
      selectedEnvironment: [],
      selectedDealer: [],
      selectedReleaseVersion: []
    });
  } else if (id === 'Environment') {
    setState({
      selectedEnvironment: selectedValue,
      selectedDealer: [],
      selectedReleaseVersion: [],
      isDealerDropdownLoading: true,
    });
    const dealerDataByEnv = await getDealerInfoByENV(selectedValue);
    const dealerData = _get(dealerDataByEnv, 'data.data', EMPTY_ARRAY);
    setState({
      dealerData,
      isDealerDropdownLoading: false,
    })
  } else if (id === 'ReleaseVersion') {
    setState({
      selectedReleaseVersion: selectedValue,
      isLoading: true,
    });
    const dashBoardDTO = createDashBoardDTO(selectedProduct, selectedEnvironment, selectedValue, selectedDealer);
    const dashboardTableStats = await getDashboardStatsByReleaseVersion(dashBoardDTO);
    setState({
      dashboardTableStats,
      isLoading: false,
    });
  } else if (id === 'DealerId') {
    setState({
      selectedDealer: selectedValue,
    })
  } else {
    setState({
      selectedTrack: selectedValue,
    });
  }
};

export const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
};
