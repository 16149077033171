import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import _get from 'lodash/get';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import Icon, { SIZES } from 'tcomponents/atoms/FontIcon';

import {
  Layout, Menu, Dropdown, Button,
} from 'antd';

import ButtonPopover from "../../components/ButtonPopover";
import { ACTION_TYPES } from './constants/navigationBar.actionTypes';
import { handleNavigationActions } from './helpers/navigationBar.actionHandlers';
import './navigationBar.css';
import TekionLogo from '../../asset/login.png';
import MenuIcon from '../../asset/Menu_icon.png';
import Styles from './navigationBar.module.scss';

function NavigationBar(props) {
  const { history, match, location } = props;
  const [loadComponent, setloadComponent] = useState(false);
  const [render, setRender] = useState();
  const [userName, setuserName] = useState();

  const { Header } = Layout;

  // const getUserName = () => {
  //   const oktaTokenStorage = _get(localStorage, 'okta-token-storage');
  //   const objectOktaStorageToken = JSON.parse(oktaTokenStorage);

  //   const idToken = _get(objectOktaStorageToken, 'idToken');
  //   console.log(idToken);

  //   const claims = _get(idToken, 'claims');
  //   // console.log(claims);
  //   const oktaUserName = _get(claims, 'email');
  //   console.log(oktaUserName);

  //   const displayUserName = oktaUserName === undefined ? 'dev_cqa@tekion.com' : oktaUserName;
  //   setuserName(displayUserName);
  //   console.log(userName);
  // };


  useEffect(() => {
    const accessToken = TokenManager.accessTokenKey;
    if (history.location.pathname.includes('login') === true) {
      setRender(true);
    } else {
      setRender(false);


      if (window.location.hostname !== 'localhost') {
        if (accessToken) {
          setloadComponent(true);
        } else {
          let redirectUrl = '/login';
          if (location && location.pathname) {
            redirectUrl = `/login?url=${location.pathname}`;
          }
          TokenManager.clearAllCookies(redirectUrl);
          console.log('else 2');
        }
      }
      // getUserName();
    }

    // if (accessToken || history.location.pathname.includes('login') === true) {
    //   setloadComponent(true);
    // } else {
    //   let redirectUrl = '/login';
    //   if (location && location.pathname) {
    //     redirectUrl = `/login?url=${location.pathname}`;
    //   }
    //   clearAllCookies(redirectUrl);
    // }
    // console.log(accessToken);
  });

  const handleMenuClick = () => (
    console.log('navbar')
  );

  const handleOnLogOutClick = () => {
    // const { history } = props;
    try {
      localStorage.clear();
      TokenManager.clearAllCookies();
      // history.push('/cqaV2/login');
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleMenuItems = (key) => {
    handleNavigationActions(history, key);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ visibility: render ? 'hidden' : 'visible' }}>
      <Layout>
        <Header style={{ backgroundColor: '#404757' }}>
          <div className="logo">
            {/* <a onClick={handleMenuClick}>
              <img
                style={{ height: '50px', width: '50px', marginLeft: '0px' }}
                src={MenuIcon}
                alt="Tekion-CQA-Platform"
              >
              </img>
            </a> */}
            <img style={{ marginRight: '10px' }} src={TekionLogo} alt="Tekion-CQA-Platform"></img>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            // defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px', float: 'right' }}
          >
            <Menu.Item key={ACTION_TYPES.HOME_BUTTON}>
              <a href="/cqaV2/app/home">
                <Icon size={SIZES.XL} className={Styles.homeIcon}>
              icon-home1
                </Icon>
              </a>
            </Menu.Item>
            <Menu.Item key={ACTION_TYPES.TESTCASES} onClick={handleMenuItems} className={Styles.menuIcons}>TestCase</Menu.Item>
            <Menu.Item key={ACTION_TYPES.TESTSESSION} onClick={handleMenuItems} className={Styles.menuIcons}>TestSession</Menu.Item>
            <Menu.Item key={ACTION_TYPES.AUTOMATION_PROGRESS} onClick={handleMenuItems} className={Styles.menuIcons}>Execution Progress</Menu.Item>
            {/* <Menu.Item key={ACTION_TYPES.UPLOAD_STATUS} onClick={handleMenuItems} className={Styles.menuIcons}>Upload Status</Menu.Item> */}
            <Menu.Item key={ACTION_TYPES.RELEASE_BOARD} onClick={handleMenuItems} className={Styles.menuIcons}>Release Board</Menu.Item>
            <Menu.Item key={ACTION_TYPES.HISTORY_COLLECTION} onClick={handleMenuItems} className={Styles.menuIcons}>History Collection</Menu.Item>
            <Menu.Item key={ACTION_TYPES.JIRA_ISSUES} onClick={handleMenuItems} className={Styles.menuIcons}>Jira Issues</Menu.Item>
            {/* <Dropdown overlay={menu} style={{ backgroundColor: 'white' }}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> */}
            <ButtonPopover />
            <Button
              // style={{ border: '5px solid #2dd4bf' }}
              onClick={handleOnLogOutClick}
            >Logout
            </Button>
            {/* </a>
            </Dropdown> */}
          </Menu>
        </Header>
      </Layout>
    </div>
  );
}

export default withRouter(NavigationBar);
