import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import { isRequiredRule } from 'tbase/utils/formValidators';
import TextAreaField from 'tcomponents/organisms/FormBuilder/fieldRenderers/textArea';
// import TYPE_OPTIONS from '../../../../../constants/typeOptions';
// import STATUS_OPTIONS from '../../../../../constants/statusOptions';
// import ENV_OPTIONS from '../../../../../constants/envOptions';
import RichTextEditorFieldRenderer from '../../../../molecules/FieldRenderer/RichTextEditorFieldRenderer';
import PRIORITY_OPTIONS from '../../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.priority';
import AUTOMATED_OPTIONS from '../../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.automated';
import STATUS_OPTIONS from '../../../../pages/testCases/organisms/TabbedTestCases/organisms/TestCasesList/constants/testCasesList.automated';
import TYPE_OPTIONS from "./typeOptions";

export const TEST_CASE_NAME = {
  id: 'testcaseName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('TestCase Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const AUTHOR = {
  id: 'author',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Author'),
    // required: true,
    // validators: [isRequiredRule],
    disabled: true,
  },
};
export const PRIORITY = {
  id: 'priority',
  renderer: SelectInput,
  renderOptions: {
    label: __('Priority'),
    required: true,
    validators: [isRequiredRule],
    options: PRIORITY_OPTIONS,
  },
};
export const COMPONENT = {
  id: 'component',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Component'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const JIRA = {
  id: 'jira',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Jira'),
    // required: true,
    // validators: [isRequiredRule],
  },
};

export const BRANCH = {
  id: 'branch',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Branch'),
  },
};

export const DESCRIPTION = {
  id: 'description',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Description'),
    required: true,
    validators: [isRequiredRule],
  },
};
export const PRECONDITIONS = {
  id: 'precondition',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Precondition'),
  },
};


export const EXIT_CRITERIA = {
  id: 'exitCriteria',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Exit Creteria'),
  },
};

export const RELEASE = {
  id: 'release',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('release'),
    // required: true,
    // validators: [isRequiredRule],
  },
};

export const TYPE = {
  id: 'type',
  renderer: SelectInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const ACTIVE = {
  id: 'isActive',
  renderer: SelectInput,
  renderOptions: {
    label: __('Active'),
    options: STATUS_OPTIONS,
  },
};
export const AUTOMATED = {
  id: 'isAutomated',
  renderer: SelectInput,
  renderOptions: {
    label: __('Automated'),
    options: AUTOMATED_OPTIONS,
  },
};

export const REGRESSION = {
  id: 'regression',
  renderer: SelectInput,
  renderOptions: {
    label: __('Regression'),
    // required: true,
    options: AUTOMATED_OPTIONS,
  },
};

export const MISC = {
  id: 'misc',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Misc'),
  },

};
export const STEPS = {
  id: 'steps',
  renderer: RichTextEditorFieldRenderer,
  renderOptions: {
    label: __('Steps'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PRODUCT = {
  id: 'productId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'projectId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Project'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const FEATURE = {
  id: 'featureName',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Module'),
    showSearch: true,
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};
export const SUBFEATURE = {
  id: 'subfeatureName',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('SubModule'),
    showSearch: true,
    placeholder: __('Select'),
  },
};

export const TCCATEGORY = {
  id: 'tcCategory',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Tc Category'),
    placeholder: __('Select'),
  },
};

export const UPSTREAMCONSUMPTION = {
  id: 'upstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Upstream Consumption'),
    placeholder: __('Select'),
  },
};

export const DOWNSTREAMCONSUMPTION = {
  id: 'downstreamConsumption',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Downstream Consumption'),
    placeholder: __('Select'),
  },
};

export const HORIZONTALIMPLEMENTATION = {
  id: 'horizontalImplementation',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Horizontal Implementation'),
    placeholder: __('Select'),
  },
};
