export const updateStatusTestResult = (action, testCaseId) => ({
  id: testCaseId,
  status: action,
});

export const updateBulkStatusTestResult = (selection, testSessionId, key) => ({
  testSessionId: testSessionId,
  id: selection,
  status: key,
});

export const getTestResultsDTO = (testSessionId, currentPage, pageSize, selectedFilters) => {
  const DEFAULT_BODY = {
    type: 'test_session_id',
    operator: 'IN',
    values: [
      testSessionId,
    ],
  };

  const objectBody = ({
    filter: [DEFAULT_BODY, ...selectedFilters],
    size: pageSize,
    page: currentPage,
  });

  return objectBody;
};

export const filterDTO = (value, testSessionId, currentPage, pageSize) => {
  const filterValue = [...value];
  const DEFAULT_BODY = ({
    type: 'test_session_id',
    operator: 'IN',
    values: [
      testSessionId,
    ],
  });

  filterValue.push(DEFAULT_BODY);

  const objectBody = ({
    filter: filterValue,
    size: pageSize,
    page: currentPage,
  });

  return objectBody;
};

export const uploadMediaDTO = (mediaList) => {
  const formData = new FormData();
  formData.append('media', mediaList[0].file);
  return formData;
};

export const paginationDTO = (page, resultsPerPage, tabId, selectedFilters) => {
  const DEFAULT_BODY = {
    type: 'test_session_id',
    operator: 'IN',
    values: [
      tabId,
    ],
  };

  const objectBody = ({
    filter: [...selectedFilters, DEFAULT_BODY],
    size: resultsPerPage,
    page,
  });

  return objectBody;
};

export const paginationDTOwithoutFilter = (page, resultsPerPage, tabId) => {
  const DEFAULT_BODY = {
    type: 'test_session_id',
    operator: 'IN',
    values: [
      tabId,
    ],
  };

  const objectBody = ({
    filter: [DEFAULT_BODY],
    size: resultsPerPage,
    page,
  });

  return objectBody;
};
