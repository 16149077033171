export const clearMigratedTokens = () => {
    const keys = [
      'access_token',
      'uname',
      'rememberMe',
      'email',
      'roleId',
      'idToken',
      'access_token_expire_date',
    ];
    keys.forEach(key => localStorage.removeItem(key));
  };