import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { compose } from 'recompose';

import {
  withRouter,
} from 'react-router-dom';

import withActions from 'tcomponents/connectors/withActions';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import { isRequiredRule } from 'tbase/utils/formValidators';
import useFetchData from 'tbase/customHooks/useFetchData';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import StatsTable from './organisms/StatsTable';
import { ACTION_HANDLERS } from './helpers/executionScreen.actionHandlers';
import ACTION_TYPES from './constants/executionScreen.actionTypes';
import { getExecutionScreenData } from './services/executionScreen.api';

import styles from './executionScreen.module.scss';

const ExecutionScreen = (props) => {
  const {
    onAction, selectedProduct, selectedEnvironment, selectedReleaseVersion, isLoading, executionScreenResponse, history, executionId,
  } = props;

  const onFieldChange = useCallback(
    (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        onAction({
          type: ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            value, selectedProduct, selectedEnvironment, selectedReleaseVersion,
          },
        });
      }
    },
    [onAction]
  );

  return (
    <>
      {
        <StatsTable data={executionScreenResponse} isLoading={isLoading} onAction={onAction} executionId={executionId} />
      }
    </>
  );
};

ExecutionScreen.propTypes = {
  isResetClicked: PropTypes.bool,
  match: PropTypes.object.isRequired,
};

ExecutionScreen.defaultProps = {
  isResetClicked: true,
};


export default compose(withRouter, withActions({}, ACTION_HANDLERS))(ExecutionScreen);
