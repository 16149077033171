import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, RELEASE, TYPE, STATUS, ENV,
} from './testsessionForm.fields';

const SECTIONS = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [TEST_SESSION_NAME.id],
      },
      {
        columns: [PRODUCT.id],
      },
      {
        columns: [PROJECT.id],
      },
      {
        columns: [RELEASE.id],
      },
      {
        columns: [TYPE.id, STATUS.id],
      },
      {
        columns: [ENV.id],
      },
    ],
  },
];

const LIST_SECTION = [
  {
    className: 'm-t-20',
    rows: [
      {
        columns: [TYPE.id, STATUS.id],
      },
      {
        columns: [ENV.id],
      },
    ],
  }
]

export const getSections = (isFromTestSessionList) => {
  if(isFromTestSessionList) {
    return LIST_SECTION;
  }
  return SECTIONS;
}
