import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
// import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
// import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
// import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';


// const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const NAME = {
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Name',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const TOTAL_TEST_CASES = {
  id: 'totalTestcases',
  key: 'totalTestcases',
  accessor: 'totalTestcases',
  Header: 'Total Test Cases',
  Cell: TextRenderer,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const TOTAL_TEST_CASES_FAILED = {
  id: 'totalTestcasesFailed',
  key: 'totalTestcasesFailed',
  accessor: 'totalTestcasesFailed',
  Header: 'Total Test Cases Failed',
  Cell: TextRenderer,
  sortable: true,
};
const TOTAL_TEST_CASES_PASSED = {
  id: 'totalTestcasesPassed',
  key: 'totalTestcasesPassed',
  accessor: 'totalTestcasesPassed',
  Header: 'Total Test Cases Passed',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const TOTAL_TEST_CASES_PENDING = {
  id: 'totalTestcasesPending',
  key: 'totalTestcasesPending',
  accessor: 'totalTestcasesPending',
  Header: 'Total Test Cases Pending',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const TOTAL_TEST_CASES_SKIPPED = {
  id: 'totalTestcasesSkipped',
  key: 'totalTestcasesSkipped',
  accessor: 'totalTestcasesSkipped',
  Header: 'Total Test Cases Skipped',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const ENVIRONMENT = {
  id: 'environment',
  key: 'environment',
  accessor: 'environment',
  Header: 'Environment',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const RELEASE_VERSION = {
  id: 'releaseVersion',
  key: 'releaseVersion',
  accessor: 'releaseVersion',
  Header: 'Release Version',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const TYPE = {
  id: 'type',
  key: 'type',
  accessor: 'type',
  Header: 'Type',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const STATUS = {
  id: 'status',
  key: 'status',
  accessor: 'status',
  Header: 'Status',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const CREATED_BY = {
  id: 'createdBy',
  key: 'createdBy',
  accessor: 'createdBy',
  Header: 'Created By',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};
const CREATED_AT = {
  id: 'createdAt',
  key: 'createdAt',
  accessor: 'createdAt',
  Header: 'Created At',
  Cell: TextRenderer,
  // getProps: TextRenderer,
  sortable: true,
};

export const columns = [NAME, TOTAL_TEST_CASES, TOTAL_TEST_CASES_PASSED, TOTAL_TEST_CASES_FAILED, TOTAL_TEST_CASES_PENDING, TOTAL_TEST_CASES_SKIPPED, ENVIRONMENT, RELEASE_VERSION, TYPE, STATUS, CREATED_AT, CREATED_BY];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
