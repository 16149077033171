import _get from 'lodash/get';

import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

const HandleErrorAndStatus = (error) => {
  const { response } = error || {};
  const data = _get(response, 'data');
  const statusCode = _get(data, 'status');
  const errorMessage = _get(data, 'message');

  switch (statusCode) {
    case 401:
      toaster(TOASTER_TYPE.ERROR, errorMessage);
      break;
    default:
      toaster(TOASTER_TYPE.ERROR, errorMessage);
  }
};

export default HandleErrorAndStatus;
