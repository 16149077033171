// Libraries
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import JiraIssueList from "./organisms/JiraIssueList";
import IssueTable from "./organisms/JiraIssueList/organisms/IssueTable";
import CreateJiraRequest from "./organisms/CreateJiraRequest";

const JiraIssues = (props) => {
  const { match } = props;
  const { url: matchUrl } = match;
  return (
    <Switch>
        <Route path={`${matchUrl}/list`} component={JiraIssueList} />
        <Route path={`${matchUrl}/createjirarequest`} component={CreateJiraRequest} />
        <Route path={`${matchUrl}/:testsessionId`} component={IssueTable} />
      {/*<Route path={`${matchUrl}/create-testsession`} component={CreateTestSession} />*/}
      {/*<Route path={`${matchUrl}/clone/:testsessionId`} component={CloneTestSession} />*/}
        <Redirect from="*" to={`${matchUrl}/list`} />
    </Switch>
  );
};

JiraIssues.propTypes = {
  match: PropTypes.object.isRequired,
};

export default JiraIssues;
