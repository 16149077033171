const ACTION_TYPES = {
  REDIRECT_TO_ADD_TEST_CASES: 'REDIRECT_TO_ADD_TEST_CASES',
  UPDATE_BULK_TEST_RESULTS_STATUS: 'UPDATE_BULK_TEST_RESULTS_STATUS',
  ROW_ACTION: 'ROW_ACTION',
  ROW_BULK_ACTION: 'ROW_BULK_ACTION',
  GET_TEST_SESSION: 'GET_TEST_SESSION',
  UPLOAD_MEDIA: 'UPLOAD_MEDIA',
  OPEN_MEDIA_MODAL: 'OPEN_MEDIA_MODAL',
  CLOSE_MEDIA_MODAL: 'CLOSE_MEDIA_MODAL',
  UPDATE_TEST_RESULT_LIST: 'UPDATE_TEST_RESULT_LIST',
  OPEN_UPLOAD_MODAL: 'OPEN_UPLOAD_MODAL',
  CLOSE_UPLOAD_MODAL: 'CLOSE_UPLOAD_MODAL',
  UPDATE_TEST_RESULT_NOTES: 'UPDATE_TEST_RESULT_NOTES',
  // OPEN_TESTCASE_DETAILS_MODAL: 'OPEN_TESTCASE_DETAILS_MODAL',
  CLOSE_TESTCASE_DETAILS_MODAL: 'CLOSE_TESTCASE_DETAILS_MODAL',
  RE_RUN_CLICK: 'RE_RUN_CLICK',
  CONFIRMATION_MODAL_SUBMIT: 'CONFIRMATION_MODAL_SUBMIT',
  CONFIRMATION_MODAL_CANCEL: 'CONFIRMATION_MODAL_CANCEL',
  FETCH_MODULE_FILTER:'FETCH_MODULE_FILTER'
};
export default ACTION_TYPES;
