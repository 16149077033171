import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';

// Utils
import { isRequiredRule } from 'tbase/utils/formValidators';


export const PRODUCT = {
  id: 'productId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'projectId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Module'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const TEST_PLAN_NAME = {
  id: 'name',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Name'),
    // placeholder: __('Type Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const INFO = {
  id: 'info',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Info'),
    // placeholder: __('Type Info'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const JIRA = {
  id: 'jira',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('JIRA link to story/epic'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};
