import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import _head from 'lodash/head';
import Loader from 'tcomponents/molecules/loader';

import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import FormPage from 'tcomponents/pages/formPage';
import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';
import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';

// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { getSections } from './helpers/testsessionForm.sections';
import { getFields } from './helpers/testSessionForm.formConfig';
import { INITIAL_STATE } from './constant/testSessionForm.general';
import { ACTION_HANDLERS } from './helpers/testSessionForm.actionHandlers';
import ACTION_TYPES from './constant/testSessionForm.actionTypes';
import { getTcmsBootstrap, getProjectListByProductId, getReleaseVersion } from '../../../../store/selectors/tcms.selectors';
import { PRODUCT } from './helpers/testsessionForm.fields';

const TestSessionForm = (props) => {
  const {
    history,
    onAction,
    formHeader,
    formFooter,
    formValues,
    errors,
    isEdit,
    tcmsBootstrap,
    releaseVersion,
    projectListByProductId,
    handleEditTestSessionSubmit,
    projectListOptions,
    releaseVersionListOptions,
    isTestSessionLoading,
    isFromTestSessionList,
    setNewFormValues
  } = props;

  const handleCreateRelease = useCallback(
    (newReleaseVersion) => {
      onAction({ type: ACTION_TYPES.CREATE_RELEASE_VERSION, payload: { newReleaseVersion } });
    },
    [onAction]
  );

  const selectedProductId = _head(formValues[PRODUCT.id]);

  const fields = useMemo(
    () => getFields({
      tcmsBootstrap,
      selectedProductId,
      handleCreateRelease,
      projectListOptions,
      projectListByProductId,
      releaseVersion,
      isEdit,
    }),
    [tcmsBootstrap, isEdit, projectListByProductId, selectedProductId, projectListOptions, handleCreateRelease, releaseVersion]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmit = useCallback(() => {
    triggerSubmit('Test Session Form');
  }, EMPTY_ARRAY);
  if (isTestSessionLoading) {
    return <Loader />;
  }
  return (
    <div>

      <FormPage
        sections={getSections(isFromTestSessionList)}
        headerComponent={null}
        footerComponent={null}
        onSubmit={handleSubmit}
        onClose={handleCancel}
        fields={fields}
        onAction={onAction}
        values={formValues}
        contextId="Test Session Form"
        contentHeight={175}
      />

    </div>
  );
};

const mapStateToProps = state => ({
  tcmsBootstrap: getTcmsBootstrap(state),
  projectListByProductId: getProjectListByProductId(state),
  releaseVersion: getReleaseVersion(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withSize({ hasPageFooter: 0, hasPageHeader: 0 }),
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(TestSessionForm);
