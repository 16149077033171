import _head from 'lodash/head';
import _has from 'lodash/has';

// constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import {
  PROJECT,
  COMMENTS,
  FEATURE,
  TEST_CASE_NAME,
  PRIORITY,
  ACTIVE,
  AUTOMATED,
  COMPONENT,
  DESCRIPTION,
  TYPE,
  BRANCH,
  PRECONDITIONS,
  AFFECTED_COMPONENT,
  STEPS,
  EXIT_CRITERIA,
  RELEASE,
  REGRESSION,
  JIRA,
  TCCATEGORY,
  UPSTREAMCONSUMPTION,
  DOWNSTREAMCONSUMPTION,
  HORIZONTALIMPLEMENTATION,
  MISC,
  AUTHOR,
} from '../../../organisms/uploadTestCases/uploadTestCasesFormCreate/constants/uploadTestCasesForm.fields';

// helpers
// import { getFeatureId } from './testCases.general';

const makeTestCaseRequestDTO = (formValues = EMPTY_OBJECT) => {
  let requestDto = {};
  console.log(formValues);
  if (formValues.featureId != undefined && formValues.newsubfeature == 'yes') {
    console.log('existing feature & new subfeature');
    // existing feature & new subfeature
    requestDto = {
      isCreate: true,
      moduleId: formValues.featureId,
      subModuleDto: {
        moduleId: formValues.featureId,
        name: formValues.subfeatureName[0],
        info: 'this is info',
      },
      name: formValues[TEST_CASE_NAME.id],
      [AUTHOR.id]: formValues[AUTHOR.id],
      [COMPONENT.id]: formValues[COMPONENT.id],
      [JIRA.id]: [formValues[JIRA.id]],
      [PRIORITY.id]: _head(formValues[PRIORITY.id]),
      [DESCRIPTION.id]: formValues[DESCRIPTION.id],
      [STEPS.id]: formValues[STEPS.id],
      [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
      [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
      [BRANCH.id]: formValues[BRANCH.id],
      [ACTIVE.id]: _head(formValues[ACTIVE.id]),
      [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
      [MISC.id]: formValues[MISC.id],
      [REGRESSION.id]: _head(formValues[REGRESSION.id]),
      [RELEASE.id]: formValues[RELEASE.id],
      [TYPE.id]: _head(formValues[TYPE.id]),
      [TCCATEGORY.id]: formValues[TCCATEGORY.id],
      [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
      [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
      [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    };
  } else if (formValues.subfeatureId != undefined) {
    console.log('existing feature and subfeature ');
    // existing feature and subfeature
    requestDto = {
      isCreate: false,
      subModuleId: formValues.subfeatureId,
      name: formValues[TEST_CASE_NAME.id],
      [AUTHOR.id]: formValues[AUTHOR.id],
      [COMPONENT.id]: formValues[COMPONENT.id],
      [JIRA.id]: [formValues[JIRA.id]],
      [PRIORITY.id]: _head(formValues[PRIORITY.id]),
      [DESCRIPTION.id]: formValues[DESCRIPTION.id],
      [STEPS.id]: formValues[STEPS.id],
      [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
      [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
      [BRANCH.id]: formValues[BRANCH.id],
      [ACTIVE.id]: _head(formValues[ACTIVE.id]),
      [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
      [MISC.id]: formValues[MISC.id],
      [REGRESSION.id]: _head(formValues[REGRESSION.id]),
      [RELEASE.id]: formValues[RELEASE.id],
      [TYPE.id]: _head(formValues[TYPE.id]),
      [TCCATEGORY.id]: formValues[TCCATEGORY.id],
      [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
      [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
      [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    };
  } else if ((_has(formValues, 'subfeatureName') && _has(formValues, 'featureName')) &&
  (formValues.newsubfeature == 'yes' && formValues.newfeature == 'yes')) {
    console.log('new feature and new sub feature');
    // new feature and new sub feature

    requestDto = {
      isCreate: true,
      moduleDto: {
        projectId: formValues.projectId[0],
        name: formValues.featureName[0],
        info: 'this is info',
      },
      subModuleDto: {
        moduleId: '',
        name: formValues.subfeatureName[0],
        info: 'this is info',
      },
      name: formValues[TEST_CASE_NAME.id],
      [AUTHOR.id]: formValues[AUTHOR.id],
      [COMPONENT.id]: formValues[COMPONENT.id],
      [JIRA.id]: [formValues[JIRA.id]],
      [PRIORITY.id]: _head(formValues[PRIORITY.id]),
      [DESCRIPTION.id]: formValues[DESCRIPTION.id],
      [STEPS.id]: formValues[STEPS.id],
      [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
      [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
      [BRANCH.id]: formValues[BRANCH.id],
      [ACTIVE.id]: _head(formValues[ACTIVE.id]),
      [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
      [MISC.id]: formValues[MISC.id],
      [REGRESSION.id]: _head(formValues[REGRESSION.id]),
      [RELEASE.id]: formValues[RELEASE.id],
      [TYPE.id]: _head(formValues[TYPE.id]),
      [TCCATEGORY.id]: formValues[TCCATEGORY.id],
      [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
      [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
      [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    };
  } else if (formValues.newfeature != undefined && formValues.newfeature == 'yes') {
    console.log('new feature')
    // new feature
    requestDto = {
      isCreate: true,
      projectId: formValues.projectId[0],
      moduleDto: {
        projectId: formValues.projectId[0],
        name: formValues.featureName[0],
        info: 'this is info',
      },
      name: formValues[TEST_CASE_NAME.id],
      [AUTHOR.id]: formValues[AUTHOR.id],
      [COMPONENT.id]: formValues[COMPONENT.id],
      [JIRA.id]: [formValues[JIRA.id]],
      [PRIORITY.id]: _head(formValues[PRIORITY.id]),
      [DESCRIPTION.id]: formValues[DESCRIPTION.id],
      [STEPS.id]: formValues[STEPS.id],
      [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
      [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
      [BRANCH.id]: formValues[BRANCH.id],
      [ACTIVE.id]: _head(formValues[ACTIVE.id]),
      [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
      [MISC.id]: formValues[MISC.id],
      [REGRESSION.id]: _head(formValues[REGRESSION.id]),
      [RELEASE.id]: formValues[RELEASE.id],
      [TYPE.id]: _head(formValues[TYPE.id]),
      [TCCATEGORY.id]: formValues[TCCATEGORY.id],
      [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
      [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
      [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    };
  } else if (formValues.featureId != undefined) {
    console.log('existing feature');
    // existing feature

    requestDto = {
      isCreate: false,
      moduleId: formValues.featureId,
      name: formValues[TEST_CASE_NAME.id],
      [AUTHOR.id]: formValues[AUTHOR.id],
      [COMPONENT.id]: formValues[COMPONENT.id],
      [JIRA.id]: [formValues[JIRA.id]],
      [PRIORITY.id]: _head(formValues[PRIORITY.id]),
      [DESCRIPTION.id]: formValues[DESCRIPTION.id],
      [STEPS.id]: formValues[STEPS.id],
      [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
      [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
      [BRANCH.id]: formValues[BRANCH.id],
      [ACTIVE.id]: _head(formValues[ACTIVE.id]),
      [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
      [MISC.id]: formValues[MISC.id],
      [REGRESSION.id]: _head(formValues[REGRESSION.id]),
      [RELEASE.id]: formValues[RELEASE.id],
      [TYPE.id]: _head(formValues[TYPE.id]),
      [TCCATEGORY.id]: formValues[TCCATEGORY.id],
      [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
      [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
      [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    };
  }
  return requestDto;
};


export default makeTestCaseRequestDTO;

