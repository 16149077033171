import _constant from 'lodash/constant';
import { STATUS_COLOR_MAP, STATUS_LABEL_MAP } from '../constant/addTestCaseList.status';
import { AUTOMATED_COLOR_MAP, AUTOMATED_LABEL_MAP } from '../../../../../constants/automatedOptions';

export const getStatusProps = _constant({
  labelMap: STATUS_LABEL_MAP,
  colorMap: STATUS_COLOR_MAP,
});

export const getAutomatedProps = _constant({
  labelMap: AUTOMATED_LABEL_MAP,
  colorMap: AUTOMATED_COLOR_MAP,
});
