// Constants
import { PRODUCT, PROJECT, FEATURE,SUBFEATURE } from './uploadTestCasesForm.fields';

// Styles
import styles from '../uploadTestCasesForm.module.scss';

const SECTIONS = [
  {
    className: styles.formContainer,
    rows: [
      {
        columns: [PRODUCT.id],
      },
      {
        columns: [PROJECT.id],
      },
      {
        columns: [FEATURE.id],
      },
      {
        columns: [SUBFEATURE.id],
      },
    ],
  },
];

export default SECTIONS;
