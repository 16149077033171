import React from 'react';

import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import TextWithLinkRenderer from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import { EMPTY_STRING } from "@tekion/tekion-base/app.constants";

import ACTION_TYPES from './jiraIssueList.actionTypes';

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getTestSessionIdCell = (column, onAction) => {
  const { original } = column;
  const testSessionId = _get(column, ['original','id'], EMPTY_STRING);
  return (
      <TextWithLinkRenderer value={testSessionId} onAction={onAction} actionType={ACTION_TYPES.ON_TEST_SESSION_ID_CLICK} column={original}/>
  )
}

const TEST_SESSION_NAME = {
  id: 'name',
  key: 'name',
  accessor: 'name',
  Header: 'Jira Test Session',
  Cell: TextWithLink,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const getTestSessionIdColumn = onAction => ({
  id: 'id',
  key: 'id',
  accessor: 'id',
  Header: 'Test Session Link',
  Cell: (column) => getTestSessionIdCell(column, onAction),
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
});

const TOTAL_TESTCASES = {
  id: 'totalTestcases',
  key: 'totalTestcases',
  accessor: 'totalTestcases',
  Header: 'Total Testcases',
  Cell: TextRenderer,
};

const TOTAL_TESTCASES_EXECUTED = {
  id: 'totalTestcasesExecuted',
  key: 'totalTestcasesExecuted',
  accessor: 'totalTestcasesExecuted',
  Header: 'Total Testcases Executed',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};


export const getColumns = onAction => [TEST_SESSION_NAME, getTestSessionIdColumn(onAction), TOTAL_TESTCASES, TOTAL_TESTCASES_EXECUTED];

//
// const COLUMN_CONFIG_BY_KEY = _keyBy(getColumns(onAction), 'key');
// export default COLUMN_CONFIG_BY_KEY;
