
import _get from 'lodash/get';
import _size from 'lodash/size';
// Utils
import addToRenderOptions from 'tbase/utils/addToRenderOptions';

import { RELEASE_VERSION, IS_ACTIVE } from '../constants/AddReleaseForm.fields';
import FORM_CONFIG from '../constants/AddReleaseForm.formConfig';


const isActiveOptions = [{ label: 'True', value: 'True' }, { label: 'False', value: 'False' }]

export const FIELDS = {
    ...FORM_CONFIG,
    [IS_ACTIVE.id]: addToRenderOptions(IS_ACTIVE, [{ path: 'options', value: isActiveOptions }]),
};
