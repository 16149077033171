import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import Button from 'tcomponents/atoms/Button';
import { isRequiredRule } from 'tbase/utils/formValidators';

import TYPE_OPTIONS from '../../../../../constants/typeOptions';
import { STATUS_OPTIONS } from '../../../../../constants/statusOptions';
import ENV_OPTIONS from '../../../../../constants/envOptions';
import ACTION_TYPES from "../constant/createJiraRequest.actionTypes";
import styles from '../createJiraRequest.module.scss';

export const JIRA_QUERY = {
  id: 'jiraQuery',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Jira Query'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const getValidateJQLField = handleValidateJQLClick => ({
  id: 'validateJiraQuery',
  renderer: Button,
  action: ACTION_TYPES.VALIDATE_JQL_CLICK,
  renderOptions: {
    label: __('Validate JQL'),
    view: Button.VIEW.PRIMARY,
    className: styles.buttonSpacing,
    onClick: handleValidateJQLClick,
  }
});

export const TEST_SESSION_NAME = {
  id: 'testSessionName',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('TestSession Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PRODUCT = {
  id: 'product',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'project',
  renderer: SelectInput,
  renderOptions: {
    label: __('Project Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const RELEASE = {
  id: 'release',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Release'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const TYPE = {
  id: 'type',
  renderer: SelectInput,
  renderOptions: {
    label: __('Type'),
    required: true,
    options: TYPE_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const STATUS = {
  id: 'status',
  renderer: SelectInput,
  renderOptions: {
    label: __('Status'),
    required: true,
    options: STATUS_OPTIONS,
    validators: [isRequiredRule],
    // disabled: true,
  },
};

export const ENV = {
  id: 'env',
  renderer: SelectInput,
  renderOptions: {
    label: __('Environment'),
    required: true,
    options: ENV_OPTIONS,
    validators: [isRequiredRule],
  },
};
