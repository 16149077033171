import { EMPTY_ARRAY, EMPTY_STRING } from 'tbase/app.constants';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const INITIAL_STATE = {
  currentPage: 1,
  pageSize: 50,
  selectedFilters: JSON.parse(TokenManager.getItem('selectedFilters')) || EMPTY_ARRAY,
  searchText: undefined,
};

export default INITIAL_STATE;
