import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
  LoginCallback,
  PostAuthentication,
  SingleSignon
} from '@tekion/tap-components/middlewares/authentication';
import { Security } from '@okta/okta-react';
import TokenManager from '@tekion/tap-components/utils/TokenManager';
import { Route } from '@tekion/tap-components/middlewares/applicationMonitoring/ElasticAPM';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import SignIn from '../../signIn';
import StaticSignUp from '../../SignUp';
import Settings from '../../Settings';
import Profile from '../../Settings/Profile';
import TaskManagement from '../../Settings/TaskManagement';
import Calendar from '../../Settings/Calendar';
import SupportPortal from '../../Settings/SupportPortal';
import TapApplicationsContainer from '../../TapApplictions/TapApplicationsContainer';
import TapApplications from '../../TapApplictions/TapApplications';
import Listing from '../../TapApplictions/Listing';
import CreateModule from '../../TapApplictions/CreateModule';
import ReleaseVersion from '../../TapApplictions/ReleaseVersion';
import CardManagement from '../../TapApplictions/CardManagement/CardManagement';
import CreateForm from '../../TapApplictions/CardManagement/CreateForm';
import HomePage from '../../../components/HomePage';
import TestSessions from '../../testSessions';
import TestCases from '../../testCases';
import TestPlan from '../../testPlan';
import AutomationProgress from '../../AutomationProgress';
import IntegrationHealth from '../../integrationHealth';
import SessionExpired from '../../../organisms/sessionExpired/SessionExpired';
import ReleaseBoard from '../../ReleaseBoard';
import HistoryCollection from '../../HistoryCollection';
import DatabaseHealthCheckTable from '../../DatabaseHealthCheck/DatabaseHealthCheckTable';
import TrackingControl from '../../TrackingControl';
import ExecutionScreen from '../../ExecutionScreen';
import JiraIssues from "../../JiraIssues";
import AutomationMetric from '../../AutomationMetric/AutomationMetric';


const RouteWithPermission = () => (
  <Security
    oktaAuth={TokenManager.getAuthClient("/tcmsuiV2/app/callback")}
    restoreOriginalUri={() => { }}
  >
    <Switch>
      <Route exact path="/tcmsuiV2/login" component={SignIn} />
      <Route exact path="/tcmsuiV2/signup" component={StaticSignUp} />
      <Route exact path="/tcmsuiV2" component={HomePage} />
      <Route
        path="/tcmsuiV2/testcase"
        component={TestCases}
      />
      <Route
        path="/tcmsuiV2/testsession"
        component={TestSessions}
      />
      <Route
        path="/tcmsuiV2/testplan"
        component={TestPlan}
      />
      <Route
        path="/tcmsuiV2/executionprogress"
        component={AutomationProgress}
      />
      <Route
        path="/tcmsuiV2/uploadstatus"
        component={IntegrationHealth}
      />
      <Route
        path="/tcmsuiV2/sessionexpired"
        component={SessionExpired}
      />
      <Route
        path="/tcmsuiV2/releaseBoard"
        component={ReleaseBoard}
      />
      <Route
        path="/tcmsuiV2/executionScreen"
        component={ExecutionScreen}
      />
      <Route
        path="/tcmsuiV2/historycollection"
        component={HistoryCollection}
      />
      <Route
        path="/tcmsuiV2/dbhealthcheck"
        component={DatabaseHealthCheckTable}
      />
      <Route
        path="/tcmsuiV2/trackingControl"
        component={TrackingControl}
      />
      <Route
          path="/tcmsuiV2/jiraissues"
          component={JiraIssues}
      />
      <Route
          path="/tcmsuiV2/automationmetric"
          component={AutomationMetric}
      />
      <Route
        path="/app/settings"
        component={() => (
          <Settings>
            <Route
              path="/app/settings"
              exact
              component={() => <Profile apiBaseUrl={process.env.BASE_URL} />}
            />
            <Route
              path="/app/settings/profile"
              exact
              component={() => <Profile apiBaseUrl={process.env.BASE_URL} />}
            />
            <Route
              path="/app/settings/taskmanagement"
              exact
              component={() => (
                <TaskManagement apiBaseUrl={process.env.BASE_URL} />
              )}
            />
            <Route
              path="/app/settings/calendar"
              exact
              component={() => <Calendar apiBaseUrl={process.env.BASE_URL} />}
            />
            <Route
              path="/app/settings/supportportal"
              exact
              component={() => <SupportPortal apiBaseUrl={process.env.BASE_URL} />}
            />
          </Settings>
        )}
      />
      {/* <Route exact path="/tcmsuiV2//app/callback" component={LoginCallback} /> */}
      <Route
        exact
        path="/tcmsuiV2/app/callback"
        component={() => (
          <LoginCallback
            setupUrl="/tcmsuiV2/app/setup"
          />
        )}
      />
      <Route
        exact
        path="/app/singlesignon"
        component={() => (
          <SingleSignon
            BASE_URL={process.env.BASE_URL}
            AUTHORIZATION_SERVER_ID={process.env.AUTHORIZATION_SERVER_ID}
            authenticateApiUrl="/tapapi/v2/usermanagement/authenticate-okta-user"
            clientId={process.env.OKTA_CLIENT_ID}
            redirectUri={`${window.location.origin}/app/singlesignon`}
            scope="openid profile email"
          />
        )}
      />
      <Route
        path="/app/tapapplications"
        component={() => (
          <TapApplications>
            <Switch>
              <Route
                exact
                path="/app/tapapplications"
                component={() => <TapApplicationsContainer apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/releaseversion"
                component={() => <ReleaseVersion apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards"
                component={() => <CardManagement apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards/create"
                component={() => <CreateForm apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards/:appKey/edit"
                component={() => <CreateForm apiBaseUrl={process.env.BASE_URL} />}
              />

              <Route
                exact
                path="/app/tapapplications/:feature"
                component={() => <Listing apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/:feature/create"
                component={() => <CreateModule apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/:feature/:module/edit"
                component={() => <CreateModule apiBaseUrl={process.env.BASE_URL} />}
              />
            </Switch>
          </TapApplications>
        )}
      />
      <Route
        exact
        path="/tcmsuiV2/app/home"
        component={() => <HomePage />}
      />
      <Route
        exact
        path="/tcmsuiV2/app/setup"
        component={() => (
          <PostAuthentication
            BASE_URL={process.env.BASE_URL}
            authenticateApiUrl="/tapapi/v2/usermanagement/authenticate-okta-user"
            // redirectUrl="/tcmsuiV2/app/home/"
          />
        )}
      />
      <Redirect exact from="/" to="/tcmsuiV2" />
      <Route
        path=""
        component={routerProps => <div style={{ padding: '4rem' }}> <ErrorPage {...routerProps} notFound /></div>}
      />
    </Switch>
  </Security>
);

export default RouteWithPermission;
