import React from 'react';
import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
// import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
// import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
// import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import _keyBy from 'lodash/keyBy';
import Content from 'tcomponents/atoms/Content';
import styles from '../databaseHealthCheckTable.module.scss';


// const StatusCellRenderer = makeCellRenderer(StatusRenderer);


const renderIsHealthyColumn = (column) => {
  const textToRender = column?.value === true ? 'True' : 'False';
  return (<Content className={textToRender === 'True' ? styles.greenBox : styles.redBox}>{textToRender}</Content>);
};

const TABLE_NAME = {
  id: 'tableName',
  key: 'tableName',
  accessor: 'tableName',
  Header: 'Table Name',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  sortable: true,
};

const ES_COUNT = {
  id: 'esCount',
  key: 'esCount',
  accessor: 'esCount',
  Header: 'ES Count',
  Cell: TextRenderer,
  getProps: () => ({
    // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  }),
};

const MONGO_COUNT = {
  id: 'mongoCount',
  key: 'mongoCount',
  accessor: 'mongoCount',
  Header: 'Mongo Count',
  Cell: TextRenderer,
  sortable: true,
};
const IS_HEALTHY = {
  id: 'isHealthy',
  key: 'isHealthy',
  accessor: 'isHealthy',
  Header: 'Is Healthy',
  Cell: column => renderIsHealthyColumn(column),
  // getProps: TextRenderer,
  sortable: true,
};

export const columns = [TABLE_NAME, ES_COUNT, MONGO_COUNT, IS_HEALTHY];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
