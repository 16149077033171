import { STATUS } from '../helpers/createJiraRequest.fields';
import { NOT_STARTED } from '../../../../../constants/statusOptions';

export const INITIAL_STATE = {
  formValues: {
    [STATUS.id]: [NOT_STARTED.id],
  },
};

export const VALIDATE_JIRA_QUERY = 'VALIDATE_JIRA_QUERY';
