import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import { isRequiredRule } from 'tbase/utils/formValidators';

export const RELEASE_VERSION = {
  id: 'releaseVersion',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Release Version'),
    placeholder: __('Input Release Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const IS_ACTIVE = {
  id: 'isActive',
  renderer: SelectInput,
  renderOptions: {
    label: __('IsActive'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};