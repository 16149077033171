import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';

import _map from "lodash/map";
import _uniq from "lodash/uniq";

import { connect } from 'react-redux';
import _noop from 'lodash/noop';

import { withRouter } from 'react-router-dom';
import withCustomSortTable from '@tekion/tekion-components/src/organisms/withCustomSortTable';

import withActions from 'tcomponents/connectors/withActions';
import compose from 'recompose/compose';
import withSize from 'tcomponents/hoc/withSize';

import withRowActions from 'tcomponents/molecules/table/containers/withRowActions';
import TableManager from 'tcomponents/organisms/TableManager';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import FixedColumnTable from 'tcomponents/molecules/table/FixedColumnTable';
import withCheckboxTable from 'tcomponents/organisms/withCheckboxTable';

import _get from 'lodash/get';
import {EMPTY_ARRAY, EMPTY_STRING} from '@tekion/tekion-base/app.constants';
import ACTION_HANDLERS from './helpers/testCasesList.actionHandlers';
import INITIAL_STATE from './constants/testCasesList.initialState';
import {
  makeTableProps, getSubHeaderProps, addEventListeners, removeEventListeners,
} from './helpers/testCasesList.general';
import { columns } from './constants/testCasesList.columns';
import { createFilterProps } from './helpers/testCasesList.filterProps';
import {
  getTcmsBootstrap, getFeatureListByProjectId, getProjectListByProductId, getsubFeatureListByfeatureId,
} from '../../../../../../store/selectors/tcms.selectors';
import MoveTestCasesModal from '../../../../../../organisms/MoveTestCases';
import ACTION_TYPES from './constants/testCasesList.actionTypes';
import BulkUpdateTestCaseModal from "../../../../../../organisms/BulkUpdateTestCases";
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const PAGE_HEADER_HEIGHT = 64;
const PAGE_TAB_HEIGHT = 60;
const CheckBoxTable = withRowActions(withCheckboxTable(FixedColumnTable));
const SortableTableManager = withCustomSortTable(TableManager);

function TestCasesList(props) {
  const {
    tabId,
    onAction,
    currentPage,
    isLoading,
    history,
    data,
    totalCount,
    isFetchingNextPage,
    sortDetails,
    columnConfigurator,
    selection,
    selectedFilters,
    selectedProjectId,
    selectedFeatureId,
    contentHeight,
    searchText,
    tcmsBootStrap,
    projectListByProductId,
    featureListByProjectId,
    subfeatureListByFeatureId,
    showMoveTestCaseModal,
    showBulkUpdateTestCaseModal,
    pageSize = 50,
    setSelection,
    } = props;

  let tagsData = [];
  _map(data, testCase => {
    const tags = _get(testCase, 'tags', EMPTY_ARRAY);
    tagsData = tagsData.concat(tags);
  });
  const uniqueTagValues = _uniq(tagsData);

  useEffect(() => {
    let localStorageSelections = TokenManager.getItem('selections');
    if (localStorageSelections != null) { localStorageSelections = JSON.parse(localStorageSelections); }
    const result = [];
    // const tagsData = JSON.parse(localStorage.getItem('tags'));
    // if (tagsData != null) {
    if (selection && data) {
      selection.forEach((item) => {
        const testCaseId = data.filter(x => x.id === item)[0]?.testCaseId;
        result.push({
          testCaseId, id: item, projectId: data[0]?.projectId, productId: data[0]?.productId, moduleName: data[0]?.moduleName ,isAutomated:data[0]?.isAutomated
        });
      });
    }
    TokenManager.setItem('selections', JSON.stringify(result));
  }, [selection]);

  const onCancel = useCallback(() => {
    onAction({
      type: ACTION_TYPES.MOVE_TEST_CASE_MODAL_ON_CANCEL,
    });
  }, [onAction]);

  const onSubmit = useCallback(
    (formValues) => {
      onAction({
        type:
        ACTION_TYPES.MOVE_TEST_CASE_MODAL_ON_SUBMIT,
        payload: {
          formValues,
        },
      });
    },
    [onAction]
  );

  const onCancelBulkUpdate = useCallback(() => {
    onAction({
      type: ACTION_TYPES.BULK_UPDATE_TEST_CASES_ON_CANCEL,
    });
  }, [onAction]);

  const onSubmitBulkUpdate = useCallback(
      (formValues) => {
        onAction({
          type:
          ACTION_TYPES.BULK_UPDATE_TEST_CASES_ON_SUBMIT,
          payload: {
            formValues,
          },
        });
      },
      [onAction]
  );

  const handleBulkAction = selection => useCallback(
    (action) => {
      onAction({
        type: ACTION_TYPES.ROW_BULK_ACTION,
        payload: {
          action,
          selection,
        },
      });
    },
    [onAction, selection]
  );

  const TABLE_MANAGER_PROPS = {
    showSearch: true,
    showFilter: true,
    showHeader: false,
    showSubHeader: true,
    showTotalCount: true,
    showRefreshIcon: true,
  };

  const filterProps = useMemo(() => createFilterProps({
    selectedFilters,
    tcmsBootStrap,
    projectListByProductId,
    featureListByProjectId,
    subfeatureListByFeatureId,
    tabId,
    selectedProjectId,
    selectedFeatureId,
    uniqueTagValues,
  }), [selectedFilters, tcmsBootStrap, uniqueTagValues, tabId]);

  const subHeaderProps = useMemo(() => getSubHeaderProps(handleBulkAction, selection), [handleBulkAction, onAction, selection]);

  const getTableProps = useMemo(
    () => makeTableProps({
      isFetchingNextPage,
      isLoading,
      totalCount,
      pageSize,
      currentPage,
      columnConfigurator,
      onAction,
      selection,
      sortDetails,
    }),
    [isLoading, isFetchingNextPage, columnConfigurator, totalCount, onAction, selection, sortDetails, pageSize, currentPage]
  );

  // console.log(COLUMNS);
  return (
    <PageComponent.Body style={{ height: contentHeight + 80 }}>
      <TableManager
        columns={columns}
        data={data}
        tableManagerProps={TABLE_MANAGER_PROPS}
        onAction={onAction}
        filterProps={filterProps}
        selection={selection}
        tableProps={getTableProps}
        tableComponent={CheckBoxTable}
        subHeaderProps={subHeaderProps}
        sortDetails={sortDetails}
        isMultiSort
      />
      <MoveTestCasesModal
        showMoveTestCaseModal={showMoveTestCaseModal}
        onSubmit={onSubmit}
        onCancel={onCancel}
        projectListByProductId={projectListByProductId}
        featureListByProjectId={featureListByProjectId}
        subfeatureListByFeatureId={subfeatureListByFeatureId}
        tabId={tabId}
      />
      <BulkUpdateTestCaseModal
        showBulkUpdateTestCaseModal={showBulkUpdateTestCaseModal}
        onSubmitBulkUpdate={onSubmitBulkUpdate}
        onCancelBulkUpdate={onCancelBulkUpdate}
        projectListByProductId={projectListByProductId}
        featureListByProjectId={featureListByProjectId}
        subfeatureListByFeatureId={subfeatureListByFeatureId}
        tabId={tabId}
      />
    </PageComponent.Body>
  );
}
const mapStateToProps = state => ({
  tcmsBootStrap: getTcmsBootstrap(state),
  featureListByProjectId: getFeatureListByProjectId(state),
  projectListByProductId: getProjectListByProductId(state),
  subfeatureListByFeatureId: getsubFeatureListByfeatureId(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withActions(INITIAL_STATE, ACTION_HANDLERS),
  withSize({ hasPageHeader: 1, hasPageFooter: 1 })
)(TestCasesList);
