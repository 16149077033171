import _get from 'lodash/get';
import _head from 'lodash/head';

import { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
// import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import ACTION_TYPES from '../constants/viewTrackingControl.actionTypes';
import ROW_ACTION_HANDLERS from '../organisms/ViewTrackingControlList/helper/viewTrackingControlList.rowActionHandlers';
import { getTrackingControls } from '../services/viewTrackingControl.api';
// import { IS_ACTIVE, RELEASE_VERSION } from '../organisms/AddReleaseForm/constants/AddReleaseForm.fields';
import { addTrackingControls } from '../services/viewTrackingControl.api';
import {
  PRODUCT, RELEASE_TAG, MODULE, PROJECT, TRACKING_ENABLED,
} from '../organisms/AddTrackingControlForm/constant/AddTrackingControlForm.fields';
import HandleErrorAndStatus from '../../../helpers/ErrorFunction/errorFunction';
const handleTableItemsFetch = async ({ setState }) => {
  setState({ isLoading: true });
  const tableData = await getTrackingControls();
  setState({ isLoading: false, tableData });
};

const handleOpenAddTrackingControlModal = ({ setState, getState }) => {
  setState({ isVisible: true, formValues: EMPTY_OBJECT });
};

const handleCloseAddTrackingControlModal = ({ setState, getState }) => {
  setState({ isVisible: false });
};

const handleSubmit = async ({ params, setState, getState }) => {
  const releaseVersion = _head(_get(params, 'formValues.releaseVersion', EMPTY_STRING));
  const projectName = _get(params, 'formValues.projectName', EMPTY_STRING);
  const isTrackingEnabledString = _head(_get(params, 'formValues.trackingEnabled', EMPTY_ARRAY));
  const isTrackingEnabled = isTrackingEnabledString === 'True';
  setState({ isTrackingControlModalLoading: true });

  const addTrackingControlBody = {
    projectName,
    releaseVersion,
    isTrackingEnabled,
  };

  try {
    const response = await addTrackingControls(addTrackingControlBody);
    setState({ isVisible: false, isLoading: true, isTrackingControlModalLoading: true });
    window.location.reload();
  } catch (error) {
    HandleErrorAndStatus(error);
    setState({ isVisible: false, isTrackingControlModalLoading: false });
  }
};

const handleRowActions = ({ params, getState, setState }) => {
  const releaseVersion = _get(params, 'payload.releaseVersion', EMPTY_STRING);
  const projectName = _get(params, 'payload.projectName', EMPTY_STRING);
  const trackingEnabled = _get(params, 'payload.isTrackingEnabled');
  const trackingEnabledString = trackingEnabled === false ? 'False' : 'True';
  const values = {
    releaseVersion: [releaseVersion],
    projectName,
    trackingEnabled: [trackingEnabledString],
  };
  if (params.action === 'CLICK_EDIT') {
    setState({ isVisible: true, formValues: values });
    //  setState({ values });
  }
};

// const handleReleaseVersionChange = ({ params,getState, setState }) => {
//   console.log(params);
//   console.log(getState())
// };


const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [ACTION_TYPES.OPEN_ADD_TRACKING_CONTROL_MODAL]: handleOpenAddTrackingControlModal,
  [ACTION_TYPES.CLOSE_ADD_TRACKING_CONTROL_MODAL]: handleCloseAddTrackingControlModal,
  [ACTION_TYPES.FORM_SUBMIT_MODAL]: handleSubmit,
  [ACTION_TYPES.ROW_ACTION]: handleRowActions,
  // [ACTION_TYPES.HANDLE_RELEASE_VERSION_CHANGE]: handleReleaseVersionChange,

};

export default ACTION_HANDLERS;
