import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const YES = { id: '1', name: __('Yes') };
const NO = { id: '0', name: __('No') };

const TYPES = [YES, NO];

const TESTPLAN_RADIO_OPTIONS = standardFieldOptionMapper(undefined, TYPES);

export default TESTPLAN_RADIO_OPTIONS;
