import TYPES from '../../organisms/actions.types';
import APP_ACTION_TYPES from '../../store/constants/tcms.actionTypes';
export const bodyResize = size => (dispatch) => {
  dispatch({
    type: TYPES.APP_RESIZE,
    size,
  });
};

export const fetchBootStrap = response => (dispatch) => {
  // console.log('app.actionhandlers', response);
  dispatch({
    type: APP_ACTION_TYPES.SAVE_TCMS_BOOTSTRAP,
    payload: response,
  });
};
