import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PARTNERTS_STIE_TABS, TABS } from './constants';
import './SettingsTabs.scss';

const SettingsTabs = (props) => {
  const { activeTab, onChangeTab } = props;
  const { PARTNERS_SITE } = process.env;
  const tabs = PARTNERS_SITE === 'true' ? PARTNERTS_STIE_TABS : TABS;
  return (
    <Fragment>
      {tabs.map((tab, index) => (
        <div
          className={`user-section${activeTab === tab.url ? ' active' : ''}`}
          key={tab.label}
          onClick={onChangeTab(tab.url)}
          role="button"
          tabIndex={index - 1}
        >
          <div className="user-txt">{__(tab.label)}</div>
        </div>
      ))}
    </Fragment>
  );
};

SettingsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export default SettingsTabs;
