import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';


const handleOnFormInit = () => {

};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleOnFormInit,
};

export default ACTION_HANDLERS;
