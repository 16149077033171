import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import Button from 'tcomponents/atoms/Button';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import Input from 'tcomponents/atoms/Input';
import Loader from 'tcomponents/molecules/loader';

import { updateVersion } from '../../action/ReleaseVersion.action';
import {
  loading,
} from './TapApplications.selector';

import styles from './TapApplications.module.scss';

class ReleaseVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '',
      link: '',
    };
  }

  /**
    * @description Function to update the release notes and version
  */
  onUpdate = () => {
    const { version, link } = this.state;
    const { apiBaseUrl, updateReleaseVersion, history } = this.props;
    const payload = {
      module: 'TAP',
      version,
      link,
    };
    updateReleaseVersion(apiBaseUrl, payload);
    history.push('/app/tapapplications');
  }

  /**
    * @description Function to handle the Onchage event of TextInput.
  */
  onChange = (e, type) => {
    this.setState({ [type]: e.target.value });
  }

  /**
    * @description Click event habdler for cancel button, will navigate to 'app/tapapplication' page.
  */
  onCancel = () => {
    const {
      history,
    } = this.props;
    history.push('/app/tapapplications');
  }

  _renderCreateForm = () => {
    const { loading } = this.props;
    const { version, link } = this.state;
    return (
      <React.Fragment>
        {loading ? <Loader />
          : (
            <React.Fragment>
              <div className={styles.version__control}>
                <div className={styles.create__module}>
                  <Row>
                    <Col xs={24} md={24} sm={24} lg={11}>
                      <Row className={styles.mb24} align="middle" gutter={16}>
                        <Col xs={24} md={10} sm={24} lg={7}>
                          {__('Module')}
                        </Col>
                        <Col span={12}>
                          <Input id="module" value="TAP" onChange={e => this.onChange(e, 'version')} disabled />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24} sm={24} lg={11}>
                      <Row className={styles.mb24} align="middle" gutter={16}>
                        <Col xs={24} md={10} sm={24} lg={7}>
                          {__('Release Notes')}
                        </Col>
                        <Col span={12}>
                          <Input id="link" value={link} onChange={e => this.onChange(e, 'link')} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24} sm={24} lg={11}>
                      <Row className={styles.mb24} align="middle" gutter={16}>
                        <Col xs={24} md={10} sm={24} lg={7}>
                          {__('Version')}
                        </Col>
                        <Col span={12}>
                          <Input id="version" value={version} onChange={e => this.onChange(e, 'version')} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className={styles.module__footer}>
                  <Button style={{ marginRight: '25px' }} onClick={this.onCancel}>
                    {__('Cancel')}
                  </Button>
                  <Button view="primary" onClick={this.onUpdate} disabled={!version || !link}>
                    {__('Update')}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }

  _renderErrorComponent = () => <ErrorPage notFound />;

  render() {
    const { PARTNERS_SITE } = process.env;
    return (
      <React.Fragment>
        {PARTNERS_SITE === 'true'
          ? this._renderErrorComponent()
          : this._renderCreateForm()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: loading(),
});
/**
  * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const updateReleaseVersion = bindActionCreators(updateVersion, dispatch);
  return {
    updateReleaseVersion,
  };
};
ReleaseVersion.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateReleaseVersion: PropTypes.func.isRequired,
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReleaseVersion)
);
