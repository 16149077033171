import { PRODUCT, PROJECT, TEST_PLAN_NAME, INFO, JIRA } from './addTestPlanForm.fields';


const SECTIONS = [
  {
    rows: [
      {
        columns: [PRODUCT.id],
      },
      {
        columns: [PROJECT.id],
      },
      {
        columns: [TEST_PLAN_NAME.id],
      },
      {
        columns: [INFO.id],
      },
      {
        columns: [JIRA.id],
      },
    ],
  },
];

export default SECTIONS;
