import React from 'react';
import _constant from 'lodash/constant';
import _size from 'lodash/size';
import _map from 'lodash/map';

import TABLE_TYPES from '@tekion/tekion-components/src/organisms/TableManager/constants/tableTypes';

import Button from '@tekion/tekion-components/src/atoms/Button';
import Menu from '@tekion/tekion-components/src/molecules/Menu';
import Dropdown from '@tekion/tekion-components/src/molecules/DropDown';
import ACTION_TYPES from '../constants/testResultsList.actiontypes';
import { TABLE_ROW_ACTIONS, TABLE_ROW_ACTIONS_GUEST } from './testResultsList.rowAction';
import { checkUserRoleWithSelection } from '../../../../../../../helpers/UserRole/userRole.common';
import { ROLE_TYPE, REVERSE_ROLE_TYPE } from '../../../../../../../constants/userRole/userRoleMap';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

const getBulkActionsMenu = handleBulkAction => (
  <Menu onClick={handleBulkAction}>
    {_map(TABLE_ROW_ACTIONS, ({ name, id }) => (
      <Menu.Item key={id}>{name}</Menu.Item>
    ))}
  </Menu>
);

export const getSubHeaderProps = (handleBulkAction, selection) => ({
  subHeaderRightActions: [
    {
      renderer: Dropdown,
      renderOptions: {
        overlay: getBulkActionsMenu(handleBulkAction),
        trigger: ['click'],
        disabled: checkUserRoleWithSelection(selection),
        children: (
          <Button view={Button.VIEW.SECONDARY}>
            {__('Bulk Actions')}
          </Button>
        ),
      },
    },
  ],
});


const handleRowAction = onAction => (action, payload) => {
  onAction({
    type: ACTION_TYPES.ROW_ACTION,
    payload: {
      value: payload,
      action,
    },
  });
};

const rowActions = () => {
  const rolehash = TokenManager.getItem('role') || undefined;
  if(ROLE_TYPE[rolehash] === REVERSE_ROLE_TYPE.GUEST){
    return (TABLE_ROW_ACTIONS_GUEST)
  }

  return (TABLE_ROW_ACTIONS);
};

export const makeTableProps = ({
  isLoading,
  isFetchingNextPage,
  totalCount,
  // columnConfigurator,
  // rowHeight,
  onAction,
  selection,
  sortDetails,
  pageSize,
  currentPage,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  type: TABLE_TYPES.FIXED_COLUMN,
  showActions: true,
  getRowActions: rowActions,
  onRowActionClick: handleRowAction(onAction),
  showColumnConfigurator: true,
  totalNumberOfEntries: totalCount,
  isFetchingNextPage,
  showSelectionCount: true,
  getTdProps: _constant({ onAction }),
  // checkboxHeaderClassName: 'm-l-8',
  showPagination: true,
  sortDetails,
  shouldAdaptSortDetails: false,
  pageSize,
  currentPage,
});
