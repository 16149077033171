import _get from 'lodash/get';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import ACTION_TYPES from '../constants/historyCollectionList.actionTypes';

const handleTestSessionClick = ({ params, getState }) => {
  const { productId, projectId, testSessionId } = _get(params, 'column.original', EMPTY_OBJECT);
  const { history } = getState();
  history.push(`/tcmsuiV2/testsession/testresults/${productId}/${projectId}/${testSessionId}`);
};

export const ACTION_HANDLERS = {
  [ACTION_TYPES.CLICK_TEST_SESSION]: handleTestSessionClick,
};
