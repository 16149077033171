import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Card from 'tcomponents/molecules/Card';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import FontIcon from 'tcomponents/atoms/FontIcon';
import Loader from 'tcomponents/molecules/loader';

import { fetchAuthentication } from '../../action/Authentication.action';
import { fetchAuthenticationSelector, loading } from './TapApplications.selector';
import { CONSTANTS } from '../../constants/Constants';

import styles from './TapApplications.module.scss';

function TapApplicationsContainer(props) {
  const {
    fetchAuthenticationInfo, apiBaseUrl, notAuthenticated, loader,
  } = props;
  const [authHasBeenCalled, setAuthHasBeenCalled] = useState(false);
  const { PARTNERS_SITE } = process.env;
  useEffect(() => {
    fetchAuthenticationInfo(apiBaseUrl);
    setAuthHasBeenCalled(true);
  }, []);

  /**
    * @description Function will set the selected module and redirect to edit screen with selected module.
    * @param {Object} selectedModule - Object.
  */

  function onSelectCard(pathName) {
    const { history } = props;
    history.push({
      pathname: pathName,

    });
  }

  function _renderCard() {
    return (
      <div className={`${styles.card__container} ${styles.version__control}`}>
        {CONSTANTS.TAP_APPLICATIONS.map(app => (
          <Card
            size="small"
            title=""
            className={`${styles.card__module} ${styles.application__card}`}
            key={module.module}
            onClick={() => onSelectCard(`/app/tapapplications/${app.link}`)}
          >
            <div className={styles.tap__applications}>
              <FontIcon size={30} color="rgb(66, 133, 244)">{app.icon}</FontIcon>
              <div>{app.name}</div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  function _renderModules() {
    return (
      <React.Fragment>
        {_renderCard()}
      </React.Fragment>
    );
  }

  function _renderErrorComponent() { return <div style={{ padding: '4rem' }}> <ErrorPage /> </div>; }

  function _renderTabContainer() {
    return (
      <React.Fragment>{PARTNERS_SITE === 'true' || notAuthenticated
        ? _renderErrorComponent()
        : _renderModules()}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {loader || !authHasBeenCalled ? <Loader />
        : _renderTabContainer()
      }
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  notAuthenticated: fetchAuthenticationSelector(),
  loader: loading(),
});
/**
  * @description Map the actions (as props) that are required to dispatch actions from UI
 */
const mapDispatchToProps = (dispatch) => {
  const fetchAuthenticationInfo = bindActionCreators(fetchAuthentication, dispatch);
  return {
    fetchAuthenticationInfo,
  };
};
TapApplicationsContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

TapApplicationsContainer.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  fetchAuthenticationInfo: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  notAuthenticated: PropTypes.bool.isRequired,

};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TapApplicationsContainer));
