import React from 'react';
import { mapProps } from 'recompose';

import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';

import TextRenderer from 'tcomponents/molecules/CellRenderers/TextRenderer';
import TextWithLink from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer';
import StatusRenderer from 'tcomponents/molecules/CellRenderers/statusRenderer';
import makeCellRenderer from 'tcomponents/molecules/CellRenderers/makeCellRenderer';
import CellDateRenderer from 'tcomponents/molecules/CellRenderers/dateRenderer';

import { DATE_TIME_FORMATS } from 'tbase/utils/dateUtils';
import { EMPTY_ARRAY } from "@tekion/tekion-base/app.constants";

const StatusCellRenderer = makeCellRenderer(StatusRenderer);

const getTotalTestCasesAddedColumn = column => {
  const totalTestCasesAdded = _get(column, ['original', 'testCaseList'], EMPTY_ARRAY);
  return(
      <div> {totalTestCasesAdded.length} </div>
  );
}

const getTotalTestCasesExecutedColumn = column => {
  const totalTestCasesExecuted = _get(column, ['original', 'totalTestCasesExecuted'], EMPTY_ARRAY);
  return(
      <div> {totalTestCasesExecuted.length} </div>
  );
}

const ID = {
  id: 'id',
  key: 'id',
  accessor: 'id',
  Header: 'ID',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const TEST_SESSION_ID = {
  id: 'testSessionId',
  key: 'testSessionId',
  accessor: 'testSessionId',
  Header: 'Test Session Id',
  Cell: TextRenderer,
  //   getProps: () => ({ actionType: ACTION_TYPES.CLICK_MODULE_NAME }),
  // sortable: true,
};

const KEY = {
  id: 'key',
  key: 'key',
  accessor: 'key',
  Header: 'Key',
  Cell: TextRenderer,
};

const SUMMARY = {
  id: 'summary',
  key: 'summary',
  accessor: 'summary',
  Header: 'Summary',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const STATUS = {
  id: 'status',
  key: 'status',
  accessor: 'status',
  Header: 'Status',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const ASSIGNEE = {
  id: 'assignee',
  key: 'assignee',
  accessor: 'assignee',
  Header: 'Assignee',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const REPORTER = {
  id: 'reporter',
  key: 'reporter',
  accessor: 'reporter',
  Header: 'Reporter',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const ISSUE_TYPE = {
  id: 'issueType',
  key: 'issueType',
  accessor: 'issueType',
  Header: 'Issue Type',
  Cell: TextRenderer,
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const TOTAL_TEST_CASES_ADDED = {
  id: 'totalTestCaseAdded',
  key: 'totalTestCaseAdded',
  accessor: 'totalTestCaseAdded',
  Header: 'Total Test Cases Added',
  Cell: (column) => getTotalTestCasesAddedColumn(column),
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const TOTAL_TEST_CASES_EXECUTED = {
  id: 'totalTestCaseExecuted',
  key: 'totalTestCaseExecuted',
  accessor: 'totalTestCaseExecuted',
  Header: 'Total Test Cases Executed',
  Cell: (column) => getTotalTestCasesExecutedColumn(column),
  // getProps: () => ({
  //   // actionType: ACTION_TYPES.CLICK_PRODUCT_NAME,
  // }),
};

const CREATED_BY = {
  id: 'createdBy',
  key: 'createdBy',
  accessor: 'createdBy',
  Header: 'Created By',
  Cell: TextRenderer,
  sortable: true,
};

const MODIFIED_BY = {
  id: 'modifiedBy',
  key: 'modifiedBy',
  accessor: 'modifiedBy',
  Header: 'Modified By',
  Cell: TextRenderer,
  sortable: true,
};

const MODIFIED_AT = {
  id: 'modifiedAt',
  key: 'modifiedAt',
  accessor: 'modifiedAt',
  Header: 'Modified At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};

const CREATED_AT = {
  id: 'createdAt',
  key: 'createdAt',
  accessor: 'createdAt',
  Header: 'Created At',
  Cell: mapProps(({ value }) => ({
    data: value,
    format: DATE_TIME_FORMATS.ABBREVIATED_BASE_DATE_FORMAT_US_WITH_TIME,
  }))(CellDateRenderer),
  sortable: true,
};


export const columns = [KEY, SUMMARY, STATUS, ASSIGNEE, REPORTER, ISSUE_TYPE, TOTAL_TEST_CASES_ADDED, TOTAL_TEST_CASES_EXECUTED, CREATED_BY, MODIFIED_BY, CREATED_AT, MODIFIED_AT];


const COLUMN_CONFIG_BY_KEY = _keyBy(columns, 'key');
export default COLUMN_CONFIG_BY_KEY;
