import _get from 'lodash/get';

import addOptionsToAdditional from 'tbase/utils/addOptionsToAdditional';

import {
  MODULE, STATUS, ENVIRONMENT, DEFAULT_FILTER_TYPES, MODIFIED_AT, CREATED_AT, CREATED_BY,
} from '../constants/testSessionList.filterTypes';
import makeProjectOptions from '../../../../../../../constants/projectsOptions';
import makeCreatedByOptions from '../../../../../../../constants/createdByOptions';

const createFilterTypes = (tcmsBootStrap, projectListByProductId, tabId) => {
  // const projectList = _get(tcmsBootStrap, 'projectList');
  const projectList = projectListByProductId[tabId];

  const projectOptions = makeProjectOptions(projectList);
  const projectFilter = addOptionsToAdditional(MODULE, projectOptions);

  const filterTypes = [projectFilter, STATUS, ENVIRONMENT, MODIFIED_AT, CREATED_AT];
  return filterTypes;
};

export const createFilterProps = ({
  selectedFilters, tcmsBootStrap, projectListByProductId, tabId,
}) => {
  const filterTypes = createFilterTypes(tcmsBootStrap, projectListByProductId, tabId);
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    selectedFilters,
    shouldHideSaveFilterGroup: true,
    showFilterGroupName: false,
    shouldHideFilterGroup: true,
    showResetToDefaultGroup: false,
  };
};
