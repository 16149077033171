import React, { useState, useEffect } from 'react';
import Modal from 'tcomponents/molecules/Modal';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import ExecuteTestCasesForm from '../executeTestCasesForm';

const ExecuteTestCasesModal = (props) => {
  const {
    showExecuteTestSuiteModal,
    onCancel,
    onSubmit,
    selectedDealer,
    selectedEnvironment,
    setSelectedDealer,
    setSelectedEnvironment,
    selectedReleaseVersion,
    setSelectedReleaseVersion,
    isExecutionModalLoading,
    isAutomated,
    isChecked,
    setIsChecked,
  } = props;

  const [executeButtonDisabled, setExecuteButtonDisabled] = useState();

  useEffect(() => {
    const isNonAutomatedSelected = isAutomated.includes(false);

    if ((_isEmpty(selectedDealer) || _isEmpty(selectedEnvironment))) {
      setExecuteButtonDisabled(true);
    } else if (!isChecked && isNonAutomatedSelected) {
      setExecuteButtonDisabled(true);
    } else {
      setExecuteButtonDisabled(false);
    }
  }, [selectedDealer, selectedEnvironment, selectedReleaseVersion, showExecuteTestSuiteModal, isChecked]);

  return (
    <Modal
      title={__('Execution Params')}
      submitBtnText={__('Execute')}
      loading={isExecutionModalLoading}
      visible={showExecuteTestSuiteModal}
      onSubmit={e => onSubmit(e)}
      primaryBtnDisabled={executeButtonDisabled}
      onCancel={onCancel}
      width={Modal.SIZES.SM}
      destroyOnClose
    >
      <ExecuteTestCasesForm
        selectedDealer={selectedDealer}
        setSelectedDealer={setSelectedDealer}
        selectedEnvironment={selectedEnvironment}
        setSelectedEnvironment={setSelectedEnvironment}
        selectedReleaseVersion={selectedReleaseVersion}
        setSelectedReleaseVersion={setSelectedReleaseVersion}
        isAutomated={isAutomated}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
    </Modal>
  );
};

export default ExecuteTestCasesModal;
