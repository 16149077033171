import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import skeletonReducer from '../organisms/skeleton.reducer';
import ProfileReducer from './Profile.reducers';
import CalendarReducer from './Calendar.reducers';
import TaskReducer from './TaskManagement.reducers';
import TapApplicationsReducer from './TapApplications.reducers';
import AuthenticationReducers from './Authentication.reducers';
import SupportPortalReducers from './SupportPortal.reducers';
import CardManagementReducers from './CardManagement.reducers';
import TcmsAppReducers from './TcmsApp.reducers';

// import history from '@tekion/tekion-base/utils/history';

const history = createBrowserHistory({ basename: '/tcmsuiV2' });


export const defaultReducers = {
  router: connectRouter(history),
  skeleton: skeletonReducer,
  profile: ProfileReducer,
  task: TaskReducer,
  calender: CalendarReducer,
  TapApplications: TapApplicationsReducer,
  Authentication: AuthenticationReducers,
  supportPortal: SupportPortalReducers,
  CardManagement: CardManagementReducers,
  TcmsApp: TcmsAppReducers,
};

export const rootReducer = combineReducers(defaultReducers);

export default {
  defaultReducers,
  rootReducer,
};
