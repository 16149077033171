import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { compose } from 'recompose';

import {
  withRouter,
} from 'react-router-dom';

import withActions from 'tcomponents/connectors/withActions';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import { isRequiredRule } from 'tbase/utils/formValidators';
import useFetchData from 'tbase/customHooks/useFetchData';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import HistoryCollectionList from './organisms/historyCollectionList';
import { ACTION_HANDLERS } from './helpers/HistoryCollection.actionHandlers';
import ENV_OPTIONS from '../AutomationProgress/constants/environmentOptions';
import ACTION_TYPES from './constants/historyCollection.actionTypes';

import styles from './historyCollection.module.scss';
import {
  getAllDealers,
  getAllProducts, getAllProjects,
  getAllReleaseVersions,
} from './services/historyCollection.api';

import { getProductOptionsFromData } from './constants/productOptions';
import { getReleaseVersionOptions } from './constants/releaseVersionOptions';
import { getDropdownOptions } from './constants/dropdownOptions';


const HistoryCollection = (props) => {
  const {
    onAction, selectedProduct, selectedProject, selectedEnvironment, selectedReleaseVersion, selectedDealer, historyCollectionInfo, isHistoryTableLoading
  } = props;

  const productResponse = useFetchData(getAllProducts);
  const [{ data: productsList, isLoading: isProductLoading }] = productResponse;

  const releaseVersionResponse = useFetchData(getAllReleaseVersions);
  const [{ data: releaseList, isLoading: isReleaseLoading }] = releaseVersionResponse;

  const projectResponse = useFetchData(getAllProjects);
  const [{ data: projectList, isLoading: isProjectLoading }] = projectResponse;

  const dealerResponse = useFetchData(getAllDealers);
  const [{ data: dealerList, isLoading: isDealerLoading }] = dealerResponse;


  const releaseVersionData = _get(releaseVersionResponse, '0.data.data.data.hits', EMPTY_ARRAY);
  const releaseVersionOptions = getReleaseVersionOptions(releaseVersionData);

  const productData = _get(productResponse, '0.data.data.data', EMPTY_ARRAY);
  const productOptions = getProductOptionsFromData(productData);

  const projectData = _get(projectResponse, '0.data.data.data', EMPTY_ARRAY);
  const projectOptions = getDropdownOptions(projectData);

  const dealerData = _get(dealerResponse, '0.data.data.data', EMPTY_ARRAY);
  const dealerOptions = getDropdownOptions(dealerData);

  const onFieldChange = useCallback(
    (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        onAction({
          type: ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            value, selectedProduct, selectedEnvironment, selectedReleaseVersion, selectedProject, selectedDealer,
          },
        });
      }
    },
    [onAction]
  );

  return (
    <>
      <h1 className={styles.pageStyle}>
        History Collection
      </h1>
      <div className={styles.dropdowns}>
        <SelectInput
          isLoading={isProductLoading}
          id="Product"
          label={__('Product')}
          options={productOptions}
          onAction={onFieldChange}
          value={selectedProduct}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isProjectLoading}
          id="Project"
          label={__('Project')}
          placeholder={__('Select')}
          options={projectOptions}
          // isDisabled
          onAction={onFieldChange}
          value={selectedProject}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          // isLoading={isProductLoading}
          id="Environment"
          label={__('Environment')}
          options={ENV_OPTIONS}
          onAction={onFieldChange}
          value={selectedEnvironment}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isDealerLoading}
          id="DealerId"
          label={__('Dealer')}
          options={dealerOptions}
          onAction={onFieldChange}
          value={selectedDealer}
          placeholder={__('Select')}
          // isDisabled
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isReleaseLoading}
          id="ReleaseVersion"
          label={__('Release Version')}
          placeholder={__('Select')}
          options={releaseVersionOptions}
          onAction={onFieldChange}
          value={selectedReleaseVersion}
          required
          validators={[isRequiredRule]}
        />

      </div>
      {
        <HistoryCollectionList onAction={onAction} data={historyCollectionInfo} isHistoryTableLoading={isHistoryTableLoading}/>
      }
    </>
  );
};

HistoryCollection.propTypes = {
  isResetClicked: PropTypes.bool,
};

HistoryCollection.defaultProps = {
  isResetClicked: true,
};


export default compose(withRouter, withActions({}, ACTION_HANDLERS))(HistoryCollection);
