import React, { useEffect, useCallback, useMemo } from 'react';
import _get from 'lodash/get';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';
import FormBuilder from 'tcomponents/organisms/FormBuilder';
import withActions from 'tcomponents/connectors/withActions';
import useFetchData from '@tekion/tekion-base/customHooks/useFetchData';
import Loader from 'tcomponents/molecules/loader';
import ACTION_HANDLERS from './helpers/addTrackingControlForm.actionHandlers';

import { getFields } from './helpers/addTrackingControlForm.Config';
import SECTIONS from './constant/AddTrackingControlForm.sections';
import { getAllReleaseVersionsApi } from '../../../integrationHealth/services/integrationHealth.api';
import { getReleaseVersionOptions } from '../../../integrationHealth/constants/releaseVersionOptions';


const FormWithSubmission = withEventSubmission(FormBuilder);
const AddTrackingControlForm = (props) => {
  const { onAction, formValues, onCancel } = props;
  const CONTEXT_ID = 'UPLOAD_TESTCASES_FORM';

  const releaseVersionResponse = useFetchData(getAllReleaseVersionsApi);
  const [{ data, isLoading: isReleaseVersionLoading }] = releaseVersionResponse;
  const releaseVersionOptions = getReleaseVersionOptions(_get(releaseVersionResponse, '0.data.data.data.hits', EMPTY_ARRAY));

  if (isReleaseVersionLoading) {
    return <Loader />;
  }

  return (
    <div>
      <FormWithSubmission
        fields={getFields(releaseVersionOptions)}
        sections={SECTIONS}
        values={formValues}
        onAction={onAction}
        contextId={CONTEXT_ID}
      />
    </div>
  );
};
export default withActions({}, ACTION_HANDLERS)(AddTrackingControlForm);
