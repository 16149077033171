import _head from 'lodash/head';

// constants
import { EMPTY_OBJECT } from 'tbase/app.constants';
import {
  PROJECT,
  COMMENTS,
  JIRA,
  TEST_CASE_NAME,
  PRIORITY,
  ACTIVE,
  AUTOMATED,
  COMPONENT,
  DESCRIPTION,
  TYPE,
  BRANCH,
  PRECONDITIONS,
  AFFECTED_COMPONENT,
  STEPS,
  EXIT_CRITERIA,
  REGRESSION,
  RELEASE,
  TCCATEGORY,
  UPSTREAMCONSUMPTION,
  DOWNSTREAMCONSUMPTION,
  HORIZONTALIMPLEMENTATION,
  MISC,
  AUTHOR,
} from '../organisms/TestCaseForm/helpers/testCasesForm.fields';

// helpers
// import { getFeatureId } from './testCases.general';

const makeTestCaseRequestDTO = (formValues = EMPTY_OBJECT, testCaseId) => {
  const requestDto = {
    isCreate: false,
    moduleId: formValues.featureId,
    name: formValues[TEST_CASE_NAME.id],
    [AUTHOR.id]: formValues[AUTHOR.id],
    [COMPONENT.id]: formValues[COMPONENT.id],
    [JIRA.id]: formValues[JIRA.id],
    [PRIORITY.id]: _head(formValues[PRIORITY.id]),
    [DESCRIPTION.id]: formValues[DESCRIPTION.id],
    [STEPS.id]: formValues[STEPS.id],
    [BRANCH.id]: formValues[BRANCH.id],
    [ACTIVE.id]: _head(formValues[ACTIVE.id]),
    [AUTOMATED.id]: _head(formValues[AUTOMATED.id]),
    [MISC.id]: formValues[MISC.id],
    [PRECONDITIONS.id]: formValues[PRECONDITIONS.id],
    [REGRESSION.id]: _head(formValues[REGRESSION.id]),
    [EXIT_CRITERIA.id]: formValues[EXIT_CRITERIA.id],
    [RELEASE.id]: formValues[RELEASE.id],
    [TYPE.id]: formValues[TYPE.id],
    [TCCATEGORY.id]: formValues[TCCATEGORY.id],
    [UPSTREAMCONSUMPTION.id]: formValues[UPSTREAMCONSUMPTION.id],
    [DOWNSTREAMCONSUMPTION.id]: formValues[DOWNSTREAMCONSUMPTION.id],
    [HORIZONTALIMPLEMENTATION.id]: formValues[HORIZONTALIMPLEMENTATION.id],
    createdBy: 'user',
  };
  return requestDto;
};


export default makeTestCaseRequestDTO;
