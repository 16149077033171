import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _head from 'lodash/head';

import addToRenderOptions from 'tbase/utils/addToRenderOptions';
import {
  TEST_SESSION_NAME, PRODUCT, PROJECT, RELEASE, TYPE, STATUS, ENV , JIRA_QUERY, getValidateJQLField,
} from './createJiraRequest.fields';
import { VALIDATE_JIRA_QUERY } from "../constant/createJiraRequest.general";
import makeProductOptions from '../../../../../constants/productsOptions';
import makeProjectOptions from '../../../../../constants/projectsOptions';
import makeReleaseVersionOptions from '../../../../../constants/releaseVersionOptions';

export const FORM_CONFIG = {
  [JIRA_QUERY.id]: JIRA_QUERY,
  [TEST_SESSION_NAME.id]: TEST_SESSION_NAME,
  [PRODUCT.id]: PRODUCT,
  [PROJECT.id]: PROJECT,
  [RELEASE.id]: RELEASE,
  [TYPE.id]: TYPE,
  [STATUS.id]: STATUS,
  [ENV.id]: ENV,
};

export const getFields = ({
  selectedProductId,
  isEdit,
  tcmsBootstrap,
  releaseVersion,
  handleCreateRelease, handleValidateJQLClick,
  projectListOptions,
  projectListByProductId,
}) => {
  const productList = _get(tcmsBootstrap, 'productList');
  const productOptions = makeProductOptions(productList);

  const projectOptions = makeProjectOptions(projectListOptions);

  const releaseVersionOptions = makeReleaseVersionOptions(releaseVersion);
  return {
    ...FORM_CONFIG,
    [VALIDATE_JIRA_QUERY]: getValidateJQLField(handleValidateJQLClick),
    [TEST_SESSION_NAME.id]: addToRenderOptions(TEST_SESSION_NAME, [{ path: 'disabled', value: isEdit }]),
    [PRODUCT.id]: addToRenderOptions(PRODUCT, [
      { path: 'isDisabled', value: isEdit },
      { path: 'options', value: productOptions },
    ]),
    [PROJECT.id]: addToRenderOptions(PROJECT, [
      { path: 'isDisabled', value: isEdit },
      { path: 'options', value: projectOptions },
    ]),
    [RELEASE.id]: addToRenderOptions(RELEASE, [
      { path: 'isDisabled', value: isEdit },
      { path: 'options', value: releaseVersionOptions },
      { path: 'onCreateOption', value: handleCreateRelease },
    ]),
    [STATUS.id]: addToRenderOptions(STATUS, [{ path: 'isDisabled', value: true }]),
  };
};
