import _get from 'lodash/get';
import Http from '../../../services/http';
import URL from '../../../api/urls';
import HandleErrorAndStatus from '../../../helpers/ErrorFunction/errorFunction';

// export const getTestSession = async (testSessionDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestSessionFilter}`, testSessionDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getTabsApi = async (payload) => {
//   try {
//     const response = await Http.get('CQA', `${URL.getProductsTab}`, payload);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getTestCaseByProductId = async (testCaseDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestCaseFilter}`, testCaseDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getTestResultsByTestSessionId = async (testResultDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestResultFilter}`, testResultDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const postUpdateTestResults = async (testSessionId, statusDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postUpdateTestResults}/${testSessionId}`, statusDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const postAddTestCases = async (addTestCaseDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postAddTestCase}`, addTestCaseDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getExportTestSession = (testSessionId, testSessionName) => Http.get('CQA', `${URL.getExportTestSession}?testSessionId=${testSessionId}&testSessionName=${testSessionName}`)
//   .then(response => response.blob())
//   .then((blob) => {
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = `${testSessionName}.xlsx`;
//     document.body.appendChild(a);
//     a.click();
//     a.remove();
//   });
//
// export const postBulkUpdateTestResults = async (statusDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postBulkUpdateTestResults}`, statusDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const postBulkUpdateTestSessions = async (statusDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postBulkUpdateTestSessions}`, statusDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const searchTestSessionFilter = async (filterDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestSessionFilter}`, filterDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const searchtestResultFilter = async (filterDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestResultFilter}`, filterDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const searchtestCaseFilter = async (filterDTO) => {
//   try {
//     const response = await Http.post('CQA', `${URL.searchTestCaseFilter}`, filterDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const createTestSession = async (testsessionBody) => {
//   // try {
//   const response = await Http.post('CQA', `${URL.postCreateTestSession}`, testsessionBody);
//   return response;
//   // } catch (error) {
//   //   HandleErrorAndStatus(error);
//   // }
// };
//
// export const getTestSessionDetails = async (testSessionId) => {
//   try {
//     const response = await Http.get('CQA', `${URL.getTestSessionDetails}?id=${testSessionId}`);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const cloneTestSession = async (testsessionBody, testSessionId) => {
//   const response = await Http.post('CQA', `${URL.postCloneTestSession}?id=${testSessionId}`, testsessionBody);
//   return response;
// };
//
// export const editTestSession = async (testsessionBody) => {
//   const response = await Http.post('CQA', `${URL.postUpdateTestSession}`, testsessionBody);
//   return response;
// };
// export const postTestResultsComment = async (notesBody, assetId) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postUpdateTestResults}/${assetId}`, notesBody);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getTestResultComment = async (testCaseId) => {
//   try {
//     const response = await Http.get('CQA', `${URL.getTestResultComments}/${testCaseId}`);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const postMediaTestResult = async (formDataDTO, testResultId) => {
//   try {
//     const response = await Http.post('CQA', `${URL.postMediaInTestResult}/${testResultId}`, formDataDTO);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getMediaTestResult = async (testResultId) => {
//   try {
//     const response = await Http.get('CQA', `${URL.getMediaInTestResult}/${testResultId}`);
//     return response;
//   } catch (error) {
//     HandleErrorAndStatus(error);
//   }
// };
//
// export const getTestSessionReport = async (executionId) => {
//   const response = await Http.post('CQA', `${URL.getTestSessionReport}${executionId}`);
//   return response;
// };

export const getJiraIssueList = async () => {
  try {
    const response = await Http.get('CQA', `${URL.getJiraIssueList}`);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export const getTestSessionJiraIssueData = async (testSessionId) => {
  try {
    const response = await Http.get('CQA', `${URL.getTestSessionJiraData}?testSessionId=${testSessionId}`);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export const createJiraIssue = async (jiraIssueDto) => {
  try {
    const response = await Http.post('CQA', `${URL.createJiraIssue}`, jiraIssueDto);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export const validateJqlQuery = async (validateJqlQueryDto) => {
  try {
    const response = await Http.post('CQA', `${URL.validateJqlQuery}`, validateJqlQueryDto);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

