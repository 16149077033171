import _get from 'lodash/get';

import addOptionsToAdditional from 'tbase/utils/addOptionsToAdditional';

import makeProjectOptions from '../../../../../helpers/projectOptions';
import makeFeatureOptions from '../../../../testCases/organisms/TabbedTestCases/organisms/TestCasesList/helpers/featureOptions';
import {
  FEATURE, PRIORITY, EXECUTED_AT, CREATED_AT, PROJECT, SUBFEATURE, DEFAULT_FILTER_TYPES, ACTIVE, AUTOMATED,
} from '../constants/executionScreen.filterTypes';
import makeSubFeatureOptions from './../../../../testCases/organisms/TabbedTestCases/organisms/TestCasesList/helpers/subFeatureOptions';

const createFilterTypes = (tcmsBootStrap, projectListByProductId, featureListByProjectId,
  subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId) => {
  //const projectList = _get(tcmsBootStrap, 'projectList');
  const projectList = projectListByProductId[tabId];

  //const featureList = _get(tcmsBootStrap, 'featureList'); 
  const featureList = featureListByProjectId[selectedProjectId];
  //const subFeatureList = subfeatureListByFeatureId[selectedFeatureId];

  const projectOptions = makeProjectOptions(projectList);
  const featureOptions = makeFeatureOptions(featureList);
  //const subFeatureOptions = makeSubFeatureOptions(subFeatureList);
  const projectFilter = addOptionsToAdditional(PROJECT, projectOptions);
  const featureFilter = addOptionsToAdditional(FEATURE, featureOptions);
  const subFeatureFilter = addOptionsToAdditional(SUBFEATURE);
  const filterTypes = [projectFilter, featureFilter, subFeatureFilter, PRIORITY, EXECUTED_AT, CREATED_AT, ACTIVE, AUTOMATED];
  return filterTypes;
};

export const createFilterProps = ({
  selectedFilters, projectListByProductId,
  featureListByProjectId,
  subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId,
}) => {
 const filterTypes = createFilterTypes(projectListByProductId,
    featureListByProjectId, subfeatureListByFeatureId, tabId, selectedProjectId, selectedFeatureId);
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    selectedFilters,
    shouldHideSaveFilterGroup: true,
    showFilterGroupName: false,
    shouldHideFilterGroup: true,
    showResetToDefaultGroup: false,
  };
};
