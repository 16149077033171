// Constants
import { IS_ACTIVE, RELEASE_VERSION } from './AddReleaseForm.fields';

// Styles


const SECTIONS = [
  {
    rows: [
      {
        columns: [RELEASE_VERSION.id],
      },
      {
        columns: [IS_ACTIVE.id],
      },
      
    ],
  },
];

export default SECTIONS;
