import Http from '../../../services/http';
import URL from '../../../api/urls';
import HandleErrorAndStatus from '../../../helpers/ErrorFunction/errorFunction';

export const getTestCase = async (payload) => {
  try {
    const response = await Http.get('CQA', `${URL.getTestCaseDetail}?id=${payload}`);
    return response;
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

export const editTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.editTestCase}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};

export const cloneTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.createTestCase}`, payload);
  return response;
  // .then(({ data }) => console.log(data) // );
};
