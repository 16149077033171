import React from 'react';
import _constant from 'lodash/constant';

import Button from '@tekion/tekion-components/src/atoms/Button';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import ACTION_TYPES from "../constants/jiraIssueList.actionTypes";

export const getHeaderProps = () => ({
  label: __('Jira Test Session'),
});

export const getSubHeaderProps = () => ({
  subHeaderRightActions: [
    {
      renderer: Button,
      action: ACTION_TYPES.ON_CREATE_JIRA_REQUEST_CLICK,
      renderOptions: {
        view: Button.VIEW.SECONDARY,
        label: 'Create Jira Request',
        // className: styles.exportIcon,
      },
    },
  ],
});

export const makeTableProps = ({
  isLoading,
  selection,
  columnConfigurator,
  totalCount,
  onAction,
  sortDetails,
  pageSize,
  currentPage,
}) => ({
  loading: isLoading,
  selection,
  rowHeight: 50,
  columnConfigurator,
  totalNumberOfEntries: totalCount,
  type: TABLE_TYPES.FIXED_COLUMN,
  getTdProps: _constant({ onAction }),
  checkboxHeaderClassName: 'm-l-8',
  sortDetails,
  shouldAdaptSortDetails: false,
  showPagination: true,
  pageSize,
  currentPage,
});
