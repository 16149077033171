import _map from 'lodash/map';


import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

const makefeatureOptions = (featureList) => {
  // standardFieldOptionMapper(undefined, featureList, PROJECT_OPTION_MAPPER);
  const options = _map(featureList, element => ({
    label: element.name,
    value: element.id,
    additional:
    { id: element.id, name: element.name },
  }));
  return options;
};

export default makefeatureOptions;
