// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _castArray from 'lodash/castArray';
import _isEmpty from 'lodash/isEmpty';
import _head from 'lodash/head';
import _noop from 'lodash/noop';

// Components
import ProgressBar from 'tcomponents/atoms/progressBar';
import DraggerUpload from 'tcomponents/molecules/draggerUpload';
import FontIcon from 'tcomponents/atoms/FontIcon';

// Containers
import mediaUploaderHOC from '@tekion/tekion-components/src/organisms/mediaUploader/mediaUploaderHOC';

// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';

// Readers
import fileReader from './readers/File';

// Helpers
import getRoundedFileUploadProgess from './uploadMedia.helpers';

// Styles
import styles from './uploadMedia.module.scss';

class UploadMedia extends Component {
  componentDidUpdate(prevProps) {
    const { mediaList: preMediaList } = prevProps;
    const { mediaList, onMediaChange } = this.props;
    if (mediaList !== preMediaList) {
      onMediaChange(mediaList);
    }
  }

  uploadFile = (selectedFile) => {
    const { addMedia, mediaList, isUploading } = this.props;
    const file = fileReader.file(selectedFile);
    // console.log(file)
    const fileList = _castArray(file);
    addMedia(fileList);
    // console.log(mediaList   +"CORRECT")
  };

  removeUploadedMedia = () => {
    const { mediaList, removeMedia } = this.props;
    removeMedia(mediaList);
  };

  renderFileUploadProgress(mediaToUpload) {
    const roundedFileUploadProgess = getRoundedFileUploadProgess(mediaToUpload);
    return (
      <div>
        <div className="text-center">{__('Please Wait, File Upload In Progress')}</div>
        <ProgressBar showInfo={false} percent={roundedFileUploadProgess} />
      </div>
    );
  }

  renderFileUploadSuccessful(fileName) {
    return (
      <div className="flex-center">
        <div className="p-r-20">{__('Uploaded {{fileName}} Successfully', { fileName })}</div>
        <FontIcon className="cursor-pointer" onClick={this.removeUploadedMedia}>
          icon-cross
        </FontIcon>
      </div>
    );
  }

  renderFileUploadDragger() {
    const { acceptFileTypes } = this.props;
    return (
      <DraggerUpload customRequest={this.uploadFile} showUploadList accept={acceptFileTypes}>
        <div className={styles.uploadIcon}>
          <FontIcon>icon-upload</FontIcon>
          {__('Upload File')}
        </div>
      </DraggerUpload>
    );
  }

  render() {
    const { mediaList } = this.props;

    const mediaToUpload = _head(mediaList);

    if (!_isEmpty(mediaToUpload)) {
      if (fileReader.isUploading(mediaToUpload)) {
        return this.renderFileUploadProgress(mediaToUpload);
      }

      const fileName = fileReader.name(mediaToUpload);
      return this.renderFileUploadSuccessful(fileName);
    }

    return this.renderFileUploadDragger();
  }
}

UploadMedia.propTypes = {
  mediaList: PropTypes.array,
  addMedia: PropTypes.func,
  removeMedia: PropTypes.func,
  onMediaChange: PropTypes.func,
  acceptFileTypes: PropTypes.string,
};

UploadMedia.defaultProps = {
  mediaList: EMPTY_ARRAY,
  addMedia: _noop,
  removeMedia: _noop,
  onMediaChange: _noop,
  acceptFileTypes: undefined,
};

export default mediaUploaderHOC(UploadMedia);
