// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

// Readers
import productReader from '../readers/Product';

const PRODUCT_OPTION_MAPPER = {
  label: productReader.name,
  value: productReader.id,
};
const makeProductOptions = productList => standardFieldOptionMapper(undefined, productList, PRODUCT_OPTION_MAPPER);

export default makeProductOptions;
