const ACTION_TYPES = {
  OPEN_ADD_RELEASE_MODAL: 'OPEN_ADD_RELEASE_MODAL',
  CLOSE_ADD_RELEASE_MODAL: 'CLOSE_ADD_RELEASE_MODAL',
  FORM_SUBMIT_MODAL: 'FORM_SUBMIT',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPLOAD_TEST_CASE_MODAL_ON_CANCEL: 'UPLOAD_TEST_CASE_MODAL_ON_CANCEL',
  UPLOAD_TEST_CASE_MODAL_ON_SUBMIT: 'UPLOAD_TEST_CASE_MODAL_ON_SUBMIT',
  ROW_ACTION: 'ROW_ACTION',
  ON_FORM_SUBMIT: 'ON_FORM_SUBMIT',
};

export default ACTION_TYPES;
