import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import { isRequiredRule } from '@tekion/tekion-base/utils/formValidators';
import TextInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
// Utils

const successValidator = () => ({ isValid: true }, {message:__('This field is Mandatory')});

export const JIRA = {
  id: 'jira',
  renderer: TextInput,
  renderOptions: {
    label: __('JIRA'),
    validators: [isRequiredRule],
    required: true,
  },
};