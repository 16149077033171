import _get from 'lodash/get';
import _last from "lodash/last";

import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';


import { getDashboardStatsByReleaseVersion } from '../services/executionScreen.api';
import ACTION_TYPES from '../constants/executionScreen.actionTypes';
import { getExecutionScreenData } from '../services/executionScreen.api';

const handleFieldChange = async ({ params, getState, setState }) => {
  const id = _get(params, 'value.payload.id', EMPTY_STRING);
  const { selectedEnvironment } = getState();
  const selectedValue = _get(params, 'value.payload.value', EMPTY_ARRAY);
  if (id === 'Product') {
    setState({
      selectedProduct: selectedValue,
    });
  } else if (id === 'Environment') {
    setState({
      selectedEnvironment: selectedValue,
    });
  } else if (id === 'ReleaseVersion') {
    setState({
      selectedReleaseVersion: selectedValue,
    });
    const dashboardTableStats = await getDashboardStatsByReleaseVersion(selectedValue, selectedEnvironment);
    setState({
      dashboardTableStats,
    });
  } else {
    setState({
      selectedTrack: selectedValue,
    });
  }
};

const handleTableItemsFetch = async ({ getState, setState }) => {
  const { executionId } = getState();
  setState({ isLoading: true });
  const executionScreenResponse = await getExecutionScreenData(_last(executionId));
  setState({
    executionScreenResponse,
    isLoading: false,
  });
};


export const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
};
