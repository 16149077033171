import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _head from 'lodash/head';

import { compose } from 'recompose';

import {
  withRouter,
} from 'react-router-dom';

import withActions from 'tcomponents/connectors/withActions';
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import { isRequiredRule } from 'tbase/utils/formValidators';
import useFetchData from 'tbase/customHooks/useFetchData';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import StatsTable from './organisms/StatsTable';
import { ACTION_HANDLERS } from './helpers/automationProgress.actionHandlers';
import ENV_OPTIONS from './constants/environmentOptions';
import ACTION_TYPES from './constants/automationProgress.actionTypes';

import styles from './automationProgress.module.scss';
import {
  getAllProducts,
  getAllReleaseVersions,
} from './services/automationProgress.api';
import { getProductOptionsFromData } from './constants/productOptions';
import { getReleaseVersionOptions } from './constants/releaseVersionOptions';
import { getDealerOptionsFromData } from "./constants/dealerOptions";

const AutomationProgress = (props) => {
  const {
    match, onAction, selectedProduct, selectedEnvironment, selectedReleaseVersion, selectedDealer, isResetClicked, filters, testSessionResponse, dashboardTableStats, isLoading, dealerData, isDealerDropdownLoading,
  } = props;

  const productResponse = useFetchData(getAllProducts);
  const isSelectInputLoading = _get(_head(productResponse), 'isLoading');
  const releaseVersionResponse = useFetchData(getAllReleaseVersions);
  // const [{ data, isLoading }] = releaseVersionResponse;


  const releaseVersionData = _get(releaseVersionResponse, '0.data.data.data.hits', EMPTY_ARRAY);
  const releaseVersionOptions = getReleaseVersionOptions(releaseVersionData);

  const productData = _get(productResponse, '0.data.data.data', EMPTY_ARRAY);
  const productOptions = getProductOptionsFromData(productData);

  const dealerOptions = getDealerOptionsFromData(dealerData);

  const onFieldChange = useCallback(
    (value) => {
      const type = _get(value, 'type', EMPTY_STRING);
      if (type === 'ON_CHANGE') {
        onAction({
          type: ACTION_TYPES.ON_FIELD_CHANGE,
          payload: {
            value, selectedProduct, selectedEnvironment, selectedReleaseVersion,
          },
        });
      }
    },
    [onAction]
  );

  return (
    <>
      <h1 className={styles.pageStyle}>
        Execution Progress
      </h1>
      <div className={styles.releaseDetails}>
        <SelectInput
          isLoading={isSelectInputLoading}
          id="Product"
          label={__('Product')}
          options={productOptions}
          onAction={onFieldChange}
          value={selectedProduct}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isSelectInputLoading}
          id="Environment"
          label={__('Environment')}
          options={ENV_OPTIONS}
          onAction={onFieldChange}
          value={selectedEnvironment}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isDealerDropdownLoading}
          id="DealerId"
          label={__('Dealer ID')}
          options={dealerOptions}
          onAction={onFieldChange}
          value={selectedDealer}
          placeholder={__('Select')}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isSelectInputLoading}
          id="ReleaseVersion"
          label={__('Release Version')}
          placeholder={__('Select')}
          options={releaseVersionOptions}
          onAction={onFieldChange}
          value={selectedReleaseVersion}
          required
          validators={[isRequiredRule]}
        />

        <SelectInput
          isLoading={isSelectInputLoading}
          id="Track"
          label={__('Track')}
          placeholder={__('Select')}
          // options={releaseVersionOptions}
          isDisabled
          onAction={onFieldChange}
          // value={selectedReleaseVersion}
          required
          validators={[isRequiredRule]}
        />

      </div>
      {
        <StatsTable
          data={dashboardTableStats}
          isLoading={isLoading}
          onAction={onAction}
          selectedProduct={selectedProduct}
          selectedEnvironment={selectedEnvironment}
          selectedReleaseVersion={selectedReleaseVersion}
          selectedDealer={selectedDealer}
        />
      }
    </>
  );
};

AutomationProgress.propTypes = {
  isResetClicked: PropTypes.bool,
  match: PropTypes.object.isRequired,
};

AutomationProgress.defaultProps = {
  isResetClicked: true,
};


export default compose(withRouter, withActions({}, ACTION_HANDLERS))(AutomationProgress);
