import { JIRA } from "./addTestCaseToJira.fields";

const SECTIONS = [
    {
      rows: [
        {
          columns: [JIRA.id],
        },
      ],
    },
  ];
  
  export default SECTIONS;
  