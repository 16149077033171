import _get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';


import ACTION_TYPES from '../constants/IntegrationHealth.actionTypes';
import { getTestSessions } from '../services/integrationHealth.api';


const handleFieldChange = ({ params, setState }) => {
  const id = _get(params, 'value.payload.id', EMPTY_STRING);
  const selectedValue = _get(params, 'value.payload.value', EMPTY_ARRAY);
  if (id === 'Project') {
    setState({
      selectedProject: selectedValue,
    });
  } else if (id === 'Environment') {
    setState({
      selectedEnvironment: selectedValue,
    });
  } else {
    setState({
      selectedReleaseVersion: selectedValue,
    });
  }
};

const handleSearchClick = async ({ getState, setState }) => {
  const { selectedEnvironment, selectedProject, selectedReleaseVersion } = getState();
  setState({
    isLoading: true,
  });
  const testSessionResponse = await getTestSessions(selectedEnvironment, selectedProject, selectedReleaseVersion);
  setState({
    isLoading: false,
    isResetClicked: false,
    testSessionResponse,
  });
};

const handleResetClick = ({ setState }) => {
  setState({
    isResetClicked: true,
  });
};

export const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [ACTION_TYPES.ON_SEARCH_CLICK]: handleSearchClick,
  [ACTION_TYPES.ON_RESET_CLICK]: handleResetClick,
};
