import React, { useCallback, useMemo, useState } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _first from 'lodash/first';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
import Button from 'tcomponents/atoms/Button';
// import { withRouter } from 'react-router-dom';
import withActions from 'tcomponents/connectors/withActions';
import { EMPTY_ARRAY } from '@tekion/tekion-base/app.constants';
import _map from 'lodash/map';
import ViewTrackingControlList from './organisms/ViewTrackingControlList/ViewTrackingControlList';
import ACTION_TYPES from './constants/viewTrackingControl.actionTypes';
import AddTrackingControlModal from './organisms/AddTrackingControlModal/AddTrackingControlModal';
import ACTION_HANDLERS from './helper/viewTrackingControl.actionHandlers';
import styles from './organisms/ViewTrackingControlList/viewTrackingControlList.module.scss';
// import { EMPTY_ARRAY } from 'tbase/app.constants';
// import { triggerSubmit } from 'tcomponents/pages/formPage/utils/formAction';


const ViewTrackingControl = (props) => {
  const {
    onAction,
    isVisible,
    formValues,
    isLoading,
    tableData,
    isTrackingControlModalLoading,
  } = props;

  // const CONTEXT_ID = 'UPLOAD_TESTCASES_FORM';
  const handleOnClick = (value) => {
    onAction({
      type: ACTION_TYPES.OPEN_ADD_TRACKING_CONTROL_MODAL,
      payload: { isVisible },
    });
  };
  const handleOnCancel = (value) => {
    onAction({
      type: ACTION_TYPES.CLOSE_ADD_TRACKING_CONTROL_MODAL,
      payload: { isVisible },
    });
  };

  const onSubmit = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.FORM_SUBMIT_MODAL,
        payload: {
          formValues,
        },
      });
    },
    [onAction]
  );

  // const dataList = _get(tableData, 'data.data', EMPTY_ARRAY);
  // const isTrue = _map(dataList, data => data.isTrackingEnabled);
  return (
    <div>
      <PageComponent.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100vw' }}>
          <div>
            <Heading>Tracking Control</Heading>
          </div>
          <div>
            <Button
              view={Button.VIEW.PRIMARY}
              style={{ float: 'right' }}
              onClick={handleOnClick}
            >
                      Add Tracking Control
            </Button>

          </div>
        </div>
      </PageComponent.Header>

      <AddTrackingControlModal
        isTrackingControlModalLoading={isTrackingControlModalLoading}
        onAction={onAction}
        isVisible={isVisible}
        onCancel={handleOnCancel}
        onSubmit={onSubmit}
        formValues={formValues}
      />

      <ViewTrackingControlList onAction={onAction} isLoading={isLoading} tableData={tableData} />
    </div>
  );
};


export default withActions({}, ACTION_HANDLERS)(ViewTrackingControl);
