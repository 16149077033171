/* eslint-disable rulesdir/no-inline-functions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Lodash
import _noop from 'lodash/noop';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';


// Constants
import { EMPTY_ARRAY } from 'tbase/app.constants';
import SIZES from 'tcomponents/molecules/Modal/constants/modal.sizes';
import TEST_RESULT_LIST_ACTION_TYPES from '../../constants/testResultsList.actiontypes';
import TestCaseDetails from '../../../../../../../../organisms/testCaseDetails';
import { BODY_STYLE } from './constants/testCaseDetailsModal.general';

// Components
import Modal from 'tcomponents/molecules/Modal';
import Loader from 'tcomponents/molecules/loader';
import useFetchData from 'tbase/customHooks/useFetchData';


function TestCaseDetailsModal(props) {
  const {
    isDetailsModalVisible, onAction, isDetailsModalLoading, testCaseValue, testCaseId,
  } = props;

  const handleMediaModalCancel = useCallback(() => {
    onAction({ type: TEST_RESULT_LIST_ACTION_TYPES.CLOSE_TESTCASE_DETAILS_MODAL });
  }, [onAction]);

  return (
    <Modal
      title={`${testCaseId} - Details (View Only)`}
      visible={isDetailsModalVisible}
      onCancel={handleMediaModalCancel}
      bodyStyle={BODY_STYLE}
      hideSubmit
      width={Modal.SIZES.XL}
      destroyOnClose
    >
      <TestCaseDetails
        isDetailsModalLoading={isDetailsModalLoading}
        values={testCaseValue}
      />
    </Modal>
  );
}

TestCaseDetailsModal.propTypes = {
  isDetailsModalVisible: PropTypes.bool,
  onAction: PropTypes.func,
  isDetailsModalLoading: PropTypes.bool,
};

TestCaseDetailsModal.defaultProps = {
  isDetailsModalVisible: false,
  onAction: _noop,
  isDetailsModalLoading: false,
};

export default TestCaseDetailsModal;
