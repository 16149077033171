import _get from 'lodash/get';
import addOptionsToAdditional from 'tbase/utils/addOptionsToAdditional';
import {
  FEATURE, STATUS, PRIORITY, AUTOMATED, DEFAULT_FILTER_TYPES,
} from '../constant/addTestCaseList.filterTypes';
import makefeatureOptions from '../../../../../constants/featureOptions';

const createFilterTypes = (tcmsBootStrap, featureListByProjectId, projectId,) => {
  // const featureList = _get(tcmsBootStrap, 'featureList');
  const featureList = featureListByProjectId[projectId];

  const featureOptions = makefeatureOptions(featureList);
  const featureFilter = addOptionsToAdditional(FEATURE, featureOptions);
  const filterTypes = [featureFilter, STATUS, PRIORITY, AUTOMATED];
  return filterTypes;
};

export const createFilterProps = ({
  selectedFilters, tcmsBootStrap, featureListByProjectId, projectId,
}) => {
  const filterTypes = createFilterTypes(tcmsBootStrap, featureListByProjectId, projectId,);
  return {
    filterTypes,
    defaultFilterTypes: DEFAULT_FILTER_TYPES,
    selectedFilters,
    shouldHideSaveFilterGroup: true,
    showFilterGroupName: false,
    shouldHideFilterGroup: true,
    showResetToDefaultGroup: false,
  };
};
