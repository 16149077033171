const ACTION_TYPES = {
  ON_TAB_CHANGE: 'ON_TAB_CHANGE',
  TABLE_ITEMS_FETCH: 'TABLE_ITEMS_FETCH',
  REDIRECT_TO_CREATE_TEST_CASE: 'REDIRECT_TO_CREATE_TEST_CASE',
  OPEN_UPLOAD_TEST_CASE_MODAL: 'OPEN_UPLOAD_TEST_CASE_MODAL',
  UPLOAD_TEST_CASE_MODAL_ON_CANCEL: 'UPLOAD_TEST_CASE_MODAL_ON_CANCEL',
  UPLOAD_TEST_CASE_MODAL_ON_SUBMIT: 'UPLOAD_TEST_CASE_MODAL_ON_SUBMIT',
};

export default ACTION_TYPES;
