import _size from 'lodash/size';
import _get from 'lodash/get';
import {
  PASS, FAIL, PENDING, SKIPPED, RE_RUN_PASS,
} from '../../../constants/testResultsList.status';

export const getTestCasesCount = (data) => {
  let pass = 0;
  let fail = 0;
  let skipped = 0;
  let pending = 0;
  let reRunPass = 0;
  const totalTestcases = _size(data);
  for (let i = 0; i < totalTestcases; i++) {
    const status = _get(data[i], 'status');
    if (status == PASS.id) {
      pass += 1;
    }
    if (status == FAIL.id) {
      fail += 1;
    }
    if (status == PENDING.id) {
      pending += 1;
    }
    if (status == SKIPPED.id) {
      skipped += 1;
    }
    if (status == RE_RUN_PASS.id) {
      reRunPass += 1;
    }
  }

  return ({
    totalTestcases, pass, fail, skipped, pending, reRunPass,
  });
};
