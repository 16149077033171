// Components
import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
// Utils
import { isRequiredRule } from 'tbase/utils/formValidators';

export const PRODUCT = {
  id: 'productId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'projectId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Project'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const FEATURE = {
  id: 'featureName',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Module'),
    showSearch: true,
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};
export const SUBFEATURE = {
  id: 'subfeatureName',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('SubModule'),
    showSearch: true,
    placeholder: __('Select'),
  },
};
