export const TABLE_ROW_ACTIONS = [
  {
    name: __('Not Started'),
    id: 'NOT_STARTED',
  },
  {
    name: __('In Progress'),
    id: 'IN_PROGRESS',
  },
  {
    name: __('Complete'),
    id: 'COMPLETED',
  },

];
