const ACTION_TYPES = {
  OPEN_DELETE_CONFIRMATION_DIALOG: 'OPEN_DELETE_CONFIRMATION_DIALOG',
  CLOSE_DELETE_CONFIRMATION_DIALOG: 'CLOSE_DELETE_CONFIRMATION_DIALOG',
  DELETE_TEST_SESSION: 'DELETE_TEST_SESSION',
  CLONE_TEST_SESSION: 'CLONE_TEST_SESSION',
  EDIT_TEST_SESSION: 'EDIT_TEST_SESSION',
  REDIRECT_TO_ADD_TEST_CASES: 'REDIRECT_TO_ADD_TEST_CASES',
  DOWNLOAD_TEST_RESULTS: 'DOWNLOAD_TEST_RESULTS',
  REDIRECT_TO_EXECUTION_SCREEN:'REDIRECT_TO_EXECUTION_SCREEN',
};

export default ACTION_TYPES;
