import React, {useState} from 'react';

import Checkbox from 'tcomponents/atoms/checkbox';
import {PropertyControlledComponent} from "tcomponents/molecules";

import styles
    from "../../../../../../../../organisms/executeTestCases/executeTestCasesForm/executeTestCasesForm.module.scss";


const NonAutomatedWarningMessage = ({ isNonAutomatedSelected, isChecked, setIsChecked }) => {

    const handleCheckBoxChange = (event) => {
        setIsChecked(event.target.checked);
    }

    return (
        <div>
            <p>Are you sure you want to re run the failed cases?</p>
            <PropertyControlledComponent controllerProperty={isNonAutomatedSelected}>
                <div>
                    <div className={styles.checkedBox}>
                        <Checkbox checked={isChecked} onChange={handleCheckBoxChange} />
                        <span>You have selected non automated test case. Please select the checkbox to proceed the execution.
                            <br />NOTE : TestSession has to be closed manually
                        </span>
                    </div>
                </div>
            </PropertyControlledComponent>
        </div>
    )
}

export default NonAutomatedWarningMessage;
