import React, { useCallback, useState, useEffect } from 'react';
import FieldLabel from 'tcomponents/organisms/FormBuilder/components/fieldLabel';
import CreatableSelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelectInput';
import { EMPTY_ARRAY } from 'tbase/app.constants';
import { tget } from 'tbase/utils/general';
import _noop from 'lodash/noop';

function AddLabelForm({ tagValue, setTagValue }) {
  const [tags, setTags] = useState([]);

  function triggerTagsHandler() {
    let tags = JSON.parse(localStorage?.getItem('tags'));
    if (tags) {
      if (tags != null) tags = Object.values(tags);
      const tagData = new Set();
      if (tags != null) {
        tags.forEach((lData) => {
          tagData.add(...lData);
        });
      }
      const result = [];
      tagData.forEach((d) => {
        if (d) {
          result.push({
            value: d,
            label: d,
          });
        }
      });
      setTags(result);
    }
  }

  useEffect(() => {
    triggerTagsHandler();
    return () => {
      setTags([]);
    };
  }, []);

  const handleChange = useCallback((action) => {
    const value = tget(action, 'payload.value', EMPTY_ARRAY)[0];
    const temp = tags.slice();
    if (!temp.map(x => x.value).includes(value)) setTags([...temp, { label: value, value }]);
    setTagValue([value]);
  }, EMPTY_ARRAY);

  const getHandleOnChange = useCallback(
    action => (action.type === 'ON_CHANGE' ? handleChange(action) : _noop),
    EMPTY_ARRAY
  );

  return (
    <div>
      <FieldLabel label="Select Tag" required={false} />
      <CreatableSelectInput
        value={tagValue}
        options={tags}
        className="full-width m-r-20"
        onAction={getHandleOnChange}
      />
    </div>
  );
}

export default AddLabelForm;
