import { setCookie } from '@tekion/tap-components/utils/helper';
import { toaster } from 'tcomponents/organisms/NotificationWrapper';
import { fetchUserDetailsApi, fetchUpdateUserDetailsApi } from '../api/Profile';
import { CONSTANTS } from '../constants/Constants';
import { getUserProfile, updateUserProfile } from '../api/urls';
const { ACTIONS } = CONSTANTS;

export const fetchGetUserDetails = baseUrl => (
  (dispatch) => {
    dispatch({ type: ACTIONS.LOADER, payload: true });
    const url = `${baseUrl}${getUserProfile}`;
    return fetchUserDetailsApi(url).then((response) => {
      const { firstName, lastName } = response.data;
      if (firstName && lastName) {
        const userName = `${firstName || ''} ${lastName || ''}`;
        setCookie('uname', userName);
      }
      dispatch({ type: ACTIONS.FETCH_GET_USER_DETAILS_SUCCESS, payload: response });
      dispatch({ type: ACTIONS.LOADER, payload: false });
    }).catch((error) => {
      dispatch({ type: ACTIONS.LOADER, payload: false });
      dispatch({ type: ACTIONS.FETCH_GET_USER_DETAILS_ERROR, payload: error });
    });
  }
);

export const updateUserDetails = (data, baseUrl) => (
  (dispatch) => {
    const url = `${baseUrl}${updateUserProfile}`;
    dispatch({ type: ACTIONS.LOADER, payload: true });
    return fetchUpdateUserDetailsApi(url, data).then(() => {
      const userName = `${data.firstName || ''} ${data.lastName || ''}`;
      setCookie('uname', userName);
      toaster('success', __('Profile details updated successfully'));
      dispatch({ type: ACTIONS.FETCH_UPDATE_USER_DETAILS_SUCCESS, payload: data });
      dispatch({ type: ACTIONS.LOADER, payload: false });
    }).catch((error) => {
      toaster('error', __('Failed to update profile details'));
      dispatch({ type: ACTIONS.FETCH_UPDATE_USER_DETAILS_ERROR, payload: error });
      dispatch({ type: ACTIONS.LOADER, payload: false });
    });
  }
);

export const resetAPISuccessEvent = () => (
  (dispatch) => {
    dispatch({ type: ACTIONS.RESET_USER_DETAIL_API_EVENT });
  }
);
