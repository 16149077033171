import { RELEASE_TAG, PROJECT,TRACKING_ENABLED } from './AddTrackingControlForm.fields';

const FORM_CONFIG = {
  // [PRODUCT.id]: PRODUCT,
  [RELEASE_TAG.id]: RELEASE_TAG,
  // [MODULE.id]: MODULE,
  [PROJECT.id]: PROJECT,
  [TRACKING_ENABLED.id]: TRACKING_ENABLED,
};

export default FORM_CONFIG;
