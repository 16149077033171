import _get from 'lodash/get';
import _size from 'lodash/size';
import _pick from 'lodash/pick';
import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';

import TABLE_ACTION_TYPES from 'tcomponents/organisms/TableManager/constants/actionTypes';
import TEXT_WITH_LINK_ACTION_TYPES from 'tcomponents/molecules/CellRenderers/textWithLinkRenderer/textWithLink.actionTypes';
import { getFiltersWithOutNullValue } from 'tcomponents/organisms/filterSection/utils/filterSection.utils';
import ACTION_TYPES from '../constants/testSessionList.actionTypes';

import {
  getTestSession, postBulkUpdateTestSessions, searchTestSessionFilter, getTestSessionReport, getStopExecution
} from '../../../../../services/testSessions.services';
import {
  filterDTO, testSessionDTO, updateBulkTestSessions, paginationDTO, paginationDTOwithoutFilter, stopExecutionDTO
} from './testSessionList.request';
import ROW_ACTION_HANDLERS from './testSessionList.rowActionHandler';
import HandleErrorAndStatus from '../../../../../../../helpers/ErrorFunction/errorFunction';
import { GLOBAL_ACTION_TYPES } from '../../../../../../../constants/Constants';

const handleSuccessUpdate = (response) => {
  const statusCode = _get(response, 'data.status');
  if (statusCode === 'Success') {
    toaster(TOASTER_TYPE.SUCCESS, __('Status Updated'));
  }
};

const handleTableItemsFetch = async ({ setState, getState }) => {
  const {
    tabId, searchText, currentPage, pageSize, selectedFilters, sort,
  } = getState();
  setState({ isLoading: true });
  const getTestSessionDTO = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
  const responseData = await getTestSession(getTestSessionDTO);
  const testSessionsData = _get(responseData, 'data.data.hits', []);
  const countTestSessions = _get(responseData, 'data.data.count', 0);
  const pageCount = _get(responseData, 'data.data.pageCount');
  setState({
    totalCount: countTestSessions,
    data: testSessionsData,
    isLoading: false,
    pageCount,
  });
};

const handleItemsSetFilter = async ({ params, getState, setState }) => {
  const {
    searchText, tabId, currentPage, pageSize, sort,
  } = getState();

  const { value } = params;
  const selectedFilters = getFiltersWithOutNullValue(value);
  setState({ isLoading: true });
  const filterReuestDTO = testSessionDTO(tabId, searchText, 1, pageSize, selectedFilters, sort);
  const searchData = await searchTestSessionFilter(filterReuestDTO);
  const testSessionsData = _get(searchData, 'data.data.hits');
  const countTestSessions = _size(testSessionsData);
  setState({
    totalCount: countTestSessions,
    data: testSessionsData,
    selectedFilters,
    isLoading: false,
    currentPage: 1,
  });
};

const handleTableItemSelect = ({ params, setState }) => {
  const { value: selection } = params;
  setState({ selection });
};

const handleRowActions = ({ params, setState, getState }) => {
  const { action } = params;
  // console.log(action);
  const actionHandler = ROW_ACTION_HANDLERS[action];
  actionHandler({ setState, getState, params });
};

const handleBulkRowAction = async ({ params, getState, setState }) => {
  setState({ isLoading: true });
  const { selection } = getState();
  const action = _get(params, 'action');
  const key = _get(action, 'key');
  const statusDTO = updateBulkTestSessions(selection, key);
  await postBulkUpdateTestSessions(statusDTO).then(response => handleSuccessUpdate(response));
  handleTableItemsFetch({ setState, getState });
};

const handleRedirectToTestResults = ({ params, getState }) => {
  const { history } = getState();
  const rowData = _get(params, 'rowData');
  const testSessionId = _get(rowData, 'id');
  const productId = _get(rowData, 'productId');
  const projectId = _get(rowData, 'projectId');
  // history.push(`/tcmsuiV2/testsession/testresults/${productId}/${projectId}/${testSessionId}`);
  window.open(`/tcmsuiV2/testsession/testresults/${productId}/${projectId}/${testSessionId}`, '_blank');
};

const handleTestSessionNameClick = ({ params, getState }) => {
  const { history } = getState();
  const rowData = _get(params, 'column');
  const testSessionId = _get(rowData, 'id');
  const productId = _get(rowData, 'productId');
  const projectId = _get(rowData, 'projectId');
  // history.push(`/tcmsuiV2/testsession/testresults/${productId}/${projectId}/${testSessionId}`);
  window.open(`/tcmsuiV2/testsession/testresults/${productId}/${projectId}/${testSessionId}`, '_blank');
}

const handlePagination = async ({ params, setState, getState }) => {
  const {
    tabId, selectedFilters, searchText, sort,
  } = getState();
  const { value } = params;
  const { page: currentPage, resultsPerPage: pageSize } = value;
  if (selectedFilters === undefined) {
    const pagination = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
    setState({ isLoading: true });
    const responseData = await getTestSession(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  } else {
    const pagination = testSessionDTO(tabId, searchText, currentPage, pageSize, selectedFilters, sort);
    setState({ isLoading: true });
    const responseData = await getTestSession(pagination);
    const testCaseData = _get(responseData, 'data.data.hits');
    setState({
      data: testCaseData,
      isLoading: false,
      pageSize,
      currentPage,
    });
  }
};

const handleSortChange = ({ params, setState, getState }) => {
  const { value } = params;
  const sortTypeMap = _get(value, 'sortTypeMap');
  const keyToSort = _get(value, 'column.key',);
  const sortDetails = _pick(sortTypeMap, keyToSort);
  setState({
    sortDetails,
  });
};

const handleSearch = async ({ params, setState, getState }) => {
  const { value: searchText } = params;
  const {
    tabId, currentPage, pageSize, selectedFilters, sort,
  } = getState();
  setState({ isLoading: true });
  const filterReuestDTO = testSessionDTO(tabId, searchText, 1, pageSize, selectedFilters, sort);
  const searchData = await getTestSession(filterReuestDTO);
  const testSessionData = _get(searchData, 'data.data.hits');
  const countTestSessions = _get(searchData, 'data.data.count');
  setState({
    totalCount: countTestSessions,
    data: testSessionData,
    isLoading: false,
    searchText,
    currentPage: 1,
  });
};

const handleOnDownloadReport = async ({ params, setState, getState }) => {
  const { executionId } = params;
  try {
    toaster(TOASTER_TYPE.INFO, __('Generating Report'));
    const responseData = await getTestSessionReport(executionId);
    const reportLink = _get(responseData, 'data.data');
    toaster(TOASTER_TYPE.SUCCESS, __('Report Generation Successfull...Check Downloads'));
    window.open(reportLink, '_self');
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

const handleStopExecutionButton = async ({ params, setState, getState }) => {
  const { executionId } = params;
  try {
    toaster(TOASTER_TYPE.INFO, __('Stopping Execution'));
    const getStopExecutionDTO = stopExecutionDTO(executionId);
    const responseData = await getStopExecution(getStopExecutionDTO).then(()=>{ 
      toaster(TOASTER_TYPE.SUCCESS, __('Execution is stopped'));
    });
  } catch (error) {
    HandleErrorAndStatus(error);
  }
};

const handleExecutionModalCancel = ({ setState }) => {
  setState({
    isExecutionScreenModalVisible: false,
  })
}

const testSessionModalCancel = ({getState, setState}) => {
  setState({isModalVisible : false});
  handleTableItemsFetch({getState, setState});
}

const handleNewFormValues = ({params, setState}) => {
  setState({newFormValues : params.formValues});
}

const ACTION_HANDLERS = {
  [TABLE_ACTION_TYPES.TABLE_ITEMS_FETCH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEM_SELECT]: handleTableItemSelect,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SET_FILTER]: handleItemsSetFilter,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_REFRESH]: handleTableItemsFetch,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_PAGE_UPDATE]: handlePagination,
  [TABLE_ACTION_TYPES.TABLE_ITEMS_SORT]: handleSortChange,
  [TABLE_ACTION_TYPES.TABLE_SEARCH]: handleSearch,
  [TEXT_WITH_LINK_ACTION_TYPES.TEXT_CLICK]: handleRedirectToTestResults,
  [ACTION_TYPES.ROW_ACTION]: handleRowActions,
  [ACTION_TYPES.ROW_BULK_ACTION]: handleBulkRowAction,
  [ACTION_TYPES.ON_DOWNLOAD_REPORT]: handleOnDownloadReport,
  [ACTION_TYPES.ON_CANCEL_EXECUTION_MODAL]: handleExecutionModalCancel,
  [ACTION_TYPES.ON_TEST_SESSION_NAME_CLICK]: handleTestSessionNameClick,
  [GLOBAL_ACTION_TYPES.ON_STOP_EXECUTION]: handleStopExecutionButton,
  [ACTION_TYPES.ON_TEST_SESSION_MODAL_CANCEL] : testSessionModalCancel,
  [ACTION_TYPES.HANDLE_NEW_FORM_VALUES] : handleNewFormValues
};

export default ACTION_HANDLERS;
