import { toaster, TOASTER_TYPE } from 'tcomponents/organisms/NotificationWrapper';
import urls from '../../../api/urls';
import TokenManager from '@tekion/tap-components/utils/TokenManager';


const { TRR_API_URL } = process.env;
const { TRR_API_KEY } = process.env;
const { TRR_APP_KEY } = process.env;

const JWT_TOKEN = TokenManager.getItem(TokenManager.accessTokenKey);

export const exportTestCase = async (requestDTO) => {
  await fetch(`${TRR_API_URL}/cqa` + `${urls.exportTestCaseExcel}`, {
    method: 'POST',
    headers: {
      // 'app-key': TRR_APP_KEY,
      'x-api-key': TRR_API_KEY,
      'content-type': 'application/json',
      Authorization: `Bearer ${JWT_TOKEN}`,
    },

    body: JSON.stringify(requestDTO),
  })
    .then(response => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'TESTCASE_DOWNLOAD.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  // .catch(toaster(TOASTER_TYPE.ERROR, __('Some Error Occured')));
};
