import _get from 'lodash/get';

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'tbase/app.constants';
import { AUTHOR } from './uploadTestCasesForm.fields';

// const oktaTokenStorage = _get(localStorage, 'okta-token-storage');
// const objectOktaStorageToken = JSON.parse(oktaTokenStorage);

// const idToken = _get(objectOktaStorageToken, 'idToken');

// const claims = _get(idToken, 'claims');

// const oktaUserName = _get(claims, 'email');

const INITIAL_STATE = {
  // formValues: {
  //   [AUTHOR.id]: oktaUserName || 'user@tekion.com',
  // },
  mediaList: EMPTY_ARRAY,
  errors: EMPTY_OBJECT,
};

export default INITIAL_STATE;
