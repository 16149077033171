import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import updateDirection from 'ti18n/utils/updateDirection';
import TekionI18nProvider from 'ti18n/TekionI18nProvider';
import { ShortcutProvider } from 'tcomponents/molecules/Accessibility/Shortcuts';
import ErrorBoundary from 'tcomponents/atoms/ErrorBoundary';
import AutoFontResizer from '@tekion/tap-components/molecules/AutoFontResizer/AutoFontResizer';
import makeStore from 'tbase/redux/store';
import history from '../tap-dev-kit/common/utils/history';
import i18n from './i18nConfig';
import App from '../app/pages/base';
import reducers from '../app/store';
import 'sanitize.css/sanitize.css';
import 'tstyles/index.scss';
import LanguageSelector from '../app/molecules/LanguageSelector';
import NavigationBar from '../app/organisms/navigationBar';

const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.render(
    <TekionI18nProvider i18n={i18n}>
      <Provider store={makeStore(reducers, history)}>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <ShortcutProvider>
              <AutoFontResizer displayScales={{ L: 62.5 }} />
              <LanguageSelector />
              <NavigationBar />
              <App />
            </ShortcutProvider>
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>
    </TekionI18nProvider>,
    MOUNT_NODE
  );
};


const main = () => {
  updateDirection(i18n);
  if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['../app/pages/base'], () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    });
  }
  render();
};


main();
