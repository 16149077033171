import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
// constants
import { EMPTY_ARRAY } from 'tbase/app.constants';

// components
import UploadTestCasesForm from '../../../../organisms/uploadTestCases/uploadTestCasesFormCreate';

// helpers
import createTestCaseSubmit from './helpers/createTestCase.onFormSubmit';

const CreateTestCase = (props) => {
  const { history } = props;

  // const headerProps = useMemo(getHeaderProps, EMPTY_ARRAY);
  const handleCreateTestCaseSubmit = useCallback(
    formValues => createTestCaseSubmit({ formValues, history })
  );

  return (
    <div>
      <UploadTestCasesForm
        onSubmit={handleCreateTestCaseSubmit}
      />
    </div>
  );
};

CreateTestCase.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CreateTestCase;
