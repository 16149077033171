import _get from "lodash/get";
import _head from "lodash/head";
import _toArray from "lodash/toArray";
import {EMPTY_ARRAY, EMPTY_STRING} from "@tekion/tekion-base/app.constants";


export const makeJiraIssueRequestDTO = formValues => {
    console.log(formValues)
    const jql = _get(formValues, 'jiraQuery', EMPTY_STRING);
    const projectId = _head(_get(formValues, 'project', EMPTY_ARRAY));
    const name = _get(formValues, 'testSessionName', EMPTY_STRING);
    const environment = _head(_get(formValues, 'env', EMPTY_ARRAY));
    const releaseVersion = _head(_get(formValues, 'release', EMPTY_ARRAY));
    const type = _head(_get(formValues, 'type', EMPTY_ARRAY));
    const status = _head(_get(formValues, 'status', EMPTY_ARRAY));

    return {
        jql,
        testSessionDto: {
            projectId,
            name,
            environment,
            releaseVersion,
            type,
            status
        }
    }
}

export const makeValidateJqlDto = formValues => {
    const jiraQuery = _get(formValues, 'jiraQuery', EMPTY_STRING);
    return {
        queries: [jiraQuery],
    }
}
