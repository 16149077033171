import _constant from 'lodash/constant';

import _size from 'lodash/size';
import Button from 'tcomponents/atoms/Button';

import TABLE_TYPES from 'tcomponents/organisms/TableManager/constants/tableTypes';
import ACTION_TYPES from '../constant/addTestCasesList.actionTypes';

// import ROW_ACTIONS from '../constants/testResultsList.status';

export const getHeaderProps = (selection, isAddTestCaseLoading) => ({
  label: __('Add Test Cases'),
  hasBack: true,
  headerRightActions: [
    {
      renderer: Button,
      renderOptions: {
        label: __('Add'),
        view: Button.VIEW.PRIMARY,
        loading: isAddTestCaseLoading,
        disabled: !_size(selection),
      },
      action: ACTION_TYPES.ADD_TEST_CASES,
    },
  ],
});

export const makeTableProps = ({
  isLoading,
  totalCount,
  columnConfigurator,
  pageSize,
  onAction,
  selection,
  sortDetails,
  currentPage,

}) => ({
  checkboxHeaderClassName: 'm-l-8',
  loading: isLoading,
  showActions: true,
  selection,
  rowHeight: 50,
  totalNumberOfEntries: totalCount,
  columnConfigurator,
  getTdProps: _constant({ onAction }),
  type: TABLE_TYPES.FIXED_COLUMN,
  sortDetails,
  shouldAdaptSortDetails: false,
  showPagination: true,
  pageSize,
  currentPage,
});
