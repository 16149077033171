// Libraries
import _head from 'lodash/head';

// Readers
import mediaReader from 'tbase/readers/Media';

// Components
import { TOASTER_TYPE, toaster } from 'tcomponents/organisms/NotificationWrapper';

// Utils
import getDataFromResponse from 'tbase/utils/getDataFromResponse';
import FORM_ACTION_TYPES from 'tcomponents/organisms/FormBuilder/constants/actionTypes';
import getCompactList from '../../../../utils/getCompactList';

// Constants
import ACTION_TYPES from '../constants/uploadTestCasesForm.actionTypes';
import {
  PRODUCT, PROJECT, FEATURE, SUBFEATURE,
} from '../constants/uploadTestCasesForm.fields';
import Feature from '../../builders/testCases.featureBuilder';
// Services
import { downloadExcelTemplate } from '../services/uploadTestCasesForm';
import HandleErrorAndStatus from '../../../../helpers/ErrorFunction/errorFunction';
const EMPTY_OBJECT = Object.freeze({});

const handleProductFieldChange = ({
  id, value, formValues, setState,
}) => {
  setState({
    formValues: {
      ...formValues, [id]: value, [PROJECT.id]: undefined, [FEATURE.id]: undefined,
    },
  });
};

const handleProjectFieldChange = ({
  id, value, formValues, setState,
}) => {
  setState({ formValues: { ...formValues, [id]: value, [FEATURE.id]: undefined } });
};

const FIELD_CHANGE_MAP = {
  [PRODUCT.id]: handleProductFieldChange,
  [PROJECT.id]: handleProjectFieldChange,
};

const handleOnFieldChange = ({ params, getState, setState }) => {
  const { id, value, option } = params;
  const { formValues } = getState();
  const fieldChangeHandler = FIELD_CHANGE_MAP[id];
  if (fieldChangeHandler) {
    fieldChangeHandler({
      id, value, formValues, setState,
    });
  } else if (id == 'featureName') {
    setState({
      formValues: {
        ...formValues, [id]: value, featureId: option.additional.id, newfeature: undefined,
      },
    });
  } else if (id == 'subfeatureName') {
    setState({
      formValues: {
        ...formValues, [id]: value, subfeatureId: option.additional.id, newsubfeature: undefined,
      },
    });
  } else {
    setState({ formValues: { ...formValues, [id]: value } });
  }
};

const handleCreateFeature = ({ params, getState, setState }) => {
  const { payload } = params;
  const { featureName } = params || EMPTY_OBJECT;
  const { formValues, featureListByProjectId } = getState();
  const projectId = _head(formValues[PROJECT.id]);
  const newFeature = new Feature().setName(featureName).setProjectId(projectId);
  const featureList = featureListByProjectId[projectId];
  const updatedFeatureList = getCompactList(featureList, newFeature);
  const updatedFeatureListByProjectId = { ...featureListByProjectId, [projectId]: updatedFeatureList };
  setState({
    formValues: {
      ...formValues, [FEATURE.id]: [featureName], newfeature: 'yes', featureId: undefined,
    },
    isNewFeatureCreated: true,
    featureListByProjectId: updatedFeatureListByProjectId,
  });
};
const handleCreateSubFeature = ({ params, getState, setState }) => {
  const { payload } = params;
  const { subfeatureName } = params || EMPTY_OBJECT;
  const { formValues, subfeatureListByFeatureId } = getState();
  const { featureId } = formValues;
  const projectId = _head(formValues[PROJECT.id]);
  const newsubFeature = new Feature().setName(subfeatureName).setProjectId(projectId);
  const subfeatureList = subfeatureListByFeatureId[featureId];
  const updatedsubFeatureList = getCompactList(subfeatureList, newsubFeature);
  const updatedsubFeatureListByFeatureId = { ...subfeatureListByFeatureId, [featureId]: updatedsubFeatureList };
  setState({
    formValues: {
      ...formValues, [SUBFEATURE.id]: [subfeatureName], newsubfeature: 'yes', subfeatureId: undefined,
    },
    isNewFeatureCreated: true,
    subfeatureListByFeatureId: updatedsubFeatureListByFeatureId,
  });
};

const handleFormErrors = ({ params, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleOnFormSubmit = ({ getState }) => {
  const { mediaList, formValues, onSubmit } = getState();
  const media = _head(mediaList);
  const mediaId = mediaReader.mediaId(media);
  onSubmit(mediaId, formValues, mediaList);
};

const handleSetMedia = ({ getState, setState, params }) => {
  const { mediaList } = params;
  setState({
    mediaList,
  });

};

const handleDownloadTemplateError = (error) => {
  toaster(TOASTER_TYPE.ERROR, __('Error occured'));
};

const handleDownloadTemplateSuccess = (response) => {
  const templateData = getDataFromResponse(response);
  const templateExcelUrl = mediaReader.url(templateData);
  window.open(templateExcelUrl, '_self');
  toaster(TOASTER_TYPE.SUCCESS, __('Template Generation successful'));
};

export const handleDownloadTemplate = async () => {
  toaster(TOASTER_TYPE.INFO, __('Fetching Testcase Template...Please Wait for a while'));
  await downloadExcelTemplate()
    .then((responseJSON) => {
      const url = responseJSON.data.data;
      const templatefile = document.createElement('a');
      templatefile.href = url;
      templatefile.download = 'Testcases_Template.xlsx';
      templatefile.click();
    }).catch(error => HandleErrorAndStatus(error));
};

const ACTION_HANDLERS = {
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleOnFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleFormErrors,
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleOnFormSubmit,
  [ACTION_TYPES.SET_MEDIA]: handleSetMedia,
  [ACTION_TYPES.CREATE_FEATURE]: handleCreateFeature,
  [ACTION_TYPES.CREATE_SUBFEATURE]: handleCreateSubFeature,
  [ACTION_TYPES.ON_DOWNLOAD_TEMPLATE]: handleDownloadTemplate,
};

export default ACTION_HANDLERS;
