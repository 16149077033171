// Constants
// Constants
import { COLOR_MAP } from 'tbase/constants/statusColors';

// Utils
import standardFieldOptionMapper from 'tbase/utils/optionMappers/standardFieldMapper';

export const ACTIVE = {
  name: __('Yes'),
  id: true,
};

export const INACTIVE = {
  name: __('No'),
  id: false,
};

export const ACTIVE_LABEL_MAP = {
  [ACTIVE.id]: ACTIVE.name,
  [INACTIVE.id]: INACTIVE.name,
};

export const ACTIVE_COLOR_MAP = {
  [ACTIVE.id]: COLOR_MAP.GREEN,
  [INACTIVE.id]: COLOR_MAP.GREY,
};

const ACTIVE_STATUSES = [ACTIVE, INACTIVE];

const ACTIVE_OPTIONS = standardFieldOptionMapper(undefined, ACTIVE_STATUSES);

export default ACTIVE_OPTIONS;
