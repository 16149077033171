export const NOTIFICATION_DAYS = [
  { label: 'Mon', value: '1' },
  { label: 'Tue', value: '2' },
  { label: 'Wed', value: '3' },
  { label: 'Thu', value: '4' },
  { label: 'Fri', value: '5' },
  { label: 'Sat', value: '6' },
  { label: 'Sun', value: '0' },
];
export const TIME_FORMAT = 'HH:mm';
export const TIME_FIELDS = [
  { value: '300', label: '5', suffix: 'minutes' },
  { value: '1800', label: '30', suffix: 'minutes' },
  { value: '3600', label: '1', suffix: 'hour' },
  { value: '14400', label: '4', suffix: 'hours' },
  { value: '28800', label: '8', suffix: 'hours' },
  { value: 'tomorrow', label: '(9 am)', prefix: 'Tomorrow' },
];
