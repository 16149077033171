import { EMPTY_ARRAY } from "@tekion/tekion-base/app.constants";

export const TABLE_ROW_ACTIONS = [
  {
    name: __('Pass'),
    id: 'PASS',
  },
  {
    name: __('Fail'),
    id: 'FAIL',
  },
  {
    name: __('Pending'),
    id: 'PENDING',
  },
  {
    name: __('Re Run Pass'),
    id: 'RE_RUN_PASS',
  },
  {
    name: __('Skipped'),
    id: 'SKIPPED',
  },
];

export const TABLE_ROW_ACTIONS_GUEST = EMPTY_ARRAY
