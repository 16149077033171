import Http from '../../../../services/http';
import URL from '../../../../api/urls';

// export const getAllReleaseVersions = async () => {
//   const response = await Http.get('CQA', `${URL.getAllReleaseInfo}`);
//   return response;
// };
//
// export const getProjectListFromReleaseVersion = async (releaseVersion) => {
//   const response = await Http.get('CQA', `${URL.projectListByReleaseVersion}?releaseVersion=${releaseVersion}`);
//   return response;
// };

export const updateTestCase = async (payload) => {
  const response = await Http.post('CQA', `${URL.editTestCase}`, payload);
  return response;
};

// export const getAllProducts = async () => {
//   const response = await Http.get('CQA', `${URL.getAllProducts}`);
//   return response;
// };
