import React, { useCallback, useMemo } from 'react';

import { compose } from 'recompose';
import _get from 'lodash/get';
import _first from 'lodash/first';

// import colors from '@tekion/tekion-styles-next/scss/variables.scss';
import withActions from 'tcomponents/connectors/withActions';
// import TabbedSections from 'tcomponents/molecules/tabbedSections';
import PageComponent from 'tcomponents/molecules/pageComponent/PageComponent';
import Heading from 'tcomponents/atoms/Heading';
// import Button from 'tcomponents/atoms/Button';
// import Loader from 'tcomponents/molecules/loader';

// import useFetchData from 'tbase/customHooks/useFetchData';
import { withRouter } from 'react-router-dom';
// import ACTION_TYPES from './constants/viewTestPlan.actionTypes';
import ACTION_HANDLERS from './helpers/editTestPlan.actionHandlers';
import EditTestPlanList from './organisms/EditTestPlanList';
import styles from './editTestPlan.module.scss';

function ViewTestPlan(props) {
  const {
    onAction
  } = props;

  return (
    <div>
      <PageComponent.Header hasBack className="ProductListingContainer">
        <div className={`full-width ${styles.headerContainer}`}>
          <Heading size={1}>{__('Edit Test Plan')}</Heading>
          {/* <Button
            view={Button.VIEW.PRIMARY}
            highlightOnHover
            onClick={handleCreateTestSession}
          >
            {__('Create Test Plan')}
          </Button> */}
        </div>
      </PageComponent.Header>
      <EditTestPlanList />
    </div>
  );
}

// const mapStateToProps = state => ({
//   selectedTabId,
// });

export default compose(withRouter, withActions({}, ACTION_HANDLERS))(ViewTestPlan);
