// Constants
import { PRIORITY, AUTOMATED, RELEASE, ACTIVE } from './bulkUpdateTestCasesForm.fields';

// Styles
// import styles from '../uploadTestCasesForm.module.scss';

const SECTIONS = [
  {
    rows: [
      {
        columns: [PRIORITY.id],
      },
      {
        columns: [AUTOMATED.id],
      },
      {
        columns: [ACTIVE.id],
      },
      {
        columns: [RELEASE.id],
      },
    ],
  },
];

export default SECTIONS;
