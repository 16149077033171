import SelectInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/SelectInput';
import TextInputRenderer from 'tcomponents/organisms/FormBuilder/fieldRenderers/textInput';
import CreatableSelect from 'tcomponents/organisms/FormBuilder/fieldRenderers/CreatableSelect';
import RadioInput from 'tcomponents/organisms/FormBuilder/fieldRenderers/radio';

import TESTPLAN_RADIO_OPTIONS from '../../../../../constants/testPlanRadioOptions';

// Utils
import { isRequiredRule } from 'tbase/utils/formValidators';

export const SERVICE_NAME = {
  id: 'productId',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Service Name'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PRODUCT = {
  id: 'productId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Product'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const PROJECT = {
  id: 'projectId',
  renderer: SelectInput,
  renderOptions: {
    label: __('Module'),
    placeholder: __('Select'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const WORKFLOW_SCREEN = {
  id: 'workFlowScreen',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('WorkFlow Screen'),
    // placeholder: __('Type Name'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const ATTACHED_DESIGN = {
  id: 'attachedDesign',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Attached Design'),
    // placeholder: __('Type Info'),
    required: true,
    validators: [isRequiredRule],
  },
};

export const MODULES_AFFECTED = {
  id: 'modulesAffected',
  renderer: RadioInput,
  renderOptions: {
    label: __('Modules Affected'),
    options: TESTPLAN_RADIO_OPTIONS,
    required: true,
    validators: [isRequiredRule],
  },
};

export const AFFECTED_UPSTREAM_SERVICES = {
  id: 'affectedUpStream',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Affected Upstream Services'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const AFFECTED_DOWNSTREAM_SERVICES = {
  id: 'affectedDownStream',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Affected Downstream Services'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const JIRA_LINKS = {
  id: 'jira',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('Jira Links'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const INFO = {
  id: 'info',
  renderer: TextInputRenderer,
  renderOptions: {
    label: __('info'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const ANY_CHANGE_IN_SCHEMA = {
  id: 'anyChangeInSchema',
  renderer: RadioInput,
  renderOptions: {
    label: __('Any Change In schema'),
    options: TESTPLAN_RADIO_OPTIONS,
    validators: [isRequiredRule],
  },
};

export const ANY_DEALER_PROPERTY_TO_BE_SET = {
  id: 'anyDealerPropertyToBeSet',
  renderer: RadioInput,
  renderOptions: {
    label: __('Any Dealer Property To Be Set'),
    options: TESTPLAN_RADIO_OPTIONS,
    required: true,
    validators: [isRequiredRule],
  },
};

export const ANY_RESTRICTED_API_NEED_TO_SETUP = {
  id: 'anyRestrictedApiNeed',
  renderer: RadioInput,
  renderOptions: {
    label: __('Any Restricted API Need To Setup'),
    options: TESTPLAN_RADIO_OPTIONS,
    required: true,
    validators: [isRequiredRule],
  },
};

export const ANY_PERMISSIONS_ASSOCIATED = {
  id: 'anyPermissionsAssociated',
  renderer: RadioInput,
  renderOptions: {
    label: __('Any Permissions Associated'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const AREA_OF_REGRESSION = {
  id: 'areaOfRegression',
  renderer: CreatableSelect,
  renderOptions: {
    label: __('Area Of Regression'),
    // placeholder: __('Type '),
    required: true,
    validators: [isRequiredRule],
  },
};

export const AUTOMATED = {
  id: 'automated',
  renderer: RadioInput,
  renderOptions: {
    label: __('Modules Affected'),
    options: TESTPLAN_RADIO_OPTIONS,
    required: true,
    validators: [isRequiredRule],
  },
};
