import { EMPTY_ARRAY } from 'tbase/app.constants';

const INITIAL_STATE = {
  currentPage: 1,
  pageSize: 50,
  selectedFilters: EMPTY_ARRAY,
  searchText: undefined,
};

export default INITIAL_STATE;
