import React from 'react';

import { compose } from 'recompose';


import FormBuilder from 'tcomponents/organisms/FormBuilder';
import withActions from 'tcomponents/connectors/withActions';
import withEventSubmission from 'tcomponents/pages/formPage/withEventSubmission';

import ACTION_HANDLERS from './helpers/addTestPlanForm.actionHandlers';
import INITIAL_STATE from './constants/addTestPlanForm.initialState';
import SECTIONS from './constants/addTestPlanForm.sections';
import { FORM_CONFIG } from './constants/addTestPlanForm.formConfig';

const FormWithSubmission = withEventSubmission(FormBuilder);


const AddTestPlanForm = (props) => {
  const { onAction, errors } = props;
  return (
    <div>
      <FormWithSubmission
        fields={FORM_CONFIG}
        sections={SECTIONS}
        // values={formValues}
        onAction={onAction}
        errors={errors}
      />
    </div>
  );
};

export default compose(withActions(INITIAL_STATE, ACTION_HANDLERS))(AddTestPlanForm);
