import ACTION_TYPES from '../constants/editTestPlan.actionTypes';

const handleOnTabChange = () => {

};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_TAB_CHANGE]: handleOnTabChange,
};

export default ACTION_HANDLERS;
