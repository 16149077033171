/* eslint-disable rulesdir/no-array-functions */
// Constants
import _get from 'lodash/get';
import _size from 'lodash/size';

import { TOASTER_TYPE, toaster } from 'tcomponents/organisms/NotificationWrapper';
import { addUploadedTestCases } from '../../../../../organisms/uploadTestCases';
import ACTION_TYPES from '../constant/tabbedTestCases.actionTypes';

// Helpers
import TABBED_TEST_CASE_LIST_ACTION_TYPES from '../constant/tabbedTestCases.actionTypes';
import { makeBaseTestCaseUploadRequestDTO } from '../../../../../organisms/uploadTestCases';
import { UPLOAD_TEST_CASE_MODAL_SUCCESS_DELAY } from '../constant/tabbedTestCaseList.general';

import HandleErrorAndStatus from '../../../../../helpers/ErrorFunction/errorFunction';

const handleOnTabChange = ({ params, setState, getState }) => {
  const { state, history } = getState();
  const { value: selectedTabId } = params;
  history.push({ pathname:`/tcmsuiV2/testcase/list/${selectedTabId}`, params: { selectedTabId }});
  setState({
    selectedTabId,
  });
};

const handleRedirectToCreateTestCase = ({ getState }) => {
  const { history } = getState();
  // const route = getCreateTestCaseRoute();
  // history.push(route);
  history.push('/tcmsuiV2/testcase/create');
};

const handleOpenUploadTestCaseModal = ({ setState }) => {
  setState({ isUploadModalVisible: true });
};

const handleUploadTestCaseModalOnCancel = ({ setState }) => {
  setState({
    isUploadModalVisible: false,
    isUploadModalLoading: false,
  });
};

const handleUploadTestCaseModalSubmitSuccess = ({ toasterMessage, getState, setState }) => {
  toaster(TOASTER_TYPE.SUCCESS, toasterMessage);
  setState({
    isUploadModalVisible: false,
    isUploadModalLoading: false,
  });
};

const handleReloadPage = () => {
  window.location.reload();
};

const handleDelayedUploadTestCaseModalSubmitSuccess = ({ toasterMessage, getState, setState }) => {
  handleUploadTestCaseModalSubmitSuccess({ toasterMessage, getState, setState });
  setTimeout(handleReloadPage, UPLOAD_TEST_CASE_MODAL_SUCCESS_DELAY);
};


export const handleUploadTestCaseModalOnSubmit = async ({ getState, setState, params }) => {
  setState({ isUploadModalLoading: true });
  const { mediaId, formValues, mediaList } = params;
  const testCaseUploadRequestDTO = makeBaseTestCaseUploadRequestDTO(formValues, mediaId, mediaList);

  try {
    const response = await addUploadedTestCases(testCaseUploadRequestDTO);
    const toasterMessage = _get(response, 'data.data');
    handleDelayedUploadTestCaseModalSubmitSuccess({ toasterMessage, getState, setState });
    // window.location.reload();
  } catch (error) {
    HandleErrorAndStatus(error);
    setState({
      isUploadModalLoading: false,
      isUploadModalVisible: false,
    });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ON_TAB_CHANGE]: handleOnTabChange,
  [TABBED_TEST_CASE_LIST_ACTION_TYPES.REDIRECT_TO_CREATE_TEST_CASE]: handleRedirectToCreateTestCase,
  [TABBED_TEST_CASE_LIST_ACTION_TYPES.OPEN_UPLOAD_TEST_CASE_MODAL]: handleOpenUploadTestCaseModal,
  [TABBED_TEST_CASE_LIST_ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_CANCEL]: handleUploadTestCaseModalOnCancel,
  [TABBED_TEST_CASE_LIST_ACTION_TYPES.UPLOAD_TEST_CASE_MODAL_ON_SUBMIT]: handleUploadTestCaseModalOnSubmit,
};

export default ACTION_HANDLERS;
