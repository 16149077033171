import { CONSTANTS } from '../constants/Constants';

const { ACTIONS } = CONSTANTS;
const initialState = {
  perference: {},
  isFetchApiCalled: false,
};

/* - Reducer for User Details.
 * @params
 *  - prevState - object
 *  - action - object
 * @return
 *  - state - object
 */
export default (prevState, action) => {
  const state = prevState || initialState;
  switch (action.type) {
    case ACTIONS.FETCH_GET_USER_PERFERENCE_SUCCESS:
      return {
        ...state,
        perference: action.payload,
        isFetchApiCalled: true,
      };

    case ACTIONS.FETCH_GET_USER_PERFERENCE_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetchApiCalled: false,
      };
    default: return state;
  }
};
